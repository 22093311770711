
export function getBlogRow(blog: any, index: any)
{
    const concepCode:any = $("#conceptCode").val();
    const brandName:any = $("#brandName").val();
    if (concepCode === 'FSP' || concepCode === 'MLY'
       || concepCode === 'GUY' || concepCode === 'ASV'
       || concepCode === 'MRR' || brandName === 'mr-handyman' || concepCode === 'MRA')
    {
        var outhtml_img_desk = '';
        var outhtml_img_mob = '';
        if (typeof blog.custom_s_article_summary_Image_url != 'undefined' && blog.custom_s_article_summary_Image_url)
        {
            outhtml_img_desk = `<div class="left-img desk">
            <img src="${blog.custom_s_article_summary_Image_url}" alt="${blog.custom_t_content_summary_Image_url_alt}" fetchpriority="low" loading="lazy">
            </div>`
            outhtml_img_mob = `<div class="left-img mob">
            <img src="${blog.custom_s_article_summary_Image_url}" alt="${blog.custom_t_content_summary_Image_url_alt}" fetchpriority="low" loading="lazy">
            </div>`
        }
        var outhtml =
            `<li>
        ${outhtml_img_desk}
        <div class="right-box-wrap">
        <div class="right-box">
        <h4 class="list-title"><a href="${blog.custom_s_local_url}" class="text-clr text-lg">${blog.custom_s_heading}</a></h4>
        ${outhtml_img_mob}
        <p class="desc black-clr body-text">${blog.custom_t_content_summary_Desc} </p>
        </div>
        <div>
        <a href="${blog.custom_s_local_url}" class="right-arrow-link text-clr text-xs" aria-label="Read More">Read More</a>
        </div>
        </div>
        </li>`
        return outhtml;
    } else
    {
        var outhtml =
            `<div class="search-result-item">
                <div class="container">
                <div class="row text-contianer">
                <div class="col-md-8">
                <div class="blog-txt">    
                <h2 class="primary-title primary-theme-clr text-xlg">
                <a href="${blog.custom_s_local_url}" class="link-text primary-theme-clr">${blog.custom_s_heading}</a>
                </h2>
                <p class="body-text grey-clr">${blog.custom_t_content_summary_Desc} […] </p>
                <a href="${blog.custom_s_local_url}" class="link-text text-sm primary-theme-clr" aria-label="Read More" title="read more link">Read More</a>
            </div>
            </div>`;

        if (typeof blog.custom_s_article_summary_Image_url != 'undefined' && blog.custom_s_article_summary_Image_url)
        {

            outhtml = outhtml +
                `<div class="col-md-4">
                <div class="blog-img"> <a href="${blog.custom_s_local_url}" class="text-sm primary-theme-clr"><img src="${blog.custom_s_article_summary_Image_url}" alt="${blog.custom_t_content_summary_Image_url_alt}" class="img-fluid" width="370" height="210"></a> </div>
                </div>`;
        }

        outhtml = outhtml +
            `</div>
                     </div>
                     </div>`;
        return outhtml;
    }
}