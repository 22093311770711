(Number.prototype as any).degreeToRadius = function (this:any): number {
    return this * (Math.PI / 180);
};

(Number.prototype as any).radiusToDegree = function (this:any): any {
    return (180 * this) / Math.PI;
};

export function getBoundingBox(fsLatitude: any, fsLongitude: any, fiDistanceInKM: any): any[] {

    if (fiDistanceInKM == null || fiDistanceInKM == undefined || fiDistanceInKM == 0)
        fiDistanceInKM = 1;
    
    let MIN_LAT: any, MAX_LAT: any, MIN_LON: any, MAX_LON: any, ldEarthRadius: any, ldDistanceInRadius: any, lsLatitudeInDegree: any, lsLongitudeInDegree: any,
        lsLatitudeInRadius: any, lsLongitudeInRadius: any, lsMinLatitude: any, lsMaxLatitude: any, lsMinLongitude: any, lsMaxLongitude: any, deltaLon: any;
    
    // coordinate limits
    MIN_LAT = (-90 as any).degreeToRadius();
    MAX_LAT = (90 as any).degreeToRadius();
    MIN_LON = (-180 as any).degreeToRadius();
    MAX_LON = (180 as any).degreeToRadius();

    // Earth's radius (km)
    ldEarthRadius = 6378.1;

    // angular distance in radians on a great circle
    ldDistanceInRadius = fiDistanceInKM / ldEarthRadius;

    // center point coordinates (deg)
    lsLatitudeInDegree = fsLatitude;
    lsLongitudeInDegree = fsLongitude;

    // center point coordinates (rad)
    lsLatitudeInRadius = lsLatitudeInDegree.degreeToRadius();
    lsLongitudeInRadius = lsLongitudeInDegree.degreeToRadius();

    // minimum and maximum latitudes for given distance
    lsMinLatitude = lsLatitudeInRadius - ldDistanceInRadius;
    lsMaxLatitude = lsLatitudeInRadius + ldDistanceInRadius;

    // minimum and maximum longitudes for given distance
    lsMinLongitude = undefined;
    lsMaxLongitude = undefined;

    // define deltaLon to help determine min and max longitudes
    deltaLon = Math.asin(Math.sin(ldDistanceInRadius) / Math.cos(lsLatitudeInRadius));

    if (lsMinLatitude > MIN_LAT && lsMaxLatitude < MAX_LAT) {
        lsMinLongitude = lsLongitudeInRadius - deltaLon;
        lsMaxLongitude = lsLongitudeInRadius + deltaLon;
        if (lsMinLongitude < MIN_LON) {
            lsMinLongitude = lsMinLongitude + 2 * Math.PI;
        }
        if (lsMaxLongitude > MAX_LON) {
            lsMaxLongitude = lsMaxLongitude - 2 * Math.PI;
        }
    }

    // a pole is within the given distance
    else {
        lsMinLatitude = Math.max(lsMinLatitude, MIN_LAT);
        lsMaxLatitude = Math.min(lsMaxLatitude, MAX_LAT);
        lsMinLongitude = MIN_LON;
        lsMaxLongitude = MAX_LON;
    }

    return [
        lsMinLatitude.radiusToDegree(),
        lsMinLongitude.radiusToDegree(),
        lsMaxLatitude.radiusToDegree(),
        lsMaxLongitude.radiusToDegree()
    ];
};

export function getDistanceFromLatLonInKm(lat1:any,lon1:any,lat2:any,lon2:any,unit:any) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    let radlat1 = Math.PI * lat1/180;
    let radlat2 = Math.PI * lat2/180;
    let theta = lon1-lon2;
    let radtheta = (Math.PI * theta)/180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180/Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit==="km") { dist = dist * 1.609344 }
    return dist;
}
