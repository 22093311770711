import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiCaching } from "../config/cachingConfig";

export async function commonAjaxCallfunctionHidingMenu(galleryapi: any, headers: any, successHandler: any)
{
    try
    {
        const data = await getCachedAPIResponse(galleryapi, apiCaching.DynamicMenu);
        successHandler(data);
    } catch (error: any)
    {
        console.error(`Error in fetching Meet the Team api response Error message: ${error}`);
    }
}