import { $ } from "../third-party-imports";
import {refreshScheduleData} from '../util/refreshScheduleData';
import {mrApplianceBrandList} from '../util/mrApplianceBrandList';
import {schedulerState} from '../scheduler/scheduler';
import {commercialFlow} from '../scheduler/commercialFlow';
export function servicePage(){
    var serviceId = $('#serviceId').val();
    window.objOSGlobal.requestBodyValues.serviceSkuId = serviceId;
    if($("#brandName").val() == "mr-appliance"){
        $('.warranty-screen').addClass('active');
        $('.scheduler-modal .modal-content').addClass('banner-wizard');
        mrApplianceBrandList((data:any,status:any)=>{
            var mrBrandList:any = [];
                $.each(data, function (index:any, element:any){
                   mrBrandList.push('<option id="' + element.makeId + '" value="' + element.makeCode + '">'+element.makeName+'</option>');
                });
                $('#warrantyDetails #referenceDetails').empty();
                $('#warrantyDetails #referenceDetails').append('<option value="">- select one</option>')
                $('#warrantyDetails #referenceDetails').append(mrBrandList);
             });
        return;
    }
    if($("#brandName").val() == "molly-maid"){
        // $('.how-often-screen').addClass('active');
        // $('.scheduler-modal .modal-content').addClass('banner-wizard');
        commercialFlow();
        return;
    }
    if(!window.objOSGlobal.requestBodyValues.IsLeadOnly){
        schedulerState.numberOfSlot = 1;
        refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
        $('.calendar-screen').addClass('active');
        $('.scheduler-modal .modal-content').removeClass('banner-wizard');
        $('.progress-bar').css({ width:'3.2%' });
     }else{
        $('.nav-wizard.progressbar').addClass('hide-schedule');
        $('.contact-information-screen').addClass('active');
        $('.progress-bar').css({ width:'3.2%' });
        $('.scheduler-modal .modal-content').removeClass('banner-wizard');
     }
}