export function serviceMenuWebCatgoryProtypePage(root:any){
    if(root){
        let url = window.location.pathname;
        let sideMenuCategory:any = [];
        let rootMenus = root.find(function fn(item:any) {
            return item.link === url || (item.category && item.category?.find(fn)) ||(item.menu && item.menu?.find(fn)) || (item.service && item.service?.find(fn));
        });
        if(!rootMenus){
            return;
        }
        checkModifier(rootMenus,url);
        $('.sidebar-left h5').text(rootMenus.name);
        $('.sidebar-left .mega-menu-front').empty();
        var category = rootMenus.services || rootMenus.category;
        category.forEach((cat:any,index:any)=> {
            sideMenuCategory.push(generateServiceSideMenuCategory(cat,index));
        });
        $('.sidebar-left .mega-menu-front').append(sideMenuCategory);
        let hasId = window.location.hash;
        if(hasId !=''){
            $('html, body').animate({
                scrollTop: $(hasId)?.offset()?.top
              }, 500);
        }
    }
}

function generateServiceSideMenuCategory(cat:any,index:any){
    $('.services-list').empty();
    let actCls= cat.actCls?'active':'';
    if(cat.serviceType.toLowerCase() =="otherservice"){
        if(cat.category.length == 0){
            return `<li class="list-item ${actCls}"> 
                  <a href="${cat.link}">${cat.name}</a>
              </li>`
        }else{
            return `<li class="list-item ${actCls}">
                        <a href="${cat.link}">${cat.name}</a>
                        <ul class="submenu other" id="cat-${index}">${otherserviceSideList(cat,index)}</ul>
                    </li>`
        }
    }else{
        if(cat?.menu?.length == 0){
            return `<li class="list-item ${actCls}"> 
                  <a href="${cat.link}">${cat.name}</a>
              </li>`
        }else{
            return `<li class="list-item ${actCls}">
                  <a href="${checkMenuLength(cat)}">${cat.name}</a>
                  <ul class="submenu" id="cat-${index}">${generateServiceMenuSideBar(cat,index)}</ul>
              </li>`
        }
    }
}


function generateServiceMenuSideBar(cat:any,index:any){
    let menuList:any = [];
    cat?.menu?.forEach((mn:any,index:any)=> {
        menuList.push(generateMenuList(mn,index));
    });
    return menuList.join('');
}


function generateMenuList(singleMenu:any,index:any){
    let actCls= singleMenu.actCls?'active':'';
    if(singleMenu?.service?.length == 0){
        return `<li class="list-item ${actCls}">
            <a href="${singleMenu?.link}">${singleMenu?.name}</a>
         </li>`
    }else{
        return `<li class="list-item ${actCls}">
            <span class="dropdownArrow"></span>
            <a href="${singleMenu?.link}">${singleMenu?.name}</a>
            <ul class="inner-submenu" id="menu-${index}">
                 ${generateServicesSideBar(singleMenu,index)}
            </ul>
        </li>`
    }
}

function generateServicesSideBar(singleMenu:any,index:any){
    let serList:any = [];
    singleMenu?.service?.forEach((ser:any,index:any)=> {
        serList.push(generateServicesList(ser,index));
    });
    return serList.join('');
}

function generateServicesList(ser:any,index:any){
    let actCls= ser.actCls?'active':'';
    return `<li class="list-item ${actCls}">
        <a href="${ser?.link}">${ser?.name}</a>
     </li>`
   
}

let findValue = (arr:any, val:any) => {
    for(let obj of arr){
        if (obj.link === val) {
            obj.actCls = true;
            return obj;
        }
        if(obj.category){
            if(obj.category.serviceType?.toLowerCase()=='otherservice'){
                let result:any = findValue(obj.category?.category, val);
                if (result) {
                    obj.actCls = true;
                    return result;
                }

            }else{
                let result:any
                if(obj.serviceType.toLowerCase() =='otherservice'){
                    result = findValue(obj.category, val);
                    if (result) {
                        obj.actCls = true;
                        result.type = 'otherservice';
                        return result;
                    }
                }else{
                    result = findValue(obj.category, val);
                    if (result) {
                        obj.actCls = true;
                        return result;
                    }
                }
                
            }
            
        }
        if(obj.menu){
            let result:any = findValue(obj.menu, val);
            if (result) {
                obj.actCls = true;
                return result;
            }
        }
        if(obj.service){
            let result:any = findValue(obj.service, val);
            if (result) {
                obj.actCls = true;
                return result;
            }
        }
    }
    return undefined;
};

function checkModifier(root:any,path:any){
    let filterData:any;
    if (root?.link === path) {
        filterData = root;
    }else{
        filterData = findValue(root.category,path);
    }
    if(filterData){
        let type = filterData?.serviceType.toLowerCase().split(' ').join('');
        // let otherType = filterData.type;
        // if(otherType !='otherservice'){
            if(type == "protype" || type=="servicewebcategory" || type=="servicemodifier" || type=="servicegroup" || type == "otherservice"){
                if(filterData.category?.length > 0){
                    checkingProperty(filterData?.category);
                }else{
                    if(filterData.menu?.length > 0){
                        checkingProperty(filterData?.menu);
                    }else{
                        checkingProperty(filterData?.service);
                    }
                } 
            }
        // }
        
    }
}


function listGeneratorAll(data:any){
    if(data.thumbnail !=''){
        return `<div class="row row-list" id="${data?.hashid ? data?.hashid.replace('#','') : ''}">
        <div class="col-md-7">
        <h2 class="primary-title primary-theme-clr text-xmd"> ${data?.name} </h2>
        <div class="search-results-image">
         <img class="img-fluid w-100" src="${data.thumbnail}" alt="${data?.name}">
        </div>
        <p class="description">${data.description}</p>
        ${data.description !="" ? `<a href="${data?.link}" class="link-text text-sm primary-theme-clr ${data?.hashid ? 'd-none' : ''}">Read More</a>`:''}
        </div>
        <div class="col-md-5">
        <div class="search-results-image">
            <img class="img-fluid w-100" src="${data.thumbnail}" alt="${data?.name}">
        </div>
        </div>
    </div>`
    }else{
        return `<div class="row row-list" id="${data?.hashid ? data?.hashid.replace('#','') : ''}">
        <div class="col-md-12">
        <h2 class="primary-title primary-theme-clr text-xmd"> ${data?.name} </h2>
        <p class="description">${data.description}</p>
        ${data.description !="" ? `<a href="${data?.link}" class="link-text text-sm primary-theme-clr ${data?.hashid ? 'd-none' : ''}">Read More</a>`:''}
        </div>
    </div>`
    }
}


function checkingProperty(data:any){
    if(data?.length > 0){
        let listArr:any = [];
            for (let i = 0; i < data.length; i++){
                listArr.push(listGeneratorAll(data[i]));
            }
        $('.service-result').empty();
        $('.service-result').append(listArr);
   }
}

function otherserviceSideList(cat:any,index:any){
    let catList:any = [];
    cat?.category?.forEach((ct:any,index:any)=> {
        catList.push(generateOtherCategoryList(ct));
    });
    return catList.join('');
}

function generateOtherCategoryList(otherMenu:any){
    let actCls= otherMenu.actCls?'active':'';
        return `<li class="list-item ${actCls}">
            <a href="${otherMenu?.link}">${otherMenu?.name}</a>
         </li>`
}
$(document).on('click','.dropdownArrow',function(this:any){
    if($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
    }
     else {
        $('.sidebar-left .mega-menu-front > .list-item .submenu > .list-item').removeClass('active');
        $(this).parent().addClass('active');
    }
});


export function checkMenuLength(cat:any){
    let link:any;
    if(cat?.menu?.length>1){
        link= cat.link;
    }else{
        link = cat?.menu[0]?.link;
    }
   return link;
}