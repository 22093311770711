export function generateServiceHTML(serviceItem:any,catServiceType:any) {
    let linkTarget = serviceItem.openNewTab ? "target='_blank'" : "";
    return `<li class="inner-list-item">
    <a class="dropdown-item" href="${serviceItem.link}" ${linkTarget}>${serviceItem.name}</a>
   </li>`
}
export function generateServiceHTMLForOtherService(otherCatList:any) {
        let categoryList:any = [];
        otherCatList?.forEach((mn:any, inedx:any)=>{
            categoryList.push(createOthCategory(mn))
        })
        return categoryList.join('');
}

function createOthCategory(item:any) {
    let linkTarget = item.openNewTab ? "target='_blank'" : "";
    return `<li class="inner-list-item">
    <a class="dropdown-item" href="${item.link}" ${linkTarget}>${item.name}</a>
   </li>` 
}