export function getServiceTypeString(serviceType:any){
    switch(serviceType){
        case "auto":
        serviceType = '1' 
        break;
        case "Emergency":
        serviceType = "2";
        break;
        case "Commercial":
        serviceType = "3";
        break;
        default:
        serviceType = "4";
    }
   return serviceType;
}