import {$} from "../third-party-imports";
import {GetReferenceDetails} from '../util/GetReferenceDetails';
export function commercialFlow(){
    let modifier = window.objOSGlobal.requestBodyValues.modifier;
    if(modifier?.toLowerCase() ==='commercial'){
        $(".scheduler-slide .progressbar").removeClass("serviceList-bar");
        $('.progressbar li a[data-step="4"]').html('About <span>Your Office</span>');
        $('.about-home-screen').children('.cust-mly-h2-cls').text('About Your Office');
        $('#aboutHome label[for="inputbedrooms"]').text('Offices *');
        $('.progressbar').addClass('modifierCls hide-servicesList');
        $(".scheduler-slide .progressbar").addClass("hide-estimate");    
        $('.scheduler-modal .modal-content').removeClass('banner-wizard');
        $('.schedule-list').hide();
        $('.tab-pane.fieldset').removeClass('active');
        $('.about-home-screen').addClass('active');
        $('.progress-bar').css({ width:'3.2%' });
        $('.progressbar li > a[href="#step4"]').parent().addClass('isLeadABoutCls');
        GetReferenceDetails();
        $(".scheduler-slide .progressbar").addClass("commercial");
    }
}