import { Api_Error_Msg } from '../config/constant';
import { animation } from '../util/refreshScheduleData';
export function bookingFailureHandlerData(status:any){
        animation.modalFlag = true;
        if($("#brandName").val() === 'mr-handyman' || $("#brandName").val() === 'my-handyman'){
        $(".modal-header-one").addClass("d-none");
        }
        $('.result-stack-logo').show();
        $('.progressbar, .progress').hide();
        $(".tab-pane.fieldset").removeClass('active');
        $("#steptwo").addClass('active');
        $('.scheduler-modal .modal-content').addClass('banner-wizard');
        $('#steptwo .head-title.primary-theme-clr').html(Api_Error_Msg);
        $('.modal').on('hidden.bs.modal', function (){
            if (animation.modalFlag){
                location.reload();
            }
        });
}