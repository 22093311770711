import { apiConfig, getMSALInstance } from "../../config";
import { logout } from "./logout";
import { isRememberMe } from "./isRememberMe";
import { updateUI } from "../ui";
import { callApiWithAccessToken, HEADERS, updateLogin } from "../api";
import { nestLocalStorage } from '../../config'
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";

// calls the resource API with the token


export const acquireToken = (request: any) =>
{
    const myMSALObj = getMSALInstance();
    if (isRememberMe())
    {
        return myMSALObj.acquireTokenSilent(request).then((response) =>
        {
            if (response.accessToken)
            {
                var accessToken = response.accessToken;
                if (accessToken)
                {
                    nestLocalStorage.accessToken = accessToken;
                    try
                    {
                        // let decodeData = jwtParser.decodeToken(accessToken);
                        // let neighborlyUserId = decodeData.neighborlyUserId;
                        let optionKey = {
                            endpoint: apiConfig.profileApi,
                            token: accessToken,
                            neighborlyUserId: 0,//neighborlyUserId,
                        };
                        callApiWithAccessToken(optionKey).then(() =>
                        {
                            updateUI();
                            const updateLoginKey = {
                                endpoint: apiConfig.updateLoginApi+"&"+getNblyApiHeaders(),
                                socialProfileDomain: 1,
                                //neighborlyUserId: 0, //neighborlyUserId,
                                method: "post",
                                header: HEADERS
                            };
                            updateLogin(updateLoginKey);
                        });
                    } catch (err)
                    {
                        //console.log(err);
                    }
                }
            }
        }).catch(error =>
        {
            // console.log(error);
            logout();
            //return myMSALObj.acquireTokenRedirect(request);
        });
    }
    else
    {
        //return myMSALObj.acquireTokenRedirect(request);
        logout();
        //myMSALObj.loginRedirect(loginRequest);
        //return Promise.resolve();
    }
}
