import { createColumnSection } from "./create-column-section";
import { dynamicServiceArrayRowGenrator } from "./dynamic-service-array-row-genrator";
import { dynamicServiceGenrator } from "./dynamic-service-genrator";
import { enableImageObj } from "./global-variable";
import { menuGeneateListArray } from "./menu-geneate-list-array";
import { wrapMenuNormalList } from "./wrap-menu-normal-list";
import { wraplistComponent } from "./wraplist-component";
import { detectMob } from "../util/detect-mob";

export function menuGenrator(categoryMenu:any,type:any){
    let mob = detectMob();
    let catgoryArrayList:any = [];
    let serviceArrayList:any = [];
    let wrapMenuList:any = [];
    if(enableImageObj.enableImage){
        wrapMenuList.push(wraplistComponent(type));
        // $('.dropdown.menu-large a[data-title='+type.name+'] + .dropdown-menu.megamenu').remove();
        // $('.dropdown.menu-large a[data-title='+type.name+']').parent().append(wrapMenuList);
        if (!mob) {
            $('.dropdown.menu-large a[href="'+type.link+'"] + .dropdown-menu.megamenu').remove();
            $('.dropdown.menu-large a[href="'+type.link+'"]').parent().append(wrapMenuList);
        } else {
            $('.dropdown.menu-large a[data-mobLink="' + type.link + '"] + .dropdown-menu.megamenu').remove();
            $('.dropdown.menu-large a[data-mobLink="' + type.link + '"]').parent().append(wrapMenuList);
        }
        
        for(let i =0;i<categoryMenu.length;i++){
            catgoryArrayList.push(menuGeneateListArray(categoryMenu[i],type));
            serviceArrayList.push(dynamicServiceArrayRowGenrator(categoryMenu[i],type));
        }
        $('.mega-menu-front.'+ type.name).append(catgoryArrayList);
        $('.mega-menu-links.'+ type.name).append(serviceArrayList);
        for(let i =0;i<categoryMenu.length;i++){
            dynamicServiceGenrator(categoryMenu[i],type);
        }
    }else{
        wrapMenuList.push(wrapMenuNormalList(type));
        // $('.dropdown.menu-large a[data-title='+type.name+'] + .dropdown-menu.megamenu').remove();
        // $('.dropdown.menu-large a[data-title='+type.name+']').parent().append(wrapMenuList);
        if (!mob)
        {
            $('.dropdown.menu-large a[href="' + type.link + '"] + .dropdown-menu.megamenu').remove();
            $('.dropdown.menu-large a[href="' + type.link + '"]').parent().append(wrapMenuList);
        } else
        {
            $('.dropdown.menu-large a[data-mobLink="' + type.link + '"] + .dropdown-menu.megamenu').remove();
            $('.dropdown.menu-large a[data-mobLink="' + type.link + '"]').parent().append(wrapMenuList);
        }
        createColumnSection(categoryMenu,type);   
    }
}