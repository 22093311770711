import {$} from "../third-party-imports";
export function getAboutHomeDetails(){
    var bedrooms = $('#inputbedrooms option:selected').val();
    var bathrooms = $('#inputbathrooms option:selected').val();
    var SquareFeet = $('#inputsquarefeet').val();
    var modifier = sessionStorage.getItem("ServiceModifierValue");
        window.objOSGlobal.requestBodyValues.EstimateSquareFeet = SquareFeet;
        window.objOSGlobal.requestBodyValues.EstimateBedrooms = bedrooms;
        window.objOSGlobal.requestBodyValues.EstimateBathrooms = bathrooms
        window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = $('#referenceDetails option:selected').val();
        if(modifier =='Commercial'){
            let mlyHomeDetailOffice = bedrooms + ' Office(s) '+bathrooms+' Bath(s) <br/>' + SquareFeet + ' sq. ft.';
            sessionStorage.setItem('MlyHomeDetails', mlyHomeDetailOffice);
        }else{
            let mlyHomeDetail = bedrooms + ' Bedroom(s) '+bathrooms+' Bath(s) <br/>' + SquareFeet + ' sq. ft.';
            sessionStorage.setItem('MlyHomeDetails', mlyHomeDetail);
        }
}