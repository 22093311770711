import { nestLocalStorage } from '../../config'

export function isAccessTokenExpire()
{
    if (!nestLocalStorage.accessToken)
    {
        return true;
    }
    let decodeData = nestLocalStorage.decodedAccessToken;
    let exp = decodeData.exp;
    let currentTime = Math.floor(Date.now() / 1000);
    return currentTime > exp;
}
