import { $ } from "../third-party-imports";
import {mrApplianceBrandList} from '../util/mrApplianceBrandList';
// import {getApplianceProblemList} from '../util/util';
import {schedulerState} from '../scheduler/scheduler';
import {storeProTypeID} from '../services/storeProTypeID';
import { commercialFlow } from "../scheduler/commercialFlow";
export function setText(element:any){
    var text = $(element).parent().text();
    var value = $(element).parent().val();
    var serviceId = $(element).parent().attr('id');
    $('input[name="serviceListQuery"]').val(text);
    window.objOSGlobal.requestBodyValues.serviceSkuId = value;
    window.objOSGlobal.requestBodyValues.ServiceId = serviceId;
    window.objOSGlobal.requestBodyValues.ServiceSkuIds = [value];
    window.objOSGlobal.requestBodyValues.serviceName = text;
    var nextId = $(element).parents('.service-search-screen').next().attr("id");
    var formId = $("#"+nextId).find("form").attr('id');
    if(formId =='warrantyDetails'){
        $('[href="#' + nextId + '"]').tab('show');
        $('.scheduler-modal .modal-content').addClass('banner-wizard');
        $('#warrantyDetails .service-search-submit').css('pointer-events', 'none');
        mrApplianceBrandList((data:any,status:any)=>{
            var mrBrandList:any = [];
                $.each(data, function (index, element){
                   mrBrandList.push('<option id="' + element.makeId + '" value="' + element.makeCode + '">'+element.makeName+'</option>');
                });
                $('#warrantyDetails #referenceDetails').empty();
                $('#warrantyDetails #referenceDetails').append('<option value="">- select one</option>')
                $('#warrantyDetails #referenceDetails').append(mrBrandList);
             });
    }
    if($("#brandName").val() == "mr-appliance"){
        // getApplianceProblemList(value);
        return;
    }
    if($("#brandName").val() == "molly-maid"){
       $(".tab-pane.fieldset").removeClass("active");
       $(".how-often-screen").addClass("active");
       commercialFlow();
       return;

    }
    schedulerState.numberOfSlot = 1;
    storeProTypeID(value)
    //refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
    //$('.progress-bar').css({ width:'3.2%' });
    //$('.scheduler-modal .modal-content').removeClass('banner-wizard');
    //$(".tab-pane.fieldset").removeClass("active");
    //$(".calendar-screen").addClass("active");
}