import { checkMenuLength } from "../../search-service/search-web-category-protype";

export function mobileMenuModifier(root:any){
    intialJsCallforMobile();
    let catgoryList:any = [];
    let menuList:any = [];
    root?.category.forEach((cat:any,index:any)=>{
        catgoryList.push(secondMobileMenu(cat,index));
    });
    root?.category.forEach((cat:any,index:any)=>{
        menuList.push(megaMobileMenuLinks(cat,index,root));
    })   
      return   `<ul class="mega-menu-front js-mega-menu-front secound-menu">
                     ${catgoryList.join('')}
                </ul>
                <div class="mega-menu-links">
                    ${menuList.join('')}
                 </div>`
}

export function secondMobileMenu(cat:any,index:any){
    if(cat){
        return`<li class="list-item mob-top-menu-cat">
                <button class="js-mega-menu-btn arrow-right-link" data-target="cat-${index}">
                    <img src="${cat?.icons.icon}" alt="${cat?.icons.iconAlt}" class="img-hover" fetchpriority="low" loading="lazy" width = "50px" height = "50px">
                    <span class="custom-sr-only"><span class="sr-only">Click to view &nbsp;</span>${cat.name}</span>
                </button>
            </li>`;
    }
}


export function megaMobileMenuLinks(cat:any,index:any,root:any){
    let mobileMenuListArray:any = [];
    let mobileServiceListsArray:any = [];
    let wraperComponent:any;
    if(cat.serviceType.toLowerCase() !== "OtherService".toLowerCase()) {
    cat?.menu?.forEach((mn:any,index:any)=>{
        mobileMenuListArray.push(mobileMenuLists(mn,index));
    })

    cat.menu?.forEach((mn:any,index:any)=>{
        mobileServiceListsArray.push(mobileServiceLists(mn,index));
    });
    wraperComponent = `<div class="inner-menu-row show move_close">
    <div class="menu-col">
      <button class="back-arrow-link js-backMenu-link" aria-label="Back to main menu">Back</button>
      <span class="overview-link">
        <a href="${checkMenuLength(cat)}">${cat.name}</a>
      </span>
      <div class="screen-three">
        <div class="row">
          <div class="col-lg-3">
            <div class="sub-menu-left">
              <ul class="inner-menu-list-three col-list">
                ${mobileMenuListArray.join('')}
              </ul>
            </div>
          </div>
          <div class="col-lg-9">
            <div class="sub-menu-right">
            ${mobileServiceListsArray.join('')}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>` 
  } else {
    let othermobileMenuListArray:any = [];
    othermobileMenuListArray.push(otherMobileMenuLists(cat));
    wraperComponent = `<div class="inner-menu-row show move_close">
    <div class="menu-col">
      <button class="back-arrow-link js-backMenu-link" aria-label="Back to main menu">Back</button>
      <h2 class="overview-link">
        <a href="${cat.link}">${cat.name}</a>
      </h2>
      <div class="screen-three">
        <div class="row">
          <div class="col-lg-12">
            <div class="sub-menu-right">
            ${othermobileMenuListArray.join('')}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>` 
  }
    return `<div class="js-mega-menu-cont2" id="cat-${index}">
      <!-- Breadcrumb start -->
      <nav class="desktop-item" aria-label="breadcrumb">
        <ul>
          <li>
            <a href="javascript:void(0)" class="js-backMenu-link">${root.name}</a>
          </li>
         ${categoryList(cat)}
        </ul>
      </nav>
      <!-- Breadcrumb end -->
      ${wraperComponent}
    </div>`
}


function categoryList(cat:any){
    return `<li class="active" aria-current="page">
                <a class="nav-title" href="#">${cat.name}</a>
            </li>`
}


function mobileMenuLists(menu:any,index:any){
    
    let dynamicId = '#'+menu?.name?.replace(/[^A-Z0-9]+/ig, "").toLowerCase();
    return `<li class="three-list-item ${index==0 ? 'active' :''}">
    <a class="dropdown-item" href="${dynamicId}">${menu.name}</a>
  </li>`
}


function mobileServiceLists(menu:any,index:any){
    let dynamicId = menu?.name?.replace(/[^A-Z0-9]+/ig, "").toLowerCase();
    let mobileServiceListsArr:any = [];
    menu.service?.forEach((ser:any,index:any)=>{
        mobileServiceListsArr.push(serviceLiListForMobile(ser,index));
    });
    return ` <div id="${dynamicId}" class="repair-menu ${index==0 ? 'current':''}">
    <h5>
    <a href="${menu.link}" class= "menu-mob-clr">${menu.name}</a>
    </h5>
    <ul class="">
      ${mobileServiceListsArr.join('')}
    </ul>
  </div>`
}

function serviceLiListForMobile(ser:any,index:any){
    return `<li>
          <a href="${ser.link}" class="nav-link">${ser.name}</a>
         </li>`
}

function otherMobileMenuLists(othCat:any){
    let dynamicId = othCat?.name?.replace(/[^A-Z0-9]+/ig, "").toLowerCase();
    let mobileServiceListsArr:any = [];
    othCat.category?.forEach((ser:any,index:any)=>{
        mobileServiceListsArr.push(serviceLiListForMobile(ser,index));
    });
    return ` <div id="${dynamicId}" class="repair-menu current">
    <ul class="">
      ${mobileServiceListsArr.join('')}
    </ul>
  </div>`
}
function intialJsCallforMobile(){
    $(document).on("keydown", function(event:any) {
        if (event.keyCode === 27) {
            $(".navbar-collapse").removeClass("show");
            $(".current").removeClass("current");
            $("body").removeClass("overlay-active");
        }
    });

    // $("[data-trigger]").on("click", function() {
    //     let trigger_id = $(this).attr("data-trigger");
    //     $(trigger_id).toggleClass("show");
    //     $("body").toggleClass("offcanvas-active");
    //     $(".js-dropdown").removeClass("active");
    //     $(".js-dropdown").removeClass("show");
    //     $(".megamenu").removeClass("show");
    //     $(".js-dd-inner").removeClass("active");
		
    //     $('.navbar-collapse').find('.js-mega-menu-cont2').removeClass('current');
    // });


    $(document).on('click', '.secound-menu > li > button', function(){
        let $this = $(this);
        let $target = $(this).data('target');
        let $parent = $this.parents('.secound-menu');
        $parent = $parent.parent();
        $parent.find('.mega-menu-links > div').removeClass('current');
        $parent.find('.mega-menu-links').find('#' + $target).addClass('current');
    });
    

    $(document).on('click', '.screen-three .sub-menu-left .inner-menu-list-three .dropdown-item', function(e){
        let $this = $(this);
        let $target = $(this).attr('href');
        let $parent = $this.parents('.screen-three');
        $parent.find('.sub-menu-right > div').removeClass('current');
        $parent.find('.sub-menu-right').find($target).addClass('current');

        $this.parent().parent().find('.active').removeClass('active');
        e.preventDefault();
        $this.parent().addClass('active');
    });

    $(document).on("click", "button.arrow-right-link", function() {
        $(this).parent(".js-dd-inner").addClass("active");
    });
    
    //for Top most inner wrapper
    $(document).on("click", ".list-item", function() {
        $(this).parent().siblings('.mega-menu-links').find('.inner-menu-row').addClass('show');
        $(this).parent().siblings('.mega-menu-links').find('.inner-menu-row').removeClass('move_close');
    });
    
    // For sliding inner menu
    $(document).on("click", ".inner-menu-row .back-arrow-link", function() {
        $(this).parents('.inner-menu-row').addClass('move_close');
    });

    $(document).on('click', '.current .js-backMenu-link', function(){
        let $this = $(this);
        let $parent = $this.parents('.js-mega-menu-cont2');
        //$parent = $parent.parent();
        $parent.removeClass('current');
    });

    $(document).on('click','.js-mega-menu-btn', function() {
        let target_menu = "#" + $(this).data("target");
        $('.js-mega-menu-front').hide();
        $(".js-mega-menu-cont").not(target_menu).hide();
        $(target_menu).show();
    });

    $(document).on('click','.js-backMenu-link', function() {
        $('.js-mega-menu-cont').hide();
        $('.js-mega-menu-front').show();
        $(this).parent().parent('.inner-menu-row.dropdown-menu').removeClass('show');
    });
}
