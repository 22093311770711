import { $ } from "../third-party-imports"
import { getMultipleData } from "./getMultipleData";
import { profileVars } from ".";



export function setMultipleContact(profile: any)
{
    getMultipleData(profile.phone, function (value: any)
    {
        return value;
    }, function (value: any, index: any)
    {
        var phoneNumber = value.contactDetail.match(/(\d{3})(\d{3})(\d{4})/);
        if (phoneNumber != null)
        {
            phoneNumber = "(" + phoneNumber[1] + ") " + phoneNumber[2] + "-" + phoneNumber[3];
        }
        else
        {
            phoneNumber = value.contactDetail;
        }
        $('.profilePhone .addlink').css("display", "block");
        if (index === 2)
        {
            $('.profilePhone .addlink').css("display", "none");
        }
        $('.profilePhone').append('<div class="text-withedit">' +
            '<div class="label-name">' +
            '<div class="labelTxtBox">' +
            '<div class="labelTxt">' + phoneNumber + '</div>' +
            (value.contactVerificationStatusId == 2 ?
                '<div class="labelWithImg">' +
                '<img src="/us/en-us/_assets/images/Shield-Outline.svg" alt="Shield Outline" fetchpriority="low" loading="lazy" />' +
                '<img src="/us/en-us/_assets/images/register-icons/noun_Check.svg" alt="noun check" fetchpriority="low" loading="lazy">' +
                '</div>' : '') +
            '</div>' +
            (value.primaryPhoneID == 1 ?
                '<div class="labelPrimary">' +
                '<div class="primaryWithStar">' +
                '<img src="/us/en-us/_assets/images/Gold-Star-Outline.svg" alt="gold star" fetchpriority="low" loading="lazy">Primary' +
                '</div>' +
                '</div>' : '') +
            '</div>' +
            '<div class="editBox">' +
            '<a href="#" class="link-btn editLink" data-attr="editPhone" data-index="' + index + '">Edit</a>' +
            '</div>' +
            '</div>'
        );
    }, function (arrayVal: any)
    {
        $(".profilePhone a.editLink").click(function ()
        {
            profileVars.allowSubmit = true;
            profileVars.deleteButton = true;
            if (arrayVal.length == 1)
            {
                $('#editPhone .delete').css('display', 'none');
            }
            else
            {
                $('#editPhone .delete').css('display', 'block');
            }
            //$('#js-popupModalDialog span.delete').css("display", "block");
            $("#editPhone .top_txt h3").text('Edit Phone');
            $('.form_container_wrapperBox:first-child').hide();
            var index = $(this).attr("data-index");
            var obj = arrayVal[index];
            var phoneNumber = obj.contactDetail.match(/(\d{3})(\d{3})(\d{4})/);
            if (phoneNumber != null)
            {
                phoneNumber = "(" + phoneNumber[1] + ") " + phoneNumber[2] + "-" + phoneNumber[3];
            }
            else
            {
                phoneNumber = obj.contactDetail;
            }
            $('#editPhone').addClass('active');
            $('#phoneApplyBtn').attr('data-edit', obj.neighborlyAccountContactId);
            $('#phone1').val(phoneNumber);
            $("#customCheck-nb10").prop("checked", obj.primaryPhoneID == 0 ? false : true);
            $("#customCheck-nb10").prop("disabled", obj.primaryPhoneID == 0 ? false : true);
            $("#customCheck-nb11").prop("checked", obj.isOptIn == 0 ? false : true);
            $("#editPhone .delete").css("display",obj.primaryPhoneID == 0 ? 'block' :'none');
            $('#editPhone .delete').attr("data-nblyPhoneId", obj.neighborlyAccountContactId);

        });
    });
}
