import {$} from "../third-party-imports";
import {GetReferenceDetails} from './GetReferenceDetails';
import {getStateFun} from './getStateFun';
import { startLoader } from "./startLoader";
import { stopLoader } from "./stopLoader";
import { fullAddressRoundRobinApi } from "./fullAddressRoundRobinApi";
import { GetLeadSourceMolly } from "./GetLeadSourceMolly";

export function EstimateForm(){
    $(".contact-form #mobile-check").prop('checked', true);
    let zipCode:any = localStorage.getItem("zipcode") ? localStorage.getItem("zipcode") : ""
    $("#estimateInputZipCode").val(zipCode);
    GetReferenceDetails();
    getStateFun();
    formatPhoneNumber();
 }

 function formatPhoneNumber() {
    var inputs = document.querySelectorAll("#estimateForm input[name='estinputPhnNumber']");
    if (inputs)
    {
        for (var i = 0; i < inputs.length; i++)
        {
            var element = inputs[i];
            element.addEventListener('input', function (e:any)
            {
                var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            });
        }
    }
}
 export function estFormBookingApiCall() {
    let FirstName:any = $("#estimateForm input[name='estimateFirstName']").val();
    let LastName:any =  $("#estimateForm input[name='estimateLastName']").val();
    let Email:any =     $("#estimateForm input[name='estimateEmail']").val();
    let Phone:any =     $("#estimateForm input[name='estinputPhnNumber']").val();
    let Address:any =   $("#estimateForm input[name='estimateInputAddress']").val();
    let Address2:any =  $("#estimateForm input[name='estimateInputAddress2']").val();
    let City:any =      $("#estimateForm input[name='estimateInputCity2']").val();
    let State:any =     $('select[name="estimateInputState"] option:selected').val();
    let PostalCode:any = $("#estimateForm input[name='estimateInputZipCode']").val();
    let EstimateSquareFeet:any, EstimateBedrooms:any,EstimateBathrooms:any,NeighborlyLeadSourceId:any = null,LeadSourceId:number=0;
    if($("#estimateSqf")[0]) {
         EstimateSquareFeet = $("#estimateForm input[id='estimateSqf']").val();
         EstimateBedrooms =   $('#estimateBedroomsOne option:selected').val();
         EstimateBathrooms =   $('#estimateBedroomsTwo option:selected').val();
         LeadSourceId = $('#estimateBedroomsThree option:selected').val();
    }else {
        EstimateSquareFeet = "";
        EstimateBedrooms = "";
        EstimateBathrooms = "";
    } 
    
    storeFormFieldToSession(FirstName, LastName, Email, Phone, Address, Address2, City, PostalCode, EstimateSquareFeet,EstimateBedrooms,EstimateBathrooms,State);
    var isLead:any;
    if(localStorage.getItem("estIsLeadOnly") === 'true') {
        isLead = true;
    }else {
        isLead = false
    }
    const options:any = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }
    let JobPromisedDate:any = new Date().toLocaleDateString("en-US", options);
    let franchiseId = 0;
    let webLocationId = 0;
    var host = window.location.hostname.toLowerCase();
    if(host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) 
        {
            window.objOSGlobal.requestBodyValues.IsTest = true;
        }else {
            window.objOSGlobal.requestBodyValues.IsTest = false;
    }
    if ($('#conceptCode').val().toUpperCase() === "MRA"){
        window.objOSGlobal.requestBodyValues.Warranty = true;
        if(!window.objOSGlobal.requestBodyValues.MakeId){
            window.objOSGlobal.requestBodyValues.MakeId = 133;
        }
        if(!localStorage.getItem("estserviceSkuId")){
            localStorage.setItem("estserviceSkuId",'7989');
        }
    }else{
        window.objOSGlobal.requestBodyValues.Warranty = false;
    }
    if($('#conceptCode').val().toUpperCase() === "MLY"){
        GetLeadSourceMolly();
    }
    var payload = {
        "FranchiseId": franchiseId,
        "HasSameDayScheduling":true,
        "IsBetaTester": window.objOSGlobal.requestBodyValues.IsBetaTester ? window.objOSGlobal.requestBodyValues.IsBetaTester : false ,
        "Comments": "",
        "IsLocalized": true,
        "ConceptId":Number($('#conceptId').val()) ? Number($('#conceptId').val()) : 0,
        "UseFranchiseProfileOptInForPOSScheduling": true,
        "IsFranchiseOptedIntoPOSScheduling": true,
        "C4ChannelTypeId": 0,
        "ConceptCalledId":Number($('#conceptId').val()) ? Number($('#conceptId').val()) : 0,
        "ConceptName":"",
        "ConceptCode":$('#conceptCode').val() ? $('#conceptCode').val() : "",
        "C4LeadsEnabled": window.objOSGlobal.requestBodyValues.C4LeadsEnabled ? window.objOSGlobal.requestBodyValues.C4LeadsEnabled : true,
        "CanceledJobInformation": "",
        "VendorId":window.objOSGlobal.requestBodyValues.VendorId ? window.objOSGlobal.requestBodyValues.VendorId : "", 
        "NationalLeadId": "",
        "IsTest": window.objOSGlobal.requestBodyValues.IsTest,
        "VendorName":window.objOSGlobal.requestBodyValues.VendorName ? window.objOSGlobal.requestBodyValues.VendorName : "",
        "WebLocationId": webLocationId,
        "C4CallCenterId": 0,
        "DayTimePhoneNumber":"",
        "ZipCode": "",
        "Note": "",
        "FirstName":FirstName ? FirstName : "" ,
        "LastName":LastName ? LastName : "",
        "Email":Email ? Email : "",
        "Phone":Phone ? Phone : "",
        "PhoneType":"",
        "PostalCode":PostalCode ? PostalCode : "",
        "City":City ? City : "",
        "State":State ? State : "",
        "Country":$("#countryCode").val() ? $("#countryCode").val().toUpperCase() : "",
        "Address":Address ? Address : "",
        "Address2":Address2 ? Address2 : "",
        "LeadSource":window.objOSGlobal.requestBodyValues.leadSource,
        "LicenseNumber": localStorage.getItem('estlicenseNumber') ? localStorage.getItem('estlicenseNumber') : "",
        "WebLocationAccountCode": "",
        "LeadOrigin": window.objOSGlobal.requestBodyValues.leadOrigin,
        "CampaignName":window.objOSGlobal.requestBodyValues.CampaignName,
        "ReferringURL": document.referrer,
        "CurrentPage":window.location.hostname.toLowerCase(),
        "Disposition": "",
        "CallType": window.objOSGlobal.requestBodyValues.CallType,
        "PreferredCommunicationType": "",
        "EmailOptOut": window.objOSGlobal.requestBodyValues.EmailOptOut,
        "TagName": "",
        "Latitude": 0,
        "Longitude": 0,
        "LocationType": "",
        "IsGoogleAddress": true,
        "GoogleCalendarEventId": "",
        "GoogleCalendarEmail": "",
        "ServiceText": $('#conceptCode').val() == 'MLY' ? "Lead Only" : "",
        "ServiceID":localStorage.getItem('estServiceId') ? localStorage.getItem('estServiceId') : 0,
        "Warranty": window.objOSGlobal.requestBodyValues.Warranty,
        "TimeSlotTypeId": 0,
        "MakeId": window.objOSGlobal.requestBodyValues.MakeId,
        "LeadSourceId":LeadSourceId?LeadSourceId:0,
        "SecondaryLeadSourceId":0,
        "ServiceTypeProblem":"",
        "OverrideJobPromisedDate":"",
        "ServiceSkuId":localStorage.getItem('estserviceSkuId') ? localStorage.getItem('estserviceSkuId') : 0 ,
        "EstimateHouseStyle":"",
        "EstimateSquareFeet":Number(EstimateSquareFeet) ? Number(EstimateSquareFeet) : "",
        "EstimateBedrooms":Number(EstimateBedrooms) ? Number(EstimateBedrooms) : "",
        "EstimateBathrooms":Number(EstimateBathrooms) ? Number(EstimateBathrooms) : "",
        "EstimatePets": "",
        "CCFirstName":"",
        "CCLastName":"",
        "CCType":"",
        "CCSuffix": "",
        "CCToken": "",
        "CCExpirationDate": "",
        "CCComment": "",
        "IsLeadOnly": true,
        "IsEstimate":false,
        "TimeBlock": {
            "ConceptId": $('#conceptId').val() ? $('#conceptId').val() : 0,
            "GoogleCalendarEventId": "",
            "GoogleCalendarEmail": "",
            "TimeSlotTypeId": 0,
            "ServiceSkuId": localStorage.getItem('estserviceSkuId') ? localStorage.getItem('estserviceSkuId') : 0,
            "JobPromisedDate": JobPromisedDate,
            "IsAvailable": true,
            "IsFranchiseInPOS": true,
            "DisplayText": ""
        },
        "OverrideCSRName": "",
        "OverrideComment": "",
        "SyncTenantName": "",
        "IsEmergencyCall": false,
        "CallId": "",
        "IsFranchiseInPOS": true,
        "CustomerType": "",
        "NeighborlyAccountId": 0,
        "NeighborlyLeadSourceId": NeighborlyLeadSourceId,
        "FileUrls": [
            {
                "FileUrl": ""
            },
            {
                "FileUrl": ""
            },
            {
                "FileUrl": ""
            }
        ],
        "JobFrequency":"",
        "JobFrequencyDetail":"",
        "Year":0,
        "Make": "",
        "Model": "",
        "BodyStyle": "",
        "VIN": "",
        "LicensePlate": "",
        "WindshieldRepair": "",
        "HeadlightRestoration": "",
        "ServiceRequested": [] as any[],
        "LeadAdditionalNotes": {
            "LeadAdditionalNoteLabel": window.objOSGlobal.requestBodyValues.LeadAdditionalNoteLabel,
            "LeadAdditionalNoteText": ""
        },
       "FormOfPayment": "",
       "InsuranceCompany": "",
       "DeductibleAmount": 0,
       "PolicyNumber": "",
       "ScheduleID": "",
       "message": ""
    }
    startLoader();
    
    //Franchise Lookup call
    fullAddressRoundRobinApi(PostalCode,true,()=>{
        if(window.objOSGlobal.requestBodyValues.FranchiseId){
            franchiseId = window.objOSGlobal.requestBodyValues.FranchiseId;
            payload.FranchiseId = franchiseId?franchiseId:0;
            webLocationId = Number(window.objOSGlobal.requestBodyValues.WebLocationId);
            payload.WebLocationId = webLocationId?webLocationId:0;
            callBookingApi(payload);
        }
        else{
            showErrorThankYouMsg(PostalCode);
            stopLoader();
            }  
    },"");
}
function showErrorThankYouMsg(PostalCode:number){
    $('.contact-error-thankyou .card-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${PostalCode}</span>`);
    $('.contact-error-thankyou').removeClass('d-none');
    $('.contact-form').addClass('d-none');
    $('.container > h3.card-title').addClass('d-none');
}
function callBookingApi(payload:any){
    $.ajax({
        url: window.DEFINE_VALUE.bookingEndpoint + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY,
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(payload),
        success: function (data:any, status:any){
         stopLoader();
         localStorage.setItem("OSWebLocationId", window.objOSGlobal.requestBodyValues.WebLocationId);
         localStorage.setItem("OSDbaFullName", window.objOSGlobal.paramsUI.doingBusinessAs);
         let websiteurl =  window.location.origin.toLowerCase();
         let redirectPage = $("#hdn_thankyou_page").val();
	     window.location.replace(websiteurl + redirectPage);
        },
        error: function (data:any, status:any)
        {
            stopLoader();
        }
    })
}
$('.contact-error-thankyou .js-next-btn').on('click', function () {
    $('.contact-error-thankyou').addClass('d-none');
    $('.contact-form').removeClass('d-none');
});

$('#estimateFirstName,#estimateLastName,#estimateInputAddress,#estimateInputAddress2').bind('keypress', function (event:any) {

    var keyCode = event.keyCode || event.which ;
    if (keyCode == 60 || keyCode == 62 || keyCode == 47 || keyCode == 92) { 
        return false;
    } 
});

 $('#estimateFirstName,#estimateLastName,#estimateInputAddress,#estimateInputAddress2').bind('paste',function(e:any)
   {
    let element:any = this;
    let elementId:any = element.id;
       setTimeout(function()
       { 
          //get the value of the input text
          let data= $("#" +elementId).val();
          //replace the special characters to '' 
         let  regx = /[^<>/]/g ; 
          if(regx.test(data)){
            let data1 = $("#" +elementId).val().replaceAll('<','');
            let data2 = data1.replaceAll('>','');
            let data3 = data2.replaceAll('/','');
            $("#" +elementId).val(data3);
          }
         
       });

    });

    $("#estimateSqf").bind('keypress', function() {
        let maxChars = 4;
        if ($(this).val().length > maxChars) {
            $(this).val($(this).val().substr(0, maxChars));
        }
    });

    $("#estimateBedroomsTwo,estimateBedroomsOne").on('change', function(){
        if($('#estimateForm').valid()){
            $('.contact-form .js-next-btn').removeClass("disble-btn");
            $('.contact-form .js-next-btn').prop("disabled", false);
        }else {
            $('.contact-form .js-next-btn').addClass("disble-btn");
            $('.contact-form .js-next-btn').prop("disabled", true);
        }
    });
    
    function storeFormFieldToSession(FirstName:any,LastName:any, Email:any, Phone:any, Address:any, Address2:any, City:any, PostalCode:any, EstimateSquareFeet:any,EstimateBedrooms:any,EstimateBathrooms:any,State:any){
        sessionStorage.setItem('estFirstName', FirstName);
        sessionStorage.setItem('estLastName', LastName);
        sessionStorage.setItem('estEmail', Email);
        sessionStorage.setItem('estPhone', Phone);
        sessionStorage.setItem('estAddress', Address);
        sessionStorage.setItem('estAddress2', Address2);
        sessionStorage.setItem('estFirstName', FirstName);
        sessionStorage.setItem('estCity', City);
        sessionStorage.setItem('estState', State);
        sessionStorage.setItem('estPostalCode', PostalCode);
        sessionStorage.setItem('estSqft', $("#estimateSqf")[0] ? EstimateSquareFeet : "");
        sessionStorage.setItem('estBedRoom', $("#estimateSqf")[0] ? EstimateBedrooms : "");
        sessionStorage.setItem('estBaathRoom', $("#estimateSqf")[0] ? EstimateBathrooms : "");
    }
    