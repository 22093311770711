import { apiCall, apiConfig, refreshScheduleData, schedulerState, startLoader } from "..";
import {$} from "../third-party-imports";
export async function mollyMaidEstimate(){
    sessionStorage.removeItem("estimateSaturdayPrice");
    apiCall.error = false;
    schedulerState.numberOfSlot = 1;
    let mlyCleaning = sessionStorage.getItem('MlyCleaning');
    var {WebLocationId,Note,squareFeet,bedrooms,bathrooms,licenseNumber,Address,Address2,City,FirstName,LastName,Phone,Email,State,NeighborlyLeadSourceId} = window.objOSGlobal.requestBodyValues;
    Address2 = Address2 ?  " " + Address2 : "";
    let completeAddress =  Address + Address2;
    var payload ={
        "franchiseWebLocationId": WebLocationId.toString(),
        "LicenseNumber": licenseNumber,
        "LeadSourceId": NeighborlyLeadSourceId,
        "SecondaryLeadSourceId": 0,
        "Note": (Note && Note == null) ? Note : "",
        "EstimateTitle": "null",
        "FirstName": FirstName,
        "LastName": LastName,
        "Address": completeAddress,
        "City": City,
        "State": State,
        "PostalCode": localStorage.getItem('zipcode'),
        "Email": Email,
        "Phone": Phone,
        "PreferredCommunicationType": "null",
        "EstimateSquareFeet": Number(squareFeet),
        "EstimateBedrooms": Number(bedrooms),
        "EstimateBathrooms": Number(bathrooms),
        "ReceiveEmailUpdates": true
    }
    startLoader();
    $.ajax({
        url: apiConfig.calculateEstimate + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY,
        type: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(payload),
        success: function (data:any, status:any){
            sessionStorage.setItem("mlyEstimateData",data);
            $(".saturday-selected").show();

            if(mlyCleaning =="oneTimeClean"){
                sessionStorage.setItem('mollyEstimate','$' +  Math.round(data.estimateOccasionalRangeLow) + "-$" +  Math.round(data.estimateOccasionalRangeHigh));
                $('.saturday-selected-price').text('$' +  Math.round(data.estimateOccasionalRangeLow) + "-$" +  Math.round(data.estimateOccasionalRangeHigh));
                sessionStorage.setItem('estimateSaturdayPrice','$' +Math.round(data.estimateOccasionalRangeLowSaturday) + "-$" +  Math.round(data.estimateOccasionalRangeHighSaturday));
                sessionStorage.setItem("mollymaidprice",'$' +  Math.round(data.estimateOccasionalRangeLow) + "-$" +  Math.round(data.estimateOccasionalRangeHigh));
            }else{
                $('.saturday-selected-price').text('$' +  Math.round(data.estimateMoveRangeLow) + "-$" +  Math.round(data.estimateMoveRangeHigh));
                sessionStorage.setItem('mollyEstimate','$' +  Math.round(data.estimateMoveRangeLow) + "-$" +  Math.round(data.estimateMoveRangeHigh));
                sessionStorage.setItem('estimateSaturdayPrice','$' +Math.round(data.estimateMoveRangeLowSaturday) + "-$" +  Math.round(data.estimateMoveRangeHighSaturday));
                sessionStorage.setItem("mollymaidprice",'$' +  Math.round(data.estimateMoveRangeLow) + "-$" +  Math.round(data.estimateMoveRangeHigh));
            }
            let note = window.objOSGlobal.requestBodyValues.Note;
            if(note !=''){
                window.objOSGlobal.requestBodyValues.Note = note + '\nMLY Estimate: ' + $('.saturday-selected-price').text();
            }
        },
        error: function (data:any, status:any){
            sessionStorage.removeItem("estimateSaturdayPrice");
            $(".saturday-selected").hide();
            let note = window.objOSGlobal.requestBodyValues.Note;
            if(note !=''){
                window.objOSGlobal.requestBodyValues.Note = note + '\nMLY Estimate: ';
            }
        }
    });
    await refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
}