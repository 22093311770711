import { generateServiceHTML } from "./mrh-service-html";

export function mrhGenerateServices(categoryList: any, root: any) {
    let serviceListArray: any = [];
    let cateId = categoryList.name.replace(/[^A-Z0-9]+/ig, "").toLowerCase();
    let catServiceType = categoryList.serviceType.toLowerCase();
    if (categoryList.serviceType.toLowerCase() !== "OtherService".toLowerCase()) {
        if (categoryList?.menu && categoryList?.menu.length !== 0) {
            categoryList?.menu.forEach((menuList:any)=>{
                let menuId = categoryList.name.toLowerCase()+menuList.name.toLowerCase();
                menuId = menuId.replace(/[^A-Z0-9]+/gi, "").toLowerCase();
                if(menuList?.service && menuList?.service.length !== 0){
                    menuList.service.forEach((serviceList:any)=>{
                        //serviceListArray.push(generateServiceHTML(serviceList)) 
                        const services = generateServiceHTML(serviceList,catServiceType)
                        $('.menu-col #menu-'+menuId).append(services)
                    })
                }
            })
        }
    }else {
        /*commenting for showing only categoty in case of */
        // categoryList?.category.forEach((catItem: any, catIndex: any) => {
        //     if (catItem?.menu && catItem?.menu.length !== 0) {
        //         catItem?.menu.forEach((menuList: any) => {
        //             let menuId = menuList.name.replace(/[^A-Z0-9]+/gi, "").toLowerCase();
        //             if (menuList?.service && menuList?.service.length !== 0) {
        //                 menuList.service.forEach((serviceList: any) => {
        //                     //serviceListArray.push(generateServiceHTML(serviceList))
        //                     const services = generateServiceHTML(serviceList);
        //                     $(".menu-col #menu-" + menuId).append(services);
        //                 });
        //             }
        //         });
        //     }
        // });
    }
}