import { $ } from "../third-party-imports";
import {getServiceTypeString} from '../services/getServiceTypeString';
import {getServicesForCategory} from '../services/getServicesForCategory';
import {categoryResults} from './categoryResults';
import { stopLoader } from ".";
export function categoryPage(){
        var serviceId = $('#serviceId').val();
        var serviceModifier = $('#serviceModifier').val();
        var serviceType = getServiceTypeString(serviceModifier);
        var conceptId = $('#conceptId').val();
        var postalCode = window.objOSGlobal.requestBodyValues.PostalCode;
        window.objOSGlobal.requestBodyValues.modifier = serviceModifier;
        getServicesForCategory(postalCode,conceptId,serviceType,serviceId,function(result:any){
            stopLoader();
            categoryResults(result,postalCode);
        },function(data:any, status:any, error:any){
            if(data.responseJSON.Message.includes("Can not find the location with")){
                $(".tab-pane.fieldset").removeClass("active");
                $(".no-service-screen").addClass("active");
                $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${postalCode}</span>`);
            }
        });
}