import { domLoaded } from '@nbly/crownpeak';
import { getElavonClient } from './getElavonClient';
import { submitGc } from './submitGc';
import { validatePurchaserInfoForm } from './validatePurchaserInfoForm';

async function initGcPage()
{
    //This is supposed to do the exact same thing as elavonClient.getMainWrapper(), but without loading the library first. 
    const mainWrapper = document.querySelector(".gift-certificate-form");

    if (!mainWrapper)
        return;

    const elavonClient = await getElavonClient();

    //This will be double checked to make sure the above check doesn't get out of sync with the library.
    if (mainWrapper !== elavonClient.getMainWrapper())
        throw new Error("Gift certificate wrapper does not match the elavon library wrapper.");

    const form = elavonClient.getPurchaserInfoForm();

    const submitFn = async (ev: Event) => {
        ev.preventDefault();
        if (validatePurchaserInfoForm()) {
            await submitGc(elavonClient);
        }
    };

    const submitBtn = elavonClient.getSubmitToPaymentButton();
    if (submitBtn.type != "submit")
        submitBtn.addEventListener("click", submitFn);
        
    form.addEventListener("submit", submitFn);
}

domLoaded(() => { initGcPage() });