
import { getMSALInstance } from "../../config";
import { logout } from "../../auth/authRedirect";

export const clearStorage = () =>
{
    const myMSALObj = getMSALInstance();
    let session = sessionStorage.getItem('isRememberMeNotSelected');
    let local = localStorage.getItem('isRememberMeNotSelected');
    if (myMSALObj.getAccount())
    {
        if (session == null && local == null)
        {
            logout();
        }
    }
}
