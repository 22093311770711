export function wraplistComponent(type:any){
    return `<ul class="dropdown-menu megamenu">
    <li class="row ml-0 mr-0">
    <hr>
        <div class="dropmenu Total_Level2_Count_9">
            <ul class="mega-menu-front js-mega-menu-front ${type.name}">
                <button class="back-arrow-link js-back-menu mob-link" aria-label="Back to main menu">Back</button>
                <span class="overview-link"><a class="overview-heading-link" href="${type.link}">${type.name}</a></span>
            </ul>
            <div class="mega-menu-links ${type.name}">
                
            </div>
        </div>
    </li>
 </ul>`
}
