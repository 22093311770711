import { $ } from "../../third-party-imports";
export async function storeValueOneTimeAndRecurring(){
    let frequency:any = $("input:radio[name=chkFrequesy]:checked").val();
    localStorage.setItem('selectedAboutHome', frequency);
    window.objOSGlobal.requestBodyValues.ScheduleID = '';
    sessionStorage.removeItem('mollymaidprice');
    sessionStorage.getItem("mollyEstimate");
    $(".mly-service-contact-form-screen #inputZipCode").val(localStorage.getItem('zipcode'));
    if(localStorage.getItem("selectedAboutHome") == "onetime"){
        let oneTimeVal = $('input[name="chkCleaning"]:checked').val();
        sessionStorage.setItem('MlyCleaning',oneTimeVal);
        let leadFlow =  window.objOSGlobal.requestBodyValues.optInForPOSScheduling2 ? false : true;
        window.objOSGlobal.requestBodyValues.IsLeadOnly = leadFlow;
        window.objOSGlobal.requestBodyValues.IsEstimate = false;
        if(window.objOSGlobal.requestBodyValues.IsLeadOnly){
            window.objOSGlobal.requestBodyValues.ServiceText = "Lead Only";
        }else{
            window.objOSGlobal.requestBodyValues.ServiceText = "One Time Clean";
        }
        if (oneTimeVal ==="oneTimeClean") {
            window.objOSGlobal.requestBodyValues.Note = '';
            window.objOSGlobal.requestBodyValues.Note = 'MLY Frequency: One Time, Not a Move in or Move out clean'
        } else {
            window.objOSGlobal.requestBodyValues.Note = '';
            window.objOSGlobal.requestBodyValues.Note = 'MLY Frequency: One Time, Move in or Move out clean'
        }
        $('.mly-service-estimate-screen .next').html("Confirm Your Appointment "+$('.js-next-btn img')[0].outerHTML);
    }else if(localStorage.getItem("selectedAboutHome") == "recurring"){
        let leadFlow =  window.objOSGlobal.requestBodyValues.optInForPOSScheduling ? false : true;
        window.objOSGlobal.requestBodyValues.IsLeadOnly = leadFlow;
        sessionStorage.removeItem('mollymaidprice');
        sessionStorage.getItem("mollyEstimate");
        window.objOSGlobal.requestBodyValues.IsEstimate = true;
        if(window.objOSGlobal.requestBodyValues.IsLeadOnly){
            window.objOSGlobal.requestBodyValues.ServiceText = "Lead Only";
        }else{
            window.objOSGlobal.requestBodyValues.ServiceText = "Estimate";
        }
        window.objOSGlobal.requestBodyValues.Note = '';
        window.objOSGlobal.requestBodyValues.Note = 'MLY Frequency: Recurring';
        $('.mly-service-estimate-screen .next').html("Confirm Your Estimate Appointment "+$('.js-next-btn img')[0].outerHTML);
    }
    
    $('.contact-information-screen input[name="inputZipCode"]').keyup(function (this: any)
    {
        var value: any = $(this).val();
        var regex: any = new RegExp(/^([0-9]{5}|[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9])$/);
        if (regex.test(value))
        {
            window.objOSGlobal.requestBodyValues.PostalCode = value;
            if (localStorage.getItem('zipcode').toUpperCase() != value.toUpperCase())
            {
                sessionStorage.setItem('changeZip', value);
            } else
            {
                sessionStorage.removeItem('changeZip');
            }
        }
    });
}

export function updateCommercialFlow(){
    let checkModifier = window.location.href.includes('commercial');
    if(checkModifier){
        mlyServicesInfomationResidentialCommercial('commercial');
        window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
        window.objOSGlobal.requestBodyValues.optInForPOSScheduling = false;
        window.objOSGlobal.requestBodyValues.optInForPOSScheduling  = false;
        window.objOSGlobal.requestBodyValues.modifier = 'Commercial';
    }else{
        mlyServicesInfomationResidentialCommercial('residential');
        window.objOSGlobal.requestBodyValues.modifier = 'Residential';
    }
    
    sessionStorage.setItem('ServiceModifierValue', window.objOSGlobal.requestBodyValues.modifier);
}

function mlyServicesInfomationResidentialCommercial(modifier:any){
    $('input[type=radio][name=chkFrequesy]').change(function(this:any) {
        $('.services-list-screen').addClass('d-none');
        if (this.value === 'onetime'){
            let oneTimeVal = $('input[name="chkCleaning"]:checked').val();
            if(modifier ==='commercial'){
                if(oneTimeVal==='oneTimeClean'){
                    $('.mly-service-commercial-list-onetime_moveinout_no').removeClass('d-none');
                }else{
                    $('.mly-service-commercial-list-onetime_moveinout_yes').removeClass('d-none');
                }
                
            }else if(modifier==='residential'){
                if(oneTimeVal==='oneTimeClean'){
                    $('.mly-service-residential-list-onetime_moveinout_no').removeClass('d-none')
                }else{
                    $('.mly-service-residential-list-onetime_moveinout_yes').removeClass('d-none')
                };
            }
            checkMlyOneTimeYesNo(modifier);
        }else{
            if(modifier ==='commercial'){
                $('.mly-service-commercial-list').removeClass('d-none');
            }else if(modifier==='residential'){
                $('.mly-service-residential-list').removeClass('d-none');
            }

        }
    });
}
function checkMlyOneTimeYesNo(modifier:any){
    $('input[type=radio][name=chkCleaning]').change(function(this:any) {
        let val = $(this).val();
        $('.services-list-screen').addClass('d-none');
        if(modifier ==='commercial'){
            if (val.toLowerCase() === 'onetimeclean') {
                $('.mly-service-commercial-list-onetime_moveinout_yes').removeClass('d-none');
            }
            else{
                $('.mly-service-commercial-list-onetime_moveinout_no').removeClass('d-none');
            }

        }else if(modifier==='residential'){
            if (val.toLowerCase() === 'onetimeclean') {
                $('.mly-service-residential-list-onetime_moveinout_no').removeClass('d-none');
            }
            else{
                $('.mly-service-residential-list-onetime_moveinout_yes').removeClass('d-none');
            }
        }
    });   
}

export function croConfirmation(){
    let jobDate = sessionStorage.getItem("CJobDate");
    let time = sessionStorage.getItem("CTime");
    let street = sessionStorage.getItem("CAddress");
    let street2 = sessionStorage.getItem("CAddress2");
    let city = sessionStorage.getItem("CCity");
    let state = sessionStorage.getItem("CState");
    let pincode = sessionStorage.getItem("CPostalCode");
    let firstName = sessionStorage.getItem("CFirstName");
    let lastName = sessionStorage.getItem("CLastName");
    let email = sessionStorage.getItem("CEmail");
    let phone = sessionStorage.getItem("CPhone");
    let estimate = sessionStorage.getItem('mollymaidprice');
    let isLeadOnly = sessionStorage.getItem("CLeadOnly");
    let preferredCommunicationText = sessionStorage.getItem('PreferredCommunicationText');
    let fullname = firstName + " " + lastName;
    let addressObj = street2 != "" ? street + ' ' + street2 : street;
    let fulladdress = addressObj + '<br/>' + city + ', ' + state + ' ' + pincode;
    let selectedAboutHome = localStorage.getItem("selectedAboutHome");

    if(!estimate){
        $(".estimate-info").addClass("d-none");
    }
    if(!preferredCommunicationText){
        $(".appointment-info-heading.pt-2").addClass("d-none");
    }
    if(isLeadOnly==='true'){
        $(".date-and-time-info").addClass("d-none");
    }

    $('.date-info .appointment-info-content').text(jobDate);
    $('.time-info .appointment-info-content').text(time);
    $('.estimate-info .appointment-info-content').text(estimate);
    $('.address-info .appointment-info-content').html(fulladdress);
    $('.contact-info-name').text(fullname);
    $('.contact-info-email').text(email);
    $('.contact-info-mobile').text(phone);
    $('.preferred-contact-info-name').text(preferredCommunicationText);
    
    if(selectedAboutHome!=="recurring"){
            $(".confirmation-mly-recurring").addClass("d-none")
            $(".confirmation-mly-one-time").removeClass("d-none")
    }    
}

export function preferredMethodContactType(){
    let inputElems = document.querySelectorAll(".preferContactWrap .custom-checkbox input");
        let preferMethodType:any =[];
        let preferMethodText:any = [];
        inputElems.forEach((el:any)=>{
            if (el.type == "checkbox" && el.checked == true){
                preferMethodType.push(el.value);
                preferMethodText.push(el.getAttribute("data-text"));
            }
        });
        sessionStorage.setItem('PreferredCommunicationText',preferMethodText.toString());
        window.objOSGlobal.requestBodyValues.PreferredCommunicationType = preferMethodType.toString();
}