import { $ } from "../third-party-imports";
import { daysInMonth } from './daysInMonth';
import { slotsDate, slotIn } from './refreshScheduleData'
import { startLoader } from "./startLoader";
import { stopLoader } from "./stopLoader";
import { apiConfig } from "../config/apiConfig";
import { getWeekObj } from "./get-brands-json";
import { isWeekendSaturday, saturdayPriceDisplay, schedulerState } from "../scheduler";
import { stopLoaderWithDelay } from "./stopLoaderWithDelay";

export const defaultEnableCal = {
    enable: true,
    changeActive:false,
}

export function dateDisplay(count: any){
    var slotRows = [];
    var datesArray = [];

    for (var i = count; i < 5 + count; i++){
        if (i >= slotsDate.slotsByDate.length){
            var dateArray = slotsDate.slotsByDate[slotsDate.slotsByDate.length - 1].date.split('-');
            var mm = dateArray[1];
            var dd = parseInt(dateArray[2]);
            var yyyy = dateArray[0]
            var datesInMonth = daysInMonth(mm, yyyy);
            if (dd == datesInMonth){
                dd = 1;
                mm = parseInt(dateArray[1]) + 1;
            } else{
                dd = dd + 1;
            }
            var dateUrl = mm + '/' + dd + '/' + yyyy;
            if (!getWeekObj.getNumberOfWeeks){
                nextPrevDatesValues(new Date().getFullYear(), new Date().getMonth(), dateUrl, function (){
                    var finalData = slotsDate.slotsByDate.length - count;
                    if (finalData > 0){
                        lastUpdateData(count, finalData);
                        return;
                    }
                });
                // return;
            }
        }
        var stringDate = slotsDate?.slotsByDate[i]?.date;
        //    if(!stringDate){

        //       return;

        //    }
        if (stringDate){
            $('.calendar-wrapper .next-btn').removeClass('hidden');
            var monthName = new Date(stringDate.replace(/-/g, '\/').replace(/T.+/, '')).toLocaleString("default", { month: "short" });
            var weeks = new Date(stringDate.replace(/-/g, '\/').replace(/T.+/, '')).toLocaleDateString('en-US', { weekday: 'short' });
            var dates = new Date(stringDate.replace(/-/g, '\/').replace(/T.+/, '')).getDate()
            var allAvailable = slotsDate.slotsByDate[i].slots.reduce((a: any, b: any) => a || b.isAvailable, false);
            datesArray.push(generateDateBlock(monthName, dates, weeks, i, allAvailable));
            var temp: any = [];
            slotsDate.slotsByDate[i].slots.forEach((element: any) =>{
                if(!($('#conceptCode').val().toUpperCase() == 'MRR' && element.displayText==="After Hours" && element.reasonForUnavailability.includes("not available for after hours") && !isConsecutiveDaysAfterHoursSlotsAvailable(i) )){
                temp.push(generateSlotButtonBlock(element.displayText, !element.isAvailable, { monthName, weeks, dates }, element));
                }
            });

            slotRows.push(generateSlotRows(temp, i));
            if (stringDate == slotsDate.slotsByDate[slotsDate.slotsByDate.length - 1].date){
                $('.calendar-wrapper .next-btn').addClass('hidden');
            }
        }else{
            $('.calendar-wrapper .next-btn').addClass('hidden');
            $('.date-details-wrapper').html(datesArray.join(''));
            $('.calendar-row').html(slotRows.join(''));
            return;
        }
    }
    $('.date-details-wrapper').html(datesArray.join(''));
    $('.calendar-row').html(slotRows.join(''));
    stopLoaderWithDelay();
    
}

function nextPrevDatesValues(year: any, month: any, dateForUrl: any, callback: any){
    var isEstimate = localStorage.getItem("selectedAboutHome") == "recurring" ? "true" : "false"
    var isSchedule = $("#brandName").val() == "molly-maid" ? "&IsEstimate=" + isEstimate : "";
    var webLocationId = window.objOSGlobal.requestBodyValues.WebLocationId ? window.objOSGlobal.requestBodyValues.WebLocationId : '8113';
    var serviceSkuId = window.objOSGlobal.requestBodyValues.serviceSkuId
    var getZipCode = localStorage.getItem('zipcode');
    var zipCode = getZipCode ? getZipCode : 43230;
    var date = new Date();
    date = new Date(date.getFullYear(), date.getMonth(), 1);
    var numberOfDays = 31;
    if (year == date.getFullYear() && month == (date.getMonth() + 1)){
        numberOfDays = daysInMonth(month, year);
    }else{
        numberOfDays = daysInMonth(month, year);
    }
    startLoader();
    $.ajax({
        url: apiConfig.techAvalibility + '?PostalCode=' + zipCode + '&WebLocationId=' + webLocationId + '&NumberOfDays=' + numberOfDays + '&ServiceSkuId=' + serviceSkuId + '&DateStart=' + dateForUrl + isSchedule + '&apikey=' + window.DEFINE_VALUE.JS_API_KEY,
        type: "GET",
        contentType: "application/json",
        dataType: 'json',
        success: function (results: any){
            stopLoader();
            if (results.resultCollection.length != 0){
                $('.calendar-wrapper .next-btn').removeClass('disble-btn').prop('disabled', false);
                results.resultCollection.forEach((element: any) =>{
                    var date = element.startDate.split('T')[0]
                    var item = slotsDate.slotsByDate.find((itm: any) => itm.date == date);
                    if (!item){
                        item = { date, slots: [] }
                        slotsDate.slotsByDate.push(item);
                    }
                    item.slots.push(element)
                });
                dateDisplay(slotIn.slotIndex);
            } else{
                $('.calendar-wrapper .next-btn').addClass('disble-btn').prop('disabled', true);
                callback();
            }
        },
        error: function (data: any){
            stopLoader();
        }
    });
}
function lastUpdateData(count: any, finalData: any)
{
    var slotRows = [];
    var datesArray = [];
    for (var i = 0 + count; i < finalData + count; i++)
    {
        var stringDate = slotsDate.slotsByDate[i].date;
        var monthName = new Date(stringDate).toLocaleString("default", { month: "short" });
        var weeks = new Date(stringDate).toLocaleDateString('en-US', { weekday: 'short' });
        var dates = new Date(stringDate).getDate();
        var allAvailable = slotsDate.slotsByDate[i].slots.reduce((a: any, b: any) => a || b.isAvailable, false);
        datesArray.push(generateDateBlock(monthName, dates, weeks, i, allAvailable));
        var temp: any = [];
        slotsDate.slotsByDate[i].slots.forEach((element: any) =>
        {
            if(!($('#conceptCode').val().toUpperCase() == 'MRR' && element.displayText==="After Hours" && element.reasonForUnavailability.includes("not available for after hours") && !isConsecutiveDaysAfterHoursSlotsAvailable(i) )){
            temp.push(generateSlotButtonBlock(element.displayText, !element.isAvailable, { monthName, weeks, dates }, element));
            }
        });
        slotRows.push(generateSlotRows(temp, i));
    }
    $('.date-details-wrapper').html(datesArray.join(''));
    $('.calendar-row').html(slotRows.join(''));
}


function generateDateBlock(month: any, date: any, week: any, id: any, isactive: any)
{
    var activecls = "";
    let activeButton = defaultEnableCal.changeActive ? false : isactive;
    if (activeButton)
    {
        activecls = 'active';
        defaultEnableCal.changeActive = true;
    }
    return '<button class="date-details ' + activecls + '" data-index="id' + id + '">' +
        '<strong class="body-text-sm">' + month + ' <span>' + date + '</span></strong>' +
        '<span class="body-text-sm">' + week + '</span>' +
        '</button>'
}
function generateSlotButtonBlock(btnText: any, isDisable: any, obj: any, dt: any)
{
    var modifiedTime = btnText.replace(/(?:|\W)AM|PM/i, "");
    var displayTime = obj.weeks + ' ' + obj.monthName + ' ' + obj.dates + ', ' + (isDisable ? 'Unavailable' : modifiedTime);
    if ($('#conceptCode').val() == 'MLY')
    {
        if (defaultEnableCal.enable && !isDisable)
        {
            defaultEnableCal.enable = false;
            $('.calendar-screen .next').removeClass('disble-btn').prop('disabled', false);
            $('.selected-date-txt').text(displayTime);
            window.objOSGlobal.requestBodyValues.ScheduleID = dt.scheduleID;
            schedulerState.selectedDate = dt.startDate;
            sessionStorage.setItem('CTime', modifiedTime);
            let myDate = new Date(schedulerState.selectedDate);
            if(isWeekendSaturday(myDate)){
                let saturdayPrice = sessionStorage.getItem("estimateSaturdayPrice");
                saturdayPriceDisplay(saturdayPrice);
                $('.saturday-selected-text').removeClass('d-none');
            }
            $('.selected-date').removeClass('d-none');
            return '<button  data-scheduleId="' + dt.scheduleID + '" data-selectedDate="' + dt.startDate + '" data-time="' + displayTime + '"  class="calendar-btn active-cal active' + (isDisable ? 'disble-btn' : '') + '" ' + (isDisable ? 'disabled' : '') + '>' + (isDisable ? 'Unavailable' : modifiedTime) + '</button>'
        } else
        {
            return '<button  data-scheduleId="' + dt.scheduleID + '" data-selectedDate="' + dt.startDate + '" data-time="' + displayTime + '"  class="calendar-btn ' + (isDisable ? 'disble-btn' : '') + '" ' + (isDisable ? 'disabled' : '') + '>' + (isDisable ? 'Unavailable' : modifiedTime) + '</button>'
        }
    } else
    {
        return '<button  data-scheduleId="' + dt.scheduleID + '" data-selectedDate="' + dt.startDate + '" data-time="' + displayTime + '"  class="calendar-btn ' + (isDisable ? 'disble-btn' : '') + '" ' + (isDisable ? 'disabled' : '') + '>' + (isDisable ? 'Unavailable' : modifiedTime) + '</button>'
    }
}
function generateSlotRows(btnArray: any, colId: any)
{
    return '<div class="calendar-col" id="id' + colId + '">' + btnArray.join('') + '</div>';
}

function isConsecutiveDaysAfterHoursSlotsAvailable(index:number){
    let slotArray = slotsDate.slotsByDate;
    let checkIndices = [];
    if (index <= 2) {
        checkIndices = [index + 1, index + 2];
    } else if (index >= 98) {
        checkIndices = [index - 1, index - 2];
    } else {
        checkIndices = [index - 2, index - 1, index + 1, index + 2];
    }
    for (let i of checkIndices) {
        if (slotArray[i].slots[2].isAvailable) {
            return true;
        }
    }
    return false;
}