import { detectMob } from "../util/detect-mob";
export function colSection(categoryMenu:any,serviceList:any,index:any){
    let mob = detectMob();
    let linkTarget = categoryMenu.openNewTab ? "target='_blank'" : "";
    let linkTag:any;
    let checkNumOfService:any = serviceCategoryChecker(categoryMenu);
    if (checkNumOfService.menu){
        if (categoryMenu?.menu.length === 0){
            linkTag = `<a href="${categoryMenu.link}" class="arrow-right-link mob-link js-nav-link">${categoryMenu.name}</a>`;
        }else{
        
            linkTag = `<button type="button" class="arrow-right-link mob-link js-nav-link">${categoryMenu.name}</button>`;
        }
        if(mob) {
            return `<div class="col-section js-dd-inner">
             ${linkTag}
             <div class="dropdown-menu-inner ">
                 <button class="back-arrow-link js-backMenuInner" aria-label="Back to main menu">Back</button>
                 <span class="overview-link"><a class="overview-heading-link" href="${categoryMenu.link}">${categoryMenu.name}</a></span>
                 <ul class= "services ${categoryMenu.name+index}">
                    ${serviceList}
                    <li class="inner-list-item d-none"><a class="dropdown-item view-all-link" href="${categoryMenu.link}">View ${categoryMenu.name}</a><li>
                 </ul>
             </div>
    </div>`
        }else {
            return `<div class="col-section js-dd-inner">
            <a href="${categoryMenu.link}" ${linkTarget} title="${categoryMenu.name}" class="desktop-link">${categoryMenu.name}</a>
             <div class="dropdown-menu-inner ">
                 <button class="back-arrow-link js-backMenuInner" aria-label="Back to main menu">Back</button>
                 <span class="overview-link"><a class="overview-heading-link" href="${categoryMenu.link}">${categoryMenu.name}</a></span>
                 <ul class= "services ${categoryMenu.name+index}">
                    ${serviceList}
                    <li class="inner-list-item d-none"><a class="dropdown-item view-all-link" href="${categoryMenu.link}">View ${categoryMenu.name}</a><li>
                 </ul>
             </div>
    </div>`
        }
    }else{
        linkTag = `<a href="${checkNumOfService.link}" class="arrow-right-link mob-link js-nav-link">${checkNumOfService.name}</a>`;
        if(mob) {
            return `<div class="col-section js-dd-inner">
                    ${linkTag}
                   </div>`
        }else {
            return `<div class="col-section js-dd-inner">
                       <a href="${checkNumOfService.link}" ${linkTarget} title="${checkNumOfService.name}" class="desktop-link">${checkNumOfService.name}</a>
                </div>`
        }
    } 
}

function serviceCategoryChecker(categoryMenu:any) {
    let serviceObj: any = {};
    if (categoryMenu?.menu.length === 1){
        serviceObj.name = categoryMenu?.menu[0]?.name;
        serviceObj.serviceUrl = categoryMenu?.menu[0]?.link;
        serviceObj.link = categoryMenu?.menu[0]?.link;
        return serviceObj;
    }else{
        return categoryMenu;
    }
}