import { emptyAddressFilds } from "./emptyAddressFilds";
import { startLoader } from "./startLoader";
import { stopLoader } from "./stopLoader";
import { apiConfig } from "../config/apiConfig";
import { $ } from "../third-party-imports";
import {addressBumper} from './findHelpAddressBumper';
import {findHelpAddressBumper} from './findHelpAddressBumper';
import axios from "axios";
import { apiCaching } from "../config/cachingConfig";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";

export async function fullAddressRoundRobinApi(addressParam: any,roundRobin: any,successHandler:any,errorHandler:any){
    window.objOSGlobal.requestBodyValues.ConceptId = $('#conceptId').val();
    let brandJsonItems = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj;
    const croleadNoZipEnable =brandJsonItems?.[0]?.enable_cro_enhance_lead_no_zip_match || false ;
    const isCountry = $("#countryCode").val()?.toLowerCase();
    const conceptCode = $('#conceptCode').val()?.toLowerCase();
    var conceptID = window.objOSGlobal.requestBodyValues.ConceptId;
    var apiUrl =   apiConfig.FranchiseLookUpByServiceAddress+addressParam+'&IsRoundRobin='+roundRobin+'&conceptID='+conceptID+'&RequireAddressOnPartialPostalCode=true&apikey=' + window.DEFINE_VALUE.JS_API_KEY;
    //var apiUrl = 'https://api2-test-unifiedsyncplatform.dwyergroup.com/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress?&Address='+addressParam+'&IsRoundRobin='+roundRobin+'&conceptID='+conceptID+'&RequireAddressOnPartialPostalCode=true&apikey=' + window.DEFINE_VALUE.JS_API_KEY;
    if(addressBumper.addressBumperFlag) {
        addressBumper.addressBumperFlag = false;
        startLoader();
    }
    try{
        let response = await getCachedAPIResponse(apiUrl, apiCaching.LocateLocationApiWithRoundRobinTrue);
        window.objOSGlobal.requestBodyValues.isFsaLead = false;
        let result:any = response;
        if(result.length==0){
            stopLoader();
            if(conceptCode ==="mrr" && isCountry ==="ca" || croleadNoZipEnable){
                window.objOSGlobal.requestBodyValues.isFsaLead = true;
                successHandler();
            }else{
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
                $(".tab-pane.fieldset").removeClass("active");
                if($("#conceptCode").val() === "GUY"){
                $('.no-service-screen .head-title').html($("#hdnSD_NoResultsMsg").html());
                }else {
                $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.PostalCode ? window.objOSGlobal.requestBodyValues.PostalCode : addressParam}</span>`);
                }
                $(".no-service-screen").addClass("active");
                localStorage.removeItem('zipcode');
                successHandler();
            }
        }else{
            window.objOSGlobal.requestBodyValues.WebLocationId = result[0].franchiseWebLocationId;
            window.objOSGlobal.requestBodyValues.FranchiseId = result[0].franchiseId;
            localStorage.setItem('estwebLocationId', window.objOSGlobal.requestBodyValues.WebLocationId);
            localStorage.setItem('estfranchiseId', window.objOSGlobal.requestBodyValues.franchiseId);
            window.objOSGlobal.paramsUI.brandPhoneNumber = result[0].phoneNumber;
            window.objOSGlobal.paramsUI.doingBusinessAs = result[0].doingBusinessAs;
            window.objOSGlobal.requestBodyValues.licenseNumber = result[0].licenseNumber;
            localStorage.setItem("estlicenseNumber",window.objOSGlobal.requestBodyValues.licenseNumber);
            window.objOSGlobal.paramsUI.dbaShortName=result[0].dbaShortName;
            successHandler();
        }
    }catch(error){
        stopLoader();
        var duplicate:any = error;
        duplicate = JSON.parse(duplicate["message"])?.message;
        if(conceptCode ==="mrr" && isCountry ==="ca" || croleadNoZipEnable){
            window.objOSGlobal.requestBodyValues.isFsaLead = true;
            successHandler();
        }else{
            if (duplicate == "Full address is needed to narrow down results"){
                $(".tab-pane.fieldset").removeClass("active");
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
                $('.address-bumper-screen').addClass('active');
                $(".address-bumper-screen .back").show();
                emptyAddressFilds();
                sessionStorage.setItem("isAddressbumper", "true");
                findHelpAddressBumper(addressParam);
                }else{
                    $(".tab-pane.fieldset").removeClass("active");
                    $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.PostalCode}</span>`);
                    $(".no-service-screen").addClass("active");
                }
        }
        if(duplicate !== "Full address is needed to narrow down results") {    
            console.info('Error in fetching FranchiseLookUpByServiceAddress API data:', error);
        }
    } 
    /*      
    let config = {
        method: 'get',
        url: apiUrl,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    axios(config)
    .then(function (response:any) {
        window.objOSGlobal.requestBodyValues.isFsaLead = false;
        let result:any = response.data;
        if(result.length==0){
            stopLoader();
            if(conceptCode ==="mrr" && isCountry ==="ca" || croleadNoZipEnable){
                window.objOSGlobal.requestBodyValues.isFsaLead = true;
                successHandler();
            }else{
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
                $(".tab-pane.fieldset").removeClass("active");
                if($("#conceptCode").val() === "GUY"){
                $('.no-service-screen .head-title').html($("#hdnSD_NoResultsMsg").html());
                }else {
                $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.PostalCode ? window.objOSGlobal.requestBodyValues.PostalCode : addressParam}</span>`);
                }
                $(".no-service-screen").addClass("active");
                localStorage.removeItem('zipcode');
                successHandler();
            }
        }else{
            window.objOSGlobal.requestBodyValues.WebLocationId = result[0].franchiseWebLocationId;
            window.objOSGlobal.requestBodyValues.FranchiseId = result[0].franchiseId;
            localStorage.setItem('estwebLocationId', window.objOSGlobal.requestBodyValues.WebLocationId);
            localStorage.setItem('estfranchiseId', window.objOSGlobal.requestBodyValues.franchiseId);
            window.objOSGlobal.paramsUI.brandPhoneNumber = result[0].phoneNumber;
            window.objOSGlobal.paramsUI.doingBusinessAs = result[0].doingBusinessAs;
            window.objOSGlobal.requestBodyValues.licenseNumber = result[0].licenseNumber;
            localStorage.setItem("estlicenseNumber",window.objOSGlobal.requestBodyValues.licenseNumber);
            window.objOSGlobal.paramsUI.dbaShortName=result[0].dbaShortName;
            successHandler();
        }
    }).catch(function (error:any) {
        stopLoader();
        var duplicate:any = error;
        duplicate = JSON.parse(duplicate["message"])?.message;
        if(conceptCode ==="mrr" && isCountry ==="ca" || croleadNoZipEnable){
            window.objOSGlobal.requestBodyValues.isFsaLead = true;
            successHandler();
        }else{
            if (duplicate.Message == "Full address is needed to narrow down results"){
                $(".tab-pane.fieldset").removeClass("active");
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
                $('.address-bumper-screen').addClass('active');
                $(".address-bumper-screen .back").show();
                emptyAddressFilds();
                sessionStorage.setItem("isAddressbumper", "true");
                findHelpAddressBumper(addressParam);
                }else{
                    $(".tab-pane.fieldset").removeClass("active");
                    $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.PostalCode}</span>`);
                    $(".no-service-screen").addClass("active");
                }
        }
        if(duplicate !== "Full address is needed to narrow down results") {    
                console.info('Error in fetching FranchiseLookUpByServiceAddress API data:', error);
        }
    })
    */
}
