import { loadAtStart } from "../scheduler/required-Items-on-Load";
import { searchServiceInitial } from "../search-service/search-service-initial";
import { filterByVisibility } from "./filter-by-visibility";
import { mrhCategotyListGenerator } from "./mrh-category-list";
import { mrhDynamicGetMenu } from "./mrh-Dynamic-get-menu";
import { mrhEnableImageObj } from "./mrh-global-variable";
import { mrh_innerSubMenuService } from "./mrh-innersubmenu-service";
import { mrh_rootarraymenu } from "./mrh-root-array-menu";

export function mrhHeaderDynamicLocalMenu(){
        //added for time being
        $('.nav-section a[href="/neighborly-done-right-promise/"]').parent().remove();
        let localMenu:any;
        let brandMenu:any;
        let categoryMenu:any;
        let localStorageDbaName:any = localStorage.getItem('dbaname');
        let localStorageStateCode:any = localStorage.getItem('stateCode');
        let brandName = $('#brandName').val();
        let countryCode = $('#countryCode').val();
        let localWeblocationId:any = $('#weblocationId').val();
        let dbaName:any = $('#dbaName').val();
            dbaName  = dbaName?.replace(' ','-');
        let stateCode:any = $("#stateCode").val() || (window.objOSGlobal.requestBodyValues.localStateCode && window.objOSGlobal.requestBodyValues.localStateCode.toLowerCase());
        if(dbaName && stateCode){
            dbaName = dbaName;
            localStorage.setItem('dbaname',dbaName);
            localStorage.setItem('stateCode',stateCode);
            localStorage.setItem('localWebLocationId',localWeblocationId);
        }else{
            dbaName = localStorageDbaName;
            stateCode = localStorageStateCode || (window.objOSGlobal.requestBodyValues.localStateCode && window.objOSGlobal.requestBodyValues.localStateCode.toLowerCase());
        }
        if(!dbaName){
            dbaName = new URL(window.objOSGlobal.requestBodyValues.locationWebsiteUrl)
            dbaName = dbaName?.pathname?.replaceAll('/','');
        }
        loadAtStart();
        let brandNavigationUrl = `${window.location.origin}/${countryCode}/en-${countryCode}/${brandName}/_assets/js/data/dynamic_menu.json`;
        let localNavigationUrl = `${window.location.origin}/${countryCode}/en-${countryCode}/_assets/${brandName}/${stateCode}/${dbaName}/js/data/dynamic_menu.json`;
        mrhDynamicGetMenu(brandNavigationUrl,function(result:any){
            let brandData =  result.serviceMenu;
            let brandOptions =  brandData["country"][0].brand[0].option;
            brandMenu = brandOptions;
            mrhDynamicGetMenu(localNavigationUrl,function(result:any){
                let menuLength:any;
                let brandCategories:any;
                let localCategories:any;
                let brndCat:any;
                let localData = result.serviceMenu;
                let localOptions =  localData["country"][0].brand[0].webLocation[0].option;
                let rootMenus:any = [];
                mrhEnableImageObj.enableImage = localData["country"][0].brand[0].enableImages;
                localMenu = localOptions;
                if(brandMenu?.length > localMenu?.length){
                    menuLength = localMenu.length;
                }else{
                    menuLength = brandMenu.length;
                }
                for(let i=0;i<menuLength;i++){
                    if(brandMenu[i].name == localMenu[i].name){
                        if(localMenu[i].category.length == 0 ){
                            brandCategories = brandMenu[i].services;
                            localCategories = localMenu[i].services;
                            brndCat = brandCategories.map((ser:any)=>{
                            return ser.name;
                            });
                        }else{
                            brandCategories = brandMenu[i].category;
                            localCategories = localMenu[i].category;
                            brndCat = brandCategories.map((cat:any)=>{
                            return cat.name;
                            });
                        }
                        localCategories.sort((a:any,b:any)=>{
                            let aIndex= brndCat.indexOf(a.name);
                            let bIndex = brndCat.indexOf(b.name);
                            if(aIndex == -1){
                            return 1
                            }
                            if(bIndex ==-1){
                                return -1
                            }
                            if(aIndex<bIndex){
                                return -1
                            }
                            if(aIndex>bIndex){
                                return 1
                            }
                            return 0
                        })
                        
                    }
                }
                let filterBrandMenu = filterByVisibility(localMenu);
                sessionStorage.setItem('filterService',JSON.stringify(filterBrandMenu));
                searchServiceInitial();
                for(let i = 0;i<filterBrandMenu.length;i++){
                    categoryMenu = filterBrandMenu[i].category;
                    mrh_rootarraymenu(filterBrandMenu[i],rootMenus);  
                }
                $('.main-nav .nav-section .nav.navbar-nav').prepend(rootMenus);
                for(let i = 0;i<filterBrandMenu.length;i++){
                    categoryMenu = filterBrandMenu[i]?.category || [];
                    mrhCategotyListGenerator(categoryMenu,filterBrandMenu[i])
                }
                for(let i = 0;i<filterBrandMenu.length;i++){
                    categoryMenu = filterBrandMenu[i]?.category || [];
                    mrh_innerSubMenuService(categoryMenu,filterBrandMenu[i])
                }
        });
    })
}