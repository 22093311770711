import { nestLocalStorage } from '../../config/NestLocalStorage';

export const setSessionRememberMe = () =>
{
    let decodeData = nestLocalStorage.decodedAccessToken;
    if (decodeData.isRememberMe == 'False')
    {
        sessionStorage.setItem('isRememberMeNotSelected', decodeData.isRememberMe);
    } else
    {
        localStorage.setItem('isRememberMeNotSelected', decodeData.isRememberMe);
    }
}
