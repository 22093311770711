import { stripURL } from "./stripURL";

/* * * * * * * * * * * * * * * * *
 * Pagination
 * javascript page navigation
 * * * * * * * * * * * * * * * * */
export const Pagination = {
    code: '',
    size: 0,
    page: 1,
    step: 3,
    e: (undefined as any),
    innerHTML: "",

    // --------------------
    // Utility
    // --------------------


    // converting initialize data
    Extend: function(data: any) {
        data = data || {};
        Pagination.size = data.size || 0;
        Pagination.page = data.page || 1;
        Pagination.step = data.step || 3;
    },

    // add pages by number (from [s] to [f])
    Add: function(s: any, f: any) {
        for (var i = s; i < f; i++) {
            Pagination.code += `<li class="page-item"><a id="pagination${i}" href="${stripURL(window.location.href)}/page/${i}" class="pagination-item text-sm">${i}</a></li>`;
        }
    },

    // add last page with separator 
   // Last: function() {
      //  Pagination.code += `<li class="page-item">[…]</li><li class="page-item"><a id="paginationLast" href="${stripURL(window.location.href)}/page/${Pagination.size}" class="pagination-item text-sm">${Pagination.size}</a></li>`;
   // },

      // add last page with separator as per the design 
    Last: function() {
       Pagination.code += `<li class="page-item"><a class="pagination-item text-sm"><span class="sr-only">Expand page</span>…</a></li><li class="page-item"><a id="paginationLast" href="${stripURL(window.location.href)}/page/${Pagination.size-1}" class="pagination-item text-sm">${Pagination.size-1}</a></li> <li class="page-item"><a id="paginationLast" href="${stripURL(window.location.href)}/page/${Pagination.size}" class="pagination-item text-sm">${Pagination.size}</a></li>`;
    },

    // add first page with separator
    First: function() {
        Pagination.code += `<li class="page-item"><a id="pagination1" href="${stripURL(window.location.href)}/page/1" class="pagination-item text-sm">1</a></li><li class="page-item"><a class="pagination-item text-sm"><span class="sr-only">Expand page</span>…</a></li>`;
    },
    // --------------------
    // Handlers
    // --------------------

    // change page
    Click: function() {
        Pagination.page = +this.innerHTML;
        Pagination.Start();
    },

    // previous page
    Prev: function() {
        Pagination.page--;
        if (Pagination.page < 1) {
            Pagination.page = 1;
        }
        Pagination.Start();
    },

    // next page
    Next: function() {
        Pagination.page++;
        if (Pagination.page > Pagination.size) {
            Pagination.page = Pagination.size;
        }
        Pagination.Start();
    },
    // --------------------
    // Script
    // --------------------

    // binding pages
    Bind: function() {
        var a = Pagination.e.getElementsByTagName('a');
        for (var i = 0; i < a.length; i++) {
            if (+a[i].innerHTML == Pagination.page) {
              // a[i].parentNode.classList.add('blackColor');
              a[i].setAttribute("aria-current", "page");
            }
        }
    },
    // write pagination
    Finish: function() {
        Pagination.e.innerHTML = Pagination.code;
        Pagination.code = '';
        Pagination.Bind();
    },
    // find pagination type
    Start: function() {
        if (Pagination.size < Pagination.step * 2 + 10) {
            Pagination.Add(1, Pagination.size + 1);
        }
        else if (Pagination.page < Pagination.step * 2 + 1) {
            Pagination.Add(1, Pagination.step * 2);
           // Pagination.Add(1, Pagination.step * 2 + 4);
            Pagination.Last();
        }
        else if (Pagination.page > Pagination.size - Pagination.step * 2) {
            Pagination.First();
            Pagination.Add(Pagination.size - Pagination.step * 2 - 2, Pagination.size + 1);
        }
        else {
            Pagination.First();
          // console.log(Pagination.size + "last-size" + Pagination.step + "step");
            Pagination.Add(Pagination.page - Pagination.step, Pagination.page + Pagination.step + 1);
            Pagination.Last();
        }
        Pagination.Finish();
    },
    // --------------------
    // Initialization
    // --------------------

    // binding buttons
    Buttons: function(e: any) {
        var nav = e.getElementsByTagName('a');
        nav[0].addEventListener('click', Pagination.Prev, false);
        nav[1].addEventListener('click', Pagination.Next, false);
    },
    
    // create skeleton
    Create: function(e: any) {
        const nextPage = Pagination.page == Pagination.size ? '#' : `${stripURL(window.location.href)}/page/${parseInt(Pagination.page as any) + 1}`;
        const prevPage = Pagination.page == 1 ? '#' : `${stripURL(window.location.href)}/page/${parseInt(Pagination.page as any) - 1}`;
        const firstPage	= Pagination.page == 1 ? '#' : `${stripURL(window.location.href)}/page/1`;
        const lastPage	= Pagination.page == Pagination.size ? '#' : `${stripURL(window.location.href)}/page/${(Pagination.size)}`;
        var html = [
            `<li><a class="pagination-item nav-arrow first ${firstPage == '#' ? 'disabled' : '' }" href="${firstPage}" aria-disabled="true" aria-label="First page">First</a></li>`,
                   `<li><a class="pagination-item nav-arrow prev ${prevPage == '#' ? 'disabled' : '' }" href="${prevPage}" aria-disabled="true" aria-label="Previous page">Back</a></li>`,
                   '<li><ul class="page-numbers"></ul></li>',
                   `<li><a class="pagination-item nav-arrow next ${nextPage == '#' ? 'disabled' : '' }" href="${nextPage}" aria-label="Next page">Next</a></li>`,
                   `<li><a class="pagination-item nav-arrow last ${lastPage == '#' ? 'disabled' : '' }" href="${lastPage}" aria-label="Last page">Last</a></li>`
           ]; 
           
               
                        
        e.innerHTML = html.join('');
        Pagination.e = e.getElementsByClassName('page-numbers')[0];
        // Pagination.Buttons(e);
    },

    // init
    Init: function(e: any, data: any) {
        Pagination.Extend(data);
        Pagination.Create(e);
        Pagination.Start();
    }
};