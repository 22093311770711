export const profileVars: {
    editId: any;
    type: any;
    neighborlyUserId: any;
    neighborlyAccountId: any;
    neighborlyAccountContactId: any;
    country:any;
    usStates:any;
    caStates:any;
    allowSubmit:boolean;
    deleteButton:boolean;
    validateBtn:boolean;
} = {
    editId: undefined,
    type: undefined,
    neighborlyUserId: undefined,
    neighborlyAccountId: undefined,
    neighborlyAccountContactId: undefined,
    country:'USA',
    usStates:[],
    caStates:[],
    allowSubmit:true,
    deleteButton:true,
    validateBtn:true,
};