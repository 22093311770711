import { $ } from "../third-party-imports";
import _ from "lodash";

let wkts:any = [];
let mapType: any = "";
let centroid: any = "";

$(function ()
{
    setTimeout(function ()
    {
    if ($(".map-container").length > 0) {
	$('.map-container').append('<div class = "gmap-loader"></div>');
        getwellKnownText();
    }
    }, 1000);
});

function multipleWkts(){
    _.map(wkts, function(wkt:any) {
        if(mapType == "polygon")
            initGMapPolygon();
        else
            initGMap(wkt);
    });
  }
  function initGMap(wkt:any){
    var geometry = Terraformer.WKT.parse(wkt);
   // var center = geometry?.coordinates[0][0][0]; // bug fix 111641
    var center = centroid.replace("POINT (", "").replace(')', "").split(" "); // bug fix 111641
    var data = {
            "type": "FeatureCollection",
                "features": [ {
                "type": "Feature",
                    "properties": {
                    "fillColor": "#1E90FF"
                },
                    "geometry": geometry
            }]
        };
        let zoomLevel:any = $("#hdn_zoomLevel").val() && Number($("#hdn_zoomLevel").val());
        var mapProp = {
            center: {lat: parseFloat(center[1]), lng: parseFloat(center[0])},
            zoom: zoomLevel ? zoomLevel : 8,
            zoomControl: true,
            scaleControl: true,
        };
    
        var map = new google.maps.Map(document.getElementById("gmap"), mapProp);
    
        map.data.addGeoJson(data);
        map.data.setStyle(function (feature:any) {
            var color = feature.getProperty('fillColor');
            return {
                fillColor: color,
                strokeWeight: 1,
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                fillOpacity: 0.35
            };
        });
    }

    function initGMapPolygon(){
        let map;
    
        let minX:any, minY:any, maxX:any, maxY:any;
    
        //function to add points from individual rings
        let addPoints = function(ptsArray:any, data:any) {
            //first spilt the string into individual points
            let pointsData = data.split(",");
            let str = "";
            //iterate over each points data and create a latlong
            //& add it to the cords array
            let len = pointsData.length;
            for (let i = 0; i < len; i++)
            {
                let xy = pointsData[i].trim().split(" ");
                let pt = new google.maps.LatLng(xy[1], xy[0]);
                str = str + ", " + xy[1] + " " + xy[0];
                ptsArray.push(pt);
    
                if (i === 0){ 
                // if first point 
                    minX = maxX = xy[1];
                    minY = maxY = xy[0];
                }
                else{
                    minX = Math.min(xy[1], minX);
                    minY = Math.min(xy[0], minY);
                    maxX = Math.max(xy[1], maxX);
                    maxY = Math.max(xy[0], maxY);
                }
            }
        }
        let createPoly = function(wkt:any) {
            
            //using regex, we will get the indivudal Rings
            let regex = /\(([^()]+)\)/g; 
            let Rings = [];
            let results;
    
            while (results = regex.exec(wkt)){
                Rings.push(results[1]);
            }
    
            //Rings.push(wkt);
    
            let ptsArray:any = [];
            let polyLen = Rings.length;
            let maxLen = 0;
                    //var maxLen2 = 0;
            let j = 0;
                    //var k = 0;
    
            /*
            //now we need to draw the polygon for each of inner rings, but reversed
            for (var i = 0; i < polyLen; i++) {
                  addPoints(ptsArray, Rings[i]);
            }
            */
    
    
            for (var i = 0; i < polyLen; i++){
                if (maxLen <= Rings[i].length){
                    maxLen = Rings[i].length;
                    j = i;
                }
            }
     
            addPoints(ptsArray, Rings[j]);
                    //addPoints(ptsArray, Rings[k]);  
    
    
        var poly = new google.maps.Polygon({
            paths: ptsArray,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#1E90FF',
            fillOpacity: 0.35
        });
            return poly;
    
        };
    
    var centerLatitude:any, centerLongitude:any;
    
    _.map(wkts, function(wkt:any) {
        var poly = createPoly(wkt);
        centerLatitude = $("#hdnLatitude").val();
        centerLongitude = $("#hdnLongitude").val();
    
       if ($("#hdnLatitude").val() === "" && $("#hdnLongitude").val() === "")
          {
           centerLatitude = ((maxX + minX) / 2);
          centerLongitude = ((maxY + minY) / 2);  
       }
    
        //console.log("lat : " + centerLatitude + " long : " + centerLongitude);
        let zoomLevel:any = $("#hdn_zoomLevel").val() && Number($("#hdn_zoomLevel").val());
        var myOptions = {
                zoom: zoomLevel ? zoomLevel :8.8,
                center: new google.maps.LatLng(centerLatitude, centerLongitude),
                zoomControl: true,
                scaleControl: true,
            };
            
        map = new google.maps.Map($('#gmap')[0], myOptions);
        var bounds = new google.maps.LatLngBounds();
        poly.getPath().forEach(function (path, index) {
            bounds.extend(path);
        });
        map.fitBounds(bounds);
        poly.setMap(map);
        return poly;
        });
    }

function getwellKnownText(){
  var franchiseId = $("#hdnFranchiseId").val();
  var apiDomain = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? window.DEFINE_VALUE.JS_API_URL : "https://api.neighborly.com";
  var apiKey = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? window.DEFINE_VALUE.JS_API_KEY : "pneiweb-9pbaOywNKQj853D";
  var digitalorchestrations = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? window.DEFINE_VALUE.JS_DigitalOrchestrations_URL : "digitalorchestrations-api";
  
  //var apiUrl = `${apiDomain}/${digitalorchestrations}/v1/public/franchiseTerritories/postalCodes/${franchiseId}`;
  var apiUrl = `${apiDomain}/${digitalorchestrations}/v1/public/franchiseGroupOrchestrator/0/${franchiseId}`;
  var transactionID = sessionStorage.getItem('corelationID');
  var sessionID = sessionStorage.getItem('sessionID');
  let correlationId = `correlationId=${transactionID}&sessionId=${sessionID}`;
  var isMapDraw = 0;
$('.gmap-loader').addClass('loader-wrapper-test loader-ring-test');
    $.ajax({
        type: "GET",
        url: apiUrl,
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'apikey': apiKey,
            'X-Corellation-Id': correlationId
        },
        success: function(result:any, status:any, xhr:any) {
            setTimeout( function(){
                $('.gmap-loader').removeClass('loader-wrapper-test loader-ring-test');
            },1000);
		    let territoryData = result.franchiseTerritory;
            $.each(territoryData.franchiseTerritories, function(key:any, value:any) {
            if ( isMapDraw === 0){
                if(value.franchiseWebLocationId == $("#weblocationId").val() && value.comment.includes("website")){
                    var wktData = value.territoryBoundary.wellKnownText;
                    wkts.push(wktData);
                    mapType = wktData.split(" ")[0].toLowerCase();
                    isMapDraw = 1;
                    centroid = value.territoryBoundary.centroid;
                }
            }
        });

    if (isMapDraw === 1){
        multipleWkts();
    }
},
    error: function(xhr:any, status:any, error:any) {
	 $('.gmap-loader').removeClass('loader-wrapper-test loader-ring-test');
        }
    });
}