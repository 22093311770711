
import { b2cPolicies, getMSALInstance } from '../../config';
import { $ } from "../../third-party-imports"

export function changePassword()
{
    const myMSALObj = getMSALInstance();
    $('.popupModalDialog').hide();
    myMSALObj.loginRedirect(b2cPolicies.authorities.forgotPassword);
}
