import { apiConfig } from "..";
import {$} from "../third-party-imports"
export function Get_Diagnostic_Fee_API_MRA() {
    let getDigFeeIndex:any;
    const DefaultDigFee:any = 99;
    let FinalDigFee:any;
    try {
        var str_weblocationId = window.objOSGlobal.requestBodyValues.WebLocationId;
        var transactionID = sessionStorage.getItem('corelationID');
        var sessionID = sessionStorage.getItem('sessionID');
        let correlationId = `correlationId=${transactionID}&sessionId=${sessionID}`;
        $.ajax({
            type: "GET",
            url: apiConfig.franchiseWebLocationProfileFees + str_weblocationId,
            headers:{
                'Content-Type': 'application/json',
                'apikey': window.DEFINE_VALUE.JS_API_KEY,
                'X-Corellation-Id': correlationId
             },
            dataType: 'json',
            success: function (data:any) {
                var resultArray = data;
                if(resultArray.length !== 0){
                    $.each(resultArray, function (index: any, resultArray: any){
                        if (resultArray.name.trim().toLowerCase() == "diagnostic fee"){
                            getDigFeeIndex = index;
                        }
                    });
                
                    if(getDigFeeIndex !== undefined){
                        FinalDigFee = resultArray[getDigFeeIndex].defaultFee;
                    }
                    else{
                        FinalDigFee = DefaultDigFee;
                    }

                } else {
                    FinalDigFee = DefaultDigFee;
                }
                $('.mra-diagnose-fee').text('$'+FinalDigFee);
                sessionStorage.setItem('mra-diagnose-fee', FinalDigFee);

            //     let DigFeeObj = data.filter((obj:any) => obj.name.includes("Diagnostic"));
            //     if(DigFeeObj.length > 0){
            //         let mostRecentFee = DigFeeObj.reduce((a:any, b:any) => a.createdDateTime > b.createdDateTime ? a : b);
            //         if(!mostRecentFee || mostRecentFee !=null){
            //             $('.mra-diagnose-fee').text('$'+Math.round(mostRecentFee.defaultFee));
            //         }
            //      }else{
            //         $('.mra-diagnose-fee').text("");
            //      }
            },
            error: function (error:any) {
                $('.mra-diagnose-fee').text('$'+DefaultDigFee);
            }
        });
    } catch (e) {
        $('.mra-diagnose-fee').text("");
    }
}