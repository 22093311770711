import { getBrandDetailsFromBrandJson } from "../util/util";
import {$} from "../third-party-imports";
import { ValidateZipPostalCode, croEnableForFindLocation } from "./ValidateZipPostalCode";
import { hereMapScriptLoad } from "./near-by-location";

const locationSearch:any = document.getElementById('locationSearch_change_loc');
const changeZipLock:any = document.getElementById('clickabletext_change_loc');

const clickLocationGps =  document.getElementById('click-location-gps');

$(window).on("load", function (){
   if(changeZipLock){
        changeZipLock.addEventListener('click',function(this:any){
                getCurrentLocation(function(zipcode:any){
                    locationSearch.value = zipcode;
                    if(checkCroEnhanceZipEntryModal()){
                        $(".location-services-change").valid();
                    }else{
                        validateZipCode();
                    }
                    
                });
        });
        if(!checkCroEnhanceZipEntryModal()){
            locationSearch.addEventListener('click',function(){
                const locationIcon:any  = document.querySelector('.location-icon');
                locationIcon.classList.add('open');
            });
        
            locationSearch.addEventListener('search',function(){
                const errorMessage = document.getElementById("message_change_loc");
                errorMessage.innerHTML = "";
                locationSearch.classList.remove("locationerror");
            });
        
            locationSearch.addEventListener('keyup', function(this:any){
                validateZipCode();
                const errorMessage = document.getElementById("message_change_loc");
                if(locationSearch.value.length === 0){
                    errorMessage.innerHTML = "";
                    locationSearch.classList.remove("locationerror");
                }
            }); 
            
        }
   }

   if(clickLocationGps){
       clickLocationGps.addEventListener('click', function (this: any)
       {
           getCurrentLocation(function (zipcode: any)
           {
               $('.location-services input[name="locationSearch"]').val(zipcode);
               $(".zip-entry-screen .location-services").valid();
           });
       });
   }
   
    $(".location-services .field-close-icon").on("click", function (e:any)
    {
        e.preventDefault();
        $("#locationSearch").val('');
        $(".location-services").valid();
    });

    $("#hero-location-gps").on("click", function (){
        getCurrentLocation(function (zipcode: any)
           {
               $('#inputHeroZipCode').val(zipcode);
               if(!$(".banner-text-sec .navbar-form").valid()){
                $('.banner-text-sec #inputHeroZipCode').addClass("locationerror");
                $('.banner-text-sec .field-close-icon').addClass("locationerror");
                $('.banner-text-sec #hero-location-gps').addClass("gps-icon-error");
               }
           });
    });

    $(".zip-code-search .field-close-icon").on("click", function (e:any)
    {
        e.preventDefault();
        $("#inputHeroZipCode").val('');
        $(".banner-text-sec .navbar-form").valid();
        $('.field-close-icon').addClass("d-none");
        $('.banner-text-sec #inputHeroZipCode').addClass("locationerror");
        $('.banner-text-sec #hero-location-gps').addClass("gps-icon-error");
    });
    $('#inputHeroZipCode').keyup(function (){
        if ($('#inputHeroZipCode').val()){
            $('.field-close-icon').removeClass("d-none");
        } else{
            $('.field-close-icon').addClass("d-none");
        }
    });
});
async function getCurrentLocation(callback:any){
    if (navigator.geolocation)
    {
        const { LatLng, Geocoder } =  await google.maps;
        navigator.geolocation.getCurrentPosition(function (position)
        {
            var lat = position.coords.latitude;
            var long = position.coords.longitude;
            var point = new LatLng(lat, long);
            var zipcode = '';
            new Geocoder().geocode({ "location": point }, function (res:any, status:any)
            {
                var address = res[0].address_components;
                for (var i = 0; i < address.length; i++)
                {
                    if (address[i].types.includes("postal_code"))
                    {  
                         zipcode = address[i].short_name;
                         if(callback){
                            callback(zipcode);
                         }	
                     }
                }
            });
        });
        $('.banner-text-sec .field-close-icon').removeClass("d-none");
    }
}

export function validateZipCode() {
    let country = $("#countryCode").val();
    const postalCode:any = locationSearch.value;
    let zipCode = postalCode.toString().trim();
    let zipCodePattern:any = /^([0-9]{5}|[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9])$/;
    if(croEnableForFindLocation() && country.toLowerCase() ==='ca'){
        if(zipCode.length > 3){
            zipCodePattern = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        }else{
            zipCodePattern =  /^[A-Za-z]\d[A-Za-z]/;
        }
    }
    let message = document.getElementById("message_change_loc") || document.getElementById('locationSearch_change_loc');
    if (zipCodePattern.test(zipCode)) {
            message.innerText= "";
            locationSearch.classList.remove("locationerror");
            return true;
    } else {
        message.innerText= "Invalid Zip/Postal Code.";
        locationSearch?.classList.add("locationerror");
        return false;
    }   
}

export async function checkCroEnhanceZipEntryModal(){
        let brandJsonItems:any;
        if(!window.objOSGlobal.requestBodyValues.brandJsonGlobalObj) {
            const result = await getBrandDetailsFromBrandJson();
            if (result.length > 0) {
                brandJsonItems = $.grep(result, function (e:any) { return e.code === $('#conceptCode').val()});
                window.objOSGlobal.requestBodyValues.brandJsonGlobalObj = brandJsonItems;
                
            }
        }else {
            brandJsonItems = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj
        }

        if(brandJsonItems){
            if(brandJsonItems.length) {
                if(brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match){
                    hereMapScriptLoad()
                }
                return brandJsonItems[0].enable_cro_enhance_zip_entry_modal ? brandJsonItems[0].enable_cro_enhance_zip_entry_modal : false;
            }
        }
        return false;
}

$(".header-classic.desktop-header .top-menu .locate-form input[name='zipcode']").keyup(()=>{
    let inputVal =$(".header-classic.desktop-header .top-menu .locate-form input[name='zipcode']").val();
    console.log(inputVal);
    ValidateZipPostalCode(inputVal,"desk",'');
})
$(".header-classic.mobile-header .top-menu .locate-form input[name='zipcode']").keyup(()=>{
    let inputVal =$(".header-classic.mobile-header .top-menu .locate-form input[name='zipcode']").val();
    ValidateZipPostalCode(inputVal,"desk",'');
})