import { $ } from "../third-party-imports"
import { apiConfig } from "../config";
import { linkAndUnlinkSocial } from "./linkAndUnlinkSocial";
import { setMultipleContact } from "./setMultipleContact";
import { setMultipleaddress } from "./setMultipleaddress";
import { setProfileName } from "./setProfileName";
import { setProfileEmail } from "./setProfileEmail";
import { getNblyMicroServiceApiHeaders, getNblyApiHeaders } from "../auth/api";

export function updateProfile(callback?: Function)
{
    /*To show the loader only in online scheduler flow so commenting the loader [+57326] 
    start_loader();
    */
    $.ajax({
        url: apiConfig.profileApi+"?"+getNblyApiHeaders(), //+ profileVars.neighborlyUserId,
        headers:  getNblyMicroServiceApiHeaders(),
        type: "GET",
        success: function (data, status)
        {
            // stop_loader();
            $("body").find('header#site-header').removeClass('hideHeader');
            $('#js-popupModalDialog .popupModalbody').removeClass("padfingTop40");
            $('#js-popupModalDialog .closeDialogCont').show();
            $('#js-popupModalDialog .close.js-closePopUpModal').show();
            $('#js-popupModalDialog  #editPhone .top_txt .delete').show();
            $('.text-withedit').remove();
            localStorage.setItem('profile', JSON.stringify(data));
            var profile = JSON.parse(localStorage.getItem('profile'));
            //getProfileState();
            setProfileName(profile);
            setProfileEmail(profile);
            setMultipleContact(profile);
            setMultipleaddress(profile);
            linkAndUnlinkSocial(profile);
            if (callback)
            {
                callback();
            }
        },
        error: function (xhr, status, error)
        {
            // stop_loader();
            // console.log(error);
        }
    });
}
