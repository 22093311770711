export function removePreFilledData(){
    $('.personal-info-form  input[name="inputFirstName"]').val('');
    $('.personal-info-form  input[name="inputLastName"]').val('');
    $('.personal-info-form  input[name="email"]').val('');
    $('.personal-info-form  input[name="inputPhnNumber"]').val('');
    $('.personal-info-form  input[name="inputAddress"]').val('');
    $('.personal-info-form  input[name="apartment"]').val('');
    $('.personal-info-form  input[name="inputcity"]').val('');
}

export function removeProgressBarOnLocalHelp(){
    $('#service-entry-btn').removeClass('js-next-btn');
    $('a[data-target="#js-schedulerDialog"]').click(function(){
        $('.progress, .progressbar').hide();
    });
}