export function getServiceType(serviceType:any){
    switch(serviceType){
        case "1":
        serviceType = 'auto' 
        break;
        case "2":
        serviceType = "Emergency";
        break;
        case "3":
        serviceType = "Commercial";
        break;
        default:
        serviceType = "Residential";
    }
   return serviceType;
}