export function phoneFormatter(fieldName:string='inputPhnNumber')
{
    const inputs = document.querySelectorAll(`[name="${fieldName}"]`);
    if (inputs)
    {
        for (let i = 0; i < inputs.length; i++)
        {
            let element = inputs[i];
            element.addEventListener('input', function (e:any)
            {
                let x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            });
        }
    }
}