import { apiCaching, apiConfig, getCachedAPIResponse } from "..";
import { $ } from "../third-party-imports";
import { GetLeadSourceMolly } from "./GetLeadSourceMolly";

export async function GetReferenceDetails() {
    const brandName = $("#brandName").val();
    if( brandName.toLowerCase() === 'molly-maid'){
        GetLeadSourceMolly();
    }
    else{
        var apiUrl;
        if(window.objOSGlobal.requestBodyValues.FranchiseId){
            apiUrl = apiConfig.GenericLeadSources+'&franchiseId='+window.objOSGlobal.requestBodyValues.FranchiseId;  
        }
        else{
            apiUrl = apiConfig.GenericLeadSources;
        }

        $('#estimateBedroomsThree').empty();
        $('#blogAboutUs').empty();
        try{
           const result = await getCachedAPIResponse(apiUrl, apiCaching.Genericleadsources);
           if(result){
            if (result.length > 0) {
                var json_items = $.grep(result, function (e:any) { return e.isActive === true; });    
                if (json_items.length > 0) {
                    json_items.sort(function(x: any,y: any){
                        return x.leadSourceName.localeCompare(y.leadSourceName);
                    });
                    window.objOSGlobal.leadSourceObj = json_items.map(
                        function (item:any) {
                            return {"leadSourceName":item.leadSourceName,"referralTypeId":item.referralTypeId};
                        });  
                    $('#serviceDetails #referenceDetails').find('option').remove();
                    $('.mra-contact-information-screen .referenceDetails').find('option').remove();
                    $('#contactusformMly #referenceDetails').find('option').remove();
                    $('#inputreason').find('option').remove();
                    $('#inputHearAbout').find('option').remove();
                    $('form#contactusformMly #referenceDetails').append(new Option("-Select one"));
                    for (var i = 0; i < json_items.length; i++) {
                        if (i===0){
                            // $('form#serviceDetails #referenceDetails').append(new Option($('.service-details-screen #sd_lbl_referenceDetails').text().trim().replace("*",""), '0'));
                            $('.mra-contact-information-screen #referenceDetails').append(new Option("-Select one",""));
                            $('#serviceDetails #referenceDetails').append(new Option("-Select one",""));
                            $('#estimateBedroomsThree').append(new Option("-Select one"));
                            $('#blogAboutUs').append(new Option("Select", ""));
                            $('#inputHearAbout').append(new Option("Select", ""));
                        }
                        if($("#brandName").val() == "mr-appliance"){
                            $('.referenceDetails').append(new Option(json_items[i].leadSourceName, json_items[i].syncGenericLeadSourceId));
                            $('#estimateBedroomsThree').append(new Option(json_items[i].leadSourceName, json_items[i].syncGenericLeadSourceId));
                            $('#blogAboutUs').append(new Option(json_items[i].leadSourceName, json_items[i].syncGenericLeadSourceId));
                        }else{
                            $('form#contactusformMly #referenceDetails').append(new Option(json_items[i].leadSourceName, json_items[i].syncGenericLeadSourceId));
                            $('#serviceDetails #referenceDetails').append(new Option(json_items[i].leadSourceName, json_items[i].syncGenericLeadSourceId));
                            $('#estimateBedroomsThree').append(new Option(json_items[i].leadSourceName, json_items[i].syncGenericLeadSourceId));
                            $('#blogAboutUs').append(new Option(json_items[i].leadSourceName, json_items[i].syncGenericLeadSourceId));
                            $('#inputHearAbout').append(new Option(json_items[i].leadSourceName, json_items[i].syncGenericLeadSourceId));
                        }
                    }
                    
                    $('#referenceDetails option[value=""]', '#inputreason option[value=""]', '#blogAboutUs option[value=""]').attr('selected','selected');
                }
            }
           }
        }catch(error: any){
            console.error(`Error in fetching generic lead source api response Error message: ${error}`);
        }
    }
}