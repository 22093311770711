import { detectMob } from "../util/detect-mob";

export function mrhCategotyGeneateListArray(categoryList: any, root: any)
{
    let mob = detectMob()
     let dataId:any = categoryList.name.replace(/[^A-Z0-9]+/ig, "").toLowerCase();
    let img = categoryList.icons.icon;
    // let hoverImg = categoryList.icons.hoverIcon;
    let alt = categoryList.icons.iconAlt;
    if(!mob) {
    return `<li class="list-item top-menu-cat" data-id="${dataId}">
   <button class="js-mega-menu-btn arrow-right-link" data-target="menu${root.id}">
   <img src="${img}" alt="${alt}" class="img-hover" fetchpriority="low" loading="lazy" width = "50px" height = "50px">
   <span>
   <span class="sr-only">Click to view &nbsp;</span>${categoryList.name} </span>
   </button>
   </li>`
    }
    // else {
    //     return `<li class="list-item mob-top-menu-cat" data-id="${dataId}">
    //     <button class="js-mega-menu-btn arrow-right-link" data-target="menu${root.id}">
    //     <img src="${img}" alt="${alt}" class="img-hover">
    //     <span class= "custom-sr-only">
    //     <span class="sr-only">Click to view &nbsp;</span>${categoryList.name} </span>
    //     </button>
    //     </li>`
    // }
}