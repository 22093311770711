export function displayAddress(data:any) { 
    var cAddr = $('#p_contact_hours_localdbaaddress').clone().children().remove().end().text();
    /* local schema */
    let structuredData = document.getElementById('franchise-addr');
    
    let addressBlock:any = [];
    let contactAddressBlock:any = [];

    if (data.displayAddressSwitch){
        let streetaddr = data.address;
        let stateCityZip = data.city;
        if(data.address != '' && data.address != null){
            addressBlock.push(generateSpanFooter(data.address));
            contactAddressBlock.push(generateSpanFooter(data.address));
            streetaddr = data.address;
        }
        if(data.address != '' && data.address != null && data.address2 != '' && data.address2 != null){
            streetaddr = data.address + ", " + data.address2;
        }
        if (data.address2 != '' && data.address2 != null){
            addressBlock.push(generateSpanFooter(data.address2));
            contactAddressBlock.push(generateSpanFooter(data.address2));
            streetaddr = data.address2;
        }
        if (data.city != '' && data.city != null && data.state != '' && data.state != null && data.zip != '' && data.zip != null){
            stateCityZip = data.city + ", " + data.state + " " + data.zip;
        }
        else if(data.city != '' && data.city != null && data.state != '' && data.state != null){
            stateCityZip = data.city + ", " + data.state;
        }
        else if(data.city != '' && data.city != null && data.state != '' && data.state != null){
            stateCityZip = data.city + ", " + data.state;
        }
        else if(data.state != '' && data.state != null && data.zip != '' && data.zip != null){
            stateCityZip = data.state +" "+ data.zip;
        }
        else if(data.city != '' && data.zip != '' && data.zip != null){
            stateCityZip = data.city +", "+ data.zip;
        }
        else if(data.city != '' && data.state != '' && data.state != null){
            stateCityZip = data.city +", "+ data.state;
        }
        else{
            stateCityZip = stateCityZip;
        }
        if(stateCityZip){
            addressBlock.push(generateSpanFooter(stateCityZip));
            contactAddressBlock.push(generateSpanFooter(stateCityZip));
        }

        if (typeof (data.stateLicenseNumbers) !== 'undefined') {
            let licenseNumbers:any =[];
            if(data.stateLicenseNumbers.length !=1){
                licenseNumbers = data.stateLicenseNumbers.join(', ');
            }else{
                $.each(data.stateLicenseNumbers, function (index, licenseNumber) {
                    licenseNumbers = licenseNumber;
                });
            }
            if (licenseNumbers !== "" && licenseNumbers !== "N/A" && licenseNumbers != null) {
                let combineText = "License: #" + licenseNumbers;
                addressBlock.push(generateSpanFooter(combineText));
            }
        }

        $('.address').html(addressBlock);
        $('#p_contact_hours_localdbaaddress').html(contactAddressBlock);

        if (structuredData != null) {
            let parsedSD = JSON.parse(structuredData.innerText);
            if (typeof parsedSD.address !== "undefined") {
                parsedSD.address.streetAddress = streetaddr;
                structuredData.innerText = JSON.stringify(parsedSD);
            }     
        }
        // var streetaddr = data.address;
        // var contactAddr = data.address;
        // if (data.address != '' && data.address != null && data.address2 != '' && data.address2 != null) {
        //     streetaddr = data.address + ", " + data.address2;
        //     contactAddr = data.address + " " + data.address2;
        // }
        // else if (data.address2 != null && data.address2 != '') {
        //     streetaddr = data.address2;
        //     contactAddr = data.address2;
        // }
        // else {
        //     streetaddr = streetaddr;
        // }
        // /* contact-us addr block--start */                    
        // if (cAddr == '') {            
        //     $('#p_contact_hours_localdbaaddress').prepend(contactAddr);
        // }
        // /* contact-us addr block--ends */

        // /* Footer */
        // if (data.address2 != null && data.address2 != '' && $('.address span').text().indexOf(data.address2) == -1) {
        //     $('.address').prepend(`<span>${data.address2}</span>`);
        // }

        // if (data.address != null && data.address != '' && $('.address span').text().indexOf(data.address) == -1) {
        //     $('.address').prepend(`<span>${data.address}</span>`);            
        // }

        // if (structuredData != null) {
        //     let parsedSD = JSON.parse(structuredData.innerText);
        //     parsedSD.address.streetAddress = streetaddr;
        //     structuredData.innerText = JSON.stringify(parsedSD);            
        // }
        
    }
    else {
        let stateCityZip = data.city;
        if (data.city != '' && data.city != null && data.state != '' && data.state != null && data.zip != '' && data.zip != null){
            stateCityZip = data.city + ", " + data.state + " " + data.zip;
        }
        else if(data.city != '' && data.city != null && data.state != '' && data.state != null){
            stateCityZip = data.city + ", " + data.state;
        }
        else if(data.city != '' && data.city != null && data.state != '' && data.state != null){
            stateCityZip = data.city + ", " + data.state;
        }
        else if(data.state != '' && data.state != null && data.zip != '' && data.zip != null){
            stateCityZip = data.state +" "+ data.zip;
        }
        else if(data.city != '' && data.zip != '' && data.zip != null){
            stateCityZip = data.city +", "+ data.zip;
        }
        else if(data.city != '' && data.state != '' && data.state != null){
            stateCityZip = data.city +", "+ data.state;
        }
        else{
            stateCityZip = stateCityZip;
        }
        if(stateCityZip){
            addressBlock.push(generateSpanFooter(stateCityZip));
            contactAddressBlock.push(generateSpanFooter(stateCityZip));
        }
        if (typeof (data.stateLicenseNumbers) !== 'undefined') {
            let licenseNumbers:any =[];
            if(data.stateLicenseNumbers.length !=1){
                licenseNumbers = data.stateLicenseNumbers.join(', ');
            }else{
                $.each(data.stateLicenseNumbers, function (index, licenseNumber) {
                    licenseNumbers = licenseNumber;
                });
            }
            if (licenseNumbers !== "" && licenseNumbers !== "N/A" && licenseNumbers != null) {
                let combineText = "License: #" + licenseNumbers;
                addressBlock.push(generateSpanFooter(combineText));
            }
        }
        //contact-us addr block
        // if (cAddr.length) {
        //     $('#p_contact_hours_localdbaaddress')[0].childNodes[0].remove();
        // }

        // if (data.address != '' && $('.address span').text().indexOf(data.address) != -1) {
        //     $('.address').find('span:first').remove();
        // }

        // if($('.address span').text().indexOf(data.address) == -1 && data.address2 != "" && $('.address span').text().indexOf(data.address2) != -1) { 
        //     $('.address').find('span:first').remove();
        // }

        if (structuredData != null) {
            let parsedSD = JSON.parse(structuredData.innerText);
            if (typeof parsedSD.address !== "undefined") {
                delete parsedSD.address.streetAddress;
                structuredData.innerText = JSON.stringify(parsedSD);
            }
        }
        $('.address').html(addressBlock);
        $('#p_contact_hours_localdbaaddress').html(contactAddressBlock);
    }
}

function generateSpanFooter(data:any){
    if(data.includes("null")) {
        return `<span class="d-none">${data}</span>`
    }else {
        return `<span>${data}</span>`
    }
    
}