export function storeAllfieldsValue($button: any)
{
    var $current_step = $button.parents('.tab-pane');
    const $name_fields = $current_step.find('.form-control');
    $name_fields.each(function (i: any, $element: any)
    {
        const $field = $($element);
        let $name: any = $field.attr('name');
        if ($field.is(':visible'))
        {
            if ($current_step[0].id == 'step2')
            {
                if ($field.is(':radio') || $field.is(':checkbox'))
                {
                    let $checked = $current_step.find('input[name="' + $name + '"]:checked');
                    window.objOSGlobal.requestBodyValues.conatctInformation[$name] = $checked.val();
                } else
                {
                    window.objOSGlobal.requestBodyValues.conatctInformation[$name] = $field.val();
                }
            }
            else if($current_step[0].id == 'step3'){
                window.objOSGlobal.requestBodyValues.serviceDetails[$name] = $field.val();
                storeglobalObjectValue();
            }
            else
            {
                window.objOSGlobal.requestBodyValues[$name] = $field.val();
            }
        }
    });
}

export function storeglobalObjectValue(){
    let {inputFirstName,inputLastName,inputEmail,inputPhnNumber,inputAddress,inputAddress2,inputcity,inputstate,inputZipCode}  =window.objOSGlobal.requestBodyValues.conatctInformation;
    window.objOSGlobal.requestBodyValues.FirstName = inputFirstName;
    window.objOSGlobal.requestBodyValues.LastName = inputLastName;
    window.objOSGlobal.requestBodyValues.Email = inputEmail?.trim();
    window.objOSGlobal.requestBodyValues.Phone = inputPhnNumber;
    window.objOSGlobal.requestBodyValues.Address = inputAddress;
    window.objOSGlobal.requestBodyValues.Address2 = inputAddress2;
    window.objOSGlobal.requestBodyValues.City = inputcity;
    window.objOSGlobal.requestBodyValues.State = inputstate;
    window.objOSGlobal.requestBodyValues.PostalCode = inputZipCode;
    var addressParamsVal:any = inputAddress + "&" + inputAddress2 + "&" + inputcity + "&" + inputstate + "&" + window.objOSGlobal.requestBodyValues.PostalCode;
    return addressParamsVal
}

export function serviceDetails() {
    window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = $('#referenceDetails option:selected').val();
    window.objOSGlobal.paramsUI.serviceDetailsSelectedText = "Lead Source: " + $('#referenceDetails option:selected').text();
}