import { $ } from "../third-party-imports"
import { getBrandDetailsFromBrandJson } from "../util/util";

export async function inputCare()
{
    const searchEl = document.getElementById('inputCare') as HTMLInputElement | null;
    const searchValue = searchEl?.value?.trim() ?? "";
    const searchRegex = /^[a-zA-Z0-9-\s]+$/g;
    const errMsg = document.querySelector('#searchInputErrMsg');

    if (!searchRegex.test(searchValue))
    {

        if (errMsg)
            return;

        const errorElem = `<div class="error help-block invalid-feedback" id="searchInputErrMsg">Please enter a valid search term</div>`;
        $('.srchBtnn').after(errorElem);

        if (window.g2Data.response.numFound === 0)
        {
            $('.pagination-section').remove();
        }

        return;
    }

    if (errMsg)
    {
        $('#searchInputErrMsg').remove();
    }

    if (!searchValue)
        return;
    let searchTermUpdated = searchValue.replace(/\s/g, '-');
    searchTermUpdated = searchTermUpdated.replace(/\'/g, '#');
    let domainType = window.DEFINE_VALUE.Domain_Type;
    var concepId = $('#conceptId').val();
    let country = $("#countryCode").val();
    let brandName = $('#brandName').val();
    if (domainType == "digital")
    { 
        const localDBA = window.g2Data.responseHeader.params.fq[0].toLowerCase().replace("custom_s_local_dba:","").replace("(","").replace(" or none)","");
        
        const result = await getBrandDetailsFromBrandJson();
        if (result.length > 0)
        {
            let brandJsonObj = result.find((item: any) => item.id === concepId);
            let brandblogURLName: any;
            let brandlocalblogURLName: any;
            if (brandName == 'my-handyman')
            {
                brandblogURLName = brandJsonObj ? brandJsonObj.AlternateBrandList[0].alternate_brand_blog_url_name : "";
                brandlocalblogURLName = brandJsonObj ? brandJsonObj.AlternateBrandList[0].alternate_brand_local_blog_url_name : "";
            } else
            {
                brandblogURLName = brandJsonObj ? brandJsonObj.brand_blog_url_name : "";
                brandlocalblogURLName = brandJsonObj ? brandJsonObj.brand_local_blog_url_name : "";
            }
            if (localDBA == "none")
            {
                window.location.href = encodeURI(`${window.location.origin}/${brandblogURLName}/search/${encodeURIComponent(searchTermUpdated)}`);
            }
            else
            {
                window.location.href = encodeURI(`${window.location.origin}/${localDBA}/${brandlocalblogURLName}/search/${encodeURIComponent(searchTermUpdated)}`);
            }
        }
    }
    else
    {
        var afterCom = window.location.href.split('.com')[1];
        // var envionment =window.location.hostname;
        // var devEnv = "www-nei.nblydev.com";
        var brandNameUS = afterCom.split('/')[1];
        var brandNameCA = afterCom.split('/')[2];

        var isWebSite = afterCom.split('/')[1].replace(/[_\W]+/g, "") === "ca" ? "ca" : "us";

        if (isWebSite == 'ca')
        {
            if (afterCom.split("/")[5] != undefined)
            {
                if (afterCom.split("/")[5].indexOf("expert-tips") != -1)
                {
                    window.location.href = encodeURI(`${window.location.origin}/ca/${brandNameCA}/${afterCom.split("/")[3]}/${afterCom.split("/")[4]}/expert-tips/search/${encodeURIComponent(searchTermUpdated)}`);
                }
                else
                {
                    window.location.href = encodeURI(`${window.location.origin}/ca/${brandNameCA}/expert-tips/search/${encodeURIComponent(searchTermUpdated)}`);
                }
            }

            else
            {
                window.location.href = encodeURI(`${window.location.origin}/ca/${brandNameCA}/expert-tips/search/${encodeURIComponent(searchTermUpdated)}`);
            }
        }
        else
        {
            if (afterCom.split("/")[4] != undefined)
            {
                if (afterCom.split("/")[4].indexOf("expert-tips") != -1)
                {
                    window.location.href = encodeURI(`${window.location.origin}/${brandNameUS}/${afterCom.split("/")[2]}/${afterCom.split("/")[3]}/expert-tips/search/${encodeURIComponent(searchTermUpdated)}`);
                }
                else
                {
                    window.location.href = encodeURI(`${window.location.origin}/${brandNameUS}/expert-tips/search/${encodeURIComponent(searchTermUpdated)}`);
                }
            }
            else
            {
                window.location.href = encodeURI(`${window.location.origin}/${brandNameUS}/expert-tips/search/${encodeURIComponent(searchTermUpdated)}`);

            }
        }
    }
}