import { $ } from "../third-party-imports";
export function paginationMeetTheTeam(){
    let pageSize = 9;
    let curPage = 0;
    let pageCount =  $(".image-card").length / pageSize;
    let currentIndex:any;
    let item = [];
    for(let i = 1; i<=pageCount;i++){
        item.push(i);
    }
    $('#current_page').val(1);
    $('#show_per_page').val(pageCount);
    render();
    let showPage = function(page:any) {
        $(".image-card").hide();
        $(".image-card").each(function(this:any,n:any) {
            if (n >= pageSize * (page - 1) && n < pageSize * page)
                $(this).show();
        });        
    }
    showPage(1);
    function isPageInRange( curPage:any, index:any, maxPages:any, pageBefore:any, pageAfter:any ) {
            if (index <= 1) {
                // first 2 pages
                return true;
            }
            if (index >= maxPages - 2) {
                // last 2 pages
                return true;
            }
            if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
                return true;
            }
      }
    function render(){
            let separatorAdded:Boolean = false;
            let new_page:any;
            $("#pagin").empty();
            $("#pagin").prepend('<li><a class="previous_link pagination-item nav-arrow prev" href="#">Back</a></li> ');
            $("#pagin").prepend('<li><a class="first_link pagination-item nav-arrow first" href="#">First</a></li> ');
            for(let i = 0 ; i<pageCount;i++){
                    if ( isPageInRange( curPage, i, pageCount, 2, 2 ) ) {
                        $("#pagin").append('<li><a href="#" class="page-no" data-page='+(i+1)+'>'+(i+1)+'</a></li> ');
                        separatorAdded = false;
                    }else{
                        if (!separatorAdded) {
                            $("#pagin").append('<li class="separator" />');
                            separatorAdded = true;
                        }    
                    }
            }
            $("#pagin").append('<li><a class="next_link pagination-item nav-arrow next" href="#">Next</a></li> ');
            $("#pagin").append('<li><a class="last_link pagination-item nav-arrow last" href="#">Last</a></li> ');
            if(currentIndex == 1){
                $('.previous_link').addClass('disabled-link');
                $('.first').addClass('disabled-link');
            }
            if(currentIndex == Math.ceil(pageCount)){
                $('.next_link').addClass('disabled-link');
                $('.last').addClass('disabled-link');
            }
            $("#pagin li a.page-no").click(function(this:any, e:any) {
                e.preventDefault();
                $("#pagin li a.page-no").removeClass("current");
                $('.previous_link').removeClass('disabled-link');
                $('.next_link').removeClass('disabled-link');
                $('.first').removeClass('disabled-link');
                $('.last').removeClass('disabled-link');
                currentIndex = $(this).attr('data-page');
                $('#current_page').val(currentIndex);
                curPage = parseInt(currentIndex);
                render();
                showPage(parseInt(currentIndex));
                $('a[data-page='+curPage+']').addClass("current");
                scrollUp();
            });
            $('#pagin li a.next').click(function(e:any){
                e.preventDefault();
                new_page = parseInt($('#current_page').val() as any) + 1;
                var lastIndex = Math.ceil(pageCount);
                curPage = parseInt(new_page);
                render();
                if(new_page !=lastIndex + 1){
                    $('.next_link').removeClass('disabled-link');
                    $('#current_page').val(new_page);
                    $("#pagin li a").removeClass("current");
                    $('.previous_link').removeClass('disabled-link');
                    $('a[data-page='+new_page+']').addClass("current");
                    $('.first').removeClass('disabled-link');
                    $('.last').removeClass('disabled-link');
                    showPage(new_page);
                }
                if(new_page == lastIndex){
                    $('.next_link').addClass('disabled-link');
                    $('.last').addClass('disabled-link');
                }
                scrollUp();
            });
            $('#pagin li a.prev').click(function(e){
                e.preventDefault();
                new_page = parseInt($('#current_page').val() as any) - 1;
                curPage = parseInt(new_page);
                render();
                if(new_page!=0){
                    $('.next_link').removeClass('disabled-link');
                    $('.previous_link').removeClass('disabled-link');
                    $('.first').removeClass('disabled-link');
                    $('#current_page').val(new_page);
                    $("#pagin li a").removeClass("current");
                    $('a[data-page='+new_page+']').addClass("current");
                    showPage(new_page);
                }
                if(new_page ==1){
                	$('.previous_link').addClass('disabled-link');
                	$('.first').addClass('disabled-link');
                }
                scrollUp();
                });
            $('#pagin li a.first').click(function(e){
                e.preventDefault();
                $("#pagin li a").removeClass("current");
                $('a[data-page="1"]').addClass("current");
                $('.next_link').removeClass('disabled-link');
                $('.last').removeClass('disabled-link');
                $('.previous_link').addClass('disabled-link');
                $('.first').addClass('disabled-link');
                $('#current_page').val(1);
                showPage(1);
                scrollUp();
            });
             $('#pagin li a.last').click(function(e){
                e.preventDefault();
                var lastPage = Math.ceil(pageCount);
                $("#pagin li a").removeClass("current");
                $('a[data-page='+lastPage+']').addClass("current");
                $('.next_link').addClass('disabled-link');
                $('.last').addClass('disabled-link');
                $('.previous_link').removeClass('disabled-link');
                $('.first').removeClass('disabled-link');
                $('#current_page').val(lastPage);
                showPage(lastPage);
                scrollUp(); 
         });
        }
 function scrollUp(){
    $('html, body').animate({
        scrollTop: $(".team-grid").offset().top
    }, 500);
  }
  $('#pagin li:nth-child(3) a').addClass('current');
}