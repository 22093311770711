import { updateFooterLinks } from "./updateFooterLinks";
import { attachNextPrevClickEvent } from "./attachNextPrevClickEvent";
import { $ } from "../third-party-imports"
import { getBrandDetailsFromBrandJson } from "../util/util";

/**
 * Get All Blogs
 */

export function getAllBlogs(g2CollectionURL: any)
{
    let country = $("#countryCode").val();
    var concepId = $('#conceptId').val();
    let brandName = $('#brandName').val();
    $.ajax({
        type: 'GET',
        url: g2CollectionURL,
        dataType: 'json',
        async:false,
        success: async function (data)
        {
            var allBlogs = data.response.docs;
            let linkHref = window.location.href.toLowerCase();
            // Update Footer Links
           // window.location.href.toLowerCase().indexOf("blog") > 0 && updateFooterLinks(allBlogs);
          // const localDBA = window.g2Data.responseHeader.params.fq[0].toLowerCase().replace("custom_s_local_dba:","").replace("(","").replace(" or none)","");
          let localDBA = "none";
          if (window.g2Data)
          {
              localDBA = window.g2Data.responseHeader.params.fq[0].toLowerCase().replace("custom_s_local_dba:", "").replace("(", "").replace(" or none)", "");
          }
          else
          {
              localDBA = $("#weblocationId").length ? linkHref.split('/')[3] : "none";
          } 
        
           const result = await getBrandDetailsFromBrandJson();
           if (result.length > 0)
            {                
                let brandJsonObj = result.find((item: any) => item.id === concepId);
                let brandblogURLName:any;
                 let brandlocalblogURLName:any;
                 if(brandName =='my-handyman'){
                     brandblogURLName = brandJsonObj ? brandJsonObj.AlternateBrandList[0].alternate_brand_blog_url_name : "";     
                     brandlocalblogURLName = brandJsonObj ? brandJsonObj.AlternateBrandList[0].alternate_brand_local_blog_url_name : "";
                 }else{
                     brandblogURLName = brandJsonObj ? brandJsonObj.brand_blog_url_name : "";     
                     brandlocalblogURLName = brandJsonObj ? brandJsonObj.brand_local_blog_url_name : ""; 
                 }                          
                if (localDBA != "none")
                {
                 window.location.href.toLowerCase().indexOf(brandlocalblogURLName) > 0 && updateFooterLinks(allBlogs);                    
                }
                else
                {
                 window.location.href.toLowerCase().indexOf(brandblogURLName) > 0 && updateFooterLinks(allBlogs);                    
                }
             }
            attachNextPrevClickEvent();

        },
        error: function (e: any)
        {
            // console.log(e.message);
        }
    });
}