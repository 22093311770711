
import { nestLocalStorage } from "../../config";
import { getMSALInstance } from "../../config";

export function unlinkSocial(userEmailAddress: any, type: any, subObjId: any)
{
    const myMSALObj = getMSALInstance();
    const unlinkingRequest = {
        scopes: ["openid"],
        authority: window.DEFINE_VALUE.JS_APP_ADB2C_INSTANCE + "/" + window.DEFINE_VALUE.JS_APP_ADB2C_TENANT + "/" + window.DEFINE_VALUE.JS_unLinkAccountPolicy,
        extraQueryParameters: { ObjId: subObjId, LogonEmail: userEmailAddress, SocialAccountType: type }
    };
    localStorage.setItem('previousToken', nestLocalStorage.accessToken);
    myMSALObj.loginRedirect(unlinkingRequest);
}


