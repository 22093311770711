export function footertServiceModifierMenu(serviceModifier:any){
    let footerServiceModifierList:any = [];
      if(serviceModifier){
        $('.serviceModifier').remove();
        for(let i = 0;i<serviceModifier.length;i++){
            footerServiceModifierList.push(generatefooterModifierLi(serviceModifier[i]))
        }
        $('.footer .footer-search .col-6:first-child .list-unstyled').prepend(footerServiceModifierList);
      }
}

export function generatefooterModifierLi(serModifier:any){
    return(`<li class="serviceModifier"><a class="white-clr body-text-sm medium-font" href="${serModifier.link}">${serModifier.name} </a></li>`)
}