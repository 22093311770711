import { mrhCategotyGeneateListArray } from "./mrh-category-generate-list";
import { mrhEnableImageObj } from "./mrh-global-variable";
import { mrhWraplistComponent } from "./mrh-wraplist-component";
import { detectMob } from "../util/detect-mob";
let mob = detectMob();
export function mrhCategotyListGenerator(categoryMenuArr:any,root:any){
    let rootId:any;
    let wrapMenuList:any = [];
    let categoryListArr:any = [];
    let htmlcatgoryArrayList:any = []
   if(mrhEnableImageObj.enableImage) {
    categoryMenuArr?.forEach((cat:any)=>{
       if(cat.serviceType.toLowerCase() === "ProType".toLowerCase() 
       || cat.serviceType.toLowerCase() === "Service WebCategory".toLowerCase()
       || cat.serviceType.toLowerCase() === "OtherService".toLowerCase()) {
       categoryListArr.push(cat)
       }
    })  
   }
   wrapMenuList.push(mrhWraplistComponent(root));
   if(!mob){
    $('.dropdown.menu-large a[href="'+root.link+'"] + .dropdown-menu.megamenu').remove();
    $('.dropdown.menu-large a[href="'+root.link+'"]').parent().append(wrapMenuList);
   }else {
    rootId = `menu${root.id}`
    $('.dropdown.menu-large a[id="'+rootId+'"] + .dropdown-menu.megamenu').remove();
    $('.dropdown.menu-large a[id="'+rootId+'"]').parent().append(wrapMenuList);
   }
   for(let i =0;i<categoryListArr.length;i++){
    htmlcatgoryArrayList.push(mrhCategotyGeneateListArray(categoryListArr[i],root));
   }
   if(!mob) {
   $('.dropdown.menu-large a[href="'+root.link+'"] + .dropdown-menu.megamenu .dropmenu.new-submenu ul:nth-child(2)').append(htmlcatgoryArrayList);
   } else {
   $('.dropdown.menu-large a[href="'+root.link+'"] + .dropdown-menu.megamenu .dropmenu .secound-menu').append(htmlcatgoryArrayList);
   }
}
