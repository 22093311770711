import { $ } from "../third-party-imports";
export function localBrandSite(){
    var webSiteID = $('#weblocationId').val();
    var conceptCodeID:any = $('#conceptCode').val();
    var local = JSON.parse(sessionStorage.getItem('localSite'));
    //var conceptCodeID = localStorage.getItem('conceptCodeID');
    if(!local){ 
    var obj = {
        localizedHeader:[] as any[],
        lastVisitedBrandSite:{
            conceptCodeID:''
        }
     }
    }
    if(conceptCodeID){
        if(local){
            local.lastVisitedBrandSite.conceptCodeID = conceptCodeID;
            if(local.localizedHeader){
                var elementTrue = local.localizedHeader.some((el:any)=>el.conceptCodeID==conceptCodeID)
                if(!elementTrue){
                    local.localizedHeader.push({
                        conceptCodeID:conceptCodeID,
                        webSiteID:webSiteID ? webSiteID : "22716"
                        });
                    sessionStorage.setItem('localSite',JSON.stringify(local))    
                }
            }
        }else{
            obj.lastVisitedBrandSite.conceptCodeID = conceptCodeID;
            obj.localizedHeader.push({
                conceptCodeID:conceptCodeID,
                webSiteID:webSiteID ? webSiteID : "22716"
                });
            sessionStorage.setItem('localSite',JSON.stringify(obj))  
        }
        
    }
}