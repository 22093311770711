interface MappingCategory {
    [key: string]: string;
}

interface Mappings {
    Brand: MappingCategory;
    Country: MappingCategory;
    FundingSource: MappingCategory;
    PaidOrNonPaid: MappingCategory;
    CampaignType: MappingCategory;
    Funnel: MappingCategory;
}

export const mappings: Mappings = {
    "Brand":{
        "GUY":"GUY",
        "MRR":"MRR",
        "FSP":"FSP",
        "ASV":"ASV",
        "MLY":"MLY",
        "MRA":"MRA",
        "MRH":"MRH",
        "PLG":"PLG"
    },
    "Country": {
        "US": "United States",
        "CA": "Canada"
    },
    "FundingSource": {
        "CO": "Co-op",
        "LO": "Local",
        "NB": "National Brand",
        "NN": "National Neighborly"
    },
    "PaidOrNonPaid": {
        "PA": "Paid",
        "NP": "Non-Paid"
    },
    "CampaignType": {
        "BILLB": "Billboards",
        "BLOGS": "Blogs",
        "CHAMB": "Chamber of Commerce/BBB",
        "CHATS": "IM/chat",
        "CONFE": "Conferences/Seminars",
        "DIREC": "Local Service Directories",
        "DISPL": "Online Banner Display Advertising",
        "DMAIL": "Direct Mail",
        "EMAIL": "Email",
        "EVENT": "Local Events/Sponsorships",
        "GROUP": "Online groups",
        "HOMEO": "Home Owners Association (HOA)",
        "HOMES": "Home Service Referral Sites (HomeAdvisor, Angie's List)",
        "INSUR": "Insurance Provider",
        "KIOSK": "Kiosks",
        "LEAVE": "Leave Behinds (Magnets, Business Cards)",
        "LOYAL": "Loyalty Programs",
        "MAGAD": "Magazine Ads",
        "MOBIL": "Mobile apps",
        "NBLYW": "Neighborly Brand Website",
        "NEWAD": "Newspaper Ads",
        "NEWMO": "New Mover Programs",
        "OCALL": "Outbound Call Center",
        "PODCA": "Home Service Related Podcasts",
        "POSTC": "Postcards/Posters/Flyers",
        "RADIO": "Radio Ads",
        "REFFE": "Referrals from other Home Service Professionals/Providers",
        "REVIE": "Customer Review Sites (GMB, Yelp, FB)",
        "SEARC": "Online Search",
        "SERWE": "Service Brand Websites",
        "SIGNS": "Yard Signs",
        "SOCIA": "Social networks",
        "TEXTS": "SMS/Text Message Campaigns",
        "TRADE": "Trade/Home Shows",
        "TVADS": "TV Ads",
        "VIDEO": "Online video",
        "WORDS": "Word-of-Mouth (Friend, Relative, Neighbor, etc)",
        "WRAPS": "Service Vehicle Wrap Advertising",
        "YELLO": "Yellow Pages"
    },
    "Funnel": {
        "AD": "Advocacy",
        "AW": "Awareness",
        "CO": "Consideration",
        "CV": "Conversion",
        "IN": "Intent",
        "LO": "Loyalty",
        "AL": "All"
    }
}