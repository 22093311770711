import { dynamicGetMenu } from "./dynamic-get-menu";
import { filterByValue } from "./filter-by-value";
import { menuGenrator } from "./menu-genrator";
import { rootArrayMenu } from "./root-array-menu";
import { sideMenuCategoryWrap } from "./side-menu-category-wrap";
import { enableImageObj } from "./global-variable";
import { serviceProvideFunction } from "./service-provide";
// import { addMainmenuClickEvent } from "./add-mainmenu-click-event";
import { footertServiceModifierMenu } from "./footer-menu";

export function headerDynamicBrandMenu(){
    //let enableImage:any;
    let brandMenu:any;
    let categoryMenu:any;
    let services:any;
    let brandName = $('#brandName').val();
    let countryCode = $('#countryCode').val();
    let brandNavigationUrl = `${window.location.origin}/${countryCode}/en-${countryCode}/${brandName}/_assets/js/data/dynamic_menu.json`;
    $('.dropdown.menu-large.service-menu').remove();
    dynamicGetMenu(brandNavigationUrl,function(result:any){
        if(result){
            let data =  result.serviceMenu;
            let options =  data["country"][0].brand[0].option;
            //enableImage = data["country"][0].brand[0].enableImages;
            let rootMenus:any = [];
            enableImageObj.enableImage = data["country"][0].brand[0].enableImages;
            brandMenu = options;
            let filterBrandMenu = filterByValue(brandMenu);
            sessionStorage.setItem('filterService',JSON.stringify(filterBrandMenu));
            footertServiceModifierMenu(filterBrandMenu);
                for(let i = 0;i<filterBrandMenu.length;i++){
                    categoryMenu = filterBrandMenu[i].category;
                    services = filterBrandMenu[i].services || [];
                    if(services.length) {
                        categoryMenu = services;
                    }
                    rootArrayMenu(filterBrandMenu[i],rootMenus);
                }
                $('.main-nav .nav-section .nav.navbar-nav').prepend(rootMenus);
                for(let i = 0;i<filterBrandMenu.length;i++){
                    categoryMenu = filterBrandMenu[i].category;
                    services = filterBrandMenu[i].services || [];
                    if(services.length) {
                        categoryMenu = services;
                    }
                    if(categoryMenu.length !== 0 ) {
                    menuGenrator(categoryMenu, filterBrandMenu[i]);
                    }
                    // if(window.objOSGlobal?.requestBodyValues?.brandJsonGlobalObj?.[0]?.enable_menu_header_close_btn){
                    if($('#brandName')?.val()==='mr-rooter'){
                        createCloseBtnForMenu();
                    }
                }
                
                sideMenuCategoryWrap(filterBrandMenu);
                if($('#services').hasClass('services-list-wrap')){
                    serviceProvideFunction(brandMenu);
                }
                //addHastoMobileurl();
               
        }
        // addMainmenuClickEvent();
        
    });
}

export function createCloseBtnForMenu() {
    // Create the button element with the img inside it
    const $closeButton = $(`
    <button type="button" class="close desktop-only" data-dismiss="modal" aria-label="Close">
        <img height="25px" width="25px" src="/us/en-us/mr-rooter/_assets/images/megamenu-close.svg" alt="close">
    </button>
    `);

    // Append the button to the target li element
    $('.dropdown-menu[aria-labelledby="menu-residential"] li.row.ml-0.mr-0').append($closeButton);
    $('.dropdown-menu[aria-labelledby="menu-commercial"] li.row.ml-0.mr-0').append($closeButton);
}

$(document).on('click', '.dropdown-menu .close', function() {
    const dropdownMenu = $(this)?.closest('.dropdown-menu');
    if (dropdownMenu) {
        dropdownMenu.addClass('d-none');
    }
})

// Remove the hidden class on hover
$(document).on('mouseenter', '.dropdown.menu-large.nav-item', function() {
    const dropdownMenu =  $(this)?.find('.dropdown-menu');
    if (dropdownMenu?.hasClass('d-none')) {
        dropdownMenu.removeClass('d-none');
    }
});