import { $ } from "../third-party-imports";
import { getBrandDetailsFromBrandJson } from "../util/util";
export async function setAlternateBrandData() {
	var alternateConcept = localStorage.getItem('alternateConcept');
	var franchiseWebLocationId = localStorage.getItem('franchiseWebLocationId');

	if(alternateConcept != null && typeof(alternateConcept) != 'undefined' && franchiseWebLocationId != null && typeof(franchiseWebLocationId) != 'undefined'	&& franchiseWebLocationId == $('#weblocationId').val()) {
		const result = await getBrandDetailsFromBrandJson();
		if (result.length > 0) {
			var items = $.grep(result, function (e:any) { return e.code === $('#conceptCode').val() }); //window.objOSGlobal.requestBodyValues.ConceptId;
			window.objOSGlobal.requestBodyValues.brandJsonGlobalObj = items;
			if(items.length) {
				var alternateBrandData = $.grep(items[0].AlternateBrandList, function (b:any) { return b.alternate_brand === alternateConcept; });
				if(alternateBrandData.length) {
					if($('.navbar-brand img').length) {
						$('.navbar-brand img').attr('src', alternateBrandData[0].image);
					}
					else {
						$('.navbar-brand').append('<img src="' + alternateBrandData[0].image + '" />');
					}
					$('.call-icon').get(0).lastChild.nodeValue = alternateBrandData[0].phone;
					$('strong.call-icon').get(0).lastChild.nodeValue = alternateBrandData[0].phone;
					$('.call-icon').attr('href', 'tel:' + alternateBrandData[0].phone);						
				}
			}
		}
	}
}