import { disableCurrentPageOnPaginationSection } from "./disableCurrentPageOnPaginationSection";
import { updateBlogResultText } from "./updateBlogResultText";
import { Pagination } from ".";


export function initPagination(totalCount: number)
{    
    const totalPages = Math.ceil(totalCount / 10);

    const categoryRegex = /\/category\/[\s\-a-zA-Z0-9]+\/?/gim;
    const tagRegex = /\/tag\/[\s\-a-zA-Z0-9]+\/?/gim;
    const pageRegex = /\/page\/[0-9]+\/?/gim;
    const contentRegex = /\/search\/[\-a-zA-Z0-9]+\/?/gim;

    const url = window.location.href;

    const matchedPage = url.match(pageRegex) || ["/page/1"];
    const matchedCategory = url.match(categoryRegex) || ["/category/none"];
    const matchedTag = url.match(tagRegex) || ["/tag/"];
    const matchedContent = url.match(contentRegex) || ["/search/"];

    const currentPage = matchedPage[0].split("/page/")[1].replace("/", "");
    const category = matchedCategory[0].split("/category/")[1].replace("/", "").replace(/-/g, ' ');
    const tag = matchedTag[0].split("/tag/")[1].replace("/", "").replace(/-/g, ' ');
    const content = matchedContent[0].split("/search/")[1].replace("/", "").replace(/-/g, ' ');

    updateBlogResultText(totalCount, category, tag, content);

    localStorage.setItem('blog_current_page', currentPage);
    localStorage.setItem('blog_total_count', totalCount.toString());
    localStorage.setItem('blog_category', category);
    localStorage.setItem('blog_tag', tag);

    if (totalPages)
    {
        Pagination.Init(document.querySelector('.pagination'), {
            size: totalPages,
            page: parseInt(currentPage),
            step: currentPage === "4" ? 3 : 2 // pages before and after current
        });
    };

    //To remove the pagination when the page count is 0
    if (window.g2Data.response.numFound === 0)
    {
        $('.pagination-section').remove();
    }

    disableCurrentPageOnPaginationSection(currentPage);
}
 