import { chunkArray } from "../util/chunkArray";

export function serviceProvideFunction(rootMenu:any){
    let filterMenu:any = filterByVisibility(rootMenu);
    $('.services-list-wrap .col-md-4').remove();
    let conceptId  = $('#conceptCode').val();
    if(conceptId =="PLG"){
        let filterMenuServices = filterAutoResidential(filterMenu);
        if(filterMenuServices?.length !=0){
            filterMenuServices.map((modifier:any)=>{
                createColumnSectionServiceProvider(modifier);
            })   
        }
    }else{
        let rootMenus = filterMenu[0];
        if(rootMenus){
            createColumnSectionServiceProvider(rootMenus);
        }
    }
}

function createColumnSectionServiceProvider({category}:any){
    let j = 0;
    let menuCols:any = [];
    var splitCount;
    if (category.length < 3) splitCount = 1;
    else splitCount = Math.round(category.length / 3);
    let result:any = chunkArray(category, splitCount);
    for(let i=0;i<result.length;i++){
        let serviceCat:any = [];
        result[i].forEach((element:any) => {
            let serviceList:any = [];
            $.each(element.menu,function(index,ser){
                serviceList.push(dynamciServiceNormalLiList(ser)); 
            });
            serviceCat.push(collapsableList(element,serviceList.join(''),j));
            j++;
        });
        menuCols.push(menuServiceProviderCol(serviceCat.join('')));
    }
     $('#services.services-list-wrap').append(menuCols);
}


function menuServiceProviderCol(serviceCat:any){
    return `<div class="col-md-4">
       ${serviceCat}
    </div>`
}
function collapsableList(serviceCat:any,servicelist:any,index:any){
    let checkNumOfService:any = singleServicePageLink(serviceCat);
    if(servicelist==""){
        let linkTarget = serviceCat.openNewTab ? "target='_blank'" : "";
        return `<div class="collapsable-list">
        <h3><a class="collapsed primary-theme-clr text-sm ser-pro-list" style="padding-left: 1.9rem;" href="${serviceCat.link}" ${linkTarget} id="accordion${index}id">${serviceCat.name}</a></h3>
    </div>`
    }else if(checkNumOfService.serviceLength){
        return `<div class="collapsable-list">
        <h3><a class="collapsed primary-theme-clr text-sm ser-pro-list" style="padding-left: 1.9rem;" href="${checkNumOfService.serviceUrl}" id="accordion${index}id">${checkNumOfService.name}</a></h3>
    </div>`
    }else{
        return `<div class="collapsable-list">
        <h3><button class="collapsed primary-theme-clr text-sm" data-toggle="collapse" href="#item-${index}" aria-expanded="false" aria-controls="item-${index}" id="accordion${index}id">${serviceCat.name}</button></h3>
        <div id="item-${index}" role="region" class="collapse" aria-labelledby="accordion1${index}d" data-parent="#services">
        <ul class="services-list text-sm">
            ${servicelist}
        </ul>
        </div>
    </div>`
    }
    
}

function dynamciServiceNormalLiList(ser:any){
    return `<li><a class="grey-clr" href="${ser.link}">${ser.name}</a></li>`
}


function filterByVisibility(root:any){
    let newRoot = root.filter((itm:any)=>itm.visibility);
	   let filt = newRoot.map((element:any) => {
		    return {...element, category: element.category.filter((cat:any) => cat.visibility ===true)}
		})
        filt.forEach((obj:any)=>{
            obj.category.forEach((cat:any)=>{
                cat.menu = cat?.menu?.filter((menu:any)=>menu.visibility);
            })
        });
       return filt;
}


export function singleServicePageLink(categoryMenu:any) {
    let serviceObj: any = {};
    if (categoryMenu.menu?.length === 1){
            serviceObj.serviceLength = 1;
            serviceObj.serviceUrl = categoryMenu.menu[0].link;
            serviceObj.name = categoryMenu.menu[0].name;
        return serviceObj;
    }
        return categoryMenu;
    }


function filterAutoResidential(dynamicMenu:any){
    let filtMn = dynamicMenu.filter((mn:any)=>mn?.name.toLowerCase().includes('auto') || mn?.name?.toLowerCase().includes('residential'));
    return filtMn;
}