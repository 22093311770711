import { apiConfig, stopLoader } from "..";
import {$} from "../third-party-imports";
export function locateAddressBumper(){
    $('.scheduler-modal .modal-content').addClass('banner-wizard');
    $('#stepthree .js-next-btn').off('click').on('click',function(e:any){ 
            e.preventDefault();
            var sAddressBumper:any = "";
            if (!$("#serviceAddressBumper").valid()) {
                return false;
            }
            if ($('#stepthree #input-addressBumper').length){	
                if (($('#stepthree #input-addressBumper').val() as any).length){	
                sAddressBumper = $('#stepthree #input-addressBumper').val();	
                  }	
            }	
            
            var sAddressBumper1:any = "";
     
            if ($('#stepthree #input-addressBumper2').length){	
                if (($('#stepthree #input-addressBumper2').val() as any).length){	
                sAddressBumper1 = $('#stepthree #input-addressBumper2').val();	
                  }	
            }	
           
            var sCityBumper:any = "";
     
            if ($('#stepthree #inputcity').length){	
                if (($('#stepthree #inputcity').val() as any).length){	
                sCityBumper = $('#stepthree #inputcity').val();	
                  }	
            }	
            if(sCityBumper === "")	
            {	
               if ($('#stepthree #input-city1').length){	
                if (($('#stepthree #input-city1').val() as any).length){	
                    sCityBumper = $('#stepthree #input-city1').val();	
                    }	
               }	
            }
            
            var sStateBumper:any = "";
     
            if ($('#stepthree #inputstate1').length){	
                if (($('#stepthree #inputstate1').val() as any).length){	
                sStateBumper = $('#stepthree #inputstate1').val();	
                  }	
            }	
            if(sStateBumper === "")	
            {	
               if ($('#stepthree #inputstate').length){	
                if (($('#stepthree #inputstate').val() as any).length){	
                    sStateBumper = $('#stepthree #inputstate').val();	
                    }	
               }	
            }
            var sZipCodeBumper:any = "";
     
            if ($('#stepthree #input-zip-code').length){	
                if (($('#stepthree #input-zip-code').val() as any).length){	
                sZipCodeBumper = $('#stepthree #input-zip-code').val();	
                  }	
            }	
            if(sZipCodeBumper === "")	
            {	
               if ($('#stepthree #inputZipCode1').length){	
                if (($('#stepthree #inputZipCode1').val() as any).length){	
                    sZipCodeBumper = $('#stepthree #inputZipCode1').val();	
                    }	
               }	
            }
            
            var sAddressParam = sAddressBumper + " " + sAddressBumper1 + " " + sCityBumper + " " + sStateBumper + " " + sZipCodeBumper
            
            var apiUrl = apiConfig.LocateLocationApiWithRoundRobinTrue.replace("sAddressParamValue", encodeURIComponent(sAddressParam));
            $.getJSON(apiUrl, function (result:any) {
        
                if (result.length > 0) {
                    var json_items = $.grep(result, function (e:any) { return e.franchiseId === e.franchiseId; });
                    if (json_items.length > 0) {
                        
                        if (json_items.length === 1) {
                            var sRedirectURL = "";
                            if(json_items[0].locationWebsiteUrl!=null){
                                var locationWebsiteUrl = json_items[0].locationWebsiteUrl;
                                
                            if (locationWebsiteUrl?.indexOf("http://"))
                            {
                                sRedirectURL = locationWebsiteUrl.replace("http:", "https:");
                            }
                            else
                            {
                                if (locationWebsiteUrl?.indexOf("https://"))
                                    sRedirectURL = locationWebsiteUrl;
                                else
                                    sRedirectURL = "https://" + locationWebsiteUrl;
                            }
                                
                                window.location.href = sRedirectURL;
                           }else{
                            stopLoader();
                            $('.tab-pane.fieldset').removeClass('active');
                            $("#steptwo h2:first").html($("#hdnSD_NoResultsMsg").html()); 
                            $("#steptwo").addClass('active');
                            $('.scheduler-modal .modal-content').addClass('banner-wizard');
                            $('#js-schedulerDialog').modal(); 
                           }
                        }
                    }else{
                        stopLoader();
                        $('.tab-pane.fieldset').removeClass('active');
                        $("#steptwo h2:first").html($("#hdnSD_NoResultsMsg").html()); 
                        $("#steptwo").addClass('active');
                        $('.scheduler-modal .modal-content').addClass('banner-wizard');
                        $('#js-schedulerDialog').modal(); 
                    }
                }else{
                    stopLoader();
                    $('.tab-pane.fieldset').removeClass('active');
                    $("#steptwo h2:first").html($("#hdnSD_NoResultsMsg").html()); 
                    $("#steptwo").addClass('active');
                    $('.scheduler-modal .modal-content').addClass('banner-wizard');
                    $('#js-schedulerDialog').modal(); 
                }
        
            });
        });	
    }