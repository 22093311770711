/**
 * Filter Blog from List
 */
export function filterBlogFromList(blog: any)
{   
    let compareUrlFlag = false;
    var brandName = $("#conceptCode").val();
    var custom_s_context_url =  blog.custom_s_context_url.split("/");
    /**try catch 
    only for ASV
    having logic to check for traling sleshes in blog url if / is not present
    in blog adding the trailing slash in end of it
    compareUrlFlag is used for to compare the web url and blog url 
    **/
    try
    {
        var checkLastSlashInUrl = blog.custom_s_context_url.substring(blog.custom_s_context_url.lastIndexOf('/')) !== '/';
        if (checkLastSlashInUrl && (brandName == 'ASV' || brandName == 'MRA'))
        {
            var webUrl = decodeURI(window.location.href);
            var checkForWebeUrl = blog.custom_s_context_url.includes(window.location.hostname);
            if(checkForWebeUrl) {
                let browserUrl = new URL(webUrl).pathname ;
                let blogUrl =    new URL(blog.custom_s_context_url).pathname ;
                compareUrlFlag = !(browserUrl === blogUrl);
            }else {
                let browserUrl = new URL(webUrl).pathname ;
                let blogUrl =    blog.custom_s_context_url;
                compareUrlFlag = !(browserUrl === blogUrl);
            }
            blog.custom_s_context_url = blog.custom_s_context_url + "/";
        }
    } catch { }
    var blogTitle;
    if(compareUrlFlag){
        blogTitle = custom_s_context_url[custom_s_context_url.length-1];
    }else {
        blogTitle = blog.custom_s_context_url.substring(blog.custom_s_context_url.lastIndexOf('/')) == '/' ? custom_s_context_url[custom_s_context_url.length-2] : custom_s_context_url[custom_s_context_url.length-1];
    }
    blogTitle = blogTitle.replace(/["']/g, ""); //remove single/double quote
    blogTitle = blogTitle.replace(/[^a-zA-Z0-9 ]/g, ""); //remove special character
    blogTitle = blogTitle.split(" ").join("").toLowerCase(); //remove space and convert to lowercase

    
    var decodeHref = decodeURI(window.location.href);
    var decodeHrefArray =  decodeHref.split("/");
    var blogTitleToCheck;
    if(compareUrlFlag){
        blogTitleToCheck = decodeHrefArray[custom_s_context_url.length-1];
    } else {
        blogTitleToCheck = decodeHref.substring(decodeHref.lastIndexOf('/')) == '/' ? decodeHrefArray[custom_s_context_url.length-2]  : decodeHrefArray[custom_s_context_url.length-1];
    }
    blogTitleToCheck = blogTitleToCheck?.replace('%E2%80%99', ''); //remove %E2%80%99
    blogTitleToCheck = blogTitleToCheck?.replace(/["']/g, ""); //remove single/double quote
    blogTitleToCheck = blogTitleToCheck?.replace(/[^a-zA-Z0-9 ]/g, ""); //remove special character
    blogTitleToCheck = blogTitleToCheck?.split(" ").join("").toLowerCase(); //remove space and convert to lowercase
    if (blogTitle === blogTitleToCheck)
    {
        return true;
    }
}
 