import { changeZipOnlineScheduleFlow } from "./mra-form-information";

$('.changeZipChecker input[name="postal"]').keyup(function (this: any){
    var value: any = $(this).val();
    changeZipOnlineScheduleFlow(value);
});
export function inputZipChecker(){
    $('.changeZipChecker input[name="postal"]').keypress(function (this: any, e: any){
        if (this.value === "0000" && e.which == 48){
            return false;
        }
    });
}