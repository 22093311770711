import { $ } from "../third-party-imports";
// import {getStateFun} from './getStateFun';
import {phoneFormatter} from './phoneFormatter';
import {contactPhoneFormatter} from './contactPhoneFormatter';
import {localBrandSite} from './localBrandSite';
import {FspSchedulerFlow} from './FspSchedulerFlow';
import {file_upload} from './file_upload';
import { mrh_file_upload } from "./mrh_file_upload";
import { clickToCallBtn } from "./click-to-call";
export function defaultCallFunction(){
    let country:any = $("#countryCode").val()
    window.objOSGlobal.requestBodyValues.ConceptId = $('#conceptId').val();
    window.objOSGlobal.requestBodyValues.ConceptCalledId = $('#conceptId').val();
    window.objOSGlobal.requestBodyValues.ConceptName  = window.brandData?.name || "";
    window.objOSGlobal.requestBodyValues.ConceptCode  = $('#conceptCode').val();
    window.objOSGlobal.requestBodyValues.Country  = country.toUpperCase();
    phoneFormatter();
    contactPhoneFormatter();
    localBrandSite();
    file_upload();
    mrh_file_upload();
    clickToCallBtn();
    window.innerWidth >= 992 ? $('.mega-menu-links').removeClass('adjust-nav-height') : $('.mega-menu-links').addClass('adjust-nav-height');
    if($('#referenceDetails').attr('required')?.length >0 && document.getElementsByClassName('div_diagnosticfee')?.length >0)
    {
        $(".service-details-screen .next").addClass("disble-btn").prop("disabled", true);
    }
    else
    {
        $(".service-details-screen .next").removeClass("disble-btn").prop("disabled", false);
    }
    if(!$('#weblocationId').val()) {
    $(".cta-wrapper .services-btn").addClass("disble-btn").prop("disabled", true);
    $(".banner-sec #service_modal").addClass("disble-btn").prop("disabled", true);
    }
    $(".cta-wrapper #a_servicepage_bannerwithcta_button,#a_servicepage_fullwidthcta_button").removeClass("disble-btn").prop("disabled", true);
    var redirectZip = sessionStorage.getItem('redirectZip');
    if(redirectZip){
        $(".tab-pane.fieldset").removeClass("active");
        $('#js-schedulerDialog').modal(); 
        FspSchedulerFlow(redirectZip);
        sessionStorage.removeItem('redirectZip');
    }
}