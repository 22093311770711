import { $ } from "../third-party-imports";
import {schedulerState} from '../scheduler/scheduler'
import {setPersonalInfo} from '../util/setPersonalInfo'
export function waitForProfile() {
    schedulerState.profileObj = JSON.parse(localStorage.getItem("profile"));
    if ("profile" in localStorage){ 
       $(".contact-information-screen .sub-heading").css("display","none");
       if ("step3" in localStorage){
           $(".zip-entry-screen").removeClass('active');
           $("#js-schedulerDialog").modal('show');
           $('.scheduler-modal .modal-content').removeClass('banner-wizard');
           $(".contact-information-screen").addClass('active');
           setPersonalInfo(schedulerState.profileObj);
           var globalObjectVal = sessionStorage.getItem('objectGlobal');
           var paramUIValue = sessionStorage.getItem('paramUIValue');
           if(globalObjectVal){
               window.objOSGlobal.requestBodyValues = JSON.parse(globalObjectVal);
               window.objOSGlobal.paramsUI= JSON.parse(paramUIValue);
               $('.contact-information-screen').addClass('active');
               if(window.objOSGlobal.requestBodyValues.IsLeadOnly){
                  $('.progress-bar').css({ width:'3.2%' });
               }else{
                  $('.progress-bar').css({ width:'27%' });
               }
           }
           sessionStorage.removeItem('objectGlobal');
           sessionStorage.removeItem('paramUIValue');
           localStorage.removeItem("step3");
       }else{
           setPersonalInfo(schedulerState.profileObj);
           checkPersonalInfoData();
       }
     }
}
function checkPersonalInfoData(){
    var fistName = $(".contact-information-screen #inputFirstName").val();
    var lastName = $(".contact-information-screen #inputLastName").val();
    var email = $(".contact-information-screen #inputEmail").val();
    var phoneNumber = $(".contact-information-screen #inputPhnNumber").val();
    if(fistName!='' && lastName!=''&& email!='' && phoneNumber !=''){
      $(".contact-information-screen .next").removeClass("disble-btn").prop("disabled", false);
    }else{
      $(".contact-information-screen .next").addClass("disble-btn").prop("disabled", true);
    }
 }