import { detectMob } from "../util/detect-mob";

export function mrhSubMenuLeftcatgoryList(categoryList: any, root: any,i:any){
let activeClass:any;
let dataId = categoryList.name.replace(/[^A-Z0-9]+/ig, "").toLowerCase();
// (i == 0) ? activeClass = "active" : activeClass = "";
let catName = categoryList.name.replace(/[^A-Z0-9]+/ig, "");
let mob:any = detectMob();
if(!mob){
return `<li class="inner-list-item sub-menu-cat" data-id="${dataId}" >
        <a class="dropdown-item" href="javascript:void(0)">${categoryList.name}</a>
        </li>`
    } else {
        return `<div class="menu-col" data-id="${dataId}>
        <button class="back-arrow-link js-backMenu-link" aria-label="Back to main menu">Back</button>
        <span class="overview-link">
           <a href="javascript:void(0)">${categoryList.name}</a>
        </span>
        <div class="screen-three">
           <div class="row">
              <div class="col-lg-3">
                 <div class="sub-menu-left">
                    <ul class="inner-menu-list-three col-list" id="${dataId}"></ul>
                 </div>
              </div>
              <div class="col-lg-9">
                 <div class="sub-menu-right"></div>
              </div>
           </div>
        </div>
     </div>`
    }
}