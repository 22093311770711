import { schedulerState } from "../..";
import { LeadConfirmation } from "../../services/LeadConfirmation";
import { $ } from "../../third-party-imports";
import { GetReferenceDetails, addDataToNotes, checkZipLocalSite, mrhs3upload } from "../../util";
import { changeZipCodeInContactScreenFlow } from "../common-os/change-zip-code-contact";
import { guyFormValidation } from "./guy-form-validation";
import { requestFormValidation } from "../../util/contact-request-form";
import { preferredMethodOfContact, validTextMethodChk } from "../common-os/os-flow-optimize";

export function guy_OptOsFlow()
{
guyFormValidation();
GetReferenceDetails();
changeZipCodeInContactScreenFlow();
$(".calendar-row").on("click", ".calendar-btn", function (this: any){
    $(".dt-error-calender").remove();
});
  $(".js-next-btn").off().on('click',async function (this: any){
    if($('#conceptCode').val() == "GUY") {
    if($('#FinancingForm').length>0){
        requestFormValidation($(this));
        return;
    }
    let nextId = $(this).parents('.tab-pane').next().attr("id");
    if(window.objOSGlobal.requestBodyValues.IsLeadOnly) {
        if (nextId == 'stepone_change_location_local') {
            //getStateFun();
            $("#contactusformguy .col-md-12").hide();
            if($(".personal-info-form .invalid-feedback")[0]){
              $(".invalid-feedback").remove();
            }
            if($(".search-services-form").valid()){
              makeActive(nextId);  
              $('[href="#' + nextId + '"]').tab('show');
            }
        }else if(nextId ==='step4'){
          $('.contact-information-screen').addClass('active');
          preferredMethodOfContact();
          if($("#contactusformguy").valid() && validTextMethodChk()){
                var changeZip = sessionStorage.getItem("changeZip");
                if (changeZip) {
                checkZipLocalSite(nextId);
                return;
                } 
              guyContactInformation();
              makeActive(nextId);
              $('[href="#' + nextId + '"]').tab('show');
            } else{
                $('.contact-information-screen').addClass('active');
            }
            // showOfferAttachedText();
        }else if(nextId == "hdnSD_NoResultsMsg") {    
            if ($("#referenceDetails").attr("required")?.length) {
                if(validateRefDetails() && validatedServiceType() && validatedServiceRequestType()){
                    guyServiceDetails();
                    if ($("#file").length > 0) {
                    mrhs3upload(function () {
                      LeadConfirmation();
                    });
                    } else {
                     LeadConfirmation();
                    }
                } else {
                    if(!validatedServiceType()){
                    $("#serviceRequestA-error").removeClass('d-none')
                    $("#serviceRequestA-error").css('display','inline');
                    }
                    if(!validatedServiceRequestType()) {
                     $("#serviceRequestB-error").removeClass('d-none');
                     $("#serviceRequestB-error").css('display','inline');
                    }
                    if(!validateRefDetails()){
                     $("#referenceDetails-error").removeClass('d-none');
                     $("#referenceDetails-error").css('display','inline');
                    }
                    // var spanServiceTypeErrorToMove = $("#serviceTypeA-error");
                    // $("#serviceTypeA-error").remove();
                    // var spanServiceRequestErrorToMove = $("#serviceRequestA-error");
                    // $("#serviceRequestA-error").remove();
                    // if(spanServiceTypeErrorToMove){
                    // $("#serviceDetails .form-radio:first .radio-txt:first").append(spanServiceTypeErrorToMove);
                    // }
                    // if(spanServiceRequestErrorToMove){
                    // $("#serviceDetails .form-radio:nth-child(1) .radio-txt:first").append(spanServiceRequestErrorToMove);
                    // }
                }
            } else {
                if(validatedServiceType() && validatedServiceRequestType()) {
                    guyServiceDetails();
                    if ($("#file").length > 0)
                    {
                        mrhs3upload(function ()
                        {
                            LeadConfirmation();
                        });
                    } else
                    {
                        LeadConfirmation();
                    }
                } else
                {
                    if (!validatedServiceType())
                    {
                        $("#serviceRequestA-error").removeClass('d-none')
                        $("#serviceRequestA-error").css('display', 'inline');
                    }
                    if (!validatedServiceRequestType())
                    {
                        $("#serviceRequestB-error").removeClass('d-none');
                        $("#serviceRequestB-error").css('display', 'inline');
                    }
                }
            }
        }
    }else {
            if(nextId=='step3') {
            var displayDate = $(".selected-date-txt").text();
            if(displayDate){
            let postalCode = localStorage.getItem('zipcode');
            $('.guy-contact-info-screen input[name="inputZipCode"]').val(postalCode);
            makeActive(nextId);
            $('[href="#' + nextId + '"]').tab('show');
            } else {
            $('.dt-error-calender').remove();
            if(!window.objOSGlobal.requestBodyValues.brandJsonGlobalObj[0].opus_call_back_comp_enabled){
            $(".progressbar").after('<div class="dt-error-calender body-text-sm text-center">Please select your preferred date and time. </div>');
            } else {
            $(".progressbar").after('<div class="dt-error-calender body-text-sm text-center">Please select your preferred date and time or choose to get a call back. </div>');
            }
            $('.modal').animate({
                'scrollTop' : $("#schedulerWizard").offset().top
            });
            }      
            } else if(nextId === "step4"){
              $('.contact-information-screen').addClass('active');
              window.objOSGlobal.paramsUI.selectedDate = schedulerState.selectedDate;
              if($("#contactusformguy").valid()){
                  var changeZip = sessionStorage.getItem("changeZip");
                  if (changeZip) {
                        checkZipLocalSite(nextId);
                        return;
                  } 
                  guyContactInformation();
                  makeActive(nextId);
                  $('[href="#' + nextId + '"]').tab('show');
                }
            } else if(nextId == "hdnSD_NoResultsMsg") {
                if ($("#referenceDetails").attr("required")?.length) {
                    if(validateRefDetails() && validatedServiceType() && validatedServiceRequestType()){
                        guyServiceDetails();
                        if ($("#file").length > 0) {
                        mrhs3upload(function () {
                         LeadConfirmation();
                        });
                        } else {
                         LeadConfirmation();
                        }
                    } else {
                        if(!validatedServiceType()){
                        $("#serviceRequestA-error").removeClass('d-none')
                        $("#serviceRequestA-error").css('display','inline');
                        }
                        if(!validatedServiceRequestType()) {
                         $("#serviceRequestB-error").removeClass('d-none');
                         $("#serviceRequestB-error").css('display','inline');
                        }
                        if(!validateRefDetails()){
                        $("#referenceDetails-error").removeClass('d-none');
                        $("#referenceDetails-error").css('display','inline');
                        }
                    }
                } else {
                    if(validatedServiceType() && validatedServiceRequestType()) {
                        guyServiceDetails();
                        if ($("#file").length > 0) {
                          mrhs3upload(function () {
                           LeadConfirmation();
                        });
                        } else {
                        LeadConfirmation();
                        }
                        } else {
                        if(!validatedServiceType()){
                        $("#serviceRequestA-error").removeClass('d-none')
                        $("#serviceRequestA-error").css('display','inline');
                        }
                        if(!validatedServiceRequestType()) {
                         $("#serviceRequestB-error").removeClass('d-none');
                         $("#serviceRequestB-error").css('display','inline');
                        }
                    }
                }
            }
        }
    }
  });

    //Previous button click code

  $(".js-previous-btn").off().on('click',function (this: any)
  {
    if($('#conceptCode').val() == "GUY") {
    let brandJsonItems = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj;
    let checkCroleadEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match || false ;
    let prevId = $(this).parents('.tab-pane').prev().attr("id");
    if(window.objOSGlobal.requestBodyValues.IsLeadOnly){
    if (prevId =="step2") {
        $('.scheduler-modal .modal-content').addClass('banner-wizard');
        $('.tab-pane.fieldset').removeClass('active');
        $('.progress, .progressbar').hide();
        $('.schedulerModalLabel').addClass('d-none');
        $('.schedulerModalLabel').empty();
        $('.dt-error-calender').remove();
        if(checkCroleadEnable){
            if(window.objOSGlobal.requestBodyValues.fsaLead){
                let schedule = localStorage.getItem('home-scheduler-btn');
                if(schedule == 'true'){
                    localStorage.removeItem('home-scheduler-btn');
                    $('#schedulerWizard button.close').trigger('click');
                }else{
                    $('.zip-entry-screen').addClass('active');
                    $('.modal-header-one').addClass('d-none');
                    $('.modal-header-two').removeClass('d-none');
                }
            }else{
                $('#step-location').addClass('active');
                $('.modal-header-one').removeClass('d-none');
                $('.modal-header-two').addClass('d-none');
            }
        }else{
            $('.zip-entry-screen').addClass('active');
            $('.modal-header-two').removeClass('d-none');
            $('.modal-header-one').addClass('d-none');
        }
    }else if (prevId =="step3"){
        $('[href="#' + prevId + '"]').tab('show');
    }
  }else {
        if(prevId =="step1") {
        $('.scheduler-modal .modal-content').addClass('banner-wizard');
        $('.tab-pane.fieldset').removeClass('active');
        $('.progress, .progressbar').hide();
        $('.schedulerModalLabel').addClass('d-none');
        $('.schedulerModalLabel').empty();
        $('.dt-error-calender').remove();
        if(checkCroleadEnable){
            if(window.objOSGlobal.requestBodyValues.fsaLead){
                let schedule = localStorage.getItem('home-scheduler-btn');
                if(schedule == 'true'){
                    localStorage.removeItem('home-scheduler-btn');
                    $('#schedulerWizard button.close').trigger('click');
                }else{
                    $('.zip-entry-screen').addClass('active');
                    $('.modal-header-one').addClass('d-none');
                    $('.modal-header-two').removeClass('d-none');
                }
            }else{
                $('#step-location').addClass('active');
                $('.modal-header-one').removeClass('d-none');
                $('.modal-header-two').addClass('d-none');
            }
        }else{
            $('.zip-entry-screen').addClass('active');
            $('.modal-header-two').removeClass('d-none');
            $('.modal-header-one').addClass('d-none');
        }
        }else {
        $('[href="#' + prevId + '"]').tab('show');
        }
  }
}
  });
  
  //Progress bar progress code
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e: any)
  {
    let isLead = window.objOSGlobal.requestBodyValues.IsLeadOnly;
    let checker:any = false;
    if(isLead){    
      checker=true;    
    }else{
      
    }
    //update progress
    let step = $(e.target).data('step');
    let percent: any = ((parseInt(step) / 3) * 100).toFixed(2);
    switch (step)
    {
      case 1:
        percent = 5.2;
        $('.progress-bar').css({ width: percent + '%' });
        break;
      case 2:
        percent = checker ? 5.2 : 3.5;
        $('.progress-bar').css({ width: percent + '%' });
        break;
      case 3:
        percent = checker ? 5.2 : 53;
        $('.progress-bar').css({ width: percent + '%' });
        break;
      case 4:
        percent = 100;
        $('.progress-bar').css({ width: percent + '%' });
        break;
      case 7:
        percent = 100;
        $('.progress-bar').css({ width: percent + '%' });
        $(".progress").addClass("d-none");
        $(".progressbar").addClass("d-none");
        $(".modal-header-one").addClass("d-none");
        break;
    }
  });
}

export function guyContactInformation(){
  let firsName = $(".guy-contact-info-screen input[name='inputFirstName']").val();
  let lastName = $(".guy-contact-info-screen input[name='inputLastName']").val();
  let email = $(".guy-contact-info-screen input[name='email']").val();
  let phoneNumber = $(".guy-contact-info-screen input[name='inputPhnNumber']").val();
  let address = $(".guy-contact-info-screen input[name='inputAddress']").val();
  let apartment = $(".guy-contact-info-screen input[name='inputAddress2']").val();
  let city = $(".guy-contact-info-screen input[name='inputcity']").val();
  let zipcode  = $(".guy-contact-info-screen input[name='inputZipCode']").val();
  let state  = $(".guy-contact-info-screen select[name='inputstate']").val();
  window.objOSGlobal.requestBodyValues.SignUpForUpdates = $("#contactusformguy input[name='buyer-requestA']").prop('checked');
  window.objOSGlobal.requestBodyValues.FirstName = firsName;
  window.objOSGlobal.requestBodyValues.LastName = lastName;
  window.objOSGlobal.requestBodyValues.Email = email.trim();
  window.objOSGlobal.requestBodyValues.Phone = phoneNumber;
  window.objOSGlobal.requestBodyValues.Address = address;
  window.objOSGlobal.requestBodyValues.Address2 = apartment;
  window.objOSGlobal.requestBodyValues.City = city;
  window.objOSGlobal.requestBodyValues.State = state;
  window.objOSGlobal.requestBodyValues.PostalCode = zipcode;
//   var addressParamsVal:any = address + "&" + apartment + "&" + city + "&" + state + "&" + window.objOSGlobal.requestBodyValues.PostalCode;
  apartment = apartment ? apartment + " ": "";
  var addressParamsVal:any = address + " " + apartment +city + " " + state + " " + window.objOSGlobal.requestBodyValues.PostalCode;
  return addressParamsVal
}

export function guyServiceDetails() {
  window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = $('#referenceDetails option:selected').val();
  const guyServiceDetailsNote = " ServiceType: " + $('#serviceDetails input[name="serviceTypeA"]:checked').next().text().toLowerCase() + ", \r\n ServiceRequest: " + $('#serviceDetails input[name="serviceRequestB"]:checked').next().text().toLowerCase();
  addDataToNotes(guyServiceDetailsNote)
  window.objOSGlobal.requestBodyValues.guyServiceType = $('#serviceDetails input[name="serviceTypeA"]:checked').next().text().toLowerCase();
  window.objOSGlobal.requestBodyValues.guyServiceRequest = $('#serviceDetails input[name="serviceRequestB"]:checked').next().text().toLowerCase();

}

function makeActive(tabId:any) {
  $('.progressbar li').removeClass('active')
  $('.progressbar li').addClass('disabled')
  $('a[href="#' + tabId + '"]').parent().removeClass('disabled');
  $('a[href="#' + tabId + '"]').parent().addClass('active');
}

function validatedServiceType() {
    if($('input[value="residential"]').prop('checked') || $('input[value="commercial"]').prop('checked')){
        return true;
    }else {
        return false;
    }
}
function validatedServiceRequestType() {
    if($('input[value="maintenance"]').prop('checked') || $('input[value="project"]').prop('checked')) {
        return true;
    }else {
        return false;
    }
}
function validateRefDetails() {
    if($('#referenceDetails option:selected').val() !== ''){
        return true;
    }else {
        return false;  
    }
}
$('input[name="serviceTypeA"]').off('change').on('change', function(){
    if($('input[value="residential"]').prop('checked') || $('input[value="commercial"]').prop('checked')){
        $("#serviceRequestA-error").addClass('d-none')
        $("#serviceRequestA-error").css('display','none');
    }
  });
  $('input[name="serviceRequestB"]').off('change').on('change', function(){
    if($('input[value="maintenance"]').prop('checked') || $('input[value="project"]').prop('checked')){
        $("#serviceRequestB-error").addClass('d-none');
        $("#serviceRequestB-error").css('display','none');
    }
  });
  $('#referenceDetails').off('change').on('change', function (this: any){
    let selectedItem = $('#referenceDetails option:selected').val();
    if(selectedItem !== ''){
        $("#referenceDetails-error").addClass('d-none');
        $("#referenceDetails-error").css('display','none');
    }else {
        $("#referenceDetails-error").removeClass('d-none');
        $("#referenceDetails-error").css('display','inline');
    }
  })

