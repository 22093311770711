export function filterByVisibility(root: any)
{
    let newRoot = root.filter((itm: any) => itm.visibility);
    let filt = newRoot.map((element: any) =>
    {
        if (element.category?.length == 0)
        {
            return { ...element };
        } else
        {
            return { ...element, category: element?.category.filter((cat: any) => cat.visibility === true) }
        }
    });
    filt.forEach((obj: any) =>
    {
        if (obj.category?.length == 0)
        {
            return;
        } else
        {
            obj.category.forEach((cat: any) =>
            {
                if (cat.serviceType !== "OtherService")
                {
                    cat.menu = cat.menu.filter((menu: any) => menu.visibility);
                    if (cat.menu?.length === 0)
                    {
                        return;
                    } else
                    {
                        cat.menu.forEach((itm: any) =>
                        {
                            itm.service = itm.service.filter((service: any) => service.visibility)
                        })
                    }
                } else
                {
                    cat.category.forEach((othCat: any) =>
                    {
                        if (othCat?.menu)
                        {
                            othCat.menu = othCat.menu.filter((menu: any) => menu.visibility);
                            if (othCat.menu.length === 0)
                            {
                                return;
                            } else
                            {
                                othCat.menu.forEach((inneritm: any) =>
                                {
                                    inneritm.service = inneritm.service.filter((service: any) => service.visibility)
                                })
                            }
                        }
                    })
                }
            });
        }
    });
    return filt;
}