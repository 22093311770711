import { $ } from "../third-party-imports";
export function matchCtaHeaderPhoneNumber () {
let headerPhoneNum:any = $($(".anchor_desktop_phone_number").clone()[1]).children().remove().end().text().trim();//$("#anchor_desktop_phone_number").clone().children().remove().end().text().trim();
let ctaComPhoneNum:any = $('.white-clr.text-lg').find('a:first').clone().children().remove().end().text().trim();
if (headerPhoneNum !== ctaComPhoneNum) {
        //$('.white-clr.text-lg').find('a:first').text(headerPhoneNum);
        $('.footer .white-clr.text-lg').contents().get(1).nodeValue = headerPhoneNum;
        $('.white-clr.text-lg').find('a:first').attr("href", `tel: ${headerPhoneNum}`)
    }
}
