import { $ } from "../third-party-imports";
export function plgFormValidation()
{
    $.validator.setDefaults({
        errorElement: "span",
        errorPlacement: function (error: any, element: any)
        {
            error.addClass("error-msg help-block invalid-feedback");
            if(element[0].name === "preferredcontact"){
                error.insertAfter(element.parents(".preferred-method"));
            }
            else{
                error.insertAfter(element);
            }
        },
        highlight: function (element: any, errorClass: any, validClass: any)
        {
            $(element)
                .parents(".form-group")
                .addClass("has-error")
                .removeClass("has-success")
                .addClass('step-has-error');
        },
        unhighlight: function (element: any, errorClass: any, validClass: any)
        {
            $(element)
                .parents(".form-group")
                .addClass("has-success")
                .removeClass("has-error")
                .addClass('step-has-error');
        },
    });

    $("#plg-contact-information").validate({
        onkeyup: function (this: any, element: any)
        {
            if ($(element).val() != "" || $(element).val() == "")
            {
                $(element).valid();
            }
        },
        rules: {
            firstname: {
                required: true,
            },
            lastname: {
                required: true,
            },
            email: {
                required: true,
                email: true,
            },
            inputPhnNumber: {
                required: true,
                minlength: 14,
                maxlength: 14,
            },
            address: {
                required: true,
            },
            city: {
                required: true,
                cityValidate:true,
            },
            state: {
                required: true,
            },
            postal: {
                required: true,
                addressZip: true
            },
            howDidHereAbout: {
                required: false, // making required as false for plg As per Tricia
            },
            preferredcontact:{
                required:true,
            }

        },
        messages: {
            inputPhnNumber: "Please enter a valid phone number",
            preferredcontact: "This field is required",
        },
    });


    $("#plg-vechicle-information").validate({
        onkeyup: function (this: any, element: any)
        {
            if ($(element).val() != "" || $(element).val() == "")
            {
                $(element).valid();
            }
        },
        rules: {
            year: {
                required: true,
                minlength: 4,
                maxlength: 4,
            },
            make: {
                required: true,
            },
            model: {
                required: true,
            },
            style: {
                required: true,
            },
            vin: {
                required: true,
            },
            glasstype: {
                required: true
            }
        },
        messages: {
            year: "Enter a valid year",
        },
    });
    
    $("#irvingrewardsform").validate({
        onkeyup: function (this: any, element: any)
        {
            if ($(element).val() != "" || $(element).val() == "")
            {
                $(element).valid();
            }
        },
        rules: {
            inputFirstName: {
                required: true,
            },
            inputLastName: {
                required: true,
            },
            inputEmail: {
                required: true,
                email: true,
            },
            inputPhnNumber: {
                required: true,
                minlength: 14,
                maxlength: 14,
            },
            inputStAdress: {
                required: true,
            },
            inputCity: {
                required: true,
                cityValidate:true,
            },
            inputState: {
                required: true,
            },
            inputZip: {
                required: true,
                addressZip: true
            },
            preferredcontact:{
                required: true,
            },
        },
        messages: {
            inputPhnNumber: "Please enter a valid phone number",
            preferredcontact: "This field is required" //Please indicate your preferred contact method.
        },
    });
    
    $("#plgcontactform").validate({
        onkeyup: function (this: any, element: any)
        {
            if ($(element).val() != "" || $(element).val() == "")
            {
                $(element).valid();
            }
        },
        rules: {
            inputFirstName: {
                required: true,
            },
            inputLastName: {
                required: true,
            },
            inputEmail: {
                required: true,
                email: true,
            },
            inputPhnNumber: {
                required: true,
                minlength: 14,
                maxlength: 14,
            },
            inputStAdress: {
                required: true,
            },
            inputCity: {
                required: true,
                cityValidate:true,
            },
            inputState: {
                required: true,
            },
            inputZip: {
                required: true,
                addressZip: true
            }
        },
        messages: {
            inputPhnNumber: "Please enter a valid phone number",
        },
    });
    
    let inputFieldValidate = 'input[name="make"],input[name="model"],input[name="vin"],input[name="insuranceCompany"],input[name="policyNumber"]';

    $(inputFieldValidate).bind('keypress', function (event:any) {
        var keyCode = event.keyCode || event.which ;
        if (keyCode == 60 || keyCode == 62 || keyCode == 47 || keyCode == 92) { 
            return false;
        } 
    });

    $(inputFieldValidate).bind('paste',function(e:any){
        let element:any = this;
        let elementName:any = element.name;
           setTimeout(function(){
              let data= $('input[name='+elementName+']').val();
              let  regx = /[^<>/]/g ; 
              let regxS = /[<>/]/g;
              if(regx.test(data) || regxS.test(data)){
                let data1 = $('input[name='+elementName+']').val().replaceAll('<','');
                let data2 = data1.replaceAll('>','');
                let data3 = data2.replaceAll('/','');
                $('input[name='+elementName+']').val(data3);
              }
           });
    });

    $(inputFieldValidate).on('input', function (e:any){
        let element = this;
        let elementName = element.name;
        let data = $('input[name='+elementName+']').val();
        if (/^\s/.test(data))
        {
            $('input[name='+elementName+']').val('');
        }
        let data1 = $('input[name='+elementName+']').val().replaceAll(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, '')
        $('input[name='+elementName+']').val(data1);
    });

    $('input[name="year"]').on('input', function (e: any)
    {
        const inputField = e.currentTarget;
        let maxChars = 4;
        if (inputField.value.length > maxChars)
        {
            inputField.value = inputField.value.substr(0, maxChars);
        }
        if (inputField.value.length === 1 && inputField.value === "0")
        {
            inputField.value = inputField.value.replace("0", '');
            return false;
        }
        inputField.value = inputField.value.replace(/\D/g, '');
    
    });
}

$(document).ready(function ()
{
    $('input:radio[name=dropoff]').change(function (this: any)
    {
        let formPayment = $(".service-type-pi input:radio[name=insurance-claim]:checked").attr('id');
        if (this.id == 'dropoff1')
        {
            sessionStorage.setItem('typeOfServiecPlg', 'dropoff1');
            clearAddressMobService();
            $('.address-mob-service').addClass("d-none");
            if (formPayment == "insurance-claim")
            {
                paymentFormValidationThree();
            }
        } else
        {
            sessionStorage.setItem('typeOfServiecPlg', 'dropoff2');
            setaddressFroMobile();
            $(".toggle-address-wrap").change(function(this: any) {
                var ischecked= $(this).is(':checked');
                if(ischecked) {
                    setaddressFroMobile();
                } else {
                    clearAddressMobService();
                }
            });
            $('.address-mob-service').removeClass("d-none");
            if (formPayment == "insurance-claim")
            {
                paymentFormValidation();
            } else
            {
                paymentFormValidationTwo();
            }
        }
    });

    $('input:radio[name=insurance-claim]').change(function (this: any)
    {
        let paymentInfo = $(".drop-off-radio input:radio[name=dropoff]:checked").attr('id');
        if (this.id == 'insurance-claim')
        {
            $('.insurance-company-service').removeClass("d-none");
            window.objOSGlobal.requestBodyValues.FormOfPayment = "Insurance Claim";
            clearFormOfPayment();
            if (paymentInfo == "dropoff1")
            {
                paymentFormValidationThree();
            } else
            {
                paymentFormValidation();
            }
        } else
        {
            $('.insurance-company-service').addClass("d-none");
            window.objOSGlobal.requestBodyValues.FormOfPayment = "Personal (Cash, Check or Charge)";
            if (paymentInfo !== "dropoff1")
            {
                paymentFormValidationTwo();
            }
        }
    });
    

    $('.insurance-company-service input[name="deductibleAmount"]').on('input', function (e: any){
        const inputField = e.currentTarget;
        let maxChars = 10;
            if (inputField.value.length > maxChars) {
                inputField.value = inputField.value.substr(0, maxChars);
            }
        inputField.value = inputField.value.replace(/[^0-9\.]/g, '');
        let data1 = inputField.value
        if (isNaN(data1))
        {
            data1 = data1.replace(/[^0-9\.]/g, '');
            if (data1.split('.').length > 2)
                data1 = data1.replace(/\.+$/, "");
            inputField.value = data1
        }
    });
});


function clearAddressMobService()
{
    let getInputstems: any = document.querySelectorAll('.address-mob-service input,.address-mob-service select');
    getInputstems.forEach((item: any, index: any) =>
    {
        $('.address-mob-service ' + item.localName + '[name="' + item.name + '"]').val('');
    });
}

function clearFormOfPayment()
{
    let getInputstems: any = document.querySelectorAll('.insurance-company-service input');
    getInputstems.forEach((item: any, index: any) =>
    {
        $('.insurance-company-service ' + item.localName + '[name="' + item.name + '"]').val('');
    });
}


function paymentFormValidation()
{
    $("#plg-payment-info").validate({
        onkeyup: function (this: any, element: any)
        {
            if ($(element).val() != "" || $(element).val() == "")
            {
                $(element).valid();
            }
        },
        rules: {
            insuranceCompany: {
                required: true,
            },
            policyNumber: {
                required: true,
            },
            deductibleAmount: {
                required: true,
            },
            address: {
                required: true,
            },
            city: {
                required: true,
            },
            state: {
                required: true,
            },
            postal: {
                required: true,
                addressZip: true
            },
        }
    });
}

function paymentFormValidationTwo()
{
    $("#plg-payment-info").validate({
        onkeyup: function (this: any, element: any)
        {
            if ($(element).val() != "")
            {
                $(element).valid();
            }
        },
        rules: {
            address: {
                required: true,
            },
            city: {
                required: true,
            },
            state: {
                required: true,
            },
            postal: {
                required: true,
                addressZip: true
            },
        }
    });
}

function paymentFormValidationThree()
{
    $("#plg-payment-info").validate({
        onkeyup: function (this: any, element: any)
        {
            if ($(element).val() != "")
            {
                $(element).valid();
            }
        },
        rules: {
            insuranceCompany: {
                required: true,
            },
            policyNumber: {
                required: true,
            },
            deductibleAmount: {
                required: true,
            }
        }
    });
}

function setaddressFroMobile()
{
    $('.address-mob-service input[name="address"]').val(window.objOSGlobal.requestBodyValues.Address);
    $('.address-mob-service input[name="apartment"]').val(window.objOSGlobal.requestBodyValues.Address2);
    $('.address-mob-service input[name="city"]').val(window.objOSGlobal.requestBodyValues.City);
    $('.address-mob-service select[name="state"]').val(window.objOSGlobal.requestBodyValues.State);
    $('.address-mob-service input[name="postal"]').val(window.objOSGlobal.requestBodyValues.PostalCode);
}

$('.address-mob-service select[name="state"]').change(function(){
    let formPayment = $(".service-type-pi input:radio[name=insurance-claim]:checked").attr('id');
    if (formPayment == "insurance-claim"){
        $('#plg-payment-info').valid();
    }else{
        $('#plg-payment-info').valid();
    }
});
