import { LeadConfirmation } from "../../services/LeadConfirmation";
import { $ } from "../../third-party-imports";
import { GetReferenceDetails,checkZipLocalSite, mrhs3upload } from "../../util";
import { checkSchedulerProIntegration } from "../../util/get-service-list";
import { changeZipCodeInContactScreenFlow } from "../common-os/change-zip-code-contact";
import { preferredMethodOfContact, validTextMethodChk } from "../common-os/os-flow-optimize";

export function asv_OptOsFlow()
{
    GetReferenceDetails();
    changeZipCodeInContactScreenFlow();
    $(".calendar-row").on("click", ".calendar-btn", function (this: any){
        $(".dt-error-calender").remove();
    });    
    $(".js-next-btn").on('click',function (this: any) {
        if ($("#conceptCode").val() == "ASV") {
            let nextId = $(this).parents(".tab-pane").next().attr("id");
            if (nextId == "step3") {
                var displayDate = $(".selected-date-txt").text();
                if (displayDate) {
                    let postalCode = localStorage.getItem("zipcode");
                    $('#contactusformasv input[name="inputZipCode"]').val(
                        postalCode
                    );
                    $('.tab-pane.fieldset').removeClass('active');
                    $('.progress-bar').css({ width:'53%' });
                    $('.contact-information-screen').addClass('active');
                } else {
                    $('.dt-error-calender').remove();
                    if(!window.objOSGlobal.requestBodyValues.brandJsonGlobalObj[0].opus_call_back_comp_enabled){
                    $(".progressbar").after('<div class="dt-error-calender body-text-sm text-center">Please select your preferred date and time. </div>');
                    } else {
                    $(".progressbar").after('<div class="dt-error-calender body-text-sm text-center">Please select your preferred date and time or choose to get a call back. </div>');
                    }
                    $('.modal').animate({
                        'scrollTop' : $("#schedulerWizard").offset().top
                    });
                }
            } else if (nextId == "step4") {
                if ($("#contactusformasv").valid() && validTextMethodChk()){
                    checkSchedulerProIntegration('serviceDetails');
                    asvContactInformation();
                    preferredMethodOfContact();
                    // showOfferAttachedText();
                    var changeZip = sessionStorage.getItem("changeZip");
                    if (changeZip) {
                        checkZipLocalSite(nextId);
                        return;
                    }
                    $('[href="#' + nextId + '"]').tab('show');
                } else{
                    $('.contact-information-screen').addClass('active');
                }
            } else if (nextId == "hdnSD_NoResultsMsg") {
                if ($("#referenceDetails").attr("required")?.length) {
                    if ($("#serviceDetails").valid()) {
                            asvServiceDetails();
                            if ($("#file").length > 0) {
                                mrhs3upload( async function () {
                                    LeadConfirmation();
                                });
                            } else {
                                LeadConfirmation();
                            }
                       
                    } else {
                        return false;
                    }
                } else {
                        asvServiceDetails();
                        if ($("#file").length > 0) {
                            mrhs3upload(function () {
                                LeadConfirmation();
                            });
                        } else {
                            LeadConfirmation();
                        }
                   
                }
            }
        }
    });

     //Previous button click code
    $(".js-previous-btn").click(function (this: any){
       if($('#conceptCode').val() == "ASV") {
        let brandJsonItems = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj;
        let checkCroleadEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match || false ;
        let prevId = $(this).parents('.tab-pane').prev().attr("id");
        if(window.objOSGlobal.requestBodyValues.IsLeadOnly){
            if (prevId =="step2") {
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
                    $('.tab-pane.fieldset').removeClass('active');
                    $('.progress, .progressbar').hide();
                    $('.schedulerModalLabel').addClass('d-none');
                    $('.schedulerModalLabel').empty();
                    $('.dt-error-calender').remove();
                if(checkCroleadEnable){
                    if(window.objOSGlobal.requestBodyValues.fsaLead){
                        $('.zip-entry-screen').addClass('active');
                        $('.modal-header-two').removeClass('d-none');
                        $('.modal-header-one').addClass('d-none')
                    }else{
                        $('#step-location').addClass('active');
                        $('.modal-header-two').addClass('d-none');
                        $('.modal-header-one').removeClass('d-none');
                    }
                }else{
                    $('.zip-entry-screen').addClass('active');
                    $('.modal-header-two').removeClass('d-none');
                    $('.modal-header-one').addClass('d-none');
                }
               
            }else if (prevId =="step3"){
                $('[href="#' + prevId + '"]').tab('show');
            }
          }else {
                if(prevId =="step1") {
                    $('.scheduler-modal .modal-content').addClass('banner-wizard');
                    $('.tab-pane.fieldset').removeClass('active');
                    $('.progress, .progressbar').hide();
                    $('.schedulerModalLabel').addClass('d-none');
                    $('.schedulerModalLabel').empty();
                    $('.dt-error-calender').remove();
                if(checkCroleadEnable){
                    if(window.objOSGlobal.requestBodyValues.fsaLead){
                        $('.zip-entry-screen').addClass('active');
                        $('.modal-header-one').addClass('d-none');
                        $('.modal-header-two').removeClass('d-none');
                    }else{
                        $('#step-location').addClass('active');
                        $('.modal-header-one').removeClass('d-none');
                        $('.modal-header-two').addClass('d-none');
                    }
                }else{
                    $('.zip-entry-screen').addClass('active');
                    $('.modal-header-two').removeClass('d-none');
                    $('.modal-header-one').addClass('d-none');
                }
                }else if(prevId =="step2"){
                    $('.tab-pane.fieldset').removeClass('active');
                    $('.progress-bar').css({ width:'3.2%' });
                    $('.calendar-screen').addClass('active');
                    }else {
                    $('[href="#' + prevId + '"]').tab('show');
                }
          }
        }
    });
    
    //Progress bar progress code
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e:any)
    {
        //update progress
        let step = $(e.target).data('step');
        let percent:any = ((parseInt(step) / 3) * 100).toFixed(2);
        let isLead:any  = window.objOSGlobal.requestBodyValues.IsLeadOnly;

        switch (step) {
            case 1:
                percent = 4.2;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 2:
                percent = isLead ? 4.2 : 3.2;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 3:
                percent = isLead ? 4.2 : 53;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 4:
                percent = 100;
                $('.progress-bar').css({ width: percent + '%' });
                break;
        }
    })
}

export function asvContactInformation(){
    let firsName = $(".asv-contact-info-screen input[name='inputFirstName']").val();
    let lastName = $(".asv-contact-info-screen input[name='inputLastName']").val();
    let email = $(".asv-contact-info-screen input[name='email']").val();
    let phoneNumber = $(".asv-contact-info-screen input[name='inputPhnNumber']").val();
    let address = $(".asv-contact-info-screen input[name='inputAddress']").val();
    let apartment = $(".asv-contact-info-screen input[name='inputAddress2']").val();
    let city = $(".asv-contact-info-screen input[name='inputcity']").val();
    let zipcode  = $(".asv-contact-info-screen input[name='inputZipCode']").val();
    let state  = $(".asv-contact-info-screen select[name='inputstate']").val();
    window.objOSGlobal.requestBodyValues.FirstName = firsName;
    window.objOSGlobal.requestBodyValues.LastName = lastName;
    window.objOSGlobal.requestBodyValues.Email = email.trim();
    window.objOSGlobal.requestBodyValues.Phone = phoneNumber;
    window.objOSGlobal.requestBodyValues.Address = address;
    window.objOSGlobal.requestBodyValues.Address2 = apartment;
    window.objOSGlobal.requestBodyValues.City = city;
    window.objOSGlobal.requestBodyValues.State = state;
    window.objOSGlobal.requestBodyValues.PostalCode = zipcode;
    var addressParamsVal:any = address + "&" + apartment + "&" + city + "&" + state + "&" + window.objOSGlobal.requestBodyValues.PostalCode;
    return addressParamsVal
}

export function asvServiceDetails() {
    window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = $('#referenceDetails option:selected').val();
}
