import { $ } from "../third-party-imports";
import { apiConfig } from "../config/apiConfig";
export function mrApplianceBrandList(successHandler:any){
    // var mrAppliceBrandListUrl  = 'https://api.nblytest.com/serviceorchestrations-api-develop/v1/public/concepts/';
    var conceptid = 8;
     $.ajax({
        url: apiConfig.serviceAggregates + conceptid + '/makes?&apikey=' + window.DEFINE_VALUE.JS_API_KEY,
        dataType: 'json',
        type: 'GET',
        success: successHandler,
        error: function (e:any){
            // console.log(e.message);
        }
    });
}