import { $ } from "../third-party-imports";
import { apiConfig } from "../config/apiConfig";
import { stopLoader } from "../util";
import { servieModal } from "../util/get-brands-json";
export function getFranchiseWebServices(webLocationId:any,serviceHandler:any){
    var url =apiConfig.serviceAggregatesWeblocation+webLocationId+'?apikey=' + window.DEFINE_VALUE.JS_API_KEY
    $.ajax({
        type: "GET",
        url:url,
        contentType: "application/json",
        dataType: 'json',
        success: serviceHandler,
        error: function(error:any){
        let serviceName = window.objOSGlobal.requestBodyValues.serviceName;
        let conceptCode:any  = $("#conceptCode").val();
        if(conceptCode.toUpperCase() =='FSP' || servieModal.enable){
            serviceName = window.objOSGlobal.requestBodyValues.PostalCode;
        }
        $('.no-service-screen .head-title').text(`We're sorry. We don't currently provide ${serviceName} at this location`);
        $('.address-details-screen').removeClass('active');
        $('.no-service-screen').addClass('active');
        stopLoader();
        }
    });
}