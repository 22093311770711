import { dynamciServiceLiList } from "./dynamci-service-li-list";

export function dynamicServiceGenrator(categoryList:any,type:any){
    let serviceMenu = categoryList.menu;
    let temp:any = [];
    for(let i=0;i<serviceMenu?.length;i++){
        temp.push(dynamciServiceLiList(serviceMenu[i]));
    }
    $('.'+type.name+'.'+categoryList.id).append(temp);

}