import {$} from "../third-party-imports";
export function callBackComponent() {
  const brandJsonGlobalObj:any =  window.objOSGlobal.requestBodyValues.brandJsonGlobalObj[0];
  if(brandJsonGlobalObj.opus_call_back_comp_enabled) {
    let conceptCode:any = $('#conceptCode').val();
   
    $(".call-details.contact-info.calendar-cont").show();
    $('.call-details.contact-info.calendar-cont > div:first').remove();
    if($("#brandName").val() === 'mr-handyman' || $("#brandName").val() === "my-handyman" ||
        conceptCode.toLowerCase() === "guy"){
        $(".call-details.contact-info.calendar-cont").append(`<div class="row align-items-center w-100">
        <div class="col-md-2">
        <div class="contact-left">
        <img src="${brandJsonGlobalObj?.opus_call_back_comp_phoneIcon}" alt="${brandJsonGlobalObj?.opus_call_back_comp_phoneIcon_altText}" fetchpriority="low" loading="lazy">
        </div>
        </div>
        <div class="col-md-10">
        <div class="contact-right mly-callBack">
        <h3 class="text-md contact-info text-center mb-1">${brandJsonGlobalObj?.opus_call_back_comp_title}</h3>
        <p class="body-text-sm">${brandJsonGlobalObj?.opus_call_back_comp_subTitle} <a class="call-txt" href="tel:${window.objOSGlobal?.paramsUI.brandPhoneNumber}"><b class="phnNumber">${window.objOSGlobal.paramsUI.brandPhoneNumber}</b></a>
        <span> or</span>
        <a class="call-txt callback-link">
          <b> ${brandJsonGlobalObj?.opus_call_back_comp_linkText}</b>
        </a>
        </p>
        </div>
        </div>
        </div>`);
    }else if( conceptCode.toLowerCase() === "mrr"){
        $(".call-details.contact-info.calendar-cont .call-back-box").remove();
        $(".call-details.contact-info.calendar-cont").append(`<div class="call-back-box"> 
        <div class="row call-back-inner align-items-center">
           <div class="col-sm callback-icon text-center">
              <img src="${brandJsonGlobalObj?.opus_call_back_comp_phoneIcon}" alt="${brandJsonGlobalObj?.opus_call_back_comp_phoneIcon_altText}" fetchpriority="low" loading="lazy" width="66px" height="66px"> 
           </div>
           <div class="col-sm callback-content text-center contact-right mly-callBack">   
            ${brandJsonGlobalObj?.opus_call_back_comp_subTitle} <small><br><b class="mrr-primary-red"><u> <a class="call-txt" href="tel:${window.objOSGlobal?.paramsUI.brandPhoneNumber}">${window.objOSGlobal.paramsUI.brandPhoneNumber}</a></u></b> or <a class="mrr-primary-blue call-txt" href="${brandJsonGlobalObj?.opus_call_back_comp_linkUrl}"> ${brandJsonGlobalObj?.opus_call_back_comp_linkText} </a></small> 
            </div>
          </div>
        </div>`);
    }else if( conceptCode.toLowerCase() === "asv"){
        $(".calendar-wrapper .call-back-box").remove();
        $(".calendar-wrapper").append(`<div class="call-back-box">
        <div class="row call-back-inner align-items-center">
           <div class="col-sm callback-icon text-center">
           <img src="${brandJsonGlobalObj?.opus_call_back_comp_phoneIcon}" alt="${brandJsonGlobalObj?.opus_call_back_comp_phoneIcon_altText}" width="70px" height="70px">														
           </div>
           <div class="col-sm callback-content text-center">
           ${brandJsonGlobalObj?.opus_call_back_comp_title}<br> <small>${brandJsonGlobalObj?.opus_call_back_comp_subTitle}<br><a href="tel:${window.objOSGlobal?.paramsUI.brandPhoneNumber}">${window.objOSGlobal.paramsUI.brandPhoneNumber}</a> or <a href="${brandJsonGlobalObj?.opus_call_back_comp_linkUrl}">${brandJsonGlobalObj?.opus_call_back_comp_linkText}</a></small>
           </div>
        </div>
     </div>`)
    } else if(conceptCode.toLowerCase() === "mly"){
        $(".scheduled-col-wrap.call-details").empty();
        $(".scheduled-col-wrap.call-details").append(`<div>
        <div class="mly-callBack-icon">
            <img src="${brandJsonGlobalObj?.opus_call_back_comp_phoneIcon}" alt="${brandJsonGlobalObj?.opus_call_back_comp_phoneIcon_altText}"  fetchpriority="low" loading="lazy">
        </div>
        <div class="mly-callBack">
            <p class="callBack-text">${brandJsonGlobalObj?.opus_call_back_comp_title}</p>
            <p class="callBack-link">
                Please call us at 
                <a class="call-link" href="tel:${window.objOSGlobal?.paramsUI.brandPhoneNumber}">${window.objOSGlobal?.paramsUI.brandPhoneNumber}</a>
                or
                <a class="call-link call-txt callback-link">${brandJsonGlobalObj?.opus_call_back_comp_linkText}</a>
            </p>
        </div>
    </div>`)
    }else{
        $(".call-details.contact-info.calendar-cont .call-back-box-start").remove();
        $(".call-details.contact-info.calendar-cont").append(`<div class="row align-items-center w-100 call-back-box-start">
        <div class="col-md-3">
        <div class="contact-left">
        <img src="${brandJsonGlobalObj?.opus_call_back_comp_phoneIcon}" alt="${brandJsonGlobalObj?.opus_call_back_comp_phoneIcon_altText}" fetchpriority="low" loading="lazy">
        </div>
        </div>
        <div class="col-md-9">
        <div class="contact-right">
        <h3 class="text-md contact-info text-center mb-1">${brandJsonGlobalObj?.opus_call_back_comp_title}</h3>
        <p class="body-text-sm">${brandJsonGlobalObj?.opus_call_back_comp_subTitle} <a class="call-txt" href="tel:${window.objOSGlobal?.paramsUI.brandPhoneNumber}"><b class="phnNumber">${window.objOSGlobal.paramsUI.brandPhoneNumber}</b></a></p>
        <p class="mt-1 mb-1">or</p>
        <span class="call-txt">
        <a href="${brandJsonGlobalObj?.opus_call_back_comp_linkUrl}" class="text-sm contact-info text-center">
        ${brandJsonGlobalObj?.opus_call_back_comp_linkText}
        </a>
        </span>
        </div>
        </div>
        </div>`);
    }
  }else {
    $(".call-details.contact-info.calendar-cont").hide();
  }
}