//This file deals with the Sheduler Pro User Stories - 359591,359700,359701,371369
import { getCachedAPIResponse } from "../apiCaching";
import { apiCaching } from "../config";
import { appendToString } from "./util";

let selectedServiceType:number=0;
const serviceTypeArr=['Residential','Commercial']
let selectedCategoryName:string;
let selectedServiceid:string;
let selectedServiceName:string;
let structuredData:any;

//Function to map the service & category list together
export function getServiceList(){


    const data = window.objOSGlobal.localDynamicMenu;
    if (data)
    {
        const serviceOption = JSON.parse(JSON.stringify(data?.serviceMenu?.country?.[0]?.brand?.[0]?.webLocation?.[0]?.option));
        if (serviceOption?.length)
        {
            // console.log("serviceOption before sorting by name --->",serviceOption);
            //  sorting the category and menu lists by name
            serviceOption?.forEach(element=>{
                element.category = sortByName(element?.category).map(category => ({
                    ...category,
                    menu: sortByName(category?.menu)
                  }));
            })
            // console.log("serviceOption after sorting by name --->",serviceOption);
            structuredData=dataReStructure(serviceOption);
            // console.log("serviceOption after restructuring the data as objects --->",serviceOption);

            //If service type radio btns are present then data is parsed accordingly
            if($('#chk-service-type').length){
                serviceTypeSelect(structuredData);
            }

            //If Catergory Dropdown is present with default value, disable services dropdown
            if($('.service-data select[name="serviceCategory"]').length){
                //Populate residential categories by default on page load.
                populateServiceCategoryDropdown(structuredData[selectedServiceType]?.category);
                $('.service-data select[name="services"]').attr('disabled', 'disabled');
            }
            //If Catergory Dropdown is not present
            else{
                const allServiceList = listAllServices(structuredData[selectedServiceType]);
                populateServiceListDropdown(allServiceList);
            }
            
            // Event listener for category selection
            $('.service-data select[name="serviceCategory"]').on("change",function(this:any) {
                selectedServiceName='';
                selectedServiceid='';
                selectedCategoryName = this.value
                window.objOSGlobal.requestBodyValues.serviceSkuId = 0;
                if(selectedCategoryName){
                    $('.service-data select[name="services"]').removeAttr('disabled');
                }
                else{
                    $('.service-data select[name="services"]').attr('disabled', 'disabled');
                }
                let selectedCategoryObject = structuredData[selectedServiceType]?.category[selectedCategoryName];
                //If Condition for MRH Others category, where service-menu is present in category object.
                if(selectedCategoryObject?.category?.length){
                    sortByName(selectedCategoryObject.category);
                    populateServiceListDropdown(selectedCategoryObject.category);
                }
                else{
                    //ASV condition: when dynamic menu holds service inside menu array
                    let firstMenu = selectedCategoryObject?.menu?.arrayItem[Object.keys(selectedCategoryObject?.menu?.arrayItem)[0]];
                    if (firstMenu?.serviceType != "Service Sku" && firstMenu?.service?.length)
                    {
                        const allMenuServiceList = listAllMenuServices(selectedCategoryObject);
                        populateServiceListDropdown(allMenuServiceList);
                    }
                    else
                    {
                        populateServiceListDropdown(selectedCategoryObject?.menu?.arrayItem);
                    }
                }
            });
            // Event listener for service selection
            $('.service-data select[name="services"]').on("change",function(this:any) {
                selectedServiceid = this.value;
                selectedServiceName = this.options[this.selectedIndex].text;
                window.objOSGlobal.requestBodyValues.serviceSkuId = selectedServiceid;
                // window.objOSGlobal.requestBodyValues.serviceDetailsNote = `ServiceType:${serviceTypeArr[selectedServiceType]}, ServiceCategory:${selectedCategoryName}, Service:${selectedServiceName}`;
                window.objOSGlobal.requestBodyValues.serviceDetailsNote="";
                appendToString('serviceDetailsNote','ServiceType',serviceTypeArr[selectedServiceType]);
                appendToString('serviceDetailsNote','ServiceCategory',selectedCategoryName);
                appendToString('serviceDetailsNote','Service',selectedServiceName);

            });
        }
    }
}

export function getLocalDynamicMenu(){
    const countryCode:any = $('#assetCountryCode')?.length ? $('#assetCountryCode')?.val() : $('#countryCode')?.val();
    const brandName = $('#brandName')?.val();
    const dbaName:any = $('#dbaName')?.val() || window.objOSGlobal?.paramsUI?.dbaShortName;
    const stateCode = window.objOSGlobal?.requestBodyValues?.localStateCode.toLowerCase();
    const localNavigationUrl = `${window.location.origin}/${countryCode}/en-${countryCode}/_assets/${brandName}/${stateCode}/${dbaName}/js/data/dynamic_menu.json`;
            getCachedAPIResponse(localNavigationUrl, apiCaching.DynamicMenu, false, true)
            .then(data=>{
                window.objOSGlobal.localDynamicMenu=data;
            })
            .catch(error=>{
                console.error(`Error in fetching Dynamic menu Json Error message: ${error}`);
            })
}

// Function to populate the category dropdown
function populateServiceCategoryDropdown(catergoryObj:any){
    let catergorydrpdwnData:any[] = [];
    //Creating the dropdown list items 
    for (const catergoryList in catergoryObj) {
        let element = catergoryObj[catergoryList];
        catergorydrpdwnData.push('<option id="' + element.id + '" value="' + element.name + '">' + element.name + '</option>');
    }
    $('.service-data select[name="serviceCategory"]').empty();
    $('.service-data select[name="serviceCategory"]').append('<option value="">Select a Service Category');
    $('.service-data select[name="serviceCategory"]').append(catergorydrpdwnData);
}

// Function to populate the service dropdown based on selected category
function populateServiceListDropdown(serviceObj:any) {
    let servicedrpdwnData:any[] = [];
    //Creating the dropdown list items 
    for (const servicelist in serviceObj) {
        let element = serviceObj[servicelist];
        servicedrpdwnData.push('<option id="' + element.id + '" value="' + element.id + '">' + element.name + '</option>');
    }
    $('.service-data select[name="services"]').empty();
    $('.service-data select[name="services"]').append('<option value="">Select a Service');
    $('.service-data select[name="services"]').append(servicedrpdwnData);
}

//Function when user selects the Service Type radio btns
function serviceTypeSelect(data:any){
    $('input:radio[name=serviceType]').on("change",function(this:any) {
        selectedCategoryName='';
        selectedServiceName='';
        selectedServiceid='';
        $('.service-data select[name="services"]').empty();
        $('.service-data select[name="services"]').append('<option value="">Select a Service');
        $('.service-data select[name="services"]').attr('disabled', 'disabled');
        window.objOSGlobal.requestBodyValues.serviceSkuId = 0;
        if (this.id == 'Resd' && data?.[0]?.name ==="Residential") {
            selectedServiceType = 0;
            //add hide business name field for MRH Scheduler flow
            if($("#brandName")?.val()?.toLowerCase() === 'mr-handyman'){
                $("#inputBusiness")?.val('');
                $("#inputBusiness").removeAttr('required');
                $(`#mrhServiceAddress .comm-type`).addClass('d-none');
                $('#ddl-service-category').removeClass('d-none');
                $('#ddl-services').removeClass('d-none');
            }
        }
        else if(this.id == 'Comm' && data?.[1]?.name ==="Commercial") {
            selectedServiceType = 1;
            //add hide business name field for MRH Scheduler flow
            if($("#brandName")?.val()?.toLowerCase() === 'mr-handyman'){
                $(`#mrhServiceAddress .comm-type`).removeClass('d-none');
                $("#inputBusiness").attr('required', 'required');
                $('#ddl-service-category').addClass('d-none');
                $('#ddl-services').addClass('d-none');
            }
        }
        window.objOSGlobal.requestBodyValues.serviceDetailsNote="";
        appendToString('serviceDetailsNote','ServiceType',serviceTypeArr[selectedServiceType]);
        //If category dropdown is present populate with categories
        if($('.service-data select[name="serviceCategory"]').length){
            populateServiceCategoryDropdown(data?.[selectedServiceType]?.category);
        }
        //else list all services as per service type
        else if($('.service-data select[name="services"]').length){
            const allServiceList = listAllServices(structuredData[selectedServiceType]);
            populateServiceListDropdown(allServiceList);
        }
    });
}

/*Function to restructure the data, such that the category and service list arrays,
have the ids as the key, to help with mapping the data.
*/
function dataReStructure(optionData:any){
    // console.log("data to be restructured-->",optionData);
    optionData.forEach(data =>{
        const categoriesById = {};
        data?.category?.forEach((category:any) => {
            const menuById = {};
            category?.menu?.forEach((menuItem:any) => {
                menuById[menuItem?.name] = menuItem;
            });
            categoriesById[category?.name] = {
                ...category,
                menu: { arrayItem: menuById } // Store the menu items under 'arrayItem'
            };
        });
        data.category = categoriesById;
        return data;
    })
    return optionData;
}

//Function to sort an array of objects by name
function sortByName(arr:any) {
    if(Array.isArray(arr)){
        return arr.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
    }
    else{
        console.error(`Cannot sort ${arr} by Name as its not an array`);
    }
    
}

//Make a cumulative list of all services
function listAllServices(data:any) {
    let allServiceList:any[] = [];
    for (const categoryKey in data.category) {
            const category = data.category[categoryKey];
            const arrayItem = category.menu?.arrayItem;
            let firstMenu = arrayItem?.[Object.keys(arrayItem)[0]];
            //For MRR brand and alike
            if(firstMenu?.serviceType === "Service Sku" && !firstMenu?.service?.length && arrayItem){
                    for (const menuKey in category?.menu?.arrayItem) {
                            const menuItem = category?.menu?.arrayItem[menuKey];
                            allServiceList.push(menuItem);
                    }
            }
            //for ASV brand and alike
            else if(firstMenu?.serviceType!="Service Sku" && firstMenu?.service.length && arrayItem){
                allServiceList=[...allServiceList, ...listAllMenuServices(category)];
            }
    }
    return sortByName(allServiceList);
}

function listAllMenuServices(selectedCategoryObject:any) {
    let allMenuServiceList:any[] = [];
    const arrayItems = selectedCategoryObject?.menu?.arrayItem;
    for (let key in arrayItems) {
        if (arrayItems.hasOwnProperty(key)) {
            let services = arrayItems[key]?.service;
            allMenuServiceList = allMenuServiceList.concat(services);
        }
    }
    return sortByName(allMenuServiceList);
}

export function setServiceDataConfirmationText() {
    sessionStorage.setItem('selectedServiceType', serviceTypeArr[selectedServiceType]??'');
    sessionStorage.setItem('selectedCategoryName', selectedCategoryName??'');
    sessionStorage.setItem('selectedServiceName', selectedServiceName??'');
}

export function checkSchedulerProIntegration(formId:string){
    if(window.objOSGlobal?.requestBodyValues?.calendarTypeId === 2 ){
        sessionStorage.setItem('isSchedulerProEnabled', 'true');
        $(`#${formId} .service-data`).removeClass('d-none');
        getServiceList();
        if($("#brandName")?.val()?.toLowerCase() === 'mr-handyman'){
            // $(`#mrhServiceAddress #mrh-service-type`).addClass('d-none');
            $(`#mrhServiceAddress #mrh-service-type`).remove();
        }
    }
    else{
        sessionStorage.setItem('isSchedulerProEnabled', 'false');
        $(`#${formId} .service-data`).addClass('d-none');
    }
}