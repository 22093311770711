import { LeadConfirmation } from "../services/LeadConfirmation";



$('.mra-contact-information-screen select[name="howDidHereAbout"]').off('change').on('change', function (this: any){
    let selectedItem = $(this).children("option:selected").val();
    window.objOSGlobal.requestBodyValues.LeadSourceId = selectedItem;
   
});

export function contactInfoOpus(){
    const firstnameobj = $('.personal-info-form').find('input[name="inputFirstName"]').val();
    const lastnameobj = $('.personal-info-form').find('input[name="inputLastName"]').val(); 
    const emailobj = $('.personal-info-form').find('input[name="email"]').val();
    const mobileNumberobj = $('.personal-info-form').find('input[name="inputPhnNumber"]').val();
    window.objOSGlobal.requestBodyValues.FirstName = firstnameobj;
    window.objOSGlobal.requestBodyValues.LastName = lastnameobj;
    window.objOSGlobal.requestBodyValues.Email = emailobj;
    window.objOSGlobal.requestBodyValues.Phone = mobileNumberobj;
}

export function addressInfoValOpus(){
    var address = $('.personal-info-form input[name="inputAddress"]').val();
    // var apartment = $('.personal-info-form input[name="inputAddress2"]').val();
    var city = $('.personal-info-form input[name="inputcity"]').val();
    var state = $('.personal-info-form #inputstate').val();
    var postalCode = $('.personal-info-form #inputZipCode').val();
    // var addressParamsVal:any = address + "&" + city + "&" + state + "&" + window.objOSGlobal.requestBodyValues.PostalCode;
    
    var addressParamsVal:any = address + " " + city + " " + state + " " + window.objOSGlobal.requestBodyValues.PostalCode;
    window.objOSGlobal.requestBodyValues.Address = address + ", " + $('.personal-info-form input[name="inputAddress2"]').val();
    // window.objOSGlobal.requestBodyValues.Address2 = apartment;
    window.objOSGlobal.requestBodyValues.City = city;
    window.objOSGlobal.requestBodyValues.State = state;
    window.objOSGlobal.requestBodyValues.PostalCode = postalCode;
    return addressParamsVal;
}


export function mraOnlineFormSubmit(){
    $('.confirmation-screen .next').on('click',async function (e:any){
            e.preventDefault();
            mraOnlineScheduleFlowSubmitForm();
        
    });
}


$('.mra-contact-information-screen input[name="inputZipCode"]').keyup(function (this: any){
    let value: any = $(this).val();
    let checkCroleadEnable;
    let brandJsonItems = window.objOSGlobal.requestBodyValues?.brandJsonGlobalObj;
    if(brandJsonItems){
       checkCroleadEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match || false ;
    }
    if(checkCroleadEnable){
        fsaLeadFlowChangeZip(value);
    }else{
        changeZipOnlineScheduleFlow(value);
    }
});

export function changeZipOnlineScheduleFlow(value:any){
    let  regex = new RegExp(/^([0-9]{5}|[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9])$/);
    if (regex.test(value)){
        window.objOSGlobal.requestBodyValues.PostalCode = value;
        if (localStorage.getItem('zipcode') != value){
            sessionStorage.setItem('changeZip', value);
        }else{
            sessionStorage.removeItem('changeZip');
        }
    }
}

export function mraOnlineScheduleFlowSubmitForm(){
    contactInfoOpus();
    addressInfoValOpus();
    LeadConfirmation();
}

export function erasePreFilledDataOnClose(){
    $('.mra-contact-information-screen input[name="inputFirstName"]').val('');
    $('.mra-contact-information-screen input[name="inputLastName"]').val(''); 
    $('.mra-contact-information-screen input[name="email"]').val('');
    $('.mra-contact-information-screen input[name="inputPhnNumber"]').val('');
    $('.mra-contact-information-screen input[name="inputAddress"]').val('');
    $('.mra-contact-information-screen input[name="inputAddress2"]').val('');
    $('.mra-contact-information-screen input[name="inputcity"]').val('');
    $('.mra-service-info-screen input[name="serviceListQuery"]').val('');
    $('.mra-service-info-screen textarea[name="inputService"]').val('');
    $(".servicesListDropdown").empty();
}

$('.mra-contact-information-screen .referenceDetails').on('change', function (this: any){
    let selectedItem = $(this).children("option:selected").val();
    window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = selectedItem;
});

export function fsaLeadFlowChangeZip(value:any){
     let regex:any;
     if (localStorage.getItem('zipcode').toUpperCase() != value.toUpperCase()){
        regex = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
     }else{
        regex = new RegExp(/^[A-Za-z]\d[A-Za-z]/);
     }
     if (regex.test(value)){
        window.objOSGlobal.requestBodyValues.PostalCode = value;
        if (localStorage.getItem('zipcode') != value){
            sessionStorage.setItem('changeZip', value);
        }else{
            sessionStorage.removeItem('changeZip');
        }
    }
}