

import { loginRequest } from "../../config";
import { getMSALInstance } from "../../config";


export const signIn = () => {
    const myMSALObj = getMSALInstance();
    myMSALObj.loginRedirect(loginRequest);
}

