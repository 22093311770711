import { $ } from "../third-party-imports"

export function linkAndUnlinkSocial(profile: any)
{
    var socialProfile = profile.userSocialProfiles.map((item: any) => item.socialChannelId);
    socialProfile.forEach((element: any) =>
    {
        if (element === 1)
        {
            $('.facebookLink').text('unlink');
        } else if (element === 2)
        {
            $('.gmailLink').text('unlink');
        } else if (element === 3)
        {
            $('.appleLink').text('unlink');
        }
    });
}
