import { mrhMenuGenerateList } from "./mrh-inner-menu-list";

export function mrhGenerateMenuServiceList(categoryList: any, root: any) {
    let subMenuRightServiceList: any = [];
    let subMenuRightServiceListOtherCat:any = [];
    let dataId = categoryList.name.replace(/[^A-Z0-9]+/ig, "").toLowerCase();
    let catServiceType = categoryList.serviceType.toLowerCase();
    if (categoryList.serviceType.toLowerCase() !== "OtherService".toLowerCase()) {
        if (categoryList?.menu && categoryList?.menu.length !== 0) {
            for (let i = 0; i < categoryList.menu.length; i++) {
                subMenuRightServiceList.push(mrhMenuGenerateList(categoryList.menu[i],i,catServiceType,categoryList));
            }
        }
        $('.dropdown.menu-large a[href="'+root.link+'"] + .dropdown-menu.megamenu .dropmenu.new-submenu .mega-menu-links [data-id="'+dataId+'"] .inner-menu-row').append(subMenuRightServiceList);
    }else {
        subMenuRightServiceListOtherCat.push(mrhMenuGenerateList(categoryList?.category,'',catServiceType,categoryList));
        $('.dropdown.menu-large a[href="'+root.link+'"] + .dropdown-menu.megamenu .dropmenu.new-submenu .mega-menu-links [data-id="'+dataId+'"] .inner-menu-row').append(subMenuRightServiceListOtherCat);
        /*commenting for showing only categoty in case of */
        // categoryList?.category.forEach((item:any,catIndex:any)=>{
        //     if(item?.menu && item?.menu.length !==0) {
        //     item?.menu?.forEach((menuItem:any,menuIndex:any)=>{
        //     subMenuRightServiceListOtherCat.push(mrhMenuGenerateList(menuItem,menuIndex,catServiceType));
        //     })
        //   }
        // })
        // 
    }
}

 
