import { apiConfig } from "../config/apiConfig";
import {$} from "../third-party-imports";
import { startLoader } from "./startLoader";
import { stopLoader } from "./stopLoader";
import { preferredMethodOfContact, validTextMethodChk} from "../scheduler/common-os/os-flow-optimize";
import {getBrandDetailsFromBrandJson} from "../util/util";
window.objOSGlobal = {
    "paramsUI": { "doingBusinessAs": "", "franchiseLogoPath": "", "brandPhoneNumber": "", "serviceneeds": "", "wheredidyouhear": "", "onetime": "", "moveinout": "", },
    "requestBodyValues": { "FranchiseId": "", "WebLocationId": "", "C4ChannelTypeId": 0, "Comments": "", "ConceptCalledId": "", "ConceptName": "", "ConceptCode": "", "C4LeadsEnabled": true, "VendorId": "", "VendorName":"", "IsTest": true, "C4CallCenterId": 0, "Note": "", "FirstName": "", "LastName": "", "Email": "", "Phone": "", "PhoneType": "", "PostalCode": "", "City": "", "State": "", "Country": "US", "Address": "", "Address2": "", "leadOrigin": "Web", "leadSource": "Web", "CampaignName": "N/A", "CallType": "In Bound", "EmailOptOut": false, "Warranty": false, "MakeId": 0, "licenseNumber": "", "LeadSourceId": 0, "NeighborlyLeadSourceId": 0, "NeighborlyAccountId": 0, "ServiceSkuId": 0, "IsLeadOnly": true, "ScheduleID": "", "LeadAdditionalNoteLabel": "Website", "IsBetaTester": false,"isBookingCalled":false, }
}
let gvendorId:any ; let gvendorName:any;
const objOSGlobal = window.objOSGlobal;
let phInfo: any = 'input[name="insuredFirstName"],input[name="insuredLastName"],input[name="insuredEmailAddress"],input[name="insuredStreetAddress"],input[name="insuredCity"],';
let phInfo1: any = 'input[name="insuredFirstName"],input[name="insuredLastName"],input[name="insuredStreetAddress"],input[name="insuredCity"],';
let insuInfo: any = 'input[name="infoInsuranceCompany"],input[name="infoInsurancePolicyNumber"],input[name="infoInsuranceCauseOfLoss"],';
let vehiInfo: any = 'input[name="infoVehicleMake"],input[name="infoVehicleModel"],input[name="infoVehicleStyle"],input[name="infoVehicleVimNumber"],';
let agentInfo: any = 'input[name="infoAgentName"],input[name="infoAgencyName"],input[name="infoAgencyAddress"],input[name="infoAgencyCity"],input[name="infoAgencyEmail"],';
let agentInfo1: any = 'input[name="infoAgentName"],input[name="infoAgencyName"],input[name="infoAgencyAddress"],input[name="infoAgencyCity"],';
let commentBox: any = 'textarea[name="infoComments"]';
let formElements:any = phInfo + insuInfo + vehiInfo + agentInfo  + commentBox;
let formElements1:any = phInfo1 + insuInfo + vehiInfo + agentInfo1 + commentBox;

(function ()
{
    let insuranceFormId = $('#insuranceForm').val();
    if (insuranceFormId=='')
    {
        let plgConceptCode = $('#conceptCode').val() || $('#conceptId').val();
        if (plgConceptCode === 'PLG' || plgConceptCode === '14')
        {
            insuranceGetState(); // state API
            InsuranceFormHtmlInjectionField();
            if($("#infoInsuranceDateOfLoss")[0]) {
            formateDate();
            }
            GetCampaignName();
            //callBrandJson();
        }
    }
    if($("#ins_thankyou_page")[0]) {
        displayThankyouPageDetails();
      }
}());
 $("#insuranceForm .brand-btn").on('click',function(e:any){
    e.preventDefault();
    if (!insuranceFormValidation() ){
        scrollUp();
        return false;
    }
    let addressParam:any = $('input[name="insuredZipCode"]').val();
    let roundRobin:any = false;
    getFranchiseLookUpByServiceAddressApi(addressParam,roundRobin)
});

function insuranceFormValidation(){
    let isValid = true;
    if (!$('#insuranceForm').valid() || !validTextMethodChk()){
        isValid = false;
    }
    return isValid;
 };
 function insuranceGetState(){
    let type = 'GET';
    let baseUrl = apiConfig.stateApi;
    let country = "";
    let domainName = location.hostname.toLowerCase();  
    let domainType = window.DEFINE_VALUE.Domain_Type;
if (domainType != "digital"){
        let languageTrans = window.location.href;
        let slug = languageTrans.split('/');
        country = slug[3];
    }
    else{
        country = domainName.indexOf("-ca-") === -1 && domainName.indexOf(".ca") === -1 && domainName.indexOf("-ca.") ===-1 ? "us" : "ca";
    }
    if (country == 'ca'){
        let caUrl = baseUrl + "CA?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
        let caObj ={
            caUrl,
            type
        }
        $('select[name="insuredState"]').empty();
        $('select[name="infoAgencyState"]').empty();
        insuranceCommonAjaxCall(caObj,function(results:any){
            let usUrl = baseUrl + "US?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
            let usObj ={
                usUrl,
                type
            }
            let states:any = [];
            states.push('<option value="" selected="selected">Select</option>');
            $.each(results, function (index:any, st:any) {
                states.push('<option value="' + st.stateCode +'">' + st.stateName + '</option>');
             });
             insuranceCommonAjaxCall(usObj,function(results:any){
                 $.each(results, function (index:any, st:any) {
                    states.push('<option value="' + st.stateCode +'">' + st.stateName + '</option>');
                 });
                $('select[name="insuredState"]').empty();
                $('select[name="insuredState"]').append(states);
                $('select[name="infoAgencyState"]').empty();
                $('select[name="infoAgencyState"]').append(states);
            });
            states.push('<option value="" selected="selected">Select</option>')
            $.each(results, function (index:any, st:any) {
            states.push('<option value="' + st.stateCode +'">' + st.stateName + '</option>');
            });
            $('select[name="insuredState"]').append(states);
            $('select[name="infoAgencyState"]').append(states);
        });
    }else{
        let countryCode = "US";
        let states:any = [];
        let url =  baseUrl + countryCode + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
        let obj  = {
            url,
            type
        }
        insuranceCommonAjaxCall(obj,function(results:any){
            states.push('<option value="" selected="selected">Select</option>');
            $.each(results, function (index:any, st:any) {
               states.push('<option value="' + st.stateCode +'">' + st.stateName + '</option>');
            });
           $('select[name="insuredState"]').empty();
           $('select[name="insuredState"]').append(states);
           $('select[name="infoAgencyState"]').empty();
           $('select[name="infoAgencyState"]').append(states);
       });
    }
};
function insuranceCommonAjaxCall(obj:any,successHandler:any){
    let url = obj.url;
    let type = obj.type;
    let data = JSON.stringify(obj?.payload)
    $.ajax({
      url,
      type,
      data,
      contentType: "application/json",
      dataType: 'json',
      success: function (res:any){
        successHandler(res);
      },
      error: function (error:any){ 
        stopLoader(); 
      }
    });
}
function formatPhoneNumber(value:any) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6, 10)}`;
}

//  $('input[name="insuredPrimaryPhone"],input[name="insuredAlternativePhone"],input[name="infoInsuranceCompanyPhoneNumber"],input[name="infoAgencyDirectPhoneNumber"]').on((e) => {
//     const inputField:any = e.currentTarget;
//     const formattedInputValue = formatPhoneNumber(inputField.value);
//     inputField.value = formattedInputValue;
// });

$('input[name="insuredPrimaryPhone"],input[name="insuredAlternativePhone"],input[name="infoInsuranceCompanyPhoneNumber"],input[name="infoAgencyDirectPhoneNumber"]').on('input', function(e:any) {
    let maxChars = 14;
    if ($(this).val().length > maxChars) {
        $(this).val($(this).val().substr(0, maxChars));
    }
    const inputField:any = e.currentTarget;
    const formattedInputValue = formatPhoneNumber(inputField.value);
    inputField.value = formattedInputValue;
});

function InsuranceFormHtmlInjectionField(){
    $(formElements).bind('keypress', function (event:any) {
        var id = $(event.target).attr("id");
        if(id !== "infoComments") {
        var keyCode = event.keyCode || event.which ;
        if (keyCode == 60 || keyCode == 62 || keyCode == 47 || keyCode == 92) { 
            return false;
        } 
       }
    });

    $(formElements).bind('paste',function(e:any){
        let element:any = this;
        let elementId:any = element.id;
           setTimeout(function(){
              let data= $("#" +elementId).val(); 
              let  regx = /[^<>/]/g ; 
              let regxS = /[<>/]/g;
              if(regx.test(data) || regxS.test(data)){
                let data1 = $("#" +elementId).val().replaceAll('<','');
                let data2 = data1.replaceAll('>','');
                let data3 = data2.replaceAll('/','');
                $("#" +elementId).val(data3);
              }
           });
    });
};

$(formElements1).on('input', function (e:any)
{
    let element = this;
    let elementId = element.id;
    if( elementId !== "infoComments") {
    let data = $("#" + elementId).val();
    if (/^\s/.test(data))
    {
        $("#" + elementId).val('');
    }
    let data1 = $("#" + elementId).val().replaceAll(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, '')
    $("#" + elementId).val(data1);
  }
});
$('#infoInsuranceDeductible').on('input', function (e: any)
{
    const inputField = e.currentTarget;
    let maxChars = 10;
        if (inputField.value.length > maxChars) {
            inputField.value = inputField.value.substr(0, maxChars);
        }
    if (inputField.value.length === 1)
    {
        return false;
    }
    inputField.value = inputField.value.replace(/[^0-9\.]/g, '');
    let data1 = inputField.value
    if (isNaN(data1))
    {
        data1 = data1.replace(/[^0-9\.]/g, '');
        if (data1.split('.').length > 2)
            data1 = data1.replace(/\.+$/, "");
        inputField.value = data1
    }
});

function formateDate() {
    var date = document.getElementById('infoInsuranceDateOfLoss');
    function checkValue(str:any, max:any) {
      if (str.charAt(0) !== '0' || str == '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) 
               && num.toString().length == 1 ? '0' + num : num.toString();
      };
      return str;
    };
    date.addEventListener('input', function(e) {
      this.type = 'text';
      var input = this.value;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
      var values = input.split('/').map(function(v:any) {
        return v.replace(/\D/g, '')
      });
      if (values[0]) values[0] = checkValue(values[0], 12);
      if (values[1]) values[1] = checkValue(values[1], 31);
      var output = values.map(function(v:any, i:any) {
        return v.length == 2 && i < 2 ? v + ' / ' : v;
      });
      this.value = output.join('').substr(0, 14);
    });
};
$('#infoVehicleYear').on('input', function (e: any)
{
    const inputField = e.currentTarget;
    let maxChars = 4;
    if (inputField.value.length > maxChars)
    {
        inputField.value = inputField.value.substr(0, maxChars);
    }
    if (inputField.value.length === 1 && inputField.value === "0")
    {
        inputField.value = inputField.value.replace("0", '');
        return false;
    }
    inputField.value = inputField.value.replace(/\D/g, '');

});
$('#infoInsuranceClaimNumber').on('input', function (e: any)
{
    const inputField = e.currentTarget;
    let maxChars = 20;
    if (inputField.value.length > maxChars)
    {
        inputField.value = inputField.value.substr(0, maxChars);
    }
    inputField.value = inputField.value.replace(/\D/g, '');
});

$("#infoAgencyCity").keypress(function (e)
{
    var key = e.keyCode;
    if (key >= 48 && key <= 57)
    {
        e.preventDefault();
    }
});

function submitInsuranceForm() {
    let insuredFirstName:any = $('input[name="insuredFirstName"]').val(); let insuredLastName:any  = $('input[name="insuredLastName"]').val();
    let insuredPrimaryPhone:any = $('input[name="insuredPrimaryPhone"]').val(); 
    let insuredEmailAddress:any = $('input[name="insuredEmailAddress"]').val(); 
    
    preferredMethodOfContact();

    let insuredStreetAddress:any = $('input[name="insuredStreetAddress"]').val();
    let insuredCity:any = $('input[name="insuredCity"]').val(); let insuredState:any =$('#insuredState option:selected').val();
    let insuredZipCode:any = $('input[name="insuredZipCode"]').val(); let insuranceCompany:any = $('input[name="infoInsuranceCompany"]').val();
    let insuranceCompanyPhoneNumber:any = $('input[name="infoInsuranceCompanyPhoneNumber"]').val(); let insurancePolicyNumber:any =   $('input[name="infoInsurancePolicyNumber"]').val();
    let deductibleAmount:any =   $('input[name="infoInsuranceDeductible"]').val(); let insuranceDateOfLoss:any =    $('input[name="infoInsuranceDateOfLoss"]').val().replaceAll(" ",'');
    let insuranceCauseOfLoss:any = $('input[name="infoInsuranceCauseOfLoss"]').val(); let insuranceClaimNumber:any = $('input[name="infoInsuranceClaimNumber"]').val();
    let vehicleYear:any = $('input[name="infoVehicleYear"]').val(); let vehicleMake:any = $('input[name="infoVehicleMake"]').val();
    let vehicleModel:any = $('input[name="infoVehicleModel"]').val(); let vehicleStyle:any = $('input[name="infoVehicleStyle"]').val();
    let vehicleVimNumber:any = $('input[name="infoVehicleVimNumber"]').val(); let agentName:any = $('input[name="infoAgentName"]').val();
    let agencyName:any = $('input[name="infoAgencyName"]').val(); let agencyAddress:any = $('input[name="infoAgencyAddress"]').val();
    let agencyCity:any = $('input[name="infoAgencyCity"]').val(); let agencyState:any =$('#infoAgencyState option:selected').val();
    let agencyZipCode:any = $('input[name="infoAgencyZipCode"]').val(); let agencyDirectPhoneNumber:any = $('input[name="infoAgencyDirectPhoneNumber"]').val();
    let infoAgencyEmail:any = $('input[name="infoAgencyEmail"]').val(); let infoComments:any = $('textarea[name="infoComments"]').val();
    objOSGlobal.requestBodyValues.IsEstimate = false;
    /*making is lead is true for insurance form only"*/
    objOSGlobal.requestBodyValues.IsLeadOnly = true;
    objOSGlobal.paramsUI.selectedDate = '';
    objOSGlobal.requestBodyValues.ScheduleID ='';
    objOSGlobal.requestBodyValues.ServiceText = insurancePurpose();
    objOSGlobal.requestBodyValues.Note = infoComments;
    const ServiceRequested:any =  serviceRequested();
    const options:any = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }
    let JobPromisedDate:any = new Date().toLocaleDateString("en-US", options);
    let insuranceCompanyPhNum:any = $("#infoInsuranceCompanyPhoneNumber").val();
    let agencyDirectPhNum:any = $("#infoAgencyDirectPhoneNumber").val();
    if(insuranceCompanyPhNum) {
        insuranceCompanyPhNum = insuranceCompanyPhNum.replace("(", '').replace(")", '').replace("-", '').replace(" ", '')
    }else {
        insuranceCompanyPhNum = "";
    }
    if(agencyDirectPhNum) {
        agencyDirectPhNum = agencyDirectPhNum.replace("(", '').replace(")", '').replace("-", '').replace(" ", '')
    } else {
        agencyDirectPhNum = ""
    }
    var host = window.location.hostname.toLowerCase();
        if(host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) 
        {
            objOSGlobal.requestBodyValues.IsTest = true;
        }else {
            objOSGlobal.requestBodyValues.IsTest = false;
        }
    var payload = {
        "FranchiseId": window.objOSGlobal.requestBodyValues.franchiseId ? window.objOSGlobal.requestBodyValues.franchiseId : 0,
        "HasSameDayScheduling": objOSGlobal.requestBodyValues.HasSameDayScheduling ? objOSGlobal.requestBodyValues.HasSameDayScheduling : true,
        "IsBetaTester": objOSGlobal.requestBodyValues.IsBetaTester ? objOSGlobal.requestBodyValues.IsBetaTester : false,
        "Comments": objOSGlobal.requestBodyValues.Comments ? objOSGlobal.requestBodyValues.Comments : "",
        "IsLocalized": objOSGlobal.requestBodyValues.IsLocalized ? objOSGlobal.requestBodyValues.IsLocalized : true,
        "ConceptId": Number($('#conceptId').val()) ? Number($('#conceptId').val()) : 0,
        "UseFranchiseProfileOptInForPOSScheduling": objOSGlobal.requestBodyValues.UseFranchiseProfileOptInForPOSScheduling ? objOSGlobal.requestBodyValues.UseFranchiseProfileOptInForPOSScheduling : true,
        "IsFranchiseOptedIntoPOSScheduling": objOSGlobal.requestBodyValues.IsFranchiseOptedIntoPOSScheduling ? objOSGlobal.requestBodyValues.IsFranchiseOptedIntoPOSScheduling : true,
        "C4ChannelTypeId": objOSGlobal.requestBodyValues.C4ChannelTypeId ? objOSGlobal.requestBodyValues.C4ChannelTypeId : 0,
        "ConceptCalledId": Number($('#conceptId').val()) ? Number($('#conceptId').val()) : 0,
        "ConceptName": objOSGlobal.requestBodyValues.ConceptName ? objOSGlobal.requestBodyValues.ConceptName : "",
        "ConceptCode": $('#conceptCode').val() ? $('#conceptCode').val() : "",
        "C4LeadsEnabled": objOSGlobal.requestBodyValues.C4LeadsEnabled ? objOSGlobal.requestBodyValues.C4LeadsEnabled : true,
        "CanceledJobInformation": objOSGlobal.requestBodyValues.CanceledJobInformation ? objOSGlobal.requestBodyValues.CanceledJobInformation : "",
        "VendorId": gvendorId ? gvendorId : "",
        "NationalLeadId": objOSGlobal.requestBodyValues.NationalLeadId ? objOSGlobal.requestBodyValues.NationalLeadId : "",
        "IsTest": objOSGlobal.requestBodyValues.IsTest,
        "VendorName": gvendorName ? gvendorName : "",
        "WebLocationId": window.objOSGlobal.requestBodyValues.WebLocationId ? Number(window.objOSGlobal.requestBodyValues.WebLocationId) : 0,
        "C4CallCenterId": objOSGlobal.requestBodyValues.C4CallCenterId ? objOSGlobal.requestBodyValues.C4CallCenterId : 0,
        "DayTimePhoneNumber": objOSGlobal.requestBodyValues.DayTimePhoneNumber ? objOSGlobal.requestBodyValues.DayTimePhoneNumber : "",
        "ZipCode": objOSGlobal.requestBodyValues.ZipCode ? objOSGlobal.requestBodyValues.ZipCode : "",
        "Note": objOSGlobal.requestBodyValues.Note ? objOSGlobal.requestBodyValues.Note : "",
        "FirstName": insuredFirstName ? insuredFirstName : "",
        "LastName": insuredLastName ? insuredLastName : "",
        "Email": insuredEmailAddress ? insuredEmailAddress : "",
        "Phone": insuredPrimaryPhone ? insuredPrimaryPhone : "",
        "PhoneType": objOSGlobal.requestBodyValues.PhoneType ? objOSGlobal.requestBodyValues.PhoneType : "",
        "PostalCode": insuredZipCode ? insuredZipCode : "",
        "City": insuredCity ? insuredCity : "",
        "State": insuredState ? insuredState : "",
        "Country": $("#countryCode").val() ? $("#countryCode").val().toUpperCase() : "",
        "Address": insuredStreetAddress ? insuredStreetAddress : "",
        "Address2": objOSGlobal.requestBodyValues.Address2 ? objOSGlobal.requestBodyValues.Address2 : "",
        "LeadSource": objOSGlobal.requestBodyValues.LeadSource ? objOSGlobal.requestBodyValues.LeadSource : "Web",
        "LicenseNumber": window.objOSGlobal.requestBodyValues.licenseNumber ? window.objOSGlobal.requestBodyValues.licenseNumber : "",
        "WebLocationAccountCode": objOSGlobal.requestBodyValues.WebLocationAccountCode ? objOSGlobal.requestBodyValues.WebLocationAccountCode : "",
        "LeadOrigin": objOSGlobal.requestBodyValues.LeadOrigin ? objOSGlobal.requestBodyValues.LeadOrigin : "Web",
        "CampaignName": objOSGlobal.requestBodyValues.CampaignName ? objOSGlobal.requestBodyValues.CampaignName : "",
        "ReferringURL": document.referrer,
        "Disposition": objOSGlobal.requestBodyValues.Disposition ? objOSGlobal.requestBodyValues.Disposition : "",
        "CallType": objOSGlobal.requestBodyValues.CallType ? objOSGlobal.requestBodyValues.CallType : "",
        "PreferredCommunicationType": window.objOSGlobal.requestBodyValues.PreferredCommunicationType? window.objOSGlobal.requestBodyValues.PreferredCommunicationType: "" ,
        "EmailOptOut": objOSGlobal.requestBodyValues.EmailOptOut,
        "TagName": objOSGlobal.requestBodyValues.TagName ? objOSGlobal.requestBodyValues.TagName : "",
        "Latitude": objOSGlobal.requestBodyValues.Latitude ? objOSGlobal.requestBodyValues.Latitude : 0,
        "Longitude": objOSGlobal.requestBodyValues.Longitude ? objOSGlobal.requestBodyValues.Longitude : 0,
        "LocationType": objOSGlobal.requestBodyValues.LocationType ? objOSGlobal.requestBodyValues.LocationType : "",
        "IsGoogleAddress": objOSGlobal.requestBodyValues.IsGoogleAddress ? objOSGlobal.requestBodyValues.IsGoogleAddress : true,
        "GoogleCalendarEventId": objOSGlobal.requestBodyValues.GoogleCalendarEventId ? objOSGlobal.requestBodyValues.GoogleCalendarEventId : "",
        "GoogleCalendarEmail": objOSGlobal.requestBodyValues.GoogleCalendarEmail ? objOSGlobal.requestBodyValues.GoogleCalendarEmail : "",
        "ServiceText": objOSGlobal.requestBodyValues.ServiceText ? objOSGlobal.requestBodyValues.ServiceText : "",
        "ServiceID": objOSGlobal.requestBodyValues.ServiceID ? objOSGlobal.requestBodyValues.ServiceID : 0,
        "Warranty": objOSGlobal.requestBodyValues.Warranty ? objOSGlobal.requestBodyValues.Warranty : false,
        "TimeSlotTypeId": objOSGlobal.requestBodyValues.TimeSlotTypeId ? objOSGlobal.requestBodyValues.TimeSlotTypeId : 0,
        "MakeId": objOSGlobal.requestBodyValues.MakeId ? objOSGlobal.requestBodyValues.MakeId : 0,
        "LeadSourceId": objOSGlobal.requestBodyValues.LeadSourceId ? objOSGlobal.requestBodyValues.LeadSourceId : 0,
        "SecondaryLeadSourceId": objOSGlobal.requestBodyValues.SecondaryLeadSourceId ? objOSGlobal.requestBodyValues.SecondaryLeadSourceId : 0,
        "ServiceTypeProblem": objOSGlobal.requestBodyValues.ServiceTypeProblem ? objOSGlobal.requestBodyValues.ServiceTypeProblem : "",
        "OverrideJobPromisedDate": objOSGlobal.requestBodyValues.OverrideJobPromisedDate ? objOSGlobal.requestBodyValues.OverrideJobPromisedDate : "",
        "ServiceSkuId": objOSGlobal.requestBodyValues.serviceSkuId ? objOSGlobal.requestBodyValues.serviceSkuId : 0,
        "ServiceSkuIds": objOSGlobal.requestBodyValues.ServiceSkuIds ? objOSGlobal.requestBodyValues.ServiceSkuIds : "",
        "IsEstimate": objOSGlobal.requestBodyValues.IsEstimate,
        "EstimateHouseStyle": objOSGlobal.requestBodyValues.EstimateHouseStyle ? objOSGlobal.requestBodyValues.EstimateHouseStyle : "",
        "EstimateSquareFeet": objOSGlobal.requestBodyValues.EstimateSquareFeet ? objOSGlobal.requestBodyValues.EstimateSquareFeet : "",
        "EstimateBedrooms": objOSGlobal.requestBodyValues.EstimateBedrooms ? objOSGlobal.requestBodyValues.EstimateBedrooms : "",
        "EstimateBathrooms": objOSGlobal.requestBodyValues.EstimateBathrooms ? objOSGlobal.requestBodyValues.EstimateBathrooms : "",
        "EstimatePets": objOSGlobal.requestBodyValues.EstimatePets ? objOSGlobal.requestBodyValues.EstimatePets : "",
        "CCFirstName": objOSGlobal.requestBodyValues.CCFirstName ? objOSGlobal.requestBodyValues.CCFirstName : "",
        "CCLastName": objOSGlobal.requestBodyValues.CCLastName ? objOSGlobal.requestBodyValues.CCLastName : "",
        "CCType": objOSGlobal.requestBodyValues.CCType ? objOSGlobal.requestBodyValues.CCType : "",
        "CCSuffix": objOSGlobal.requestBodyValues.CCSuffix ? objOSGlobal.requestBodyValues.CCSuffix : "",
        "CCToken": objOSGlobal.requestBodyValues.CCToken ? objOSGlobal.requestBodyValues.CCToken : "",
        "CCExpirationDate": objOSGlobal.requestBodyValues.CCExpirationDate ? objOSGlobal.requestBodyValues.CCExpirationDate : "",
        "CCComment": objOSGlobal.requestBodyValues.CCComment ? objOSGlobal.requestBodyValues.CCComment : "",
        "IsLeadOnly": objOSGlobal.requestBodyValues.IsLeadOnly,
        "TimeBlock": {
            "ConceptId": Number($('#conceptId').val()) ? Number($('#conceptId').val()) : 0,
            "GoogleCalendarEventId": objOSGlobal.requestBodyValues.GoogleCalendarEventId ? objOSGlobal.requestBodyValues.GoogleCalendarEventId : "",
            "GoogleCalendarEmail": objOSGlobal.requestBodyValues.GoogleCalendarEmail ? objOSGlobal.requestBodyValues.GoogleCalendarEmail : "",
            "TimeSlotTypeId": objOSGlobal.requestBodyValues.TimeSlotTypeId ? objOSGlobal.requestBodyValues.TimeSlotTypeId : 0,
            "ServiceSkuId": objOSGlobal.requestBodyValues.serviceSkuId ? objOSGlobal.requestBodyValues.serviceSkuId : 0,
            "IsEstimate": objOSGlobal.requestBodyValues.IsEstimate,
            "JobPromisedDate": JobPromisedDate ? JobPromisedDate : "",
            "IsAvailable": objOSGlobal.requestBodyValues.IsAvailable ? objOSGlobal.requestBodyValues.IsAvailable : true,
            "IsFranchiseInPOS": objOSGlobal.requestBodyValues.IsFranchiseInPOS ? objOSGlobal.requestBodyValues.IsFranchiseInPOS : true,
            "DisplayText": objOSGlobal.requestBodyValues.DisplayText ? objOSGlobal.requestBodyValues.DisplayText : ""
        },
        "OverrideCSRName": objOSGlobal.requestBodyValues.OverrideCSRName ? objOSGlobal.requestBodyValues.OverrideCSRName : "",
        "OverrideComment": objOSGlobal.requestBodyValues.OverrideComment ? objOSGlobal.requestBodyValues.OverrideComment : "",
        "SyncTenantName": objOSGlobal.requestBodyValues.SyncTenantName ? objOSGlobal.requestBodyValues.SyncTenantName : "",
        "IsEmergencyCall": objOSGlobal.requestBodyValues.IsEmergencyCall ? objOSGlobal.requestBodyValues.IsEmergencyCall : false,
        "CallId": objOSGlobal.requestBodyValues.CallId ? objOSGlobal.requestBodyValues.CallId : "",
        "IsFranchiseInPOS": objOSGlobal.requestBodyValues.IsFranchiseInPOS ? objOSGlobal.requestBodyValues.IsFranchiseInPOS : true,
        "CustomerType": objOSGlobal.requestBodyValues.CustomerType ? objOSGlobal.requestBodyValues.CustomerType : "",
        "NeighborlyAccountId": objOSGlobal.requestBodyValues.NeighborlyAccountId ? objOSGlobal.requestBodyValues.NeighborlyAccountId : 0,
        "NeighborlyLeadSourceId": objOSGlobal.requestBodyValues.NeighborlyLeadSourceId ? objOSGlobal.requestBodyValues.NeighborlyLeadSourceId : "",
        "ReferralTypeId": objOSGlobal.requestBodyValues.ReferralTypeId ? objOSGlobal.requestBodyValues.ReferralTypeId : null,
        "ReferralType": objOSGlobal.requestBodyValues.ReferralType ? objOSGlobal.requestBodyValues.ReferralType : null ,
        "FileUrls": [{
            "FileUrl": ""
        }, {
            "FileUrl": ""
        }, {
            "FileUrl": ""
        }],
        "JobFrequency": objOSGlobal.requestBodyValues.JobFrequency ? objOSGlobal.requestBodyValues.JobFrequency : "",
        "JobFrequencyDetail": objOSGlobal.requestBodyValues.JobFrequencyDetail ? objOSGlobal.requestBodyValues.JobFrequencyDetail : "",
        "Year": Number(vehicleYear) ? Number(vehicleYear) : 0,
        "Make": vehicleMake ? vehicleMake : "",
        "Model": vehicleModel ? vehicleModel : "",
        "BodyStyle": vehicleStyle ? vehicleStyle : "",
        "VIN": vehicleVimNumber ? vehicleVimNumber : "",
        "LicensePlateNumber": objOSGlobal.requestBodyValues.LicensePlate ? objOSGlobal.requestBodyValues.LicensePlate : "",
        "WindshieldRepair": objOSGlobal.requestBodyValues.WindshieldRepair ? objOSGlobal.requestBodyValues.WindshieldRepair : "",
        "HeadlightRestoration": objOSGlobal.requestBodyValues.HeadlightRestoration ? objOSGlobal.requestBodyValues.HeadlightRestoration : "",
        "ServiceRequested": ServiceRequested as any[],
        "LeadAdditionalNotes": {
            "LeadAdditionalNoteLabel": objOSGlobal.requestBodyValues.LeadAdditionalNoteLabel ? objOSGlobal.requestBodyValues.LeadAdditionalNoteLabel : "",
            "LeadAdditionalNoteText": objOSGlobal.requestBodyValues.LeadAdditionalNoteText ? objOSGlobal.requestBodyValues.LeadAdditionalNoteText : ""
        },
        "FormOfPayment": objOSGlobal.requestBodyValues.FormOfPayment ? objOSGlobal.requestBodyValues.FormOfPayment : "",
        "InsuranceCompany": insuranceCompany ? insuranceCompany : "",
        "DeductibleAmount": deductibleAmount ? deductibleAmount : 0,
        "PolicyNumber": insurancePolicyNumber ? insurancePolicyNumber : "",
        "ScheduleID": window.objOSGlobal.requestBodyValues.ScheduleId ? window.objOSGlobal.requestBodyValues.ScheduleId : "",
        "HowDidYouHearAboutUs":objOSGlobal.requestBodyValues.HowDidYouHearAboutUs ? objOSGlobal.requestBodyValues.HowDidYouHearAboutUs : "",
        "insuranceCompanyPhoneNumber":insuranceCompanyPhNum,
        "insuranceDateOfLoss":dateOfLossIsodate(),
        "claimNetworkReferralNumber":$("#infoInsuranceClaimNumber").val(),
        "insuranceCauseOfLoss":$("#infoInsuranceCauseOfLoss").val(),
        "agencyAgentName":$("#infoAgentName").val(),
        "agencyDirectPhoneNumber":agencyDirectPhNum,
        "agencyEmailAddress":$("#infoAgencyEmail").val(),
        "agencyName":$("#infoAgencyName").val(),
        "agencyAddress":$("#infoAgencyAddress").val(),
        "agencyCity":$("#infoAgencyCity").val(),
        "agencyState":$('#infoAgencyState option:selected').val(),
        "agencyZipCode":$("#infoAgencyZipCode").val()

    };
    $.ajax({
        url: window.DEFINE_VALUE.bookingEndpoint + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY,
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(payload),
        success: function (data:any, status:any){
            stopLoader();
            localStorage.setItem("OSWebLocationId", window.objOSGlobal.requestBodyValues.WebLocationId);
            localStorage.setItem("OSDbaFullName", window.objOSGlobal.paramsUI.doingBusinessAs);
         let websiteurl =  window.location.origin.toLowerCase();
         let redirectPage = $("#insurance_thankyou_page").val();
         plgInsurenceGetValues();
         sessionStorage.setItem("insuredFullName", insuredFirstName + " " + insuredLastName );
         sessionStorage.setItem("insuredPrimaryPhone", insuredPrimaryPhone);
         sessionStorage.setItem("insuredEmailAddress", insuredEmailAddress);
         sessionStorage.setItem("insuredAddress", insuredStreetAddress + ", " + insuredCity + ", " + $('#insuredState option:selected').text()+", "+insuredZipCode);
	     window.location.replace(websiteurl + redirectPage);
        },
        error: function (error:any,status:any){ 
            stopLoader(); 
        }
    })
}
function callBrandJson(result:any) {
    if (result.length > 0){
        var items = $.grep(result, function (e: any) { return e.code === $('#conceptCode').val() });
        window.objOSGlobal.requestBodyValues.brandJsonGlobalObj = items;
        gvendorId = items[0].vendorId ? items[0].vendorId : "";
        gvendorName = items[0].vendorName ? items[0].vendorName : "";
    }
}


function GetCampaignName()
{
    let url = window.location.href;
    let urlPath = new URL(url);
    let param = new URLSearchParams(urlPath.search);
    let cid = param.get('cid');

    let utm_source = param.get('utm_source');
    let utm_campaign = param.get('utm_campaign');
    let utm_medium = param.get('utm_medium');

    if (cid)
    {
        utm_source = utm_source ? ', ' + utm_source : '';
        utm_campaign = utm_campaign ? ', ' + utm_campaign : '';
        utm_medium = utm_medium ? ', ' + utm_medium : '';
        objOSGlobal.requestBodyValues.CampaignName = cid + utm_source + utm_campaign + utm_medium;
    } else
    {
        objOSGlobal.requestBodyValues.CampaignName = 'N/A';
    }
}

function getFranchiseLookUpByServiceAddressApi(addressParam: any,roundRobin: any){
    var conceptID = $('#conceptId').val();
    var url =   apiConfig.FranchiseLookUpByServiceAddress+addressParam+'&IsRoundRobin='+roundRobin+'&conceptID='+conceptID+'&RequireAddressOnPartialPostalCode=true&apikey=' + window.DEFINE_VALUE.JS_API_KEY;
    let type = 'GET';
    let usObj ={
        url,
        type
    }
    startLoader();
    insuranceCommonAjaxCall(usObj, async function(result:any){
        if(result.length==0){
            stopLoader();
            noPostalFound()
        }else{
            const brandJsonresult = await getBrandDetailsFromBrandJson();
            if(brandJsonresult.length > 0){
            callBrandJson(brandJsonresult)
            window.objOSGlobal.requestBodyValues.WebLocationId = result[0].franchiseWebLocationId;
            window.objOSGlobal.requestBodyValues.franchiseId = result[0].franchiseId;
            window.objOSGlobal.requestBodyValues.licenseNumber = result[0].licenseNumber;
            submitInsuranceForm()
            }
        }
    })
}
function displayThankyouPageDetails() {
    $("#div_property_details").hide();
    $($('.thankyou-sec .contact-form h3')[4]).text("Address:");
    let fullName:any = sessionStorage.getItem("insuredFullName");
    let phNum:any= sessionStorage.getItem("insuredPrimaryPhone");
    let eml:any = sessionStorage.getItem("insuredEmailAddress");
    let addr:any = sessionStorage.getItem("insuredAddress")
    $($('.thankyou-sec .contact-form p')[0]).text(fullName);
    $($('.thankyou-sec .contact-form p')[1]).text(eml);
    $($('.thankyou-sec .contact-form p')[2]).text(phNum);
    $($('.thankyou-sec .contact-form p')[3]).text(addr);
    let plgInsurenceFormData = JSON.parse(sessionStorage.getItem('plgInsurenceForm'));
    let plgPreferredContact = sessionStorage.getItem("plg-preferred-contact");
    let infoReplaceService = sessionStorage.getItem("infoReplaceService");
    
    plgInsurenceFormData.insuredStreetAddress = plgInsurenceFormData.insuredStreetAddress +', '+ plgInsurenceFormData.insuredCity +' '+plgInsurenceFormData.insuredState+' '+plgInsurenceFormData.insuredZipCode;
    plgInsurenceFormData.infoAgencyAddress = plgInsurenceFormData.infoAgencyAddress +', '+ plgInsurenceFormData.infoAgencyCity +' '+plgInsurenceFormData.infoAgencyState+' '+plgInsurenceFormData.infoAgencyZipCode;

    if(plgInsurenceFormData){
        document.querySelectorAll('.insurence-form-common-field').forEach((el:any) =>
        {
            Object.keys(plgInsurenceFormData).forEach(function (key, index)
            {
                if (el.getAttribute('id') == key)
                {
                    el.innerText = plgInsurenceFormData[key];
                }
            });
        });
        $('.plg-preferred-contact label').text(plgPreferredContact);
        $('#infoReplaceService').text(infoReplaceService);
    }
}

function noPostalFound() {
const postalCode:any = $('input[name="insuredZipCode"]').val()
$('.vertical-contact-form .form-ctn').hide();
$('.insscheduler-modal').removeClass('d-none')
$('.insscheduler-modal .js-next-btn').removeClass('disble-btn');
$('.insscheduler-modal .js-next-btn').attr('disabled',false);
$('.ins-md .contact-form .card-title').html(`We're sorry. We don't currently provide service to ${postalCode}`)
}

function serviceRequested() {
    let serviceRequestArr:any = [];
	let getCheckBoxItem:any =  document.querySelectorAll('#insuranceForm input[type="checkbox"]');
    getCheckBoxItem.forEach((item:any,index:any)=>{
        if($("#" + item.id).prop('checked')){
          let getCheckBoxLabel = $("#" + item.id).next().text();
          serviceRequestArr.push(getCheckBoxLabel)
        }
    })
    return serviceRequestArr;
}

function insurancePurpose()
{
    const insurance_Type = $("#insurance_type").val();
    let ServiceText: any;
    switch (insurance_Type)
    {
        case 'Agent Portal':
            ServiceText = 'Submission from Agent Portal';
            break;
        case 'Insurance Form':
            ServiceText = 'Submission from Insurance Form';
            break;
        case 'Veterans Portal':
            ServiceText = 'Submission from Veterans Portal';
            break;
        default:
            ServiceText = ''
            break;
    }
    return ServiceText;
}

function dateOfLossIsodate() {
let enteredDate:any = $("#infoInsuranceDateOfLoss").val().replaceAll(' ', '');
let formatedDate:any = enteredDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
let IsoDateFormat:any;
if(formatedDate) {
    IsoDateFormat = new Date(formatedDate).toISOString();
}else {
    IsoDateFormat = ''
}
return IsoDateFormat
}

function scrollUp(){
    $('html, body').animate({
        scrollTop: $("body").offset().top
    }, 500);
  }


function plgInsurenceGetValues(){
    let infoReplaceService: any = [];
    $("#insuranceForm .checkbox:checked").each(function (this:any){
        infoReplaceService.push($(this).next('.body-text-sm').text());
    });
    sessionStorage.setItem("infoReplaceService", infoReplaceService.toString());
    window.objOSGlobal.requestBodyValues.plgInsurenceForm = {};
    const plgInsurenceForm  = $('#insuranceForm');
    const $name_fields = plgInsurenceForm.find('.form-control');
    $name_fields.each(function (i: any, $element: any)
    {
        
        const $field = $($element);
        let $name: any = $field.attr('name');
        if ($field.is(':visible'))
        {
            if ($field.is(':radio') || $field.is(':checkbox'))
            {
                let $checked = plgInsurenceForm.find('input[name="' + $name + '"]:checked');
                if ($name == 'seller-check1' || $name == 'buyer-check1' || $name == 'agent-check1')
                {
                    let values: any = [];
                    $(".propertyPresent:checked").each(function (this:any)
                    {
                        values.push($(this).val());
                    });
                    window.objOSGlobal.requestBodyValues.plgInsurenceForm.inspection = values;
                } else
                {
                    window.objOSGlobal.requestBodyValues.plgInsurenceForm[$name] = $checked.val();
                }
            } else
            {
                window.objOSGlobal.requestBodyValues.plgInsurenceForm[$name] = $field.val();
            }
        }
    });
    sessionStorage.setItem('plgInsurenceForm',JSON.stringify(window.objOSGlobal.requestBodyValues.plgInsurenceForm));
}