import { searchG2QueryParams } from "./searchG2QueryParams";

/**
 * Initialize Blog Article Page
 */

export function initBlogArticlePage()
{
    const prevSearchContextCategory = localStorage.getItem('blog_category');
    const prevSearchContextTag = localStorage.getItem('blog_tag');
    let category = "none";
    let tag = "";
    if (prevSearchContextCategory || prevSearchContextTag)
    {
        category = prevSearchContextCategory;
        tag = prevSearchContextTag;
    } else
    {
        const url = window.location.href;
        const categoryRegex = /\/category\/[\s\-a-zA-Z0-9]+\/?/gim;
        const tagRegex = /\/tag\/[\s\-a-zA-Z0-9]+\/?/gim;

        const matchedCategory = url.match(categoryRegex) || ["/category/none"];
        const matchedTag = url.match(tagRegex) || ["/tag/"];

        category = matchedCategory[0].split("/category/")[1].replace("/", "").replace(/-/g, ' ');
        tag = matchedTag[0].split("/tag/")[1].replace("/", "").replace(/-/g, ' ');
    }
    if (category !== 'none')
    {
        searchG2QueryParams('custom_s_category', category);
    } else if (tag !== "")
    {
        searchG2QueryParams('custom_ss_tags', tag);
    } else
    {
        searchG2QueryParams(undefined, undefined);
    }
}
 