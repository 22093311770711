import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiCaching, apiConfig } from "../config";
import { $ } from "../third-party-imports";

export async function GetLeadSourceMolly()
{
    let blogAboutUs = $('#blogAboutUs').val();
    let referenceDetailsVal = $('#referenceDetails').val();
    if (!referenceDetailsVal || blogAboutUs === "Select")
    {
        try
        {
            const apiUrl = apiConfig.syncCleoLeadSources
            let data = await getCachedAPIResponse(apiUrl, apiCaching.syncCleoLeadSources);
            if (data)
            {
                data = data?.filter((item: any) => item.leadSourceName !== "Undefined" && item.isActive)
                $('form#contactusformMly #referenceDetails').empty();
                $('#estimateBedroomsThree').empty();
                $('#blogAboutUs').empty();
                $('#estimateBedroomsThree').append(new Option("-Select one", ""));
                $('form#contactusformMly #referenceDetails').append(new Option("-Select one", ""));
                $('#blogAboutUs').append(new Option("Select", ""));
                data.forEach((element: any) =>
                {
                    $('form#contactusformMly #referenceDetails').append(new Option(element.leadSourceName, element.leadSourceId));
                    $('#estimateBedroomsThree').append(new Option(element.leadSourceName, element.leadSourceId));
                    $('#blogAboutUs').append(new Option(element.leadSourceName, element.leadSourceId));
                });
                $('#referenceDetails option[value=""]').attr('selected', 'selected');
                $('#estimateBedroomsThree option[value=""]').attr('selected', 'selected');
                $('#blogAboutUs').attr('selected', 'selected');
            }
        } catch (error: any)
        {
            $('form#contactusformMly #referenceDetails').empty();
            $('form#contactusformMly #referenceDetails').append(new Option(""));
            $('#estimateBedroomsThree').empty();
            $('#estimateBedroomsThree').append(new Option(""));
            $('#blogAboutUs').empty();
            $('#blogAboutUs').append(new Option(""));
            console.error(`Error in fetching Molly generic lead source api response Error message: ${error}`);
        }
    }
        
}