import { $ } from '../third-party-imports';
export function Get_HoursofOperation_From_FullAttributeApi() {
    try {
        if ($("#div_contact_hours_dayofweek").length > 0) {
            var DaysOfWeek = {
                0: 'Sunday',
                1: 'Monday',
                2: 'Tuesday',
                3: 'Wednesday',
                4: 'Thursday',
                5: 'Friday',
                6: 'Saturday'
            }
            let fullAttrOnLoad = localStorage.getItem("fullAttrOnLoad");
            if(fullAttrOnLoad){
            var data = JSON.parse(fullAttrOnLoad);
                $("#div_contact_hours_dayofweek").html("");
                var curDate = new Date();
                var curDay = curDate.getDay();
                if(data.hoursofOperation) {
                var hoursofOperation = data.hoursofOperation.sort((a:any, b:any) => (a.dayOfWeek > b.dayOfWeek) ? 1 : -1);
                var hoursofOperationbyCurDay:any = [];
                var curIndex = 0;
                $.each(hoursofOperation, function (index:any, hour:any) {
                    if (index >= curDay) {
                        hoursofOperationbyCurDay[curIndex] = hour;
                        curIndex++;
                    }
                });
                $.each(hoursofOperation, function (index:any, hour:any) {
                    if (index < curDay) {
                        hoursofOperationbyCurDay[curIndex] = hour;
                        curIndex++;
                    }
                });
                $.each(hoursofOperationbyCurDay, function (index: any, hour: any) {
                    var NormalHour = "";
                    if (hour.isOpenForNormalHours == false) {
                        NormalHour = "Closed";
                    }
                    else {
                        var curStartHour:any = Math.floor(hour.startMinutes / 60);
                        var curStartMinute:any = Math.floor(hour.startMinutes % 60).toString();
                        var curEndHour:any = Math.floor(hour.endMinutes / 60);
                        var curEndMinute:any = Math.floor(hour.endMinutes % 60).toString();
                        var curStartHour_suffix = (curStartHour >= 12) ? ' PM' : ' AM';
                        var curEndHour_suffix = setSuffix(curEndHour);
                        if(curEndHour_suffix.includes("EOD")){
                           NormalHour = "24 Hours"; 
                        }
                        else{
                            curStartMinute = curStartMinute.length == 1 ? "0" + curStartMinute : curStartMinute;
                            curEndMinute = curEndMinute.length == 1 ? "0" + curEndMinute : curEndMinute;
                            curStartHour = ((curStartHour + 11) % 12 + 1) + ":" + curStartMinute + curStartHour_suffix;
                            curEndHour = ((curEndHour + 11) % 12 + 1)+ ":" + curEndMinute + curEndHour_suffix;
                            NormalHour = curStartHour + " - " + curEndHour;
                        }
                        
                    }
                    var curDay = DaysOfWeek[hour.dayOfWeek];
                    if (index == 0) {
                        $("#div_contact_hours_dayofweek").append("<dt><strong>" + curDay + ":</strong></dt>");
                        $("#div_contact_hours_dayofweek").append("<dd><strong>" + NormalHour + "</strong></dd>");
                    }
                    else {
                        $("#div_contact_hours_dayofweek").append("<dt>" + curDay + ":</dt>");
                        $("#div_contact_hours_dayofweek").append("<dd>" + NormalHour + "</dd>");
                    }
                });
             }
             try {
                    // FOR License # (if applies - needed for Legal) -CES Source
                    if (typeof (data.stateLicenseNumbers) !== 'undefined') {
                        var licenseNumbers = "";
                        $.each(data.stateLicenseNumbers, function (index: any, hour: any) {
                             if (hour !== null && $.trim(hour) !== "") {
                                if (licenseNumbers != "") {
                                    licenseNumbers = licenseNumbers + ", ";
                                }
                                licenseNumbers = licenseNumbers + hour;
                            }
                        });
                        if (licenseNumbers !== "" && licenseNumbers !== "N/A") {
                            licenseNumbers = licenseNumbers ? licenseNumbers : "";
                            $("#div_contact_hours_licensenumbers").text("License: #" + licenseNumbers);
                        }
                    }
                }
                catch (e) { }    
            }
            
        }
    } catch (e) { }
}

function setSuffix(curEndHour:number){
    var outStr;
    if(curEndHour < 12){
        outStr = ' AM';
    }
    else if(curEndHour >=12 && curEndHour <24){
        outStr = ' PM';
    }
    else if(curEndHour == 24){
        outStr = ' AM (EOD)';
    }
    return outStr;
}