import { $ } from "../third-party-imports";
import { apiConfig } from "../config/apiConfig";
import {chunkArray} from '../util/chunkArray';
export function brandWeServe() {
    $.ajax({
        type: "GET",
        url: apiConfig.BrandWeServe,
        contentType: "application/json",
        dataType: 'json',
        success: function (result) {
        let splitCount:any;
        if (result.length < 4) splitCount = 1;
        else splitCount = Math.round(result.length / 4);
        result = chunkArray(result, splitCount);
        var colmd4Elements = $.map(result, function (el)
        {
        var sub_ul = $('<ul class="check-mark-bullet-list">');
        $.each(el, function (index, service)
        {
        let liEl = window.document.createElement("li");
        liEl.setAttribute('class','media');
        liEl.id = `${service.makeId}`;

        if ($(".brands-trademark-img").length) {
            let src:any = $(".brands-trademark-img").val();
            let imgEl = window.document.createElement("img");
            imgEl.setAttribute('class', 'img-tick');
            imgEl.setAttribute('src', src);
            imgEl.setAttribute('width', '17');
            imgEl.setAttribute('height', '15');
            liEl.append(imgEl);
        }
        liEl.append(`${service.makeName}`);    
        var sub_li = $(liEl);
        sub_ul.append(sub_li);
        });
        var colmd4 = $('<div class="col-md-3 col-sm-6"></div>').append(sub_ul);
        return colmd4;
        })
        $(".brands-services-list").append(colmd4Elements);
        },
        error: function (xhr, status, error) {
        //console.log(error)
        }
        });
    }
