import { detectMob } from "../util/detect-mob"

export function mrhrootMenuList(root:any){
    let mob = detectMob();
    let linkTarget = root.openNewTab ? "target='_blank'" : "";
    if(!mob) {
    return `<li class="dropdown menu-large nav-item js-dropdown sub-menu-open">
        <a role="button" class="nav-link dropdown-toggle arrow-right-link" href=${root.link} ${linkTarget} id="menu${root.id}" data-title=${root.name}>${root.name}</a>
        </li>`

 } else {
    return `<li class="dropdown menu-large nav-item js-dropdown">
        <a role="button" class="nav-link dropdown-toggle arrow-right-link" href="javascript:void(0)" id="menu${root.id}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-title=${root.name}>${root.name}</a>
        </li>`
    }
}
