export function redirectZipCode(postal: string){
    var isValid = true;
    let domainName = location.hostname.toLowerCase();
    var brands = window.objOSGlobal.requestBodyValues.Country == 'CA' ? "/ca/en-ca/_assets/brands.json" : "/us/en-us/_assets/brands.json";
        
        // $.getJSON(brands, function (result:any) { 
        //     if (result.length > 0) {
        //         var items = $.grep(result, function (e:any) { return e.code === $('#conceptCode').val() });
                let items = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj;
                if(items.length) {
                    if(!items[0].multiple_domain){
                        return isValid;
                    }	
                    else{
                        let isWebSite:any = $('#countryCode').val();
        var destination = domainName;
        if (isWebSite == 'ca'){
            var zipTest =  /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(postal);
            if(!zipTest){
                destination = location.hostname.indexOf("dig-www-nei") == -1  ? domainName.replace(".ca", ".com") : (location.hostname.indexOf("nblyprod") !=-1 ? domainName.replace("-ca.", "."): domainName.replace("-ca-", "-"));
                sessionStorage.setItem('redirectZip',postal);
                isValid = false;
                window.location.href = "https://" + destination;
            }
        }
        if(isWebSite == 'us'){
            var zipTest = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(postal);
            var lastIndex = location.hostname.split('-').length;
            var lang  = location.hostname.split('-')[lastIndex-1];
            var zipTest = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(postal);
            if(!zipTest){
                var destination = location.hostname.indexOf("dig-www-nei") == -1 ? location.hostname.replace(".com", ".ca") : (location.hostname.indexOf("nblyprod") !=-1 ? location.hostname.replace('.nblyprod','-ca.nblyprod') : location.hostname.replace(lang, "ca-"+lang));
                sessionStorage.setItem('redirectZip',postal);
                isValid = false;
                window.location.href = "https://" + destination;
            }
        }
                    }
                }
        //     }
        // });
        // var isWebSite = domainName.indexOf("-ca-") === -1 && domainName.indexOf(".ca") === -1 && domainName.indexOf("-ca.") == -1 ? "us" : "ca";
        
     return isValid;
}