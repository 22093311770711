import { $ } from "../third-party-imports";
import {id} from '../scheduler/scheduler';
export function setPersonalInfo(profileObj:any){
    let phoneNumber = profileObj.phone[0].contactDetail.toString().match(/(\d{3})(\d{3})(\d{4})/);
    $(".contact-information-screen #inputFirstName").val(profileObj.firstName);
    $(".contact-information-screen #inputLastName").val(profileObj.lastName);
    $(".contact-information-screen #inputEmail").val(profileObj.email);
    if (phoneNumber)
    {
        phoneNumber = "(" + phoneNumber[1] + ") " + phoneNumber[2] + "-" + phoneNumber[3];
    }
    if (profileObj.phone){
        $(".contact-information-screen #inputPhnNumber").val(phoneNumber);
    }
    $(".contact-information-screen .next").removeClass("disble-btn").prop("disabled", false);
    clearInterval(id);
}
