// import { createId } from "@nbly/crownpeak";
import { makeid } from ".";
import { nestLocalStorage } from '../../config/NestLocalStorage';


export function getTransactionID()
{
    if(nestLocalStorage){    
        var decodeData = nestLocalStorage.decodedAccessToken;
        var userStatus = decodeData.tid ? decodeData.tid : "";
        var uniqueId = makeid(5);
        if (userStatus)
        {
            return decodeData.tid;
        }
        var timeStamp = Math.round(new Date().getTime() / 1000) + 120;
        return uniqueId + '-' + timeStamp;
    }
    else{
        return sessionStorage.getItem('corelationID');
    }
}
