import { bookingFailureHandlerData } from "../scheduler/booking-api-failure-handler";
import { bookingApiPayload } from "../scheduler/booking-api-payload";
import { bookingApiSuccessHandlerData } from "../scheduler/booking-api-success-handler";
import { brandJsonConfimationScreen } from "./brand-json-call";
import { bookingApiConfirmationScreen } from "../scheduler/booking-api-confirmation-screen";
import { payementInformationGet } from "../scheduler/plg-scheduler-flow";
import { $ } from "../third-party-imports";

export function confirmationClickEvent() {
    $('.confirmation-screen .next').on('click', async function (e: any) {
        if ($("#brandName").val() == "portland-glass") {
            if ($("#plg-payment-info").valid()) {
                    e.preventDefault();
                    payementInformationGet();
                    brandJsonConfimationScreen(function () {
                        let url = window.DEFINE_VALUE.bookingEndpoint + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
                        let payload = bookingApiPayload();
                        bookingApiConfirmationScreen(payload, url, function (requesData: any) {
                            bookingApiSuccessHandlerData(requesData);
                        }, function (status: any) {
                            bookingFailureHandlerData(status)
                        });
                    });
            }

        } else {
            e.preventDefault();
            payementInformationGet();
            brandJsonConfimationScreen(function () {
                let url = window.DEFINE_VALUE.bookingEndpoint + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
                let payload = bookingApiPayload();
                bookingApiConfirmationScreen(payload, url, function (requesData: any) {
                    bookingApiSuccessHandlerData(requesData);
                }, function (status: any) {
                    bookingFailureHandlerData(status)
                });
            });
        }
    });
}