//User Story 340416: [Opus 1.0] [CR] Testimonials Components Character Limitation Start
export function testimonialReadMore(){
    const testimonials = document.querySelectorAll('.testimonial-content-box');
    const maxCharacters = 140;

    testimonials.forEach(testimonial => {
        const testimonialDesc = testimonial.querySelector('q');
        const fullText = testimonialDesc.textContent.trim();
        if (fullText.length > maxCharacters) {
            const visibleText = fullText.slice(0, maxCharacters);
            const hiddenText = fullText.slice(maxCharacters);
            //add ... and span to hide the exceeding texts
            testimonialDesc.innerHTML = `
                <p class="testimonial-description"> ${visibleText}
                <span class="more-dots">...</span>
                <span class="more-text" style="display: none;"></span>
                </p>
            `
            const readMoreLink = testimonial.querySelector('.read-more');
            let moreText = testimonialDesc.querySelector('.more-text');
            readMoreLink?.classList.remove('d-none');
            moreText.textContent = hiddenText;
            const dots = testimonialDesc.querySelector('.more-dots');
            const authorInfo = testimonial.querySelector('.author-info');
            authorInfo.classList.add('d-none');
            readMoreLink?.addEventListener('click', function(e) {
                e.preventDefault();

                if (readMoreLink?.textContent === "Read more") {
                    moreText.style.display = "inline";
                    dots.style.display = "none";
                    readMoreLink.textContent = "Read less";
                    authorInfo.classList.remove('d-none');
                    
                } else {
                    moreText.style.display = "none";
                    dots.style.display = "contents";
                    readMoreLink.textContent = "Read more";
                    authorInfo.classList.add('d-none');
                }
            });
        }
    });
}
//User Story 340416: [Opus 1.0] [CR] Testimonials Components Character Limitation End
export function readMoreLess(){
    const maxCharacters = 140;
    var paragraphs = document.querySelectorAll(".sp-offers-disclaimer p");

        paragraphs.forEach(function(paragraph) {
            var fullText = paragraph.innerHTML;
            var visibleText = fullText.slice(0, maxCharacters);
            var hiddenText = fullText.slice(maxCharacters);
            if (fullText.length > maxCharacters) {
            paragraph.innerHTML = visibleText + '<span class="dots">...</span><span class="more-content" style="display:none;"> ' + hiddenText + '</span>';

            var button = document.createElement("button");
            button.className = "read-btn";
            button.innerHTML = " Read More";
            button.style.border = "none";
            button.style.background = "none";
            button.style.cursor = "pointer";
            button.style.padding = "0 0 0 2px";
            paragraph.appendChild(button); 

           
            button.addEventListener("click", function() {
                var dots = paragraph.querySelector(".dots");
                var moreContent = paragraph.querySelector(".more-content");

                if (moreContent.style.display === "none") {
                    moreContent.style.display = "inline";
                    dots.style.display = "none";
                    button.innerHTML = "Read Less";
                } else {
                    moreContent.style.display = "none";
                    dots.style.display = "inline";
                    button.innerHTML = "Read More";
                }
            });
        }
        });
}