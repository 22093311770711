// export function filterByValue(root:any){    
//     //let newRoot = root.filter((obj:any) => obj.category.some((item:any)=>item.visibility));
//     let newRoot = root.filter((obj:any) => obj.visibility || obj.category.some((item:any)=>item.visibility)  || obj.services.some((item:any)=>item.visibility)); 
//     newRoot.forEach((obj:any)=>{
//         obj.category.forEach((cat:any)=>{
//             cat.menu = cat.menu.filter((menu:any)=>menu.visibility);
//         })
//     });
//     return newRoot;
// }
export function filterByValue(root:any){    
    //let newRoot = root.filter((obj:any) => obj.category.some((item:any)=>item.visibility));
    let newRoot = root.filter((itm:any)=>itm.visibility);
    let filt = newRoot.map((element:any) => {
        if(element.category?.length == 0){
            if(element.services != null){
                return {...element, services: element?.services.filter((ser:any) => ser.visibility ===true)}
            }else{
                return {...element};
            }
        }else{
            return {...element, category: element?.category.filter((cat:any) => cat.visibility ===true)}
        }
    });
    // if(filt[0].services.length !== 0){
    //     return filt;
    // }
    filt.forEach((obj:any)=>{
        if(obj.category?.length == 0 && obj.services == null){
            return;
        }else{
            if(obj.services != null && obj.services.length !=0){
                return;
            }else{
                obj.category.forEach((cat:any)=>{
                    cat.menu = cat.menu?.filter((mn:any)=>mn.visibility);
                });
            }
        }
    });
    return filt;
}