import { $ } from "../third-party-imports";
import { bookingApiPayload } from "../scheduler/booking-api-payload";
import { bookingApiConfirmationScreen } from "../scheduler/booking-api-confirmation-screen";
import { getStateFun } from './getStateFun';
import { getFranchiseWeblocationID } from "./getFranchiseWeblocationID";
import { Api_Error_Msg } from "../config/constant";
import { irvinrewardFormConfirmationPage } from "./irvingRewards";

export function contactPortland()
{
    getStateFun();
    $('.contact-error-thankyou .js-next-btn').click(function(this:any) {
        plgcontactforminformation();
        $('.plg-contact-from').removeClass('d-none');
        $('.contact-error-thankyou').addClass('d-none');
    });
    $('#plgcontactform .submit-button').click(function (this: any)
    {
        window.objOSGlobal.requestBodyValues.plgcontactform = true;
        if ($('#plgcontactform').valid())
        {
            plgcontactforminformation();
            getFranchiseWeblocationID(window.objOSGlobal.requestBodyValues.PostalCode, function (result: any)
            {
                if (result.length > 0)
                {
                    let WebLocationId: any = result[0].franchiseWebLocationId;
                    window.objOSGlobal.requestBodyValues.franchiseId = result[0].franchiseId;
                    window.objOSGlobal.requestBodyValues.WebLocationId = WebLocationId;
                    window.objOSGlobal.requestBodyValues.licenseNumber = result[0].licenseNumber;
                    window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
                    window.objOSGlobal.requestBodyValues.IsEstimate = false;
                    let url = window.DEFINE_VALUE.bookingEndpoint + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
                    window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.requestBodyValues.contactComment !=="" ? window.objOSGlobal.requestBodyValues.Note + "Anything you want us to know:" + window.objOSGlobal.requestBodyValues.contactComment :"";
                    let payload = bookingApiPayload();
                    bookingApiConfirmationScreen(payload, url, function (requesData: any)
                    {
                        let domainName = window.location.origin.toLowerCase();
                        let trailingSlash = localStorage.getItem("isTrailingSlash") == "true" ? "/" : "";
                        let websiteurl = $('#weblocationId').length ? domainName + "/" + $('#dbaName').val() + "/" : domainName;
                        let addressObj = requesData.Address2 != "" ? requesData.Address + ' ' + requesData.Address2 : requesData.Address;
                        let fulladdress = addressObj + '<br/>' + requesData.City + ', ' + requesData.State + ' ' + requesData.PostalCode;
                        sessionStorage.setItem('plg-name', requesData.FirstName + ' ' + requesData.LastName);
                        sessionStorage.setItem('plg-email', requesData.Email);
                        sessionStorage.setItem('plg-phone', requesData.Phone);
                        sessionStorage.setItem('plg-address', fulladdress);
                        sessionStorage.setItem('comment', window.objOSGlobal.requestBodyValues.contactComment);
                        window.location.replace(websiteurl + "/" + "contact-thankyou" + trailingSlash);
                        irvinrewardFormConfirmationPage()
                        
                    }, function (status: any)
                    {
                        $('.plg-contact-from').addClass('d-none');
                        $('.contact-error-thankyou').removeClass('d-none');
                        $('.card-title').html(`${Api_Error_Msg}`);
                    });
                }
                else
                {
                    $('.plg-contact-from').addClass('d-none');
                    $('.contact-error-thankyou').removeClass('d-none');
                    $('.card-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.PostalCode}</span>`);
                }
            });
        }
    })
    $('.plg-contact-from #inputZip').keypress(function(this: any, e: any){
        if (this.value === "0000" && e.which == 48){
        return false;
        }
    });
}
export function plgcontactforminformation()
{
    let firsName = $("#plgcontactform input[name='inputFirstName']").val();
    let lastName = $("#plgcontactform input[name='inputLastName']").val();
    let email = $("#plgcontactform input[name='inputEmail']").val();
    let phoneNumber = $("#plgcontactform input[name='inputPhnNumber']").val();
    let address = $("#plgcontactform input[name='inputStAdress']").val();
    let apartment = $("#plgcontactform input[name='apartment']").val();
    let city = $("#plgcontactform input[name='inputCity']").val();
    let state = $("#plgcontactform select[name='inputState']").val();
    let zipcode = $("#plgcontactform input[name='inputZip']").val();
    let comment = $("#plgcontactform #comment").val()?.trim();
    window.objOSGlobal.requestBodyValues.FirstName = firsName;
    window.objOSGlobal.requestBodyValues.LastName = lastName;
    window.objOSGlobal.requestBodyValues.Email = email.trim();
    window.objOSGlobal.requestBodyValues.Phone = phoneNumber;
    window.objOSGlobal.requestBodyValues.Address = address;
    window.objOSGlobal.requestBodyValues.Address2 = apartment;
    window.objOSGlobal.requestBodyValues.City = city;
    window.objOSGlobal.requestBodyValues.State = state;
    window.objOSGlobal.requestBodyValues.PostalCode = zipcode;
    window.objOSGlobal.requestBodyValues.ServiceText = "Shower Glass";
    window.objOSGlobal.requestBodyValues.contactComment = comment;
   }