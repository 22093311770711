export function menuCol(type:any,serviceCat:any,length:any,i:any){
    if(i == 0){
        if(length == 1){
            return `<div class="menu-col">
            <button class="back-arrow-link js-backMenu" aria-label="Back to main menu">Back</button>
            <span class="overview-link"><a class="overview-heading-link" href="${type.link}">${type.name}</a></span>
                 ${serviceCat} 
            <div class="col-section js-dd-inner d-none"><a class="dropdown-item view-all-link arrow-right-link" href="${type.link}">View ${type.name}</a></div>
            </div>`
        }else{
            return `<div class="menu-col">
            <button class="back-arrow-link js-backMenu" aria-label="Back to main menu">Back</button>
            <span class="overview-link"><a class="overview-heading-link" href="${type.link}">${type.name}</a></span>
                ${serviceCat}
            </div>`
        }
    }else if(i+1==length){
        return `<div class="menu-col">
                ${serviceCat}
                <div class="col-section js-dd-inner d-none"><a class="dropdown-item view-all-link arrow-right-link" href="${type.link}">View ${type.name}</a></div>
        </div>`
    }else{
        return `<div class="menu-col">
                ${serviceCat}
                </div>`

        }
}

export function servicesMenuCol(type:any, service:any){
    return `<div class="menu-col">
                <button class="back-arrow-link js-backMenu" aria-label="Back to main menu">Back</button>
                <h2 class="overview-link"><a class="overview-heading-link" href="${type.link}">${type.name}</a></h2>                
                <div class="col-section js-dd-inner">
                <ul>
                    ${service}
                    <li class = "d-none"><a class="dropdown-item view-all-link arrow-right-link" href="${type.link}">View ${type.name}</a></li>
                </ul>
            </div>
        </div>`
}
