
import { $ } from "../../third-party-imports"
import { isMobile } from "../../util";

export function mobileSupport()
{
    if (isMobile())
    {
        $('header#site-header').remove();
        $('footer').remove();
        document.body.classList.add("is-mobile");
    }
    else 
    {
        document.body.classList.remove("is-mobile");
    }
}
