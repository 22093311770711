import { generateServiceHTMLForOtherService } from "./mrh-service-html";
export function mrhMenuGenerateList(menuItem:any, i:any,catServiceType:any,categoryList:any) {
    if(catServiceType !== "OtherService".toLowerCase()) {
        let linkTarget = menuItem.openNewTab ? "target='_blank'" : "";
        let menuid = categoryList.name.toLowerCase()+menuItem.name.toLowerCase();
        menuid = menuid.replace(/[^A-Z0-9]+/gi, "").toLowerCase();
        //$('.custom-menu-list-item-inner').empty();
        return `<div class="menu-col">
        <ul class="inner-menu-list col-list">
           <li class="inner-list-item" id= ${menuid}>
             ${menuItem.link !='' ? `<a class="dropdown-item" href="${menuItem.link}" ${linkTarget}>
             <strong>${menuItem.name} </strong></a>` :  `<strong>${menuItem.name} </strong>`}          
           </li>
           <ul class="custom-menu-list-item-inner" id="menu-${menuid}">
           </ul>
        </ul>
     </div>`
    }else {
        return `<div class="menu-col">
    <ul class="inner-menu-list col-list">
       <ul class="custom-menu-list-item-inner" id="menu-${catServiceType}">
        ${generateServiceHTMLForOtherService(menuItem)}
       </ul>
    </ul>
 </div>`
    }
}