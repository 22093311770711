export function mrhSubMenuRightcatgoryList(categoryList: any, root: any,i:any) {
    let dataId = categoryList.name.replace(/[^A-Z0-9]+/ig, "").toLowerCase();
    let imgLink = categoryList.icons.icon;
    // let hoverImg = categoryList.icons.hoverIcon;
    let imgAlt = categoryList.icons.iconAlt;
    let linkTarget = categoryList.openNewTab ? "target='_blank'" : "";
    return `<div class="sub-menu-right innercat-right-submenu" data-id="${dataId}">
    <h5>
       <a href="${categoryList.link}" ${linkTarget}>${categoryList.sectionName}</a>
    </h5>
    <div class="inner-menu-row">
       <div class="menu-col">
          <ul class="inner-menu-list col-list">
             <li class="list-item-img">
                <button class="js-mega-menu-btn arrow-right-link" data-target="menu${root.id}">
                <img src="${imgLink}" alt="${imgAlt}" class="img-hover" fetchpriority="low" loading="lazy">
                </button>
             </li>
          </ul>
       </div>
    </div>
 </div>`
}