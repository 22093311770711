import {apiCall, changeZipOnlineScheduleFlow, checkZipLocalSite, getStateFun, mrhs3upload, refreshScheduleData, startLoader } from "..";
import { LeadConfirmation } from "../services/LeadConfirmation";
import { $ } from "../third-party-imports";
import { htmlInjectionField } from "../util/html-injection-field";
import { preferredMethodOfContact, validTextMethodChk } from "./common-os/os-flow-optimize";
// import { showOfferAttachedText } from "../util";
import { checkSchedulerProIntegration } from "../util/get-service-list";

export async function  mrh_OptOsFlow(nextId:any) {
    if(sessionStorage.getItem("isAddressbumper") === 'true') {
    $('.result-stack-logo').css('display','block');
    }
    if(window.objOSGlobal.requestBodyValues.IsLeadOnly){
    $('[href="#mrhstep3"]').closest('li').hide();
    $('.progressbar li').css('width', 'calc(100%/2)');
    if( window.innerWidth <=414 ){
        $('.scheduler-slide .progress').css( "maxWidth", '180px');
    }else {
        $('.scheduler-slide .progress').css( "maxWidth", '333px');
    }
    $('[href="#mrhstep1"]').closest('li').addClass('mstep1');
    $('[href="#mrhstep2"]').closest('li').addClass('mstep2');
    }else {
    $('[href="#mrhstep3"]').closest('li').show();
    $('.progressbar li').css('width', 'calc(100%/3)');
    if(window.innerWidth >=375 && window.innerWidth <=390 ){
        $('.scheduler-slide .progress').css( "maxWidth", '225px');
    } else if(window.innerWidth == 412) {
        $('.scheduler-slide .progress').css( "maxWidth", '240px');
    }else {
        $('.scheduler-slide .progress').css( "maxWidth", '436px');
    }
    $('[href="#mrhstep1"]').closest('li').removeClass('mstep1');
    $('[href="#mrhstep2"]').closest('li').removeClass('mstep2');
    }
    $(".tab-pane.fieldset").removeClass("active");
    $('[href="#' + nextId + '"]').tab('show');
    $('#'+nextId).addClass('active');
    $("#Resd").attr("checked", 'true').trigger("click");
    $('.modal-header-two').css('display','flex')
    $('.progress, .progressbar').show();
    $(".form-progressbar ul").hide();
    $('.schedulerModalLabel').addClass('d-none');
    $('.schedulerModalLabel').empty();
    $('.schedulerModalLabel').removeClass('d-none');
    $('.schedulerModalLabel').html(window.objOSGlobal.paramsUI.doingBusinessAs);
    $("#mrhstep1 .form-progressbar").find('h5').hide();
    makeActive(nextId);
    if(nextId === "mrhstep1"){
        activaTab(nextId);
        $("#service_contact_information #mrhZipCode").val(window.objOSGlobal.requestBodyValues.PostalCode);
        !($('#mrhState > option').length > 2) && await getStateFun();
        htmlInjectionField();
        mrh_formatPhoneNumber();
        mrh_ServiceInfoDetails();
    }
    $("#inputHearAbout").off('change').on('change', function (this:any){
        window.objOSGlobal.paramsUI.serviceDetailsSelectedText = "";
        var selectedItem = $(this).children("option:selected").val();
        window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = selectedItem;
        window.objOSGlobal.paramsUI.serviceDetailsSelectedText = "Lead Source: " + $(this).children("option:selected").text();
    }); 
}
$('#mrhServiceAddress input:radio[name=customerType]').change(function(this:any) {
    if (this.id == 'Resd') {
    $("#mrhServiceAddress .comm-type").addClass('d-none');
    $("#inputBusiness").val('');
    }else if(this.id == 'Comm') {
    $("#mrhServiceAddress .comm-type").removeClass('d-none');
    commResFormValidation();
    }
});
function commResFormValidation()
{
    $.validator.setDefaults({
        errorElement: "span",
        errorPlacement: function (error: any, element: any)
        {
            error.addClass("error-msg help-block invalid-feedback");
            error.insertAfter(element);
        },
        highlight: function (element: any, errorClass: any, validClass: any)
        {
            $(element)
                .parents(".form-group")
                .addClass("has-error")
                .removeClass("has-success");
        },
        unhighlight: function (element: any, errorClass: any, validClass: any)
        {
            $(element)
                .parents(".form-group")
                .addClass("has-success")
                .removeClass("has-error");
        },
    });

    ($("#mrhServiceAddress")as any).validate({
        rules: {
            inputBusiness: {
                required: true
            },
        }
    });
}

export function mrh_modalHeaderOneOrTwo() {
    $('.modal-header-one').css('display','none');
    $('.modal-header-two').css('display','flex');
    $('.modal-header-two .call-details-text').removeClass('d-none');
    $('.zip-entry-header').removeClass('d-inline-flex');
    $('.zip-entry-header').addClass('d-none');
    set_HeaderPhoneNumber();
}

 function set_HeaderPhoneNumber(){
    let phoneNumber:any = $($(".anchor_desktop_phone_number").clone()[1]).children().remove().end().text().trim();
    $('.call-details-text .phnNumber').empty();
    $('.call-details-text').removeClass('d-none');
    if(!$('.modal-header-two .phnNumber').hasClass('phnNumber')){
        $('.modal-header-two .call-details-text a').append("<span class='phnNumber'>"+phoneNumber+"</span>");
    }
    $('.call-details-text a').attr('href',"tel:"+phoneNumber); 
}
export function mrh_formatPhoneNumber() {
    var inputs = document.querySelectorAll("#service_contact_information input[name='mrhPhoneNumber']");
    if (inputs)
    {
        for (var i = 0; i < inputs.length; i++)
        {
            var element = inputs[i];
            element.addEventListener('input', function (e:any)
            {
                var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            });
        }
    }
}

 export function mrh_Form_Information() {
    let firstnameobj:any = $('input[name="mrhFirstName"]').val();
    let lastnameobj:any = $('input[name="mrhLastName"]').val();
    let emailobj:any = $('input[name="mrhEmail"]').val();
    let mobileNumberobj:any = $('input[name="mrhPhoneNumber"]').val();
    window.objOSGlobal.requestBodyValues.FirstName = firstnameobj;
    window.objOSGlobal.requestBodyValues.LastName = lastnameobj;
    window.objOSGlobal.requestBodyValues.Email = emailobj;
    window.objOSGlobal.requestBodyValues.Phone = mobileNumberobj;
}
export function mrh_AddressInfoValOpus(){
    var address = $('input[name="mrhAddress"]').val();
    var city = $('input[name="mrhCity"]').val();
    var state = $('select[name="mrhState"]').val();
    var postalCode = $('input[name="mrhZipCode"]').val();
    var addressParamsVal:any = address + "&" + city + "&" + state + "&" + window.objOSGlobal.requestBodyValues.PostalCode;
    window.objOSGlobal.requestBodyValues.Address = address + ", " + $('input[name="mrhAddress2"]').val();
    window.objOSGlobal.requestBodyValues.City = city;
    window.objOSGlobal.requestBodyValues.State = state;
    window.objOSGlobal.requestBodyValues.PostalCode = postalCode;
    return addressParamsVal;
}

export function mrh_ServiceInfoDetails() {
let project_details:any = $('textarea[name="inputHelp"]').val();
let business_name:any= $('input[name="inputBusiness"]').val();
let refrenceDetails:any = $('select[name="howDidHereAboutUs"]').val();
window.objOSGlobal.requestBodyValues.ProjectDetails = project_details
window.objOSGlobal.requestBodyValues.BusinessName = business_name;
window.objOSGlobal.requestBodyValues.LeadSourceId = refrenceDetails;
}

if ($("#brandName").val() === 'mr-handyman' || $("#brandName").val() === "my-handyman")
{
    $(".js-next-btn").click(async function (this: any)
    {
        
            if (!$('.zip-entry-screen').hasClass('active'))
            {
                
                var addresses= JSON.parse(localStorage.getItem("addressMly"));
                if(addresses){
                    setAddressFormValueInAddressScreen(addresses);
                }
                document.getElementById("js-schedulerDialog").scroll(0, 0);
                let nextId = $(this).parents(".tab-pane").next().attr("id");
                if (nextId === "mrhstep2")
                {
                    preferredMethodOfContact();
                    if (!$("#service_contact_information").valid() || !validTextMethodChk()) {
                        return false;
                    }
                    var changeZip = sessionStorage.getItem("changeZip");
                    if (changeZip)
                    {
                        checkZipLocalSite(nextId);
                        return;
                    }
                    
                    $("#" + nextId + ' .form-progressbar').find('h5').hide();
                    if (window.objOSGlobal.requestBodyValues.IsLeadOnly)
                    {
                        $('#mrhstep2 .js-next-btn').text("Submit");
                        $('#mrhstep2 .js-next-btn').append(`<img src="/us/en-us/brand/_assets/images/right-arrow-white.svg" alt="next" width="18" height="13">`)
                    } else
                    {
                        if ($('#mrhstep2 .js-next-btn').text() === 'Submit')
                        {
                            $('#mrhstep2 .js-next-btn').text("Next");
                            $('#mrhstep2 .js-next-btn').append(`<img src="/us/en-us/brand/_assets/images/right-arrow-white.svg" alt="next" width="18" height="13">`);
                        }
                    }
                    $('[href="#' + nextId + '"]').tab('show');
                    $('#' + nextId).addClass('active');
                    $('.progress, .progressbar').show();
                    $(".form-progressbar ul").hide();
                    window.objOSGlobal.requestBodyValues.SignUpForUpdates = $("#service_contact_information input[name='customerType']").prop('checked');
                    makeActive(nextId);
                    mrh_Form_Information();
                    mrh_AddressInfoValOpus();
                    // showOfferAttachedText();
                    checkSchedulerProIntegration('mrhServiceAddress');
                } else if (nextId === "mrhstep3")
                {
                    window.objOSGlobal.requestBodyValues.BusinessName = $("#inputBusiness").val();
                    if ($("#Resd").prop('checked'))
                    {
                        window.objOSGlobal.requestBodyValues.MrhServiceType = "Residential";
                    } else
                    {
                        if (!$("#mrhServiceAddress").valid()) {
                            return false;
                        }
                        window.objOSGlobal.requestBodyValues.MrhServiceType = "Commercial";
                    }
                    
                    if (!window.objOSGlobal.requestBodyValues.IsLeadOnly)
                    {
                        $("#" + nextId + ' .form-progressbar').find('h5').hide();
                        $('#mrhstep3 .js-next-btn').addClass("disble-btn");
                        $('#mrhstep3 .js-next-btn').prop("disabled", true);
                        makeActive(nextId);
                        startLoader();
                        await refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
                        if(!apiCall.error){
                            $(".tab-pane.fieldset").removeClass("active");
                            $('[href="#' + nextId + '"]').tab('show');
                            $('#' + nextId).addClass('active');
                        }
                    } else
                    {
                        if ($("#file1").length > 0) {
                            mrhs3upload(function () {
                                LeadConfirmation();
                            });
                        } else {
                            LeadConfirmation();
                        }
                    }
                }else{
                    if(!$('.address-bumper-screen').hasClass('active')) {
                        if ($("#file1").length > 0 ){
                            mrhs3upload(function (){
                                LeadConfirmation();
                            })
                        }else{
                            LeadConfirmation();
                        }
                    }
                }
            }
    })
}

if($("#brandName").val() === 'mr-handyman' || $("#brandName").val() === "my-handyman") {
    $(".js-previous-btn").click(function(this:any) {
        let brandJsonItems = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj;
        let checkCroleadEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match || false ;
        let prevId = $(this).parents(".tab-pane").attr("id");
        if(prevId === "mrhstep1") {
            if(sessionStorage.getItem("isAddressbumper") === 'true') {
            $(".tab-pane.fieldset").removeClass("active");
            $('.scheduler-modal .modal-content').addClass('banner-wizard');
            $('.address-bumper-screen').addClass('active');
            $(".address-bumper-screen .back").show();
            $('.progress, .progressbar').hide();
            }else {
                $('.schedulerModalLabel').addClass('d-none');
                $('.schedulerModalLabel').empty();
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
                $('.tab-pane.fieldset').removeClass('active');
                $('.modal-header-two .schedulerModalLabel').addClass('d-none');
                $('.progress, .progressbar').hide();
                if(checkCroleadEnable){
                    if(!window.objOSGlobal.requestBodyValues.fsaLead){
                        $('#step-location').addClass('active');
                    }else{
                        $('.zip-entry-screen').addClass('active');
                        $('.modal-header-two .schedulerModalLabel').addClass('d-none');
                    }
                }else{
                    $('.zip-entry-screen').addClass('active');
                    $('.modal-header-two .schedulerModalLabel').addClass('d-none');
                }
            }
        }else if(prevId === "mrhstep2"){
            let prevId = $(this).parents(".tab-pane").prev().attr("id");
            $(".tab-pane.fieldset").removeClass("active");
            $('[href="#' + prevId + '"]').tab('show');
            $('#'+prevId).addClass('active');
            makeActive(prevId);
        }else if(prevId === "mrhstep3") {
            let prevId = $(this).parents(".tab-pane").prev().attr("id");
            $(".tab-pane.fieldset").removeClass("active");
            $('[href="#' + prevId + '"]').tab('show');
            $('#'+prevId).addClass('active');
            $('.selected-date-txt').text('');
            //defaultEnableCal.enable = true;
            makeActive(prevId);
        }
    })
}

$('a[data-toggle="tab"]').on("shown.bs.tab", function(e:any) {
    //update progress
    let isLead = window.objOSGlobal.requestBodyValues.IsLeadOnly;
    let checker:any = false;
    if(isLead){    
       checker=true;    
    }
    let step = $(e.target).data("step");
    let percent:any = ((parseInt(step) / 3) * 100).toFixed(2);
    switch (step) {
        case 1:
            percent = isLead ? 5.2 : 4;
            $(".progress-bar").css({ width: percent + "%" });
            break;
        case 2:
            percent = isLead ? 5.2 : 4;
            $(".progress-bar").css({ width: percent + "%" });
            break;
        case 3:
            percent = isLead ? 100 : 53;
            $(".progress-bar").css({ width: percent + "%" });
            break;
        case 4:
             percent =  100;
             $(".progress-bar").css({ width: percent + "%" });
             break;    
        case 5:
             percent = 100;
             $(".progress-bar").css({ width: percent + "%" });
             break;     
        case 7:
            percent = 100;
            $(".progress-bar").css({ width: percent + "%" });
            $(".progress").addClass("d-none");
            $(".progressbar").addClass("d-none");
            $(".modal-header-two").addClass("d-none");
            $(".modal-header-one").addClass("d-none");
            break;
    }
});

function activaTab(tab:any){
    $('[href="#' + tab + '"]').tab("show");
};


export function makeActive(tabId:any) {
    $('.progressbar li').removeClass('active')
    $('.progressbar li').addClass('disabled')
    $('a[href="#' + tabId + '"]').parent().removeClass('disabled');
    $('a[href="#' + tabId + '"]').parent().addClass('active');
}

  
function setAddressFormValueInAddressScreen(addressObj:any){
    if(addressObj){
         $("#service_contact_information #mrhAddress").val(addressObj.address);
         $("#service_contact_information #mrhAddress2").val(addressObj.apartment);
         $("#service_contact_information #mrhCity").val(addressObj.city);
         $('#service_contact_information option[value='+addressObj.state+']').attr('selected','selected');
         $("#service_contact_information #mrhZipCode").val(addressObj.zipCode);
    }
}
$("#service_contact_information #mrhZipCode").keyup(function (this: any){
    var value: any = $(this).val();
    changeZipOnlineScheduleFlow(value);
});