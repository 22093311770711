import { $ } from "../third-party-imports";
import { startLoader } from "../util/startLoader";
import { stopLoader } from "../util/stopLoader";
import { setText } from "../util/setText";
import { apiConfig } from "../config/apiConfig";
import { Api_Error_Msg } from "../config/constant";
let timeoutId:any;
export function serviceSearchTermApi(search_term:any,serviceType:any,webLocationId:any,searchServiceFlag:any){
    let postalCode = window.objOSGlobal.requestBodyValues.PostalCode;
    if($("#countryCode").val().toLowerCase()==="ca" ){
        postalCode = formatPostalCode(postalCode)
    }
    let brandJsonItems = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj;
    let checkCroleadEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match || false ;
    if(checkCroleadEnable && window.objOSGlobal.requestBodyValues?.servicePostalCode){
        postalCode = window.objOSGlobal.requestBodyValues.servicePostalCode;
    }
    if(searchServiceFlag) {
        startLoader();
    }
    $(".servicesListDropdown").empty();
    if (timeoutId) {
        clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;
        fetch(`${apiConfig.FranchiseWebLocations+webLocationId}/service-search-term-aggregates/search?apikey=${window.DEFINE_VALUE.JS_API_KEY}&search_term=${search_term}&service_modifier=${serviceType}&postal_code=${postalCode}&skip=0&take=10`, { signal })
          .then(response => response.json())
          .then(data => {
            if(searchServiceFlag) {
                stopLoader();
            }
            let response = sortResults(data, "rank", true);
            var len = response.length;
            $(".servicesListDropdown").empty();
            for( var i = 0; i<len; i++){
                var serviceSkuId = response[i]['serviceSkuId'];
                var name = response[i]['name'];
                var serviceId = response[i]['serviceId'];
                $(".servicesListDropdown").append("<li id ='"+serviceId+"'  value='"+serviceSkuId+"'><a href='#'>"+name+"</a></li>");
            }
            
            let checkModifier = window.location.href.includes('commercial');
            if(location.hostname.indexOf("nblytest") !=-1 || location.hostname.indexOf("nblydev")!=-1){
                if(checkModifier){
                    $(".servicesListDropdown").append("<li id ='1' value='7988'><a href='#'>Other Appliance Services</a></li>");
                }else{
                    $(".servicesListDropdown").append("<li id ='2' value='7989'><a href='#'>Other Appliance Services</a></li>");
                }
            }else{
                if(checkModifier){
                    $(".servicesListDropdown").append("<li id ='1' value='7988'><a href='#'>Other Appliance Services</a></li>");
                }else{
                    $(".servicesListDropdown").append("<li id ='2' value='7989'><a href='#'>Other Appliance Services</a></li>");
                }
            }
            $(".servicesListDropdown li a").bind("click",function(this:any,e:any,){
                e.preventDefault();
                setText(this);
            });
            $('.js-servicesListDropdown').show();
          })
          .catch(error => {
            if (error.name === 'AbortError') {
                let errval = "";
                errorModal(errval)
              return;
            }
          });
      }, 500);
}
function formatPostalCode(postalCode:any) {
    // Convert postalCode to a string to handle numeric input
    postalCode = postalCode.toString();
    // Check if the length is greater than 3 and if the 4th character is not a space
    if (postalCode?.length > 3 && postalCode[3] !== ' ') {
      // Insert space after the first 3 characters
      return `${postalCode.slice(0, 3)} ${postalCode.slice(3)}`;
    }
  
    // Return the postalCode unchanged if the space is already there
    return postalCode;
  }
function errorModal(value: any){
    $('#js-schedulerDialog').modal('show');
    $(".tab-pane.fieldset").removeClass('active');
    $(".no-service-screen").addClass('active');
    if(!value){
        $(".no-service-screen .head-title").text(Api_Error_Msg);
    }
    else {
        $(".no-service-screen .head-title").text(value);
    }
}

function sortResults(result: any, prop: any, asc: any){
    result.sort(function (a: any, b: any){
        if (asc)
        {
            return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
        }
        else
        {
            return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
        }
    });
    return result;
}