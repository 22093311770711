import { filterBlogFromList } from "./filterBlogFromList";
import { $ } from "../third-party-imports"

export function updateFooterLinks(allBlogs: any)
{
    var allBlogs = allBlogs;
    var currentBlogIndex: number = null;
    $.each(allBlogs, function (index: any, blog: any)
    {
        if (filterBlogFromList(blog) === true)
        {
            currentBlogIndex = index as number;
        }
    });
    
    if (currentBlogIndex === null)
    {
        $($('.article-pagination .prev-arrow')[0]).css('pointer-events', 'none');
        $($('.article-pagination .prev-arrow')[0]).addClass('disable-back-link');
        $($('.article-pagination .next-article-link .link-text')[0]).css('pointer-events', 'none');
        $($('.article-pagination .next-article-link .link-text')[0]).addClass('disable-next-link');
        return;
    }     
    else if (currentBlogIndex < 1 && currentBlogIndex < (allBlogs.length - 1))
    {
        var nextBlogURL = allBlogs[currentBlogIndex + 1].custom_s_local_url;
        var nextValid = nextBlogURL.includes(window.location.origin);
        if(!nextValid){
            nextBlogURL = window.location.origin + prevBlogURL;
        }
        $($('.article-pagination .prev-arrow')[0]).css('pointer-events', 'none');
        $($('.article-pagination .prev-arrow')[0]).addClass('disable-back-link');
        $($('.article-pagination .next-article-link .link-text')[0]).attr("href", `${nextBlogURL}`);
        $($('.article-pagination .next-article-link .link-text')[0]).addClass('enable-next-link');
    } 
    else if (currentBlogIndex >= 1 && currentBlogIndex < (allBlogs.length - 1))
    {       
        var prevBlogURL = allBlogs[currentBlogIndex - 1].custom_s_local_url;
        var prevValid = prevBlogURL.includes(window.location.origin);
        if(!prevValid){
            prevBlogURL = window.location.origin + prevBlogURL;
        }
        var nextBlogURL = allBlogs[currentBlogIndex + 1].custom_s_local_url;
        var nextValid = nextBlogURL.includes(window.location.origin);
        if(!nextValid){
            nextBlogURL = window.location.origin + nextBlogURL;
        }
        $($('.article-pagination .prev-arrow')[0]).attr("href", `${prevBlogURL}`);
        $($('.article-pagination .prev-arrow')[0]).addClass('enable-back-link');
        $($('.article-pagination .next-article-link .link-text')[0]).attr("href", `${nextBlogURL}`);
        $($('.article-pagination .next-article-link .link-text')[0]).addClass('enable-next-link');
    } 
    else if (currentBlogIndex >= 1 && currentBlogIndex >= (allBlogs.length - 1))
    {
        var prevBlogURL = allBlogs[currentBlogIndex - 1].custom_s_local_url;
        var prevValid = prevBlogURL.includes(window.location.origin);
        if(!prevValid){
            prevBlogURL = window.location.origin + prevBlogURL;
        }
        $($('.article-pagination .prev-arrow')[0]).attr("href", `${prevBlogURL}`);
        $($('.article-pagination .prev-arrow')[0]).addClass('enable-back-link');
        $($('.article-pagination .next-article-link .link-text')[0]).css('pointer-events', 'none');
        $($('.article-pagination .next-article-link .link-text')[0]).addClass('disable-next-link');
    }
} 