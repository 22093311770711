export function getTitleDscForExpTips (){
    let brandName,category,countryCode;
    brandName =   $("#brandName").val();
    countryCode = $("#countryCode").val();
    category =  `/${countryCode}/en-${countryCode}/${brandName}/_assets/data/expert-tips.json`;
    $.getJSON(category, function (result:any) { 
         if (result.length > 0) {
            let domainName = window.location.href.toLowerCase();
            let splitDomainArray = domainName.split('/');
            let indexofCat = splitDomainArray.indexOf('category') + 1;
            let match = splitDomainArray[indexofCat].replace(/[^A-Z0-9]/ig, " ").replace(/\s/g, "").toLowerCase();
            // if(match) {
            //     splitDomainArray[indexofCat] = splitDomainArray[indexofCat].substr(0,match.index);
            // }
              var items = $.grep(result, function (e:any) { return e.name.replace(/[^A-Z0-9]/ig, " ").replace(/\s/g, "").toLowerCase() === match });
              if(items.length) {
                $('.text-container h1').text(items[0].name);
                $('.text-container p').html(items[0].long_description);		
            }
         }
    });
}