import { apiConfig } from '../config';
import type { ElavonClient } from "@nbly/elavon";

let client: ElavonClient;

export async function getElavonClient() 
{
    const { ElavonClient } = await import("@nbly/elavon");
    if (!client)
    {
        client = new ElavonClient({ 
            paymentUrl: apiConfig.paymentPostUrl,
            appkey: apiConfig.merchantKey            
        }, {}, globalThis.document);
    }
    return client;
}