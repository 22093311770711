import { $ } from "../third-party-imports";
let filesToUpload:any = [];
export function file_upload(){
    $("#files1").fileUploader(filesToUpload, "files1");
}
// new changes
export const fileUploadState = {
    isUploadedToS3: false
};

const generatePresignedURL = function (filename: any){
    
    var apiDomain = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1 || window.location.hostname.indexOf("nblytest") != -1) ? window.DEFINE_VALUE.JS_API_URL : "https://api.neighborly.com";
    var apiKey = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1 || window.location.hostname.indexOf("nblytest") != -1) ? window.DEFINE_VALUE.JS_API_KEY : "pneiweb-9pbaOywNKQj853D";
    var digitalorchestrations = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1 || window.location.hostname.indexOf("nblytest") != -1) ? window.DEFINE_VALUE.JS_DigitalOrchestrations_URL : "digitalorchestrations-api";

    return new Promise((resolve,reject)=>{
        $.ajax({
            url:`${apiDomain}/${digitalorchestrations}/v1/public/OnlineSchedulerFileUpload/getpresignedurl?filename=${filename}&apikey=${apiKey}`,
            dataType: 'json',
            type: 'GET',
            success:function(data, status) {
                resolve(data.presignedURL);
            },
            error: function(error) {
                reject(error);
            }
        });
    })
        
}


function CreateGuid(){
    function _p8(s: any)
    {
        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
    }
    return _p8(false) + _p8(true) + _p8(true) + _p8(false);
}

function makeS3Request(presignedURL:any, file:any) {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      let reader = new FileReader();
      xhr.open("PUT", presignedURL);
      reader.onload = function(evt) {
           xhr.send(evt.target.result);
      };
      reader.readAsArrayBuffer(file);
    });
  }

export function s3upload(successHandler:any){
    window.objOSGlobal.requestBodyValues.FileUrls = [];
    if (!fileUploadState.isUploadedToS3){
        let imagesUploaded:any = filesToUpload;
        let promiseAllArray:any=[];
        if (imagesUploaded){
                for (let i = 0; i < (imagesUploaded.length); i++){
                    const guid =  CreateGuid();
                    // let file = (imagesUploaded[i] as any).files[0];
                    let {file} = imagesUploaded[i];
                    let fileName = guid + '.' + file.name.split('.').pop();
                    window.objOSGlobal.requestBodyValues.FileUrls.push(fileName);
                    
                    let presignedUrls = generatePresignedURL(fileName).then(
                        function(presignedurl:any){
                        if (presignedurl != null){
                            let blob = file.slice(0, file.size, file.type);
                            let newFile = new File([blob], fileName, {type: file.type});
                            return makeS3Request(presignedurl,newFile);
                        }
                    }).catch((error)=>{
                        console.log(error)
                    })
                    promiseAllArray.push(presignedUrls);
                }
                Promise.all(promiseAllArray).then(()=>{
                    console.log("File upload Successfull");
                    successHandler();
                },()=>{
                    console.log("File upload Error");
                });
        }
    }
}
// validation for file upload
$.fn.fileUploader = function (filesToUpload:any, sectionIdentifier:any) {
    var fileIdCounter = 0;
    var re = /(\.jpg|\.JPG|\.jpeg|\.bmp|\.gif|\.png)$/i;
    this.closest(".files").change(function (evt:any) {
        var output = [];
        var listLegth = $('.fileList li').length;
        var fileLenght = evt.target.files.length;
        if ((fileLenght + listLegth) >2){
            $(".file-chooser__input").prop('disabled', true);
        }
        if(listLegth == 3){
           return;
        }
        for (var i = 0; i < evt.target.files.length; i++) {
            fileIdCounter++;
            var file = evt.target.files[i];
            var fileId = sectionIdentifier + fileIdCounter;
            if (!re.exec(file.name)) {
                alert('File extention is not supported');
                return;
            }
            filesToUpload.push({
                id: fileId,
                file: file
            });
            var removeLink = "<a style =\"color:#000\"class=\"removeFile\" href=\"#\" data-fileid=\"" + fileId + "\">&#10006;</a>";
            output.push("<li><strong>", escape(file.name), "</strong> ", removeLink, "&nbsp;</li>");
        };
        $("#files1").val(null);
        $(this).children(".fileList")
            .append(output.join(""));
        evt.target.value = null;
    });
    $(this).on("click", ".removeFile", function (e:any) {
        e.preventDefault();
        var fileId = $(this).parent().children("a").data("fileid");
        for (var i = 0; i < filesToUpload.length; ++i) {
            if (filesToUpload[i].id === fileId)
                filesToUpload.splice(i, 1);
        }
        $(".file-chooser__input").prop('disabled', false);
        $(this).parent().remove();
    });
    this.clear = function () {
        for (var i = 0; i < filesToUpload.length; ++i) {
            if (filesToUpload[i].id.indexOf(sectionIdentifier) >= 0)
                filesToUpload.splice(i, 1);
        }
        $(this).children(".fileList").empty();
    }
    return this;
};