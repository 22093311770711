import { $} from "../third-party-imports";
export function htmlInjectionField(){
    $('input[name="inputFirstName"],input[name="inputLastName"],input[name="email"],input[name="email"],input[name="inputAddress"],input[name="inputAddress2"],input[name="inputcity"],textarea[name="inputService"],#blogInputFirstNameTxt,#blogInputLastNameTxt,#blogInputAddressTxt,#blogInputAppartmentTxt,#blogInputCityTxt').bind('keypress', function (event:any) {
        var keyCode = event.keyCode || event.which ;
        if (keyCode == 60 || keyCode == 62 || keyCode == 47 || keyCode == 92) { 
            return false;
        } 
    });

    $('input[name="inputFirstName"],input[name="inputLastName"],input[name="email"],input[name="email"],input[name="inputAddress"],input[name="inputAddress2"],input[name="inputcity"],textarea[name="inputService"],#blogInputFirstNameTxt,#blogInputLastNameTxt,blogInputAddressTxt,#blogInputAppartmentTxt,#blogInputCityTxt').bind('paste',function(e:any){
        let element:any = this;
        let elementId:any = element.id;
           setTimeout(function(){
              let data= $("#" +elementId).val(); 
              let  regx = /[^<>/]/g ; 
              let regxS = /[<>/]/g;
              if(regx.test(data) || regxS.test(data)){
                let data1 = $("#" +elementId).val().replaceAll('<','');
                let data2 = data1.replaceAll('>','');
                let data3 = data2.replaceAll('/','');
                $("#" +elementId).val(data3);
              }
           });
    });

    $('input[name="mrhFirstName"],input[name="mrhLastName"],input[name="mrhEmail"],input[name="mrhAddress"],input[name="mrhAddress2"],input[name="mrhCity"]').bind('keypress', function (event:any) {
        var keyCode = event.keyCode || event.which ;
        if (keyCode == 60 || keyCode == 62 || keyCode == 47 || keyCode == 92) { 
            return false;
        } 
    });

    $('input[name="mrhFirstName"],input[name="mrhLastName"],input[name="mrhEmail"],input[name="mrhAddress"],input[name="mrhAddress2"],input[name="mrhCity"]').bind('paste',function(e:any){
        let element:any = this;
        let elementId:any = element.id;
           setTimeout(function(){
              let data= $("#" +elementId).val(); 
              let  regx = /[^<>/]/g ; 
              let regxS = /[<>/]/g;
              if(regx.test(data) || regxS.test(data)){
                let data1 = $("#" +elementId).val().replaceAll('<','');
                let data2 = data1.replaceAll('>','');
                let data3 = data2.replaceAll('/','');
                $("#" +elementId).val(data3);
              }
           });
    });
}