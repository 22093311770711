import { generateSideMenuCategory } from "./generate-side-menu-category";
import { generateSideMenuServicesRow } from "./generate-side-menu-services-row";

export function sideMenuCategoryWrap(rootMenu:any){
    //let url = window.location.href;
    let concepCode  = $("#conceptCode").val();
    let url = window.location.pathname;
    let sideMenuCategory:any = [];
    let weblocationId = $('#weblocationId').val();
    let rootMenus:any;
    let arr1 = url.split('/').filter(function(el:any){return el; });
    if(concepCode =="ASV" || concepCode=="MRA" || concepCode=="PLG" || concepCode=="MRR" || concepCode=="GUY"){
        rootMenus = rootMenu.find(function fn(item:any) {
            return item.link === url || (item.category && item.category.find(fn)) ||(item.menu && item.menu.find(fn));
      
        });
    }else{
        if(weblocationId){
            rootMenus = rootMenu.find((el:any)=> {
                let check = url.indexOf(el.link)>= 0;
                if(!check){
                    return;
                }else{
                    return true;
                }
            });
        }else if($('#brandName').val() == arr1[0]){
            rootMenus = rootMenu.find((el:any)=> {
                let arr2 =  el.link.split('/').filter(function(el:any){return el; });
                let check = JSON.stringify(arr1) == JSON.stringify(arr2) ? true : false;
                if(check){
                    return true;
                }
            });
        }else if(concepCode == "GUY"){
            rootMenus = rootMenu.find((el:any)=> {
                let arr2 =  el.link.split('/').filter(function(el:any){return el; });
                let arr3 = [...arr2, 'residential', 'commercial' ];
                let check = arr1.some(r=> arr3.includes(r));
                if(check){
                    return true;
                }
            });
        }else{
            rootMenus = rootMenu.find((el:any)=> {
                let arr2 =  el.link.split('/').filter(function(el:any){return el; });
                let check = arr1.some(r=> arr2.includes(r));
                if(check){
                    return true;
                }
            });
        }
    }
    if(!rootMenus){
        return;
    }
    $('.services-left-nav h2').text(rootMenus.name);
    var category = rootMenus.services || rootMenus.category;
    category.forEach((cat:any,index:any)=> {
        sideMenuCategory.push(generateSideMenuCategory(cat,index));
    })
    $('.collapsable-list').remove();
    $('.services-left-nav').append(sideMenuCategory);
    category.forEach((cat:any,index:any)=> {
        generateSideMenuServicesRow(cat,index);
    });
}