import { $ } from "../third-party-imports";
export function paginationServiceSearch(){
    let pageSize = 5;
    let curPage = 0;
    let pageCount =  $('.service-search-result-display .row-list').length / pageSize;
    let currentIndex:any =1;
    let item = [];
    for(let i = 1; i<=pageCount;i++){
        item.push(i);
    }
    $('#mrh_current_page').val(1);
    $('#mrh_show_per_page').val(pageCount);
    render();
    let showPage = function(page:any) {
        $(".service-search-result-display .row-list").hide();
        $(".service-search-result-display .row-list").each(function(this:any,n:any) {
            if (n >= pageSize * (page - 1) && n < pageSize * page)
                $(this).show();
        });        
    }
    showPage(1);
    function isPageInRange( curPage:any, index:any, maxPages:any, pageBefore:any, pageAfter:any ) {
            if (index <= 1) {
                // first 2 pages
                return true;
            }
            if (index >= maxPages - 2) {
                // last 2 pages
                return true;
            }
            if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
                return true;
            }
      }
    function render(){
            let separatorAdded:Boolean = false;
            let new_page:any;
            $("#mrh_search_pagination .pagination").empty();
            $("#mrh_search_pagination .pagination").prepend('<li><a class="previous_link pagination-item nav-arrow prev" href="#">Back</a></li> ');
            $("#mrh_search_pagination .pagination").prepend('<li><a class="first_link pagination-item nav-arrow first" href="#">First</a></li> ');
            for(let i = 0 ; i<pageCount;i++){
                    if ( isPageInRange( curPage, i, pageCount, 2, 2 ) ) {
                        $("#mrh_search_pagination .pagination").append('<li><a href="#" class="page-no pagination-item" data-page='+(i+1)+'>'+(i+1)+'</a></li> ');
                        separatorAdded = false;
                    }else{
                        if (!separatorAdded) {
                            $("#mrh_search_pagination .pagination").append('<li class="separator" />');
                            separatorAdded = true;
                        }    
                    }
            }
            $("#mrh_search_pagination .pagination").append('<li><a class="next_link pagination-item nav-arrow next" href="#">Next</a></li> ');
            $("#mrh_search_pagination .pagination").append('<li><a class="last_link pagination-item nav-arrow last" href="#">Last</a></li> ');
            if(currentIndex == 1){
                $('.previous_link').addClass('disabled');
                $('.first').addClass('disabled');
            }
            if(currentIndex == Math.ceil(pageCount)){
                $('.next_link').addClass('disabled');
                $('.last').addClass('disabled');
            }
            $("#mrh_search_pagination .pagination li  a.page-no").click(function(this:any, e:any) {
                e.preventDefault();
                $("#mrh_search_pagination .pagination li  a.page-no").removeClass("current");
                $('.prev').removeClass('disabled');
                $('.next').removeClass('disabled');
                $('.first').removeClass('disabled');
                $('.last').removeClass('disabled');
                currentIndex = $(this).attr('data-page');
                $('#mrh_current_page').val(currentIndex);
                curPage = parseInt(currentIndex);
                render();
                showPage(parseInt(currentIndex));
                $('a[data-page='+curPage+']').addClass("current");
                scrollUp();
            });
            $('#mrh_search_pagination ul li a.next').click(function(e:any){
                e.preventDefault();
                new_page = parseInt($('#mrh_current_page').val() as any) + 1;
                var lastIndex = Math.ceil(pageCount);
                curPage = parseInt(new_page);
                render();
                if(new_page !=lastIndex + 1){
                    $('.next').removeClass('disabled');
                    $('#mrh_current_page').val(new_page);
                    $("#mrh_search_pagination ul li a").removeClass("current");
                    $('.prev').removeClass('disabled');
                    $('a[data-page='+new_page+']').addClass("current");
                    $('.first').removeClass('disabled');
                    $('.last').removeClass('disabled');
                    showPage(new_page);
                }
                if(new_page == lastIndex){
                    $('.next').addClass('disabled');
                    $('.last').addClass('disabled');
                }
                scrollUp();
            });
            $('#mrh_search_pagination ul li a.prev').click(function(e){
                e.preventDefault();
                new_page = parseInt($('#mrh_current_page').val() as any) - 1;
                curPage = parseInt(new_page);
                render();
                if(new_page!=0){
                    $('.next').removeClass('disabled');
                    $('.prev').removeClass('disabled');
                    $('.first').removeClass('disabled');
                    $('#mrh_current_page').val(new_page);
                    $("#mrh_search_pagination ul li a").removeClass("current");
                    $('a[data-page='+new_page+']').addClass("current");
                    showPage(new_page);
                }
                if(new_page ==1){
                	$('.prev').addClass('disabled');
                	$('.first').addClass('disabled');
                }
                scrollUp();
                });
            $('#mrh_search_pagination li a.first').click(function(e:any){
                e.preventDefault();
                $("#mrh_search_pagination li a").removeClass("current");
                $('a[data-page="1"]').addClass("current");
                $('.next').removeClass('disabled');
                $('.last').removeClass('disabled');
                $('.prev').addClass('disabled');
                $('.first').addClass('disabled');
                $('#mrh_current_page').val(1);
                showPage(1);
                scrollUp();
            });
             $('#mrh_search_pagination li a.last').click(function(e){
                e.preventDefault();
                var lastPage = Math.ceil(pageCount);
                $("#mrh_search_pagination li a").removeClass("current");
                $('a[data-page='+lastPage+']').addClass("current");
                $('.next').addClass('disabled');
                $('.last').addClass('disabled');
                $('.prev').removeClass('disabled');
                $('.first').removeClass('disabled');
                $('#mrh_current_page').val(lastPage);
                showPage(lastPage);
                scrollUp(); 
         });
        }
 function scrollUp(){
    $('html, body').animate({
        scrollTop: $("body").offset().top
    }, 500);
  }
  $('#mrh_search_pagination  li:nth-child(3) a').addClass('current');
}