import { chunkArray } from "../util";
import { colSection } from "./col-section";
import { dynamciServiceLiList } from "./dynamci-service-li-list";
import { menuCol, servicesMenuCol } from "./menu-col";

export function createColumnSection(results:any,type:any){
    let menuCols:any = [];
    var splitCount;
    if (results.length < 3) splitCount = 1;
    else splitCount = Math.ceil(results.length / 3);
    let result:any = chunkArray(results, splitCount);
    let countLength = result.length;
    let services = type.services || [];
    if(services.length) {
        let servicesmenu:any = [];
        if(result.length) {
            for(let i=0; i<result.length; i++) {
                result[i].forEach((item:any) => servicesmenu.push(dynamciServiceLiList(item)));
            }            
        }
        menuCols.push(servicesMenuCol(type,servicesmenu.join('')));
    }
    else {
        for(let i=0;i<result.length;i++){
            let serviceCat:any = [];
            result[i].forEach((element:any,index:any) => {
                let serviceList:any = [];
                let checkNumOfService  = serviceModifierChecker(element);
                if(!("serviceUrl" in checkNumOfService)){
                    $.each(element.menu,function(index,ser){
                        serviceList.push(dynamciServiceLiList(ser)); 
                    });
                }else{
                    serviceList = [];
                }
                serviceCat.push(colSection(element,serviceList.join(''),index));
            });
            menuCols.push(menuCol(type,serviceCat.join(''),countLength,i));
        }
    }
    // $('.dropmenu'+'.' +type.name).append(menuCols);
    $('.megamenu').find('[class*="'+type.name+'"]').append(menuCols);
}

function serviceModifierChecker(categoryMenu:any) {
        let serviceObj: any = {};
        if (categoryMenu?.menu.length === 1){
            serviceObj.name = categoryMenu?.menu[0]?.name;
            serviceObj.serviceUrl = categoryMenu?.menu[0]?.link;
            serviceObj.link = categoryMenu?.menu[0]?.link;
            return serviceObj;
        }
        return categoryMenu;
}