import { formatPhone } from "../location-search-map/formatPhone";
import { mrhServiceModalfullAddressRoundRobinApi } from "./mrh-ServiceModalfullAddressRoundRobinApi"
import { mrhserviceSearchTermApi } from "./mrh-serviceSearchTermApi";
import { startLoader } from "./startLoader";
import { stopLoader } from "./stopLoader";
export function mrhServiceModal(postal:any) {
    window.objOSGlobal.requestBodyValues.PostalCode = postal;
    startLoader();
    mrhServiceModalfullAddressRoundRobinApi(postal,false,(resData:any)=>{
        mrhserviceSearchTermApi(resData,(resData:any)=>{
            stopLoader();
            let search_term:any = window.objOSGlobal.requestBodyValues.SearchTeam;
            $("#mrh_modal_location_found").removeClass('d-none');
            $("#mrh_modal_location_found").addClass('show');
            $('body').append(`<div class="modal-backdrop fade show"></div>`);
            $('body.mr-handyman.wf-loading').css({overflowY: 'hidden'});
            $("#mrh_modal_location_found p.description").html(`We found ${search_term.toLowerCase()} services for zip code`);
            $("#mrh_modal_location_found p.description").append(`<span> ${postal}.</span>`)
            // $("#mrh_modal_location_found p.description").find('span').html(" "+postal+".");
            $("#mrh_modal_location_found .website-wrapper").find('h5').html(resData[0].doingBusinessAs);
            let phoneNum:any = formatPhone(resData[0].phoneNumber);
            $("#mrh_modal_location_found .website-wrapper a.call-text").get(0).lastChild.nodeValue = phoneNum;
            $("#mrh_modal_location_found .website-wrapper a.call-text").attr('href', 'tel:' + phoneNum);
            $("#mrh_modal_location_found .website-wrapper p.contact-number").find('.primary-btn').attr('href',resData[0].locationWebsiteUrl);

        },'')
    },'');
}