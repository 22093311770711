import type { PaymentUrlParameters, ElavonClient } from '@nbly/elavon';
import { giftCertificateClient } from './giftCertificateClient';

export async function submitGc(elavonClient: ElavonClient)
{
    const prePurchases = elavonClient.createPrePurchaseObjects(
        elavonClient.getConceptId(),
        elavonClient.pullGiftCertificatesFromForm(),
        elavonClient.pullPurchaseInformationFromForm()
    );

    const groupResponse = await giftCertificateClient.createPrePurchaseGroup(prePurchases);
    
    const paymentUrlParameters = {
        invoicenumber: groupResponse.data.GiftCertificateGroupId.toString(),
        appkey: elavonClient.getMerchantKey()
    };

    const purchaseForm = elavonClient.getPurchaserInfoForm()
    const purchasePostUrl = elavonClient.getPurchaseUrl(paymentUrlParameters);

    purchaseForm.method = "POST";
    purchaseForm.action = purchasePostUrl;
    purchaseForm.submit();
}
