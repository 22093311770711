export function gtmEventBrandWise(brand:any){
    
    let checker = false
    switch(brand){
        case 'FSP':
        checker = true;
        break;
        case 'MLY':
        checker = true;
        break;
        case 'GUY':
        checker = true;
        break;
        case 'ASV':
        checker = true;
        break;
        case 'MRA':
        checker = true;
        break;
        case 'PLG':
        checker = true;
        break;
        case 'MHM':
        checker = true;
        break;
        case 'HMC':
        checker = true;
        break;
        case 'MRR':
        checker = true;
        break;
        default:
        checker = false;
    }
    return checker;
}