import {$} from '../third-party-imports';
import {loadGallery}from './loadGallery';
import { updateGallery } from './updateGallery';
import {selectCategoryTab} from './selectCategoryTab';
let selectors:any; 
let first_image:any;
let last_image: any;
let isTabbedGallery:Boolean = false;
let last_container_img:any
export const photoObject = {
	current_image:0,
	counter:0,
	selector:selectors,
	first_image:first_image,
	last_image:last_image,
	isTabbedGallery:isTabbedGallery,
    last_container_img:last_container_img
} 
		//jsImageGallery
let gallery:any =  document.getElementById('jsImageGallery');
if(gallery){
        let modalId = $('#jsImageGallery');
        window.onload = function(){
            loadGallery(true, 'a.thumbnail');
            /**
             *
             * @param setIDs        Sets IDs when DOM is loaded. If using a PHP counter, set to false.
             * @param setClickAttr  Sets the attribute for the click handler.
            */
            // Category select box for mobile view
            selectCategoryTab();
            $(window).resize(selectCategoryTab);	
        }

        $('#jsNextBtn, #jsPreviousBtn').on('click', function(this:any) {
            $('#jsImageGallery #jsNextBtn,#jsImageGallery #jsPreviousBtn').removeClass('hidden');
            
           
            if ($(this).attr('id') === 'jsPreviousBtn') {	
                if(photoObject.isTabbedGallery && photoObject.current_image == photoObject.first_image) {
                    photoObject.current_image = photoObject.last_image;
                }
                else{
                    photoObject.current_image--;
                }

                if (photoObject.current_image === 0) {
                    photoObject.current_image = photoObject.counter;
                }
            } else {
                if(photoObject.isTabbedGallery && photoObject.current_image == photoObject.last_image) {
                    photoObject.current_image = photoObject.first_image;
                }
                else{ 
                    photoObject.current_image++;
                }
                if (photoObject.current_image === photoObject.counter + 1) {
                    photoObject.current_image = 1;
                }
            }
            photoObject.selector = $('[data-image-id="' + photoObject.current_image + '"]');
            updateGallery(photoObject.selector);
            //set time out for screen reader focus image 
            if(photoObject.current_image == photoObject.first_image){
                $('#jsImageGallery #jsPreviousBtn').addClass('hidden');
            }
            if(photoObject.current_image == photoObject.last_container_img){
                $('#jsImageGallery #jsNextBtn').addClass('hidden');
            }
            setTimeout(function() {
                $('#jsCarousel').focus();
            }, 200);
        });
        // build key actions
    $(document).keydown(function(e) {
        switch (e.which) {
            case 37: // left
                if ((modalId.data('bs.modal') || {})._isShown && $('#jsPreviousBtn').is(":visible")) {
                    $('#jsPreviousBtn').click();
                }
                break;

            case 39: // right
                if ((modalId.data('bs.modal') || {})._isShown && $('#jsNextBtn').is(":visible")) {
                    $('#jsNextBtn').click();
                }
                break;

            default:
                return; // exit this handler for other keys
        }
            e.preventDefault(); // prevent the default action (scroll / move caret)
    });

    $(document).on('click', '.tab-content a.thumbnail', function(this:any) {
        var $tab = $(this).closest('.tab-content').attr('id');   
        let current_Img = $(this).attr('data-image-id');
        photoObject.first_image = $('#' + $tab + ' .image-gallery-row li:first-child a').attr('data-image-id');
        photoObject.last_image = $('#' + $tab + ' .image-gallery-row li:last-child a').attr('data-image-id');
        photoObject.last_container_img = $('#' + $tab + ' .container:last-child .image-gallery-row li:last-child a').attr('data-image-id');
        $('#jsImageGallery #jsNextBtn,#jsImageGallery #jsPreviousBtn').removeClass('hidden');		
        if($tab != 'categoryTabAll') {
            photoObject.isTabbedGallery = true;
        }
        if(current_Img ==photoObject.first_image){
            $('#jsImageGallery #jsPreviousBtn').addClass('hidden');
        }
        if(current_Img ==photoObject.last_container_img){
            $('#jsImageGallery #jsNextBtn').addClass('hidden');
        }
    });	

}