import { $ } from "../third-party-imports";
import {fullAddressRoundRobinApi} from './fullAddressRoundRobinApi';
import {disPlayListOfServices} from '../services/disPlayListOfServices';
import { servieModal } from "./get-brands-json";
import { getDisplayServicesList } from "../services/getDisplayServicesList";
import { getFullAttributeApi } from "./getFullAttributeApi";
import { gtmCustomEventLeadFlow } from "./gtm-custom-event-lead-flow";
import { stopLoader } from "./stopLoader";
import { mrApplianceBrandList } from "./mrApplianceBrandList";
import { apiCall, erasePreFilledDataOnClose, mrh_OptOsFlow, refreshScheduleData } from "..";
import { MraProgressBar } from "./mra-progress-bar";
import { gtmEventBrandWise } from "../scheduler/gtm-event-brand";
import { removePreFilledData } from "../scheduler/common-os/remove-prefilled-data";
import { commonProgressBar } from "../scheduler/common-os/common-progress-bar";
import { updateOpusCroOnlineFlow } from "../scheduler/cro-os-flow/cro-os-flow";
export const addressBumper =  {
    addressBumperFlag:false
}
export function findHelpAddressBumper(zipcode:any){
        addressBumper.addressBumperFlag = true;
        $('.address-bumper-screen input[name="inputZipCode1"]').val(zipcode);
        $('.address-bumper-screen .next').click(function(event:Event){
            event.preventDefault();
        if (!$("#serviceAddressBumper").valid()) {
            return false;
        }
        $('.scheduler-modal .modal-content').addClass('banner-wizard');
        var address = $('.address-bumper-screen input[name="inputAddressBumper"]').val();
        var apartment = $('.address-bumper-screen input[name="inputAddressBumper2"]').val();
        var city = $('.address-bumper-screen input[name="inputcity1"]').val();
        var state = $('.address-bumper-screen select[name="inputstate1"]').val();
        var zipCode:any = $('.address-bumper-screen input[name="inputZipCode1"]').val();
        apartment = apartment ? apartment + " ": "";
        var addressParam = address + " " + apartment +city + " " + state + " " + zipCode;
        // var addressParam = address + "&" + apartment + "&" + city + "&" + state + "&" + zipCode;
        const addressBumperValObj:any ={address,apartment,state,city,zipCode}
        window.objOSGlobal.requestBodyValues.PostalCode = zipCode;
        localStorage.setItem('zipcode',zipCode);
        if(servieModal.enable){
            fullAddressRoundRobinApi(addressParam,false,()=>{
                getDisplayServicesList(zipCode,4,function(result:any){
                    if(result.length!=0){
                            window.objOSGlobal.requestBodyValues.FranchiseId = result[0].franchiseId+'';
                            localStorage.setItem('estfranchiseId', window.objOSGlobal.requestBodyValues.FranchiseId);
                            getFullAttributeApi(window.objOSGlobal.requestBodyValues.WebLocationId,()=>{
                                let nextId = $('.service-search-screen').next().attr("id");
                                localStorage.setItem('addressMly', JSON.stringify(addressBumperValObj));
                                if($("#brandName").val() == "molly-maid"){
                                    updateOpusCroOnlineFlow(nextId);
                                } else if($("#brandName").val() == "mr-appliance"){
                                    apiCall.error = false;
                                    erasePreFilledDataOnClose();
                                    MraProgressBar();
                                    mrApplianceBrandList((data:any,status:any)=>{
                                        var mrBrandList:any = [];
                                        $.each(data, function (index:any, element:any){
                                            mrBrandList.push('<option id="' + element.makeId + '" value="' + element.makeId + '" data-makeCode="' + element.makeCode + '">'+element.makeName+'</option>');
                                        });
                                        $('#warrantyDetails #referenceDetails').empty();
                                        $('#warrantyDetails #referenceDetails').append('<option value="">- select one</option>')
                                        $('#warrantyDetails #referenceDetails').append(mrBrandList);
                                    });
                                    if(window.objOSGlobal.requestBodyValues.IsLeadOnly){
                                        $('a[href="#step3"]').parent().addClass("d-none");
                                        $('a[href="#step5"]').parent().removeClass("d-none")
                                    }else{
                                        $('a[href="#step3"]').parent().removeClass("d-none");
                                        $('a[href="#step5"]').parent().addClass("d-none");
                                    }
                                    $('.tab-pane.fieldset').removeClass('active');
                                    $('[href="#' + nextId + '"]').tab('show');
                                    $('#'+nextId).addClass('active');
                                    $('.progress, .progressbar').show();
                                }else if($("#brandName").val() ==='mr-handyman' || $("#brandName").val() === "my-handyman"){
                                    mrh_OptOsFlow(nextId);
                                }else if($("#brandName").val() ==='mr-rooter'){
                                    removePreFilledData();
                                    commonProgressBar();
                                }else {
                                    if(!window.objOSGlobal.requestBodyValues.IsLeadOnly){
                                        $(".tab-pane.fieldset").removeClass("active");
                                        refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
                                        $('[href="#' + nextId + '"]').tab('show');
                                        $('#'+nextId).addClass('active');
                                        $('.nav-wizard.progressbar').removeClass('hide-schedule');
                                        stopLoader();
                                    }else{
                                        $('.tab-pane.fieldset').removeClass('active');
                                        $('.nav-wizard.progressbar').addClass('hide-schedule');
                                        $('.contact-information-screen').addClass('active');
                                        $('.progress-bar').css({ width:'3.2%' });
                                        stopLoader();
                                    }
                                    if (nextId == "step1") {
                                        $('.scheduler-modal .modal-content').addClass('banner-wizard');
                                    }else{
                                        $('.scheduler-modal .modal-content').removeClass('banner-wizard');
                                    }
                                }
                                let brandCode =$("#conceptCode").val().toUpperCase();
                                stopLoader();
                                if(gtmEventBrandWise(brandCode)){
                                    let leadObj = {
                                        event:'custEv_LocalDataLayer',
                                        locationID: window.objOSGlobal.requestBodyValues.WebLocationId,
                                        locationDBA:window.objOSGlobal.paramsUI.doingBusinessAs,
                                        locationZipcode:window.objOSGlobal.requestBodyValues.PostalCode,
                                        licenseNumber:window.objOSGlobal.requestBodyValues.licenseNumber
                                    }
                                    gtmCustomEventLeadFlow(leadObj);
                                }
                            });
                      }else{
                        stopLoader();
                        $(".tab-pane.fieldset").removeClass("active");
                        $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.PostalCode}</span>`);
                        $(".no-service-screen").addClass("active");
                        }
                    },function(data:any, status:any, error:any){
                        stopLoader();
                        $(".tab-pane.fieldset").removeClass("active");
                        $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${postal}</span>`);
                        $(".no-service-screen").addClass("active");
                    });
            },'');
        }else{
            fullAddressRoundRobinApi(addressParam,true,()=>{
                disPlayListOfServices(zipCode);
                localStorage.setItem('addressMly', JSON.stringify(addressBumperValObj));
            },'');
        }
    });
}