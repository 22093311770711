import {CreatesingleServicePageLink} from './single-servicePage-link';
export function menuGeneateListArray(categoryMenu:any,type:any){
    let serviceTarget = categoryMenu.name.replace(/[^A-Z0-9]+/ig, "");
    let img = categoryMenu.icons.icon;
    let hoverImg = categoryMenu.icons.hoverIcon;
    let alt = categoryMenu.icons.iconAlt;
    serviceTarget = serviceTarget+'_' + type.name + '_ServicesMenu';
    let checkNumOfService:any = CreatesingleServicePageLink(categoryMenu);
    if(categoryMenu.menu.length ===0){
        return `<li class="list-item ${type.name}">
        <a class="" href="${categoryMenu.link}">
        <button class="arrow-right-link">
        <img src="${img}" alt="${alt}" />
        <img src="${hoverImg}" alt="${alt}"class="img-hover" />
        <span><span class="sr-only">Click to view &nbsp;</span>${categoryMenu.name}</span>
        </button>
        </a>
   </li>`
    }else{
        if (checkNumOfService.menu){
            return `<li class="list-item ${type.name}">
                 <button class="js-mega-menu-btn arrow-right-link" data-target="${serviceTarget}">
                 <img fetchpriority="low" loading="lazy" src="${img}" alt="${alt}" />
                 <img fetchpriority="low" loading="lazy" src="${hoverImg}" alt="${alt}"class="img-hover" />
                 <span><span class="sr-only">Click to view &nbsp;</span>${categoryMenu.name}</span>
                 </button>
            </li>`
            } else {
                return `<li class="list-item ${type.name}">
                <a class="" href="${checkNumOfService.serviceUrl}">
                <button class="js-mega-menu-btn arrow-right-link" data-target="${serviceTarget}">
                <img fetchpriority="low" loading="lazy" src="${img}" alt="${alt}" />
                <img fetchpriority="low" loading="lazy" src="${hoverImg}" alt="${alt}"class="img-hover" />
                <span><span class="sr-only">Click to view &nbsp;</span>${checkNumOfService.name}</span>
                </button>
                </a>
           </li>`
    }
    }
}