import { inputZipChecker, refreshScheduleData } from "../../util";
import { $ } from "../../third-party-imports";
export function asvPreFilledData(nextId:any){
    let leadFlow =  window.objOSGlobal.requestBodyValues.IsLeadOnly;
    $('.tab-pane.fieldset').removeClass('active');
    $('.schedule-list').removeClass('schedule-list');
    $('.service-information').addClass("d-none");
    $('.progress, .progressbar').show();
    $('.scheduler-modal .modal-content').removeClass('banner-wizard');
    $('.nav.nav-wizard.progressbar').css('display','flex');
    refreshFormField()
    inputZipChecker();
    nextId = $('.service-search-screen').next().attr("id");
    if(leadFlow){
        asvOnlineLeadFlow();
    }else{
        onlineCalendarFlow(nextId);
    }
    $(".js-next-btn").removeClass("disble-btn").prop("disabled", false);
}


export function asvOnlineLeadFlow(){
    $(".modal-header-one").addClass("d-none");
    $('.modal-header-two').css('display', 'flex');
    $(".selected-date-txt").empty();
    $('a[href="#step2"]').parent().addClass('d-none');
    $('.progressbar li').css('width', 'calc(100%/2)');
    $('.progress-bar').css({ width:'4.2%' });
    $('.progress,.progressbar').addClass('lead-flow-scenario');
    $('.contact-information-screen').addClass('active');
    let postalCode = localStorage.getItem('zipcode');
    $('.personal-info-form input[name="inputZipCode"]').val(postalCode);
    $('.scheduler-modal .modal-content').removeClass('banner-wizard');
}

function onlineCalendarFlow(nextId:any){
    $(".modal-header-one").addClass("d-none");
    $('.modal-header-two').css('display', 'flex');
    $(".selected-date-txt").empty();
    nextId = "step2";
    $('a[href="#step2"]').parent().removeClass('d-none')
    refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
    $('.progressbar li').css('width', 'calc(100%/3)');
    $('.progress-bar').css({ width:'3.2%' });
    $('.progress,.progressbar').removeClass('lead-flow-scenario');
    $('.calendar-screen').addClass('active');
    let postalCode = localStorage.getItem('zipcode');
    $('.personal-info-form input[name="inputZipCode"]').val(postalCode);
    
}

function refreshFormField(){
    $('.personal-info-form  input[name="inputFirstName"]').val('');
    $('.personal-info-form  input[name="inputLastName"]').val('');
    $('.personal-info-form  input[name="email"]').val('');
    $('.personal-info-form  input[name="inputPhnNumber"]').val('');
    $('.personal-info-form  input[name="inputAddress"]').val('');
    $('.personal-info-form  input[name="apartment"]').val('');
    $('.personal-info-form  input[name="inputcity"]').val('');
}