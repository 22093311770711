// The current application coordinates were pre-registered in a B2C tenant.
import {$} from '../third-party-imports';
let sConceptCode:any = "MRA";

if (($('#conceptCode').val() as any).length){
	sConceptCode = $('#conceptCode').val();
}

var sConceptID:any = "1";

if (($('#conceptId').val() as any).length){
	sConceptID = $('#conceptId').val();
}
 
var sweblocationId:any = "8628";

if($("#weblocationId").length && ($("#weblocationId").val() as any).length) {
	sweblocationId = $("#weblocationId").val();
}
export const apiConfig = {
    profileApi: `${window.DEFINE_VALUE.JS_WEB_URL}/${window.DEFINE_VALUE.JS_PROFILE_API_SERVICE_NAME}/v1/protected/UserProfile/`,
    updateLoginApi: `${window.DEFINE_VALUE.JS_WEB_URL}/${window.DEFINE_VALUE.JS_PROFILE_API_SERVICE_NAME}/v1/public/IdentityExperience/UserAuthentication/Log?apikey=${window.DEFINE_VALUE.JS_API_KEY}`,
    editPhoneApi: `${window.DEFINE_VALUE.JS_WEB_URL}/${window.DEFINE_VALUE.JS_PROFILE_API_SERVICE_NAME}/v1/protected/UserProfile/Contact`,
    adddressApi: `${window.DEFINE_VALUE.JS_WEB_URL}/${window.DEFINE_VALUE.JS_PROFILE_API_SERVICE_NAME}/v1/protected/UserProfile/Address`,
    notificationApi: `${window.DEFINE_VALUE.JS_WEB_URL}/${window.DEFINE_VALUE.JS_NOTIFICATION_API_SERVICE_NAME}/v1/protected/Notification/`,
    mobileAppPromo: `${window.DEFINE_VALUE.JS_WEB_URL}/${window.DEFINE_VALUE.JS_PROFILE_API_SERVICE_NAME}/v1/public/identityExperience/MobileAppPromo?apikey=${window.DEFINE_VALUE.JS_API_KEY}`,
    techAvalibility: `${window.DEFINE_VALUE.JS_UnifiedSyncPlatform_URL}/public/UnifiedSyncPlatform/TechAvailability`,
    coreApiBaseUrl: `${window.DEFINE_VALUE.JS_CORE_API_BASE_URL}`,
    paymentPostUrl: `${window.DEFINE_VALUE.JS_PAYMENT_URL}`,
    FranchiseLookUpByServiceAddress:`${window.DEFINE_VALUE.JS_UnifiedSyncPlatform_URL}/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress?&Address=`,
    serviceWebcategoriesAggregates:`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/franchiseWebLocations/service-aggregates/search?`,
    fullAttribute:`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_DigitalOrchestrations_URL}/v1/public/franchiseGroupOrchestrator/`,
    serviceAggregatesWeblocation:`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/service/config/menu/franchise_web_location_id/`,
    serviceAggregates:`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/concepts/`,
    serviceAggregatesSearch:`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/`,
    stateApi: `${window.DEFINE_VALUE.JS_WEB_URL}/${window.DEFINE_VALUE.JS_PROFILE_API_SERVICE_NAME}/v1/public/IdentityExperience/States/`,
    serviceTypeProblemAggregates:`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/concepts/`,
    FranchiseLookUpService:`${window.DEFINE_VALUE.JS_UnifiedSyncPlatform_URL}/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress?apikey=`,
    FranchiseWebLocations:`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/franchise-web-locations/`,
    ReferenceDetailsApi: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/concept-codes/`+ encodeURIComponent(sConceptCode) +"/lead-sources?apikey=" + window.DEFINE_VALUE.JS_API_KEY,
	GenericLeadSources: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/generic-lead-sources?apikey=${window.DEFINE_VALUE.JS_API_KEY}`,
	LocateLocationApi : `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_FranchiseGroup_URL}/v1/public/concepts/`+ encodeURIComponent(sConceptCode) +"/franchiseweblocations/sZipPostalCodeValue?apikey=" + window.DEFINE_VALUE.JS_API_KEY,
	LocateLocationApiWithRoundRobinFalse : `${window.DEFINE_VALUE.JS_UnifiedSyncPlatform_URL}/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress?apikey=${window.DEFINE_VALUE.JS_API_KEY}&Address=sAddressParamValue&IsRoundRobin=false&RequireAddressOnPartialPostalCode=true&conceptID=`+ encodeURIComponent(sConceptID),
	LocateLocationApiWithRoundRobinTrue :  `${window.DEFINE_VALUE.JS_UnifiedSyncPlatform_URL}/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress?apikey=${window.DEFINE_VALUE.JS_API_KEY}&Address=sAddressParamValue&IsRoundRobin=true&RequireAddressOnPartialPostalCode=true&conceptID=`+ encodeURIComponent(sConceptID),
	PhotosApi : `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_DigitalOrchestrations_URL}/v1/public/photos/images`,
	PhotosCategoriesApi: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_DigitalOrchestrations_URL}/v1/public/photos/categories`,
    calculateEstimate: `${window.DEFINE_VALUE.JS_UnifiedSyncPlatform_URL}/public/UnifiedSyncPlatform/CalculateEstimate`,
    franchiseWebLocationProfileFees: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_DigitalOrchestrations_URL}/v1/public/franchiseWebLocationProfileFees/franchiseWeblocations/`,
    //franchiseTerritories:`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_FranchiseGroup_URL}/v1/protected/franchiseTerritories/postalCodes?franchiseId=`,
    franchiseTerritories:`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_DigitalOrchestrations_URL}/v1/public/franchiseTerritories/postalCodes?apikey=${window.DEFINE_VALUE.JS_API_KEY}&franchiseId=`,
    MeetTheTeam:`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_EmployeeOrchestrations_URL}/v1/public/MeetTheTeam/FranchiseWebLocationId/`,
    // franchiseWeblocationsFullattribute:`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_FranchiseGroup_URL}/v1/public/fullattribute/franchiseWeblocations/search?franchiseWeblocationId=`,
    BrandWeServe:`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/concepts/` + encodeURIComponent(sConceptID) + "/makes?&apikey=" + window.DEFINE_VALUE.JS_API_KEY,
    broadlyReviewConceptId:`${window.DEFINE_VALUE.JS_UnifiedSyncPlatform_URL}/public/UnifiedSyncPlatform/Reviews/review-aggregates/search?ConceptId=`,
    broadlyReviewWebLocLid:`${window.DEFINE_VALUE.JS_UnifiedSyncPlatform_URL}/public/UnifiedSyncPlatform/Reviews/review-aggregates/search?FranchiseWebLocationId=`,
    mobileAppPromoNotification:`${window.DEFINE_VALUE.JS_WEB_URL}/${window.DEFINE_VALUE.JS_NOTIFICATION_API_SERVICE_NAME}/v1/public/PushNotification/MobileAppPromo`,
    merchantKey: `${window.DEFINE_VALUE.JS_MERCHANT_KEY}`,
    digitalOrchestrationFileUploadApi: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_DigitalOrchestrations_URL}/v1/public/OnlineSchedulerFileUpload/getpresignedurl`,
    merchantKeyDonation :`${window.DEFINE_VALUE.JS_MERCHANT_DONATION_KEY}`,
    organicScorpionLeads:`${window.DEFINE_VALUE.JS_ORGANIC_SCORPION}/dis/clientwebhook/v1/api/webhookproxy/processdata?id=${window.DEFINE_VALUE.P_ID}`,
    syncCleoLeadSources :`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/concepts/${sConceptID}/lead-sources?apikey=${window.DEFINE_VALUE.JS_API_KEY}`,
    contactUSApi: `${window.DEFINE_VALUE.JS_WEB_URL}/unifiedsyncplatform-api/v1/public/UnifiedSyncPlatform/ContactUs`,
    geoCodeSearchApiUrl: 'https://geocode.search.hereapi.com/v1/geocode',
    locationSearchApiUrl: `${window.DEFINE_VALUE.JS_WEB_URL}/franchisegroup-api/v1/public/concepts/`
};
