import{commonAjaxCallfunctionHidingMenu} from "./commonAjaxCallfunctionHidingMenu";
jQuery.expr[':'].contains = function(a:any, i:any, m:any) {
    return jQuery(a).text().toUpperCase()
        .indexOf(m[3].toUpperCase()) >= 0;
  };
export function hidingMttMenuLink()
{
    let franchiseId: any = $("#franchiseId").val();
    let webLocationId:any = $("#weblocationId").val() ? $("#weblocationId").val() : localStorage.getItem("localWebLocationId");
    let apiDomain1 = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? window.DEFINE_VALUE.JS_API_URL : "https://api.neighborly.com";
    let apiKey1 = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? window.DEFINE_VALUE.JS_API_KEY : "pneiweb-9pbaOywNKQj853D";
    let franchiseemployeeorchestrations = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? window.DEFINE_VALUE.JS_EmployeeOrchestrations_URL : "franchiseemployeeorchestrations-api";
    let meetTheTeam = `${apiDomain1}/${franchiseemployeeorchestrations}/v1/public/MeetTheTeam/FranchiseWebLocationId/`;
    let apiUrl = meetTheTeam + webLocationId + '?apikey=' + apiKey1;
    
    commonAjaxCallfunctionHidingMenu(apiUrl,'', function (data: any)
    {
        var teamSize = data.teamSize;
        if (teamSize == 0)
        {
            $('.dropdown-item:contains("meet the team")').hide();
            $('.dropdown-link:contains("meet the team")').hide();

            if($('#conceptCode').val()==='MHM' || $('#conceptCode').val()==='HMC')
            {
                $('.dropdown-item:contains("our team")').hide();
                $('.dropdown-link:contains("our team")').hide();
            }
        }
        else
        {
            $('.dropdown-item:contains("meet the team")').show();
            $('.dropdown-link:contains("meet the team")').show();

            if($('#conceptCode').val()==='MHM' || $('#conceptCode').val()==='HMC')
            {
                $('.dropdown-item:contains("our team")').show();
                $('.dropdown-link:contains("our team")').show();
            }
        }
    });
}