import axios from "axios";
import { apiConfig } from "../config/apiConfig";
import { stopLoader } from "./stopLoader";
export function mrhServiceModalfullAddressRoundRobinApi(addressParam: any,roundRobin: any,successHandler:any,errorHandler:any){
    window.objOSGlobal.requestBodyValues.ConceptId = $('#conceptId').val();
    var conceptID = window.objOSGlobal.requestBodyValues.ConceptId;
    var apiUrl =   apiConfig.FranchiseLookUpByServiceAddress+addressParam+'&IsRoundRobin='+roundRobin+'&conceptID='+conceptID+'&RequireAddressOnPartialPostalCode=true&apikey=' + window.DEFINE_VALUE.JS_API_KEY;
    let config = {
        method: 'get',
        url: apiUrl,
        headers: {
            'Content-Type': 'application/json'
        }
    };
axios(config)
.then(function (response) {
    if(response.data.length == 0) {
    stopLoader();
    $("#mrh_modal_location_not_found .modal-body .zipcode-des").find('h5').find('span').html(" "+addressParam+".");
    $("#mrh_modal_location_not_found").removeClass('d-none');
    $("#mrh_modal_location_not_found").addClass('show');
    $('body').append(`<div class="modal-backdrop fade show"></div>`);
    $('body.mr-handyman.wf-loading').css({overflowY: 'hidden'});
    }else {
    successHandler(response.data);
    }
})
.catch(function (error) {
  stopLoader();
});
}