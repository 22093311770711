import { $ } from "../third-party-imports"
import { apiConfig } from "../config";
import { profileVars } from ".";
import { getNblyMicroServiceApiHeaders } from '../auth/api';
import { getNblyApiHeaders } from "../auth/api/getNblyApiHeaders";

export function addProfileVerifyPhoneNumber(verifyPhone: any, successHandler: any)
{
    var payload = {
        "ContactTypeId": 1,
        "ContactDetail": verifyPhone.contactDetail,
        "ContactVerificationStatusId": 2,
        "PrimaryPhoneID": 1,
        "neighborlyAccountContactId": profileVars.neighborlyAccountContactId,
        "isOptIn": verifyPhone.isOptIn
    };
    $.ajax({
        url: apiConfig.editPhoneApi+"?"+getNblyApiHeaders(),
        data: JSON.stringify(payload),
        headers: getNblyMicroServiceApiHeaders(),
        type: "PUT",
        contentType: "application/json",
        success: successHandler,
        error: function (xhr, status, error)
        {
            // console.log(error);
        }
    });
}
