import {CreatesingleServicePageLink} from './single-servicePage-link';
export function generateSideMenuCategory(category:any,index:any){
    let checkNumOfService:any = CreatesingleServicePageLink(category);
    if (checkNumOfService.menu){
        let categoryCount  = category.menu.filter((mn:any)=>mn.visibility).length;
        let currentPath = window.location.pathname.replace(/\/$/, "");
        //let show = category.menu.some((ser:any)=>ser.link == currentPath);
        let show = category.menu ? category.menu.some((ser:any) => currentPath.indexOf(ser.link.replace(/\/$/, "")) == 0) : false;
        let cls = show ? 'show':'';
        let collapsed = !show ? 'collapsed': ''; 
        if(category?.menu.length === 0 || categoryCount <=0){
            return `<div class="collapsable-list">
                <h3>
                    <a class="" href="${category.link}">
                        <button class="primary-theme-clr body-text collapsed" href="#serviceitem-${index}" aria-expanded="false" aria-controls="serviceitem-${index}" id="accordion${index}id">${category.name}</button>
                    </a>
                </h3>
                </div>`
        }else{
            return `<div class="collapsable-list">
            <h3><button class="primary-theme-clr body-text ${collapsed}" data-toggle="collapse" href="#serviceitem-${index}" aria-expanded="${show}" aria-controls="serviceitem-${index}" id="accordion${index}id">${category.name}</button></h3>
            <div id="serviceitem-${index}" role="region" class="collapse ${cls}" aria-labelledby="accordion1id" style="">
                <ul class="services-list body-text" id="${index}"></ul>
            </div>
            </div>`
        }
       
    }else{
        return `<div class="collapsable-list">
        <h3>
            <a class="" href="${checkNumOfService.serviceUrl}">
                <button class="primary-theme-clr body-text collapsed"  href="#serviceitem-${index}" aria-expanded="false" aria-controls="serviceitem-${index}" id="accordion${index}id">${checkNumOfService.name}</button>
            </a>
        </h3>
        </div>`
    }
}