import { inputZipChecker, refreshScheduleData } from "../../util";
import { stopLoader } from "../../util";
import { $ } from "../../third-party-imports";

export function guyPreFilledData(nextId:any) {
  $(".modal-header-one").addClass("d-none");
  $('.modal-header-two').css('display', 'flex')
  inputZipChecker();
  $('.progress, .progressbar').show();
  $('.personal-info-form  input[name="inputFirstName"]').val('');
  $('.personal-info-form  input[name="inputLastName"]').val('');
  $('.personal-info-form  input[name="email"]').val('');
  $('.personal-info-form  input[name="inputPhnNumber"]').val('');
  $('.personal-info-form  input[name="inputAddress"]').val('');
  $('.personal-info-form  input[name="apartment"]').val('');
  $('.personal-info-form  input[name="inputcity"]').val('');
  $(".js-next-btn").removeClass("disble-btn").prop("disabled", false);
  if(!window.objOSGlobal.requestBodyValues.IsLeadOnly){
    $(".tab-pane.fieldset").removeClass("active");
    refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
    $('[href="#' + nextId + '"]').tab('show');
    $('#'+nextId).addClass('active');
    $('[href="#step2"]').closest('li').show();
    $('.progressbar li').css('width', 'calc(100%/3)')
    if(window.innerWidth >=375 && window.innerWidth <=390 ){
      $('.scheduler-slide .progress').css( "maxWidth", '225px');
    } else if(window.innerWidth == 412) {
      $('.scheduler-slide .progress').css( "maxWidth", '240px');
    }else {
      $('.scheduler-slide .progress').css( "maxWidth", '436px');
    }
  }else{
    $('.tab-pane.fieldset').removeClass('active');
    $('[href="#step2"]').closest('li').hide();
    $('.progressbar li').css('width', 'calc(100%/2)');
    if(window.innerWidth >=390 && window.innerWidth <=414 ){
      $('.scheduler-slide .progress').css( "maxWidth", '180px');
    }else if(window.innerWidth <=389){
        $('.scheduler-slide .progress').css( "maxWidth", '166px');
    }else {
      $('.scheduler-slide .progress').css( "maxWidth", '333px');
    }
    $('.contact-information-screen').addClass('active');
    $('.progress-bar').css({ width:'5.2%' });
    let postalCode = localStorage.getItem('zipcode');
    $('.guy-contact-info-screen input[name="inputZipCode"]').val(postalCode);
    $('.scheduler-modal .modal-content').removeClass('banner-wizard');
    stopLoader();
  }
}