import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiCaching } from "../config/cachingConfig";
import { $ } from "../third-party-imports";
import { convertHTML } from "./convertHtml";
import {paginationMeetTheTeam} from "./paginationMeetTheTeam";
let franchiseId:any = $("#franchise-id").val();
let webLocationId:any = $("#weblocationId").val();
$(function ()
{
    franchiseId && getFranchiseWeblocationIDMeetTheTeam(franchiseId);	
});

async function getFranchiseWeblocationIDMeetTheTeam(franchiseId:any){
    let individualTeamDetails:any = [];


    var apiDomain = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? window.DEFINE_VALUE.JS_API_URL : "https://api.neighborly.com";
    var apiKey = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? window.DEFINE_VALUE.JS_API_KEY : "pneiweb-9pbaOywNKQj853D";
    var franchiseemployeeorchestrations = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? window.DEFINE_VALUE.JS_EmployeeOrchestrations_URL : "franchiseemployeeorchestrations-api";
    
    
    var meetTheTeam = `${apiDomain}/${franchiseemployeeorchestrations}/v1/public/MeetTheTeam/FranchiseWebLocationId/`;
    let apiUrl = meetTheTeam + webLocationId + '?apikey='+apiKey;
    try{
    let result = await getCachedAPIResponse(apiUrl, apiCaching.MeetTheTeam);           
            var photoType = result?.photoType.toString().toLowerCase();
            if(result.teamInfo) {
            if(result.teamInfo.photoDescription && result.teamInfo.squarePhotoUrl){
            $(".right-video-section").empty();
            $(".right-video-section").append(
            `
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <h2 class="primary-theme-clr text-xlg primary-title">${result.teamInfo.name}</h2>
                        <p class="body-text grey-clr">${result.teamInfo.photoDescription .replace(/(\\n|\r\n|\r|\n)/g, '<br>')}</p>
                        
                    </div>
                    <div class="col-md-6">
                        <div class="video-container">
                            <img src="${result.teamInfo.squarePhotoUrl}" alt="${result.teamInfo.altTag ? result.teamInfo.altTag : result.teamInfo.name}" class="img-fluid" height="319" width="570">
                        </div>
                    </div>
                </div>
            </div>
                `);
            }
        }
        if(result.teamSize != 0){
            if(result.teamSize > 6){
                $('.small-team').addClass("hidden");
                $('.team-grid').removeClass("hidden");
                $.each(result.staffPhotoInfo, function( key:any, value:any ) {
                    var profileImage = photoType == "circle" ? value.circularPhotoUrl : value.squarePhotoUrl;
                    var titleHtml = value.title ? `<p class="grey-clr body-text js-designation-txt" aria-hidden="true">${value.title}</p>` : "";
                    var photoDesc = value.photoDescription ? convertHTML(value.photoDescription) : "";
                individualTeamDetails.push(`
                <li class="col-md-4 col-sm-6 col-12 image-card">
                        <a href="#" class="image-wrap" data-toggle="modal" data-target="#jsEmpBioModal" data-bio='${photoDesc}'>
                            <img src="${profileImage ? profileImage : "/us/en-us/brand/_assets/images/default-team.png"}" class="js-img-src" alt="${value.altTag ? value.altTag : value.name}">
                            ${value.name != null ? (
                                `<h3 class="sub-title js-sub-title" aria-hidden="true">${value.name}</h3>`
                            ):''}
                            ${titleHtml}
                         </a>
                    </li>
                `);
                });
                $.each(result.employeePhotoInfo, function( key:any, value:any ) {
                    var profileImage = photoType == "circle" ? value.franchiseProfileEmployeeBioDetail.circularPhotoUrl : value.franchiseProfileEmployeeBioDetail.squarePhotoUrl;
                    var titleHtml = value.franchiseProfileEmployeeBioDetail.title ? `<p class="grey-clr body-text js-designation-txt" aria-hidden="true">${value.franchiseProfileEmployeeBioDetail.title}</p>` : "";
                    var photoDescription = value.description ? convertHTML(value.description) : "";
                individualTeamDetails.push(`
                <li class="col-md-4 col-sm-6 col-12 image-card">
                         <a href="#" class="image-wrap" data-toggle="modal" data-target="#jsEmpBioModal" data-bio='${photoDescription}'>
                            <img src="${profileImage ? profileImage : "/us/en-us/brand/_assets/images/default-team.png"}" class="js-img-src" alt="${value.franchiseProfileEmployeeBioDetail.altTag ? value.franchiseProfileEmployeeBioDetail.altTag : value.displayName}">
                            ${value.displayName != null ? (
                                `<h3 class="sub-title js-sub-title" aria-hidden="true">${value.displayName}</h3>`
                            ):''}

                            ${titleHtml}
                         </a>
                    </li>
                `);
                });
                $('#team-items').empty();
                $('#team-items').append(individualTeamDetails);
				paginationMeetTheTeam();
            }
            else{
                $('.team-grid').addClass("hidden");
                $('.small-team').removeClass("hidden");
                var imageType = photoType == "circle" ? "rounded-circle" : "";
                var brandIcon = $("#brand-icon").val();
                var brandIconAlt = $("#brand-icon-alt").val();
                $.each(result.staffPhotoInfo, function( key:any, value:any ) {
                    var profileImage = photoType == "circle" ? value.circularPhotoUrl : value.squarePhotoUrl;
                    var titleHtml = value.title ? `<p class="secondary-title body-text primary-grey-clr">${value.title}</p>` : "";
                    var photoDescriptionHtml = value.photoDescription ? `<div>${value.photoDescription}</div>` : "";
                    individualTeamDetails.push(`
                <div class="row align-items-center col-row">
                    <div class="col-md-3 left-wrap">
                        <div class="img-section">
                            <img class="img-fluid ${imageType}" src="${profileImage ? profileImage : "/us/en-us/brand/_assets/images/default-team.png"}" alt="${value.altTag ? value.altTag : value.name}" width="229" height="221">
                            <div class="top-img border-img"><img class="img-fluid" src="${brandIcon}" alt="${brandIconAlt}" width="32" height="23"></div>
                        </div>
                    </div>
                    <div class="col-md-9 right-wrap">
                        <div class="text-container">
                            ${value.name != null ? (
                                `<h2 class="primary-title text-xlg primary-theme-clr">${value.name}</h2>`
                            ):''}
                            ${titleHtml}
                            ${photoDescriptionHtml}
                        </div>
                    </div>
                </div>
                `);
                });

                $.each(result.employeePhotoInfo, function( key:any, value:any ) {
                    var profileImage = photoType == "circle" ? value.franchiseProfileEmployeeBioDetail.circularPhotoUrl : value.franchiseProfileEmployeeBioDetail.squarePhotoUrl;
                    var titleHtml = value.franchiseProfileEmployeeBioDetail.title ? `<p class="secondary-title body-text primary-grey-clr">${value.franchiseProfileEmployeeBioDetail.title}</p>` : "";
                    var descriptionHtml = value.description ? `${value.description}` : "";
                    individualTeamDetails.push(`
                <div class="row align-items-center col-row">
                    <div class="col-md-3 left-wrap">
                        <div class="img-section">
                            <img class="img-fluid ${imageType}" src="${profileImage ? profileImage : "/us/en-us/brand/_assets/images/default-team.png"}" alt="${value.franchiseProfileEmployeeBioDetail.altTag ? value.franchiseProfileEmployeeBioDetail.altTag : value.displayName}" width="229" height="221">
                            <div class="top-img border-img"><img class="img-fluid" src="${brandIcon}" alt="${brandIconAlt}" width="32" height="23"></div>
                        </div>
                    </div>
                    <div class="col-md-9 right-wrap">
                        <div class="text-container">
                            ${value.displayName != null ? (
                                `<h2 class="primary-title text-xlg primary-theme-clr">${value.displayName}</h2>`
                            ):''}
                            ${titleHtml}
                            ${descriptionHtml}
                        </div>
                    </div>
                </div>
                `);
                });
                $('.small-team .container').empty();
                $('.small-team .container').append(individualTeamDetails);
            }
            $(".image-wrap").on("click", function(this:any,event:any) {
                let modal = $('#jsEmpBioModal'),
                    data = $(this);
                modal.find(".js-emp-name").text(data.find('.js-sub-title').text());
                modal.find(".js-emp-image").attr("src", data.find('.js-img-src').attr('src'));
                modal.find(".js-emp-designation").text(data.find('.js-designation-txt').text());
                modal.find(".js-emp-bio").html(data.attr('data-bio'));
            });	
        }
        else{
            $('.small-team').addClass("hidden");
            $('.team-grid').addClass("hidden");
            $('.pagination-section').addClass("hidden");
        }
    }catch (error: any)
    {
    console.error(`Error in fetching Meet the Team api response Error message: ${error}`);
    }
}