import { $ } from "../third-party-imports";
import {getDisplayServicesList} from './getDisplayServicesList';
import {getDisplayServicesResults} from './getDisplayServicesResults';
import { stopLoader } from "../util/stopLoader";
import {getFullAttributeApi} from '../util/getFullAttributeApi'
import {getFranchiseWebServices} from './getFranchiseWebServices';
import {getServiceTypeString} from './getServiceTypeString';
import {generateServiceType} from './generateServiceType';
import { gtmCustomEventLeadFlow } from "../util/gtm-custom-event-lead-flow";
import { gtmEventBrandWise } from "../scheduler/gtm-event-brand";
export function disPlayListOfServices(postalCode:any){
    getDisplayServicesList(postalCode,4,function(result:any,status:any,xhr:any){
        $(".zipcode-service-search").text(postalCode);
        getDisplayServicesResults(result,postalCode);
        stopLoader();
        getFullAttributeApi(window.objOSGlobal.requestBodyValues.WebLocationId,'');
        getFranchiseWebServices(window.objOSGlobal.requestBodyValues.WebLocationId,function(result:any){
          var serviceTypeList = [];  
          var serviceTypeObject = [];
          if(result.length !==0){
          var data = JSON.parse(result.serviceMenu);
          var options =  data["country"][0].brand[0].webLocation[0].option;
          for(let i=0;i<options.length;i++){
            var serviceTypeValue =  getServiceTypeString(options[i].name);  
            serviceTypeObject.push({name:options[i].name,value:serviceTypeValue});
          }
          serviceTypeObject.sort(function(a, b) {
               return parseInt(a.value) - parseInt(b.value);
           });
          for(let i=0;i<serviceTypeObject.length;i++){
            var serviceList = generateServiceType(serviceTypeObject[i].name,serviceTypeObject[i].value);
            serviceTypeList.push(serviceList);
          } 
          serviceTypeList.reverse();
          $('.search-services-form .radio-group-wrap').empty();
          $('.search-services-form .radio-group-wrap').append(serviceTypeList);
          $(".search-services-form input[value='4']").prop('checked', true);
        }
      });
    let brandCode = $("#conceptCode").val().toUpperCase();
    if(gtmEventBrandWise(brandCode)){
      let leadObj = {
        event:'custEv_LocalDataLayer',
        locationID: window.objOSGlobal.requestBodyValues.WebLocationId,
        locationDBA:window.objOSGlobal.paramsUI.doingBusinessAs,
        locationZipcode:window.objOSGlobal.requestBodyValues.PostalCode,
        licenseNumber:window.objOSGlobal.requestBodyValues.licenseNumber
         }
        gtmCustomEventLeadFlow(leadObj);
    }
    },function(data:any, status:any, error:any){
        stopLoader();
        $(".tab-pane.fieldset").removeClass("active");
        $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${postalCode}</span>`);
        $(".no-service-screen").addClass("active");
  });
}