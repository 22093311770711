export function digitalDataScript(){
    try {
    var brandData:any = $('script[type="application/ld+json"]').text();
    if(brandData){
        brandData  = brandData.replace(/\n/g, '');
        brandData = brandData.replace(/\s+/g, ' ').trim();
        brandData = JSON.parse(brandData);
        let brandName = $('#conceptCode').val();
        if(window.digitalData){
            window.digitalData.push({'name':brandData.name,'branchof':brandName,'telephone':brandData.telephone});
        }
    }
 }catch(error){
    console.log("error while parsing brand data")
 }
}