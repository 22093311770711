//Please note all values are in minutes
export const apiCaching = {
    TestimonyAndReview : 60,
    DynamicMenu : 60,
    IsnRedirectConfig: 60,
    POSSystemLookup : 5,
    FranchisePOSLookup : 5,
    FullAttributeResponse : 60,
    LocateLocationApiWithRoundRobinTrue: 60,
    TechAvailability: 2,
    SiteConfig: 60,
    BrandJsonConfig: 60,
    HereMapConfig: 60,
    Genericleadsources:60,
    syncCleoLeadSources:60,
    StateResponse:60,
    PhotoGalleryAPi:60,
    MeetTheTeam:60,
  };