// Strip Page Number from the URL

export function stripPageNumber(pageURL: string)
{
    if (pageURL.includes('/page'))
    {
        return pageURL = pageURL.substring(0, pageURL.indexOf('/page'));
    }
    else
    {
        return pageURL;
    }
}
 