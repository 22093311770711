import {$} from "../third-party-imports";
export function estimateFromConfirmationCall() {
    $("#div_property_details").hide();
    let Name:any =  sessionStorage.getItem("estFirstName") + " " + sessionStorage.getItem("estLastName")
    let Email:any = sessionStorage.getItem("estEmail");
    let Phone:any = sessionStorage.getItem("estPhone");
    let state:any =  sessionStorage.getItem('estState');
    if(sessionStorage.getItem("estAddress2")) {
        var propertyAddress:any
            propertyAddress = sessionStorage.getItem("estAddress") + " " + sessionStorage.getItem("estAddress2") + ",<br/> " + sessionStorage.getItem("estCity") + " " + state+" "+  sessionStorage.getItem("estPostalCode"); 
    } else {
            propertyAddress = sessionStorage.getItem("estAddress") + ",<br/> " + sessionStorage.getItem("estCity") + " " + state+" "+ sessionStorage.getItem("estPostalCode");
    }
    $($('.thankyou-sec .contact-form p')[0]).text(Name);
    $($('.thankyou-sec .contact-form p')[1]).text(Email);
    $($('.thankyou-sec .contact-form p')[2]).text(Phone);
    $($('.thankyou-sec .contact-form p')[3]).html(propertyAddress);
    
    if(sessionStorage.getItem("estBedRoom") !== ''){
        $("#div_property_details").show();
        let propertyDetails:any = sessionStorage.getItem("estBedRoom") + " Bedrooms" + ", " + sessionStorage.getItem("estBaathRoom") + " Baths" + ", " + sessionStorage.getItem("estSqft") + " " +"sq. ft." ;
        $($('.thankyou-sec .contact-form p')[4]).text(propertyDetails);
    }
};


export function thankYouPageConfirmation(){
    let Name:any =  sessionStorage.getItem("estFirstName") + " " + sessionStorage.getItem("estLastName")
    let Email:any = sessionStorage.getItem("estEmail");
    let Phone:any = sessionStorage.getItem("estPhone");
    let propertyAddress:any

    if(sessionStorage.getItem("estAddress2")) {
            propertyAddress = sessionStorage.getItem("estAddress") + " " + sessionStorage.getItem("estAddress2") + ",<br/>" + sessionStorage.getItem("estCity") + ", " + sessionStorage.getItem("estState") + " " +  sessionStorage.getItem("estPostalCode"); 
    } else {
            propertyAddress = sessionStorage.getItem("estAddress") + ",<br/>" + sessionStorage.getItem("estCity") + ", " + sessionStorage.getItem("estState") + " " + sessionStorage.getItem("estPostalCode");
    }
    $('.appointment-contact .mly-thank-you-name').text(Name);
    $('.appointment-contact .mly-thank-you-email').text(Email);
    $('.appointment-contact .mly-thank-you-phone').text(Phone);
    $('.appointment-contact .mly-thank-you-service-address').html(propertyAddress);
}