
import { userProfile } from "../ui";

type params = { endpoint?: any, token?: any, neighborlyUserId?: any, method?: any };

export function callApiWithAccessToken({ endpoint, token, neighborlyUserId, method }: params)
{
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);

    const options = {
        method: method ? method : "GET",
        headers: headers
    };
//return fetch(endpoint + neighborlyUserId, options)
    return fetch(endpoint, options)
        .then(response => response.json())
        .then(response =>
        {
            userProfile(JSON.stringify(response));
        }).catch(error =>
        {
            // console.log("Error calling the Web api:", error);
        });
}
