import { stripTrailingSlash } from "./stripTrailingSlash";
import { stripPageNumber } from "./stripPageNumber";

// Strip URL

export function stripURL(pageURL: string)
{
    var pageURL = pageURL;
    pageURL = stripTrailingSlash(pageURL);
    pageURL = stripPageNumber(pageURL);
    return pageURL;
}
 