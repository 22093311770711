import { apiConfig, croEnableForFindLocation, emptyAddressFilds, getStateFun } from "..";
import { $ } from "../third-party-imports"
import { startLoader } from "../util/startLoader";
import { stopLoader } from "../util/stopLoader"
import { nearBySearchLocation } from "./near-by-location";
export function GetLocateLocation(sDeviceType: any, isMob=false)
{
    var sAddressParam: any = "";
    let searched_postalcode:any = "";
    
    if (sDeviceType === "desk")
    {
        let zip = isMob?  $("#zipcode_mobile").val():$("#zipcode").val();
        let zipcode: any =  zip ? zip : $('#locationSearch_change_loc').val().trim();
        searched_postalcode = zipcode;
        let domainName = location.hostname.toLowerCase();
        var isWebSite = domainName.indexOf("-ca-") === -1 && domainName.indexOf(".ca") === -1 && domainName.indexOf("-ca.") == -1 ? "us" : "ca";
        if (isWebSite == 'ca')
        {
            let isUsZipcodeRegex = new RegExp(/^([0-9]{5})$/);
            if (isUsZipcodeRegex.test(zipcode))
            {
                zipcode = zipcode;
            } else
            {
                const new_regex =   new RegExp(/^([A-Za-z][0-9][A-Za-z])$/);
                if(new_regex.test(zipcode)) {
                  //This is fsa
                  zipcode = zipcode+",canada";
                }else {
                  zipcode = zipcode.indexOf(" ") == -1 ? zipcode.replace(/^(.{3})(.*)$/, "$1 $2") : zipcode;
                }
            }
        }
        if (zipcode.length)
        {
            sAddressParam = zipcode;
        }
    } else if (sDeviceType === "mob")
    {
        let inputLocalzip: any = $('#inputLocal-zip').val();
        searched_postalcode = inputLocalzip;
        if (inputLocalzip.length)
        {
            sAddressParam = $("#inputLocal-zip").val();
        }
    }
    var locateApiConfig = apiConfig.LocateLocationApiWithRoundRobinFalse;
    var encodeAddress = encodeURIComponent(sAddressParam);
    var apiUrl = locateApiConfig.replace("sAddressParamValue", encodeAddress);
    startLoader();
    $.ajax({
        url: apiUrl,
        type: "GET",
        dataType: 'json',
        headers: {
            'Cache-Control': 'max-age=0'
        },
        success: async function (result: any, status: any, xhr: any)
        {
            let brandConceptCode = $('#conceptCode').val();
            let countryCode = $('#countryCode').val();
            if (result.length > 0)
            {
                stopLoader();
                var sRedirectURL = "";
                if (result[0].locationWebsiteUrl != null)
                {
                    if (result[0].locationWebsiteUrl?.indexOf("https://") == -1)
                        sRedirectURL = "https://" + result[0].locationWebsiteUrl;
                    else
                        sRedirectURL = result[0].locationWebsiteUrl;
                    window.location.href = sRedirectURL;
                }
                localStorage.setItem('alternateConcept', result[0].alternateConcept);
                localStorage.setItem('franchiseWebLocationId', result[0].franchiseWebLocationId);
            
            } else
            {
                if(croEnableForFindLocation()){
                    window.objOSGlobal.requestBodyValues.isFindYourLocation = true;
                    nearBySearchLocation(searched_postalcode);

                }else if(brandConceptCode?.toLowerCase() === 'mrr' && countryCode?.toLowerCase() === 'ca'){
                    mrrFindMyLocation()
                }
                else
                {
                    stopLoader();
                    $('.tab-pane.fieldset').removeClass('active');
                    $("#steptwo .cust-opus-h2-cls:first").html($("#hdnSD_NoResultsMsg").html()); 
                    $("#steptwo").addClass('active');
                    $('.scheduler-modal .modal-content').addClass('banner-wizard');
                    $('#js-schedulerDialog').modal();
                    $(".noservice-zipcode").html(searched_postalcode);
                }
            }

        },
        error: function (data: any)
        {
            stopLoader();
            var duplicate = data.responseJSON;
            if (duplicate.Message == "Full address is needed to narrow down results")
            {
                getStateFun();
                emptyAddressFilds();
                $('.tab-pane.fieldset').removeClass('active');
                $('#stepthree').addClass('active');
                $('#js-schedulerDialog').modal();
                $(".address-bumper-screen .back").hide();
                $('.address-bumper-screen input[name="inputZipCode1"]').val(sAddressParam);
            }
        }
    });
}


function mrrFindMyLocation(){
    let inputVal = "";
    if ($("#local_weblocationId").length > 0)
    {
        inputVal = $('#locationSearch_change_loc').val() != "" ? $('#locationSearch_change_loc').val() : $("#inputLocal-zip").val();
    }
    else
    {
        inputVal = $("#inputLocal-zip").val() != "" ? $("#inputLocal-zip").val() : $("#header_btn_zipcode, #main_btn_zipcode_change_loc,#header_btn_zipcode_mobile").siblings('input').val();
    }
    localStorage.setItem("locateCityname", inputVal);
    localStorage.setItem("isFSAEntered", "true");
    let sRedirectURL = window.location.origin + "/locations/";
    window.location.href = sRedirectURL;
}

export function findMyLocationRedirect(websiteUrl: any)
{
    stopLoader();
    let sRedirectURL = "";
    if (websiteUrl != null)
    {
        if (websiteUrl?.indexOf("https://") == -1){
            sRedirectURL = "https://" + websiteUrl;
        }
            sRedirectURL += websiteUrl;
            window.location.href = sRedirectURL;
    }
}