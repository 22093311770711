import {s3upload } from "..";
import { LeadConfirmation } from "../services/LeadConfirmation";
import { $ } from "./../third-party-imports";

export function confimationScreen(){
    if($("#brandName").val() === 'grounds-guys') {
        if($("#serviceDetails").valid()){
            if ($("#files1").length > 0 ){
                s3upload(function (){
                    LeadConfirmation();
                })
            }else{
                LeadConfirmation();
            }
        }  
    } else {
        if ($("#files1").length > 0 ){
            s3upload(function (){
                LeadConfirmation();
            })
        }else{
            LeadConfirmation();
        }
    }
}   