import {$} from "../third-party-imports";

$(function (){
    const conceptCode = $("#conceptCode").val()?.toLowerCase();
    const countryCode =  $("#countryCode").val()?.toLowerCase();
    $.validator.setDefaults({
        errorElement: "div",
        errorPlacement: function (error:any, element:any)
        {
            error.addClass("help-block invalid-feedback");
            if(element[0].name === "preferredcontact"){
                error.insertAfter(element.parents(".preferred-method"));
            }
            else{
                error.insertAfter(element);
            }
            
        },
        highlight: function (element:any, errorClass:any, validClass:any)
        {
            $(element)
                .parents(".form-group")
                .addClass("has-error")
                .removeClass("has-success");
        },
        unhighlight: function (element:any, errorClass:any, validClass:any)
        {
            $(element)
                .parents(".form-group")
                .addClass("has-success")
                .removeClass("has-error");
        },
    });
    $.validator.addMethod(
        "defaultInvalid",
        function (value:any, element:any)
        {
            return value != element["defaultValue"];
        },
        ""
    );
	$("#media-app-form").validate({
        onkeyup:function(this:any,element:any){
            $(element).valid();
             var checkeForm = this.checkForm();
             if(checkeForm){
                $(".media-app-form .media-app-btn").removeClass("disble-btn").prop("disabled", false);
             }else{
                $(".media-app-form .media-app-btn").addClass("disble-btn").prop("disabled", true);
             }
        },
        rules: {
            inputPhnNumber: {
                required: true,
                minlength: 14,
                maxlength: 14,
            }
        },
        messages: {
            inputPhnNumber: "Please enter a valid phone number",
        },
    });
    
    let contactFormRules = {
        inputFname: {
            required: true,
        },
        inputLname: {
            required: true,
        },
        inputPhone: {
            required: true,
            minlength: 14,
            maxlength: 14,
        },
        inputZip: {
            required: true,
            addressZip: true
        },
        inputEmail: {
            required: true,
            email: true,
        },
    }
    if(window.brandData?.enable_contact_us_form_address_feilds || $('#brandName').val().toLowerCase()==='mr-rooter'){
        Object.assign(contactFormRules, {
            inputAddress: {
                required: true,
            },
            inputCity: {
                required: true,
                cityValidate:true,
            },
            inputState: {
                required: true,
            },
        });
    }
	$("#ContactForm").validate({
        onkeyup:function(this:any,element:any){
            if($(element).val() !=""){
                $(element).valid();
            }
            var checkeForm = this.checkForm();
            if(checkeForm){
                $('#ContactForm .js-contact-btn').removeClass('disble-btn').prop('disabled', false);
            }else{
                $('#ContactForm .js-contact-btn').addClass('disble-btn').prop('disabled', true);
            }
        },
		rules:contactFormRules,
        messages:{
            inputEmail:"Enter valid email address",
            inputPhone: "Please enter a 10-digit phone number",
        },
	});

//  Short Form Validation start
    $("#short-form").validate({
        onkeyup:function(this:any,element:any){
            if($(element).val() !=""){
                $(element).valid();
            }
        },
		rules:{
            shortFormName: {
                required: true,
            },
            shortFormEmail: {
                required: true,
                email: true,
            },
            shortFormPhoneNumber: {
                required: true,
				minlength: 14,
                maxlength: 14,
            },
            shortFormZipCode: {
                required: true,
                addressZip:true
            },
			
        },
        messages:{
            shortFormEmail:"Enter valid email address",
            shortFormPhoneNumber: "Please enter a 10-digit phone number",
        },
	});
//  Short Form Validation end

    $("#contactusform").validate({
        onkeyup:function(this:any,element:any){
            if($(element).val() !=""){
               $(element).valid();
             }
             var checkeForm = this.checkForm();
             if(checkeForm){
                $(".contact-information-screen .js-next-btn").removeClass("disble-btn");
                $(".contact-information-screen .js-next-btn").prop("disabled", false);
             }else{
                $(".contact-information-screen .js-next-btn").addClass("disble-btn");
                $(".contact-information-screen .js-next-btn").prop("disabled", true);
             }
        },
        rules: {
            inputFirstName: {
                required: true,
            },
            inputLastName: {
                required: true,
            },
            email: {
                required: true,
                email: true,
            },
            inputPhnNumber: {
                required: true,
				minlength: 14,
                maxlength: 14,
            },
			
        },
		messages: {
            inputPhnNumber: "Please enter a valid phone number",
        },
    });

    $(".cro-contact-form").validate({
        rules: {
            inputFirstName: {
                required: true,
            },
            inputLastName: {
                required: true,
            },
            email: {
                required: true,
                email: true,
            },
            inputPhnNumber: {
                required: true,
				minlength: 14,
                maxlength: 14,
            },
            inputAddress: {
                required: true,
            },
            inputcity: {
                required: true,
				cityValidate:true,
            },
            inputstate: {
                required: true,
            },
            inputZipCode: {
                required: true,
                addressZip:true
            },
            squareFeet: {
                required: '#inputOneTime:checked',
            },
            bedrooms: {
                required: '#inputOneTime:checked',
            },
            bathrooms: {
                required: '#inputOneTime:checked',
            }
        },
		messages: {
            inputPhnNumber: "Please enter a valid phone number",
        },
    });


    $("#service_contact_information").validate({
        rules: {
            mrhFirstName: {
                required: true,
            },
            mrhLastName: {
                required: true,
            },
            mrhEmail: {
                required: true,
                email: true,
            },
            mrhPhoneNumber: {
                required: true,
				minlength: 14,
                maxlength: 14,
            },
            mrhAddress: {
                required: true,
            },
            mrhCity: {
                required: true,
				cityValidate:true,
            },
            mrhState: {
                required: true,
            },
            mrhZipCode: {
                required: true,
                addressZip:true
            }
			
        },
		messages: {
            mrhPhoneNumber: "Please enter a valid phone number",
        },
    });

    $("#serviceAddress").validate({
        onkeyup:function(this:any,element:any){
            if($(element).val() !=""){
                $(element).valid();
            }
            var checkeForm = this.checkForm();
            if(checkeForm){
                $(".address-details-screen .js-next-btn").removeClass("disble-btn");
                $(".address-details-screen .js-next-btn").prop("disabled", false);
            }else{
                $(".address-details-screen .js-next-btn").addClass("disble-btn");
                $(".address-details-screen .js-next-btn").prop("disabled", true);
            }
        },
        rules: {
            inputAddress: {
                required: true,
            },
            inputcity: {
                required: true,
				cityValidate:true,
            },
            inputstate: {
                required: true,
            },
            inputZipCode: {
                required: true,
                addressZip:true
            }
        }
    });
    $("#serviceAddressBumper").validate({
        rules: {
            inputAddressBumper: {
                required: true,
            },
            inputcity1: {
                required: true,
				cityValidate:true
            },
            inputstate1: {
                required: true,
            },
            inputZipCode1: {
                required: true,
                addressZip:true
            }
        }
    });
    $(".location-services").validate({
        onkeyup:function(this:any,element:any) {
            $(element).valid();
            checkLocationZip(this,'#locationSearch','locationSearch-error');
        },
        rules: {
            locationSearch: {
                required: true,
                addressZip:true
            }
        }
    });

    $(".location-services-change").validate({
        onkeyup:function(this:any,element:any) {
            $(element).valid();
            checkLocationZip(this,'#locationSearch_change_loc','locationSearch_change_loc-error');
        },
        rules: {
            locationSearch_change_loc: {
                required: true,
                addressZip:conceptCode === 'mrr' && countryCode === 'ca' ? false: true,
            }
        },
        messages:{
            locationSearch_change_loc:conceptCode === 'mrr' && countryCode === 'ca' ? "Postal Code or City cannot be blank.": "This field is required."
        }
    });
   

    $("#warrantyDetails").validate({
        rules: {
            serviceListQuery: {
                required: true,
            },
            brandAppliance: {
                required: true,
            }
        }
    });
    $("#aboutHome").validate({
        rules: {
            squareFeet: {
                required: true,
            },
            bedrooms: {
                required: true,
            },
            bathrooms: {
                required: true,
            }
        }
    });
    $(".banner-sec .navbar-form").validate({
        rules: {
            inputZip: {
                required: true,
                addressZip:true
            }
        }
    });
    $(".cta-wrapper .cta-form").validate({
        rules: {
            inputZip: {
                required: true,
                addressZip:true
            }
        }
    });
    // $(".banner-sec .banner-btn .locate-form.form-group").validate({
    //     rules: {
    //         search: {
    //             required: true,
    //             addressZip:true
    //         }
    //     }
    // });

    ($("#donation-form") as any).validate({
        rules: {
            donationFirstname: {
                required: true,
                noSpace:true
            },
            donationLastname: {
                required: true,
                noSpace:true
            },
            donationEmail: {
                required: true,
                email: true,
            },
            donationAddress1:{
                required: true,
                noSpace:true
            },
            donationCity:{
                required: true,
                noSpace:true,
                cityValidate:true
            },
            donationState:{
                required: true,
            },
            donationPostalCode:{
                required: true,
                donationZip:true
            }
			
        },
		messages: {
            donationFirstname:{required: "Please enter your first name.",},
            donationLastname:{required: "Please enter your last name."},
            donationEmail:{required:"Please enter your email address."},
            donationAddress1:{required:"Please enter your address."},
            donationCity:{required:"Please enter your city."},
            donationState:{required:"Please select your state."},
            donationPostalCode:{required:"Please enter your zip code."}
        },
    });

    ($("#donationformstep2") as any).validate({
        rules: {
            donationnationwidelist: {
                required: '#mm-yes-shelter-browser:checked',
            },
            donationstatewidelist: {
                required: '#mm-yes-shelter-state:checked',
            },
            comnationwide: {
                required: '#shelter-mm-no-browser:checked',
            },
            comStatewide: {
                required: '#shelter-mm-no-state:checked',
            }
        }
    });

    ($("#estimateForm") as any).validate({
        onkeyup:function(this:any,element:any){
            if($(element).val() !=""){
               $(element).valid();
             }
        },
        rules: {
            estimateFirstName: {
                required: true
            },
            estimateLastName: {
                required: true,
                noSpace:true
            },
            estimateEmail: {
                required: true,
                email: true,
            },
            estinputPhnNumber: {
                required: true,
				minlength: 14,
                maxlength: 14,
            },
            estimateInputAddress:{
                required: true
            },
            estimateInputCity2:{
                required: true,
                cityValidate:true
            },
            estimateInputState:{
                required: true,
            },
            estimateInputZipCode:{
                required: true,
                addressZip:true
            },
            estimateSqf: {
                required: $("#estimateSqf")[0] ? true : false,
            },
            estimateBedroomsOne: {
                required: $("#estimateSqf")[0] ? true : false,
            },
            estimateBedroomsTwo: {
                required: $("#estimateSqf")[0] ? true : false,
            },
        },
		messages: {
            estimateEmail:"Enter Valid Email address",
            estinputPhnNumber: "Please enter a valid phone number",
        },
    });
    ($("#blogContactForm") as any).validate({
        onkeyup:function(this:any,element:any){
            if($(element).val() !=""){
               $(element).valid();
             }
        },
        rules: {
            blogInputFirstNameTxt: {
                required: true
            },
            blogInputLastNameTxt: {
                required: true,
            },
            blogInputEmailAddress: {
                required: true,
                email: true,
            },
            blogInputPhone: {
                required: true,
				minlength: 14,
                maxlength: 14,
            },
            blogInputZip:{
                required: true,
                addressZip:true
            },
            blogAboutUs: {
                required:  true,
            },
            blogInputComments: {
                maxlength: 1000,
            },
            blogInputState: {
                required:  $("#blogInputState")[0] ? true :  false,
            },
            blogInputAddressTxt: {
                required:  $("#blogInputAddressTxt")[0] ? true :  false,
            },
            blogInputCityTxt: {
                required:  $("#blogInputCityTxt")[0] ? true :  false,
            },
            blogInputSquareFeetTxt: {
                required:  $("#blogInputCityTxt")[0] ? true :  false,
            },
            blogBedroomsTxt: {
                required:  $("#blogBedroomsTxt")[0] ? true :  false,
            },
            blogBathroomsTxt: {
                required:  $("#blogBathroomsTxt")[0] ? true :  false,
            },
            blogExistingCustomer: {
                required:  $("#blogExistingCustomer")[0] ? true :  false,
            },
            
        },
		messages: {
            blogInputFirstNameTxt:"First Name is required",
            blogInputLastNameTxt:"Last Name is required", 
            blogInputEmailAddress:"Enter Valid Email address",
            blogInputPhone: "Please enter a valid phone number",
        },
    });
    ($("#insuranceForm") as any).validate({
       rules: {
            insuredFirstName: {
                required: true
            },
            insuredLastName: {
                required: true
            },
            insuredEmailAddress: {
                required: true,
                email: true,
            },
            insuredPrimaryPhone: {
                required: true,
				minlength: 14,
                maxlength: 14,
            },
            insuredStreetAddress:{
                required: true
            },
            insuredCity:{
                required: true,
                cityValidate:true
            },
            insuredState:{
                required: true,
            },
            insuredZipCode:{
                required: true,
                donationZip:true
            },
            infoInsuranceCompany:{
                required: true,
            },
            infoInsurancePolicyNumber:{
                required: true,
            },
            infoInsuranceDeductible:{
                required: true,
                maxlength: 10,
            },
            infoVehicleYear:{
                required: true,
                minlength: 4,
                maxlength: 4,
            },
            infoVehicleMake:{
                required: true,
            },
            infoVehicleModel:{
                required: true,
            },
            infoAgentName:{
                required: true,
            },
            infoAgencyDirectPhoneNumber:{
                required: true,
				minlength: 14,
                maxlength: 14,
            },
            infoAgencyEmail:{
                required: true,
                email: true,
            },
            preferredcontact:{
                required: true,
            },
        },
		messages: {
            insuredFirstName:{required: "Please enter first name.",},
            insuredLastName:{required: "Please enter last name."},
            insuredEmailAddress:{required:"Please enter email address."},
            insuredPrimaryPhone:{
                required:"Please enter phone number.",
                minlength: "Please enter a valid phone number.",
                maxlength: "Please enter a valid phone number.",
            },
            insuredStreetAddress:{required:"Please enter address."},
            insuredCity:{required:"Please enter city."},
            insuredState:{required:"Please select state."},
            insuredZipCode:{required:"Please enter zip code."},
            infoInsuranceCompany:{required:"Please enter insurance company."},
            infoInsurancePolicyNumber:{required:"Please enter insurance policy number."},
            infoInsuranceDeductible:{required:"Please enter insurance deductible."},
            infoVehicleYear:{required:"Please enter vehicle year."},
            infoVehicleMake:{required:"Please enter vehicle make."},
            infoVehicleModel:{required:"Please enter vehicle model."},
            infoAgentName:{required:"Please enter agent's full name."},
            infoAgencyDirectPhoneNumber:{
                required:"Please enter agent's direct phone number.",
                minlength: "Please enter a valid phone number.",
                maxlength: "Please enter a valid phone number.",
            },
            infoAgencyEmail:{required:"Please enter agent's email address."},  
            preferredcontact:{required:"This field is required"} //Please indicate your preferred contact method.
        },
    });


    $("#contactusformMra").validate({
        rules: {
            inputFirstName: {
                required: true,
            },
            inputLastName: {
                required: true,
            },
            email: {
                required: true,
                email: true,
            },
            inputPhnNumber: {
                required: true,
				minlength: 14,
                maxlength: 14,
            },
            inputAddress: {
                required: true,
            },
            inputcity: {
                required: true,
				cityValidate:true,
            },
            inputstate: {
                required: true,
            },
            inputZipCode: {
                required: true,
                addressChangeZip:true
            },
            howDidHereAbout:{
                required: true,
            }
			
        },
		messages: {
            inputPhnNumber: "Please enter a valid phone number",
        },
    });

    $(".request-mobile-sec #change-your-location").validate({
        rules: {
            "inputLocal-zip": {
                required: true,
                addressZip:true
            }
        },
		messages: {
            "inputLocal-zip": "Invalid ZIP/Postal Code",
        },
    });

    $(".mra-service-details-screen #serviceDetails").validate({
        onkeyup:function(this:any,element:any){
            if($(element).val() !=""){
               $(element).valid();
             }
             var checkeForm = this.checkForm();
             if(checkeForm){
                $(".mra-service-details-screen .next").removeClass("disble-btn").prop("disabled", false);
             }else{
                $(".mra-service-details-screen .next").addClass("disble-btn").prop("disabled", true);
             }
        },
        rules: {
            howDidHereAbout:{
                required: true,
            }
        }
    });
    $.validator.addMethod("donationZip", function(this: any, value:any, element:any) {
        return this.optional( element ) || /^([0-9]{5}|[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9])$/.test(value);
    }, 'Invalid ZIP/Postal Code.');

    $.validator.addMethod("noSpace", function(value:any, element:any) { 
        return value == '' || value.trim().length != 0;  
    }, "Space not allowed");
    let customZipErrorMsg;
    if($("#conceptCode").val() === 'MRR' && $("#countryCode").val() === 'ca') {
        customZipErrorMsg = 'Invalid Postal Code'
    }else {
        customZipErrorMsg = 'Invalid ZIP/Postal Code'
    }
    $.validator.addMethod("addressZip", function(this: any, value:any, element:any) {
        let checkCroleadEnable;
        let brandJsonItems = window.objOSGlobal.requestBodyValues?.brandJsonGlobalObj;
        if(brandJsonItems){
           checkCroleadEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match || false ;
        }
        let country = $("#countryCode").val();
        if($("#conceptCode").val() === 'MRR' && country.toLowerCase() === 'ca' || checkCroleadEnable && country.toLowerCase() ==='ca') {
            if(value.length > 3){
                return this.optional( element ) || /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(value.trim());
            }else{
                return this.optional( element ) || /^[A-Za-z]\d[A-Za-z]/.test(value.trim());
            }
            
        }else{
            return this.optional( element ) || /^([0-9]{5}|[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9])$/.test(value.trim());
        }
    }, customZipErrorMsg);

    $.validator.addMethod("addressChangeZip", function(this: any, value:any, element:any) {
        let checkCroleadEnable;
        let brandJsonItems = window.objOSGlobal.requestBodyValues?.brandJsonGlobalObj;
        let country = $("#countryCode").val();
        if(brandJsonItems){
           checkCroleadEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match || false ;
        }
        if(checkCroleadEnable && country.toLowerCase() ==='ca') {
            if (localStorage.getItem('zipcode').toUpperCase() != value.toUpperCase()){
                return this.optional( element ) || /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(value.trim());
            }else{
                return this.optional( element ) || /^[A-Za-z]\d[A-Za-z]/.test(value.trim());
            }
        }else{
            return this.optional( element ) || /^([0-9]{5}|[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9])$/.test(value.trim());
        }
    }, customZipErrorMsg);
	
	$.validator.addMethod("cityValidate", function(this: any, value:any, element:any) {
    return this.optional(element) || /^[a-z][a-z\s]*$/i.test(value);
     }, "Please enter character only");

     $('.cta-wrapper #inputHeroZipCode').on('keyup blur', function() {
        if($(".cta-wrapper .cta-form").valid()){
            $(".cta-wrapper .services-btn").removeClass("disble-btn").prop("disabled", false);
        }else{
            $(".cta-wrapper .services-btn").addClass("disble-btn").prop("disabled", true);
        }
     });

     $('.banner-sec #cta-ser-search').on('keyup blur', function() {
           if( $('.banner-sec #cta-ser-search').val().length !== 0){
               $(".banner-sec #service_modal").removeClass("disble-btn").prop("disabled", false);
           }else{
               $(".banner-sec #service_modal").addClass("disble-btn").prop("disabled", true);
           }
        });


    validateFourZeroZipCode('.banner-sec #cta-ser-search');
    validateFourZeroZipCode('.banner-sec input[name="inputZip"]');
    validateFourZeroZipCode('.cta-wrapper input[name="inputZip"]');
    validateFourZeroZipCode('.header-destkop .locate-form input[name="zipcode"]');
    validateFourZeroZipCode('.header-mobile .locate-form input[name="zipcode"]');
    validateFourZeroZipCode('#blogInputZip');
    validateFourZeroZipCode('#short-form #shortFormZipCode');
    validateFourZeroZipCode('#change-your-location #inputLocal-zip');

    /** fix for the bug 142883 ends here */
     $.validator.addMethod("email", function (this:any ,value: any, element: any){
           return this.optional(element) || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value.trim());
     }, 'Please check email address is invalid.');

     $('#contactusform input').on('blur', function(this:any) {
            $(this).valid();
     });
	 
});

function validateFourZeroZipCode(zipElement:string){
    $(`${zipElement}`).keypress(function(this: any, e: any){
        if (this.value === "0000" && e.which == 48){
        return false;
        }
    });
}
function checkLocationZip(currentForm:any,locationSelector:any,errorSelector:any){
    var checkeForm = currentForm.checkForm();
    if(checkeForm){
        $(locationSelector).removeClass('locationerror');
    }else{
        $(locationSelector).addClass('locationerror');
    }
    var errorElement = document.getElementById(errorSelector);
    if(errorElement){
        errorElement.setAttribute("aria-label", "Invalid ZIP/Postal Code");
    }
}