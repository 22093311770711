import { $ } from "../third-party-imports";
import { apiConfig } from "../config/apiConfig";;
export function getServicesForCategory(postalCode:any,conceptId:any,serviceModifier:any,serviceCategoryId:any,successHandler:any,errorHandler:any){
    var webloc = $('#weblocationId').val();
    var conceptId = window.objOSGlobal.requestBodyValues.ConceptId;
    var query = webloc ? ('FranchiseWebLocationId='+webloc): ('ConceptId='+conceptId);
    var franchiseConcept =  webloc ? 'franchiseWebLocations' : 'concepts'
    $.ajax({
        type: "GET",
        url:apiConfig.serviceAggregatesSearch+franchiseConcept+'/service-aggregates/search?'+encodeURI(query)+'&ServiceModifier='+serviceModifier+'&ZipCode='+postalCode+'&apikey='+window.DEFINE_VALUE.JS_API_KEY+'&ServiceWebCategoryId='+serviceCategoryId,
        contentType: "application/json",
        dataType: 'json',
        success: successHandler,
        error: errorHandler
    });
}