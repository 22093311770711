export function dynamicServiceArrayRowGenrator(cat:any,type:any){
    let serviceTarget = cat.name.replace(/[^A-Z0-9]+/ig, "");
    serviceTarget = serviceTarget+'_' + type.name + '_ServicesMenu';
    let styleCss = columnClass(cat.menu?.length);
    
    return `<div class="js-mega-menu-cont" id="${serviceTarget}" data-catId= "${cat.id}" style="display: none;">
                <nav class="desktop-item" aria-label="breadcrumb">
                    <ul>
                        <li><a href="javascript:void(0)" class="js-back-menu-link">${type.name}</a></li>
                        <li class="active" aria-current="page"><a class="nav-title" href="${cat.link}">${cat.name}</a></li>
                    </ul>
                </nav>
                <div class="inner-menu-row">
                    <div class="menu-col">
                        <button type="button" class="back-arrow-link js-back-menu-link" aria-label="Back to main menu">Back</button>
                        <span class="overview-link ${cat.name}"><a class="overview-heading-link" href="${cat.link}">${cat.name}</a></span>
                        <ul class="inner-menu-list col-list ${type.name} ${cat.id}" data-id="${cat.id}" style="${styleCss} column-gap: 20px;margin-bottom: 1rem;">
                        </ul>
                    </div>
                </div>
        </div>`
}

function columnClass(col:any){
    switch(col){
        case 1:
        col =  'column-count:1;';
        break;
        case 2:
        col = 'column-count:2;';
        break;
        default:
        col = 'column-count:3;';
    }
    return col;
}
