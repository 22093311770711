import { addressDetailsScreen } from "../scheduler/addressDetailsScreen";
import { plgContactInformation } from "../scheduler/plg-scheduler-flow";
import { $ } from "../third-party-imports";
import {GetReferenceDetails} from './GetReferenceDetails';
import { addressInfoValOpus, contactInfoOpus} from "./mra-form-information";
import {guyContactInformation} from "../scheduler/guy-os-flow/guy-opt-os-flow";
import { storeCroglobalObjectValue } from "../scheduler/cro-os-flow/cro-os-flow";
export function checkZipLocalSite(nextId:any){
    let conceptCode:any  = $("#conceptCode").val();
    let changeZip = sessionStorage.getItem('changeZip');
    if(conceptCode=='MLY'){
        contactInfoMly();
        let addressParam = storeCroglobalObjectValue();
        if(changeZip){
            addressDetailsScreen(nextId,addressParam);
            sessionStorage.removeItem('changeZip');
            return;
         }else{
             $('[href="#' + nextId + '"]').tab('show');
         }
    }else if(conceptCode=='MRR'){
        let addressParam = getChangesAddressValue();
        if(changeZip){
            addressDetailsScreen(nextId,addressParam);
            sessionStorage.removeItem('changeZip');
            return;
         }else{
             $('[href="#' + nextId + '"]').tab('show');
         }
    }else if(conceptCode=='MRA'){
        contactInfoOpus();
        let addressParam = addressInfoValOpus();
        if(changeZip){
            addressDetailsScreen(nextId,addressParam);
            sessionStorage.removeItem('changeZip');
            return;
         }
    }else if(conceptCode=='PLG'){
        let addressParam = plgContactInformation();
        if(changeZip){
            addressDetailsScreen(nextId,addressParam);
            sessionStorage.removeItem('changeZip');
            return;
        }
    }else if(conceptCode=='GUY'){
        let addressParam = guyContactInformation();
        if(changeZip){
            addressDetailsScreen(nextId,addressParam);
            sessionStorage.removeItem('changeZip');
            return;
        }
    }else if(conceptCode=='ASV' || conceptCode=='FSP'){
        let addressParam = commonAddressInfoVal();
        if(changeZip){
            addressDetailsScreen(nextId,addressParam);
            sessionStorage.removeItem('changeZip');
            return;
        }
    }else if(conceptCode=='MHM' || conceptCode=='HMC'){
        let addressParam = mrhAddressInfoVal();
        if(changeZip){
            addressDetailsScreen(nextId,addressParam);
            sessionStorage.removeItem('changeZip');
            return;
        }
    }else{
        let addressParam = addressInfoVal();
        if(changeZip){
           addressDetailsScreen(nextId,addressParam);
           sessionStorage.removeItem('changeZip');
           GetReferenceDetails();
           return;
        }else{
            $('[href="#' + nextId + '"]').tab('show');
        }
        GetReferenceDetails();
        if($("#conceptCode").val() === 'PLG') {
            $(".service-details-screen .next").removeClass("disble-btn").prop("disabled", true);
        }
        
    }
}

export function contactInfoMly(){
    const firstnameobj = $('.personal-info-form').find('input[name="inputFirstName"]').val();
    const lastnameobj = $('.personal-info-form').find('input[name="inputLastName"]').val(); 
    const emailobj = $('.personal-info-form').find('input[name="email"]').val();
    const mobileNumberobj = $('.personal-info-form').find('input[name="inputPhnNumber"]').val();
    window.objOSGlobal.requestBodyValues.FirstName = firstnameobj;
    window.objOSGlobal.requestBodyValues.LastName = lastnameobj;
    window.objOSGlobal.requestBodyValues.Email = emailobj;
    window.objOSGlobal.requestBodyValues.Phone = mobileNumberobj;
}
export function addressInfoVal(){
    var address = $('.address-details-screen input[name="inputAddress"]').val();
    var apartment = $('.address-details-screen input[name="inputAddress2"]').val();
    var city = $('.address-details-screen input[name="inputcity"]').val();
    var state = $('.address-details-screen #inputstate').val();
    // var addressParamsVal:any = address + "&" + apartment + "&" + city + "&" + state + "&" + window.objOSGlobal.requestBodyValues.PostalCode;
    apartment = apartment ? apartment + " ": "";
    var addressParamsVal:any = address + " " + apartment +city + " " + state + " " + window.objOSGlobal.requestBodyValues.PostalCode;
    window.objOSGlobal.requestBodyValues.Address = address;
    window.objOSGlobal.requestBodyValues.Address2 = apartment;
    window.objOSGlobal.requestBodyValues.City = city;
    window.objOSGlobal.requestBodyValues.State = state;
    return addressParamsVal;
}

export function addressInfoValMly(){
    var address = $('.mly-service-contact-form-screen input[name="inputAddress"]').val();
    var apartment = $('.mly-service-contact-form-screen input[name="inputAddress2"]').val();
    var city = $('.mly-service-contact-form-screen input[name="inputcity"]').val();
    var state = $('.mly-service-contact-form-screen #inputstate').val();
    // var addressParamsVal:any = address + "&" + apartment + "&" + city + "&" + state + "&" + window.objOSGlobal.requestBodyValues.PostalCode;
    apartment = apartment ? apartment + " ": "";
    var addressParamsVal:any = address + " " + apartment +city + " " + state + " " + window.objOSGlobal.requestBodyValues.PostalCode;
    window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = $('#referenceDetails option:selected').val();
    window.objOSGlobal.requestBodyValues.Address = address;
    window.objOSGlobal.requestBodyValues.Address2 = apartment;
    window.objOSGlobal.requestBodyValues.City = city;
    window.objOSGlobal.requestBodyValues.State = state;
    return addressParamsVal;
}


export function getChangesAddressValue(){
    var address = $('.common-conatct-info-screen input[name="inputAddress"]').val();
    var apartment = $('.common-conatct-info-screen input[name="inputAddress2"]').val();
    var city = $('.common-conatct-info-screen input[name="inputcity"]').val();
    var state = $('.common-conatct-info-screen select[name="inputstate"]').val();
    // var addressParamsVal:any = address + "&" + apartment + "&" + city + "&" + state + "&" + window.objOSGlobal.requestBodyValues.PostalCode;
    apartment = apartment ? apartment + " ": "";
    var addressParamsVal:any = address + " " + apartment +city + " " + state + " " + window.objOSGlobal.requestBodyValues.PostalCode;
    window.objOSGlobal.requestBodyValues.Address = address;
    window.objOSGlobal.requestBodyValues.Address2 = apartment;
    window.objOSGlobal.requestBodyValues.City = city;
    window.objOSGlobal.requestBodyValues.State = state;
    return addressParamsVal;
}

function commonAddressInfoVal(){
    var address = $('.contact-information-screen input[name="inputAddress"]').val();
    var apartment = $('.contact-information-screen input[name="inputAddress2"]').val();
    var city = $('.contact-information-screen input[name="inputcity"]').val();
    var state = $('.contact-information-screen select[name="inputstate"]').val();
    // var addressParamsVal:any = address + "&" + apartment + "&" + city + "&" + state + "&" + window.objOSGlobal.requestBodyValues.PostalCode;
    apartment = apartment ? apartment + " ": "";
    var addressParamsVal:any = address + " " + apartment +city + " " + state + " " + window.objOSGlobal.requestBodyValues.PostalCode;
    window.objOSGlobal.requestBodyValues.Address = address;
    window.objOSGlobal.requestBodyValues.Address2 = apartment;
    window.objOSGlobal.requestBodyValues.City = city;
    window.objOSGlobal.requestBodyValues.State = state;
    return addressParamsVal;
}

function mrhAddressInfoVal(){
    var address = $('#service_contact_information input[name="mrhAddress"]').val();
    var apartment = $('#service_contact_information input[name="mrhAddress2"]').val();
    var city = $('#service_contact_information input[name="mrhCity"]').val();
    var state = $('#service_contact_information select[name="mrhState"]').val();
    // var addressParamsVal:any = address + "&" + apartment + "&" + city + "&" + state + "&" + window.objOSGlobal.requestBodyValues.PostalCode;
    apartment = apartment ? apartment + " ": "";
    var addressParamsVal:any = address + " " + apartment +city + " " + state + " " + window.objOSGlobal.requestBodyValues.PostalCode;
    window.objOSGlobal.requestBodyValues.Address = address;
    window.objOSGlobal.requestBodyValues.Address2 = apartment;
    window.objOSGlobal.requestBodyValues.City = city;
    window.objOSGlobal.requestBodyValues.State = state;
    return addressParamsVal;
}