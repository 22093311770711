import {$} from "../../third-party-imports";
import { refreshScheduleData } from "../../util/refreshScheduleData";
import { mrrOsOptimizeFlow } from "../mrr-os-flow/mrr-os-flow";
import { optimizeOsCommonFLow } from "./os-flow-optimize";
export function commonProgressBar(){
    let leadFlow =  window.objOSGlobal.requestBodyValues.IsLeadOnly;
    $('.tab-pane.fieldset').removeClass('active');
    $('.schedule-list').removeClass('schedule-list');
    $('.service-information').addClass("d-none");
    $('.progress, .progressbar').show();
    $('.scheduler-modal .modal-content').removeClass('banner-wizard');
    if(leadFlow){
        onlineCommonLeadFlow();
    }else{
        onlineCalendarFlow();
    }
    window.objOSGlobal.requestBodyValues.conatctInformation = {};
    window.objOSGlobal.requestBodyValues.serviceDetails = {};
    optimizeOsCommonFLow();
    changeZipChecker();
    mrrOsOptimizeFlow();
    $(".js-next-btn").removeClass("disble-btn").prop("disabled", false);

}
export function onlineCommonLeadFlow(){
    $('#mrr-emergency-box').removeClass('d-flex');
    $('a[href="#step1"]').parent().addClass('d-none');
    $('.progressbar li').css('width', 'calc(100%/2)');
    $('.progress-bar').css({ width:'4.2%' });
    $('.common-conatct-info-screen').addClass('active');
    $('.progressbar li').css('width', 'calc(100%/2)');
    $('.progress,.progressbar').addClass('lead-flow-scenario');
    $('#mrr-emergency-box').removeClass('d-flex');

}

function onlineCalendarFlow(){
    $('a[href="#step1"]').parent().removeClass('d-none')
    refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
    $('.progressbar li').css('width', 'calc(100%/3)');
    $('.calendar-screen').addClass('active');
    $('.progress-bar').css({ width:'3.2%' });
    $('.progress,.progressbar').removeClass('lead-flow-scenario');
    if($('#conceptCode').val()==='MRR'){
        $('#mrr-emergency-box').addClass('d-flex');
    }
}

function changeZipChecker (){
    $('input[name="inputZipCode"]').val(window.objOSGlobal.requestBodyValues.PostalCode);
    $('input[name="inputZipCode"]').keyup(function (this: any){
    var value:any = $(this).val();
    var regex:any = new RegExp(/^([0-9]{5}|[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9])$/);
    if($("#conceptCode").val() === 'MRR' && $("#countryCode").val() === 'ca'){
        if (localStorage.getItem('zipcode').toUpperCase() != value.toUpperCase()){
            regex = new RegExp(/^([0-9]{5}|[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9])$/);
        }else{
            regex = /^[A-Za-z]\d[A-Za-z]/.test(value);
            regex = new RegExp(/^[A-Za-z]\d[A-Za-z]/);
        }
    }
    if (regex.test(value)){
        window.objOSGlobal.requestBodyValues.PostalCode = value;
        if (localStorage.getItem('zipcode').toUpperCase() != value.toUpperCase()){
            sessionStorage.setItem('changeZip', value);
        }else{
            sessionStorage.removeItem('changeZip');
        }
    }
});
}