import { addOptInBookingApi } from "../util/util";

export function bookingApiPayload()
{
    const objOSGlobal = window.objOSGlobal;
    let host = window.location.hostname.toLowerCase();
    objOSGlobal.requestBodyValues.ReferringURL = document.referrer;
    objOSGlobal.requestBodyValues.CurrentPage = host;
    const options: any = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    if (objOSGlobal.requestBodyValues.IsLeadOnly == true)
    {
        objOSGlobal.requestBodyValues.JobPromisedDate = new Date().toLocaleDateString("en-US", options);
    }
    else if (objOSGlobal.requestBodyValues.Warranty == true)
    {
        objOSGlobal.requestBodyValues.JobPromisedDate = new Date().toLocaleDateString("en-US", options);
    }
    else
    {
        objOSGlobal.requestBodyValues.JobPromisedDate = new Date(objOSGlobal.paramsUI.selectedDate).toLocaleDateString("en-US", options);
    }
    if (window.objOSGlobal.requestBodyValues.ConceptId === "17")
    {
        objOSGlobal.requestBodyValues.ServiceText = "Interested in Property Management";
        objOSGlobal.requestBodyValues.DayTimePhoneNumber = objOSGlobal.requestBodyValues.Phone;
        objOSGlobal.requestBodyValues.ZipCode = objOSGlobal.requestBodyValues.PostalCode;

    }
    if(host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) 
        {
            objOSGlobal.requestBodyValues.IsTest = true;
        }else {
            objOSGlobal.requestBodyValues.IsTest = false;
        }
    let hrefUrl = window.location.href;
    if (hrefUrl.indexOf('?utm_campaign') > -1)
    {
        let urlPath = new URL(hrefUrl);
        let urlAfterQueryStr = urlPath.search;
        urlAfterQueryStr = urlPath.search.replace('?', '');
        objOSGlobal.requestBodyValues.CampaignName = urlAfterQueryStr;
    } else
    {
        objOSGlobal.requestBodyValues.CampaignName = 'N/A';
    }

    objOSGlobal.requestBodyValues.ReferringURL = document.referrer;
    objOSGlobal.requestBodyValues.CurrentPage = host;

    if (window.objOSGlobal.requestBodyValues.ConceptId == "3" || window.objOSGlobal.requestBodyValues.ConceptId == "4")
    {
        window.objOSGlobal.requestBodyValues.EmailOptOut = true;
    }
    objOSGlobal.requestBodyValues.VendorId = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj[0].vendorId;
    objOSGlobal.requestBodyValues.VendorName = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj[0].vendorName;
    if($("#brandName").val() == "portland-glass"){
        let plgForm = false;
        if(window.objOSGlobal.requestBodyValues.irvingrewardsform || window.objOSGlobal.requestBodyValues.plgcontactform){
            plgForm = true;
        }
        if(!plgForm){
            var serviceDetail = objOSGlobal.requestBodyValues.Message != "" ? ",\r\n Service Details: " + objOSGlobal.requestBodyValues.Message : "";
            var howhearus = $('select[name="howDidHereAbout"] option:selected').val() != -1 ? ",\r\n How did you hear about us: " + $('select[name="howDidHereAbout"] option:selected').text() : ""; 
            objOSGlobal.requestBodyValues.Note = objOSGlobal.requestBodyValues.Note != "" ? objOSGlobal.requestBodyValues.Note + ", " : objOSGlobal.requestBodyValues.Note;
            objOSGlobal.requestBodyValues.Note = objOSGlobal.requestBodyValues.Note + "Service Type: " + $(".glass-radio-boxs input:radio[name=customerType]:checked").val() + serviceDetail + howhearus;
            try
            {
                const preferMethodText = sessionStorage.getItem('PreferredCommunicationText');
                if (preferMethodText)
                {
                    window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.requestBodyValues.Note ? window.objOSGlobal.requestBodyValues.Note.concat(", " + preferMethodText.toString()) : preferMethodText.toString();
                }
            } catch (error)
            {
                console.log("not found add_preffered_method_of_contact", error);
            }
        }
    }

    objOSGlobal.requestBodyValues.ReferralTypeId = convertNullString(objOSGlobal.requestBodyValues.ReferralTypeId);
    objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = convertNullString(objOSGlobal.requestBodyValues.NeighborlyLeadSourceId);
    objOSGlobal.requestBodyValues.LeadSourceId = convertNullString(objOSGlobal.requestBodyValues.LeadSourceId);
    //US-317398: Populate LeadSourceID in booking message Start
    if($("select[name='howDidHereAboutUs']").val()){
        objOSGlobal.requestBodyValues.LeadSourceId=$("select[name='howDidHereAboutUs']").val();
    }
    else if($('#referenceDetails').val()){
        objOSGlobal.requestBodyValues.LeadSourceId=$('#referenceDetails').val();
    }   
    //US-317398: Populate LeadSourceID in booking message End
    addOptInBookingApi();
    if(window?.objOSGlobal?.requestBodyValues?.title){
        objOSGlobal.requestBodyValues.Note = objOSGlobal.requestBodyValues.Note +", "+ window.objOSGlobal.requestBodyValues.title;
    }
    const payload = {
        "FranchiseId": "",
        "HasSameDayScheduling": objOSGlobal.requestBodyValues.HasSameDayScheduling ? objOSGlobal.requestBodyValues.HasSameDayScheduling : true,
        "IsBetaTester": objOSGlobal.requestBodyValues.IsBetaTester ? objOSGlobal.requestBodyValues.IsBetaTester : false,
        "Comments": objOSGlobal.requestBodyValues.Comments ? objOSGlobal.requestBodyValues.Comments : "",
        "IsLocalized": objOSGlobal.requestBodyValues.IsLocalized ? objOSGlobal.requestBodyValues.IsLocalized : true,
        "ConceptId": objOSGlobal.requestBodyValues.ConceptId ? Number(objOSGlobal.requestBodyValues.ConceptId) : 0,
        "UseFranchiseProfileOptInForPOSScheduling": objOSGlobal.requestBodyValues.UseFranchiseProfileOptInForPOSScheduling ? objOSGlobal.requestBodyValues.UseFranchiseProfileOptInForPOSScheduling : true,
        "IsFranchiseOptedIntoPOSScheduling": objOSGlobal.requestBodyValues.IsFranchiseOptedIntoPOSScheduling ? objOSGlobal.requestBodyValues.IsFranchiseOptedIntoPOSScheduling : true,
        "C4ChannelTypeId": objOSGlobal.requestBodyValues.C4ChannelTypeId ? objOSGlobal.requestBodyValues.C4ChannelTypeId : 0,
        "ConceptCalledId": objOSGlobal.requestBodyValues.ConceptCalledId ? Number(objOSGlobal.requestBodyValues.ConceptCalledId) : 0,
        "ConceptName": objOSGlobal.requestBodyValues.ConceptName ? objOSGlobal.requestBodyValues.ConceptName : "",
        "ConceptCode": objOSGlobal.requestBodyValues.ConceptCode ? objOSGlobal.requestBodyValues.ConceptCode : "",
        "C4LeadsEnabled": objOSGlobal.requestBodyValues.C4LeadsEnabled ? objOSGlobal.requestBodyValues.C4LeadsEnabled : true,
        "CanceledJobInformation": objOSGlobal.requestBodyValues.CanceledJobInformation ? objOSGlobal.requestBodyValues.CanceledJobInformation : "",
        "VendorId": objOSGlobal.requestBodyValues.VendorId ? objOSGlobal.requestBodyValues.VendorId : "",
        "NationalLeadId": objOSGlobal.requestBodyValues.NationalLeadId ? objOSGlobal.requestBodyValues.NationalLeadId : "",
        "IsTest": objOSGlobal.requestBodyValues.IsTest,
        "VendorName": objOSGlobal.requestBodyValues.VendorName ? objOSGlobal.requestBodyValues.VendorName : "",
        "WebLocationId": objOSGlobal.requestBodyValues.WebLocationId ? Number(objOSGlobal.requestBodyValues.WebLocationId) : 0,
        "C4CallCenterId": objOSGlobal.requestBodyValues.C4CallCenterId ? objOSGlobal.requestBodyValues.C4CallCenterId : 0,
        "DayTimePhoneNumber": objOSGlobal.requestBodyValues.DayTimePhoneNumber ? objOSGlobal.requestBodyValues.DayTimePhoneNumber : "",
        "ZipCode": objOSGlobal.requestBodyValues.ZipCode ? objOSGlobal.requestBodyValues.ZipCode : "",
        "Note": objOSGlobal.requestBodyValues.Note ? objOSGlobal.requestBodyValues.Note : "",
        "FirstName": objOSGlobal.requestBodyValues.FirstName ? objOSGlobal.requestBodyValues.FirstName : "",
        "LastName": objOSGlobal.requestBodyValues.LastName ? objOSGlobal.requestBodyValues.LastName : "",
        "Email": objOSGlobal.requestBodyValues.Email ? objOSGlobal.requestBodyValues.Email.trim() : "",
        "Phone": objOSGlobal.requestBodyValues.Phone ? objOSGlobal.requestBodyValues.Phone : "",
        "PhoneType": objOSGlobal.requestBodyValues.PhoneType ? objOSGlobal.requestBodyValues.PhoneType : "",
        "PostalCode": objOSGlobal.requestBodyValues.PostalCode ? objOSGlobal.requestBodyValues.PostalCode : "",
        "City": objOSGlobal.requestBodyValues.City ? objOSGlobal.requestBodyValues.City : "",
        "State": objOSGlobal.requestBodyValues.State ? objOSGlobal.requestBodyValues.State : "",
        "Country": objOSGlobal.requestBodyValues.Country ? objOSGlobal.requestBodyValues.Country : "",
        "Address": objOSGlobal.requestBodyValues.Address ? objOSGlobal.requestBodyValues.Address : "",
        "Address2": objOSGlobal.requestBodyValues.Address2 ? objOSGlobal.requestBodyValues.Address2 : "",
        "LeadSource": objOSGlobal.requestBodyValues.LeadSource ? objOSGlobal.requestBodyValues.LeadSource : "Web",
        "LicenseNumber": objOSGlobal.requestBodyValues.LicenseNumber ? objOSGlobal.requestBodyValues.LicenseNumber : "",
        "WebLocationAccountCode": objOSGlobal.requestBodyValues.WebLocationAccountCode ? objOSGlobal.requestBodyValues.WebLocationAccountCode : "",
        "LeadOrigin": objOSGlobal.requestBodyValues.LeadOrigin ? objOSGlobal.requestBodyValues.LeadOrigin : "Web",
        "CampaignName": objOSGlobal.requestBodyValues.CampaignName ? objOSGlobal.requestBodyValues.CampaignName : "",
        "ReferringURL": objOSGlobal.requestBodyValues.ReferringURL,
        "CurrentPage": objOSGlobal.requestBodyValues.CurrentPage,
        "Disposition": objOSGlobal.requestBodyValues.Disposition ? objOSGlobal.requestBodyValues.Disposition : "",
        "CallType": objOSGlobal.requestBodyValues.CallType ? objOSGlobal.requestBodyValues.CallType : "",
        "PreferredCommunicationType": objOSGlobal.requestBodyValues.PreferredCommunicationType ? objOSGlobal.requestBodyValues.PreferredCommunicationType : "",
        "EmailOptOut": objOSGlobal.requestBodyValues.EmailOptOut,
        "TagName": objOSGlobal.requestBodyValues.TagName ? objOSGlobal.requestBodyValues.TagName : "",
        "Latitude": objOSGlobal.requestBodyValues.Latitude ? objOSGlobal.requestBodyValues.Latitude : 0,
        "Longitude": objOSGlobal.requestBodyValues.Longitude ? objOSGlobal.requestBodyValues.Longitude : 0,
        "LocationType": objOSGlobal.requestBodyValues.LocationType ? objOSGlobal.requestBodyValues.LocationType : "",
        "IsGoogleAddress": objOSGlobal.requestBodyValues.IsGoogleAddress ? objOSGlobal.requestBodyValues.IsGoogleAddress : true,
        "GoogleCalendarEventId": objOSGlobal.requestBodyValues.GoogleCalendarEventId ? objOSGlobal.requestBodyValues.GoogleCalendarEventId : "",
        "GoogleCalendarEmail": objOSGlobal.requestBodyValues.GoogleCalendarEmail ? objOSGlobal.requestBodyValues.GoogleCalendarEmail : "",
        "ServiceText": objOSGlobal.requestBodyValues.ServiceText ? objOSGlobal.requestBodyValues.ServiceText : "",
        "ServiceID": objOSGlobal.requestBodyValues.ServiceID ? objOSGlobal.requestBodyValues.ServiceID : 0,
        "Warranty": objOSGlobal.requestBodyValues.Warranty ? objOSGlobal.requestBodyValues.Warranty : false,
        "TimeSlotTypeId": objOSGlobal.requestBodyValues.TimeSlotTypeId ? objOSGlobal.requestBodyValues.TimeSlotTypeId : 0,
        "MakeId": objOSGlobal.requestBodyValues.MakeId ? objOSGlobal.requestBodyValues.MakeId : 0,
        "LeadSourceId": objOSGlobal.requestBodyValues.LeadSourceId ? objOSGlobal.requestBodyValues.LeadSourceId : 0,
        "SecondaryLeadSourceId": objOSGlobal.requestBodyValues.SecondaryLeadSourceId ? objOSGlobal.requestBodyValues.SecondaryLeadSourceId : 0,
        "ServiceTypeProblem": objOSGlobal.requestBodyValues.ServiceTypeProblem ? objOSGlobal.requestBodyValues.ServiceTypeProblem : "",
        "OverrideJobPromisedDate": objOSGlobal.requestBodyValues.OverrideJobPromisedDate ? objOSGlobal.requestBodyValues.OverrideJobPromisedDate : "",
        "ServiceSkuId": objOSGlobal.requestBodyValues.serviceSkuId ? objOSGlobal.requestBodyValues.serviceSkuId : 0,
        "ServiceSkuIds": objOSGlobal.requestBodyValues.ServiceSkuIds,
        "IsEstimate": objOSGlobal.requestBodyValues.IsEstimate,
        "EstimateHouseStyle": objOSGlobal.requestBodyValues.EstimateHouseStyle ? objOSGlobal.requestBodyValues.EstimateHouseStyle : "",
        "EstimateSquareFeet": objOSGlobal.requestBodyValues.EstimateSquareFeet ? objOSGlobal.requestBodyValues.EstimateSquareFeet : "",
        "EstimateBedrooms": objOSGlobal.requestBodyValues.EstimateBedrooms ? objOSGlobal.requestBodyValues.EstimateBedrooms : "",
        "EstimateBathrooms": objOSGlobal.requestBodyValues.EstimateBathrooms ? objOSGlobal.requestBodyValues.EstimateBathrooms : "",
        "EstimatePets": objOSGlobal.requestBodyValues.EstimatePets ? objOSGlobal.requestBodyValues.EstimatePets : "",
        "CCFirstName": objOSGlobal.requestBodyValues.CCFirstName ? objOSGlobal.requestBodyValues.CCFirstName : "",
        "CCLastName": objOSGlobal.requestBodyValues.CCLastName ? objOSGlobal.requestBodyValues.CCLastName : "",
        "CCType": objOSGlobal.requestBodyValues.CCType ? objOSGlobal.requestBodyValues.CCType : "",
        "CCSuffix": objOSGlobal.requestBodyValues.CCSuffix ? objOSGlobal.requestBodyValues.CCSuffix : "",
        "CCToken": objOSGlobal.requestBodyValues.CCToken ? objOSGlobal.requestBodyValues.CCToken : "",
        "CCExpirationDate": objOSGlobal.requestBodyValues.CCExpirationDate ? objOSGlobal.requestBodyValues.CCExpirationDate : "",
        "CCComment": objOSGlobal.requestBodyValues.CCComment ? objOSGlobal.requestBodyValues.CCComment : "",
        "IsLeadOnly": objOSGlobal.requestBodyValues.IsLeadOnly,
        "TimeBlock": {
            "ConceptId": objOSGlobal.requestBodyValues.ConceptId ? objOSGlobal.requestBodyValues.ConceptId : 0,
            "GoogleCalendarEventId": objOSGlobal.requestBodyValues.GoogleCalendarEventId ? objOSGlobal.requestBodyValues.GoogleCalendarEventId : "",
            "GoogleCalendarEmail": objOSGlobal.requestBodyValues.GoogleCalendarEmail ? objOSGlobal.requestBodyValues.GoogleCalendarEmail : "",
            "TimeSlotTypeId": objOSGlobal.requestBodyValues.TimeSlotTypeId ? objOSGlobal.requestBodyValues.TimeSlotTypeId : 0,
            "ServiceSkuId": objOSGlobal.requestBodyValues.serviceSkuId ? objOSGlobal.requestBodyValues.serviceSkuId : 0,
            "IsEstimate": objOSGlobal.requestBodyValues.IsEstimate,
            "JobPromisedDate": objOSGlobal.requestBodyValues.JobPromisedDate ? objOSGlobal.requestBodyValues.JobPromisedDate : "",
            "IsAvailable": objOSGlobal.requestBodyValues.IsAvailable ? objOSGlobal.requestBodyValues.IsAvailable : true,
            "IsFranchiseInPOS": objOSGlobal.requestBodyValues.IsFranchiseInPOS ? objOSGlobal.requestBodyValues.IsFranchiseInPOS : true,
            "DisplayText": objOSGlobal.requestBodyValues.DisplayText ? objOSGlobal.requestBodyValues.DisplayText : ""
        },
        "OverrideCSRName": objOSGlobal.requestBodyValues.OverrideCSRName ? objOSGlobal.requestBodyValues.OverrideCSRName : "",
        "OverrideComment": objOSGlobal.requestBodyValues.OverrideComment ? objOSGlobal.requestBodyValues.OverrideComment : "",
        "SyncTenantName": objOSGlobal.requestBodyValues.SyncTenantName ? objOSGlobal.requestBodyValues.SyncTenantName : "",
        "IsEmergencyCall": objOSGlobal.requestBodyValues.IsEmergencyCall ? objOSGlobal.requestBodyValues.IsEmergencyCall : false,
        "CallId": objOSGlobal.requestBodyValues.CallId ? objOSGlobal.requestBodyValues.CallId : "",
        "IsFranchiseInPOS": objOSGlobal.requestBodyValues.IsFranchiseInPOS ? objOSGlobal.requestBodyValues.IsFranchiseInPOS : true,
        "CustomerType": objOSGlobal.requestBodyValues.CustomerType ? objOSGlobal.requestBodyValues.CustomerType : "",
        "NeighborlyAccountId": objOSGlobal.requestBodyValues.NeighborlyAccountId ? objOSGlobal.requestBodyValues.NeighborlyAccountId : 0,
        "NeighborlyLeadSourceId": objOSGlobal.requestBodyValues.NeighborlyLeadSourceId,
        "JobFrequency": objOSGlobal.requestBodyValues.JobFrequency ? objOSGlobal.requestBodyValues.JobFrequency : "",
        "JobFrequencyDetail": objOSGlobal.requestBodyValues.JobFrequencyDetail ? objOSGlobal.requestBodyValues.JobFrequencyDetail : "",
        "Year": objOSGlobal.requestBodyValues.Year ? objOSGlobal.requestBodyValues.Year : 0,
        "Make": objOSGlobal.requestBodyValues.Make ? objOSGlobal.requestBodyValues.Make : "",
        "Model": objOSGlobal.requestBodyValues.Model ? objOSGlobal.requestBodyValues.Model : "",
        "BodyStyle": objOSGlobal.requestBodyValues.BodyStyle ? objOSGlobal.requestBodyValues.BodyStyle : "",
        "VIN": objOSGlobal.requestBodyValues.VIN ? objOSGlobal.requestBodyValues.VIN : "",
        "LicensePlateNumber": objOSGlobal.requestBodyValues.LicensePlate ? objOSGlobal.requestBodyValues.LicensePlate : "",
        "WindshieldRepair": objOSGlobal.requestBodyValues.WindshieldRepair ? objOSGlobal.requestBodyValues.WindshieldRepair : "",
        "HeadlightRestoration": objOSGlobal.requestBodyValues.HeadlightRestoration ? objOSGlobal.requestBodyValues.HeadlightRestoration : "",
        "ServiceRequested": window.objOSGlobal.requestBodyValues.serviceRequested  ? window.objOSGlobal.requestBodyValues.serviceRequested : [],
        "LeadAdditionalNotes": {
            "LeadAdditionalNoteLabel": objOSGlobal.requestBodyValues.LeadAdditionalNoteLabel ? objOSGlobal.requestBodyValues.LeadAdditionalNoteLabel : "",
            "LeadAdditionalNoteText": objOSGlobal.requestBodyValues.LeadAdditionalNoteText ? objOSGlobal.requestBodyValues.LeadAdditionalNoteText : ""
        },
        "FormOfPayment": objOSGlobal.requestBodyValues.FormOfPayment ? objOSGlobal.requestBodyValues.FormOfPayment : "",
        "InsuranceCompany": objOSGlobal.requestBodyValues.InsuranceCompany ? objOSGlobal.requestBodyValues.InsuranceCompany : "",
        "DeductibleAmount": objOSGlobal.requestBodyValues.DeductibleAmount ? objOSGlobal.requestBodyValues.DeductibleAmount : 0,
        "PolicyNumber": objOSGlobal.requestBodyValues.PolicyNumber ? objOSGlobal.requestBodyValues.PolicyNumber : "",
        "ScheduleID": objOSGlobal.requestBodyValues.ScheduleID ? objOSGlobal.requestBodyValues.ScheduleID : "",
        "optIn":objOSGlobal?.requestBodyValues?.optIn
    };
    if($("#brandName").val() == "portland-glass"){
        payload["message"] = objOSGlobal.requestBodyValues.Message;
    }
    return payload;
}

function convertNullString(inputVal:any){
    if(inputVal=="null"){
        inputVal = "";
    }
    return inputVal;
}