import { nestLocalStorage } from "../../config";

export const isRememberMe = () =>
{
    if (!nestLocalStorage.accessToken)
    {
        return false;
    }
    let decodeData = nestLocalStorage.decodedAccessToken;
    return decodeData.isRememberMe === "True";
}