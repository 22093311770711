import {$} from '../third-party-imports';
export function selectCategoryTab() {
    $("#jsCategoryTab button").on("click", function() {
        $(this).tab('show');
        $('.tab-content').show();
    });
    let viewportWidth = $(window).width();
    if (viewportWidth <= 767) {
        $('#jsCategorySelect').on('change', function() {
            let dropdown = $('#jsCategorySelect').val();
            //first hide all tabs again when a new option is selected
            $('.tab-content').hide();
            //then show the tab content of whatever option value was selected
            $('#' + "categoryTab" + dropdown).show().removeAttr('hidden');
        });
    }
}