
import { HEADERS } from "./api";

type params = { endpoint?: any, neighborlyUserId?: any, method?: any, socialProfileDomain?: any }
export function updateLogin({ endpoint, neighborlyUserId, method, socialProfileDomain }: params)
{
    const requestPayload = {
        neighborlyUserId,
        neighborlyChannelId: 1,
        socialProfileDomain: socialProfileDomain,
        loginActionTypeId: 1,
        loginActionStatusId: 1,
        loginActionDetails: 'Login'
    };
    const options = {
        headers: HEADERS,
        method: method,
        body: JSON.stringify(requestPayload)
    };

    fetch(endpoint, options)
        .then(response => response.json())
        .then(response =>
        {
        }).catch(error =>
        {
            // console.log("Error calling the Web api:", error);
        });

}
