import { $ } from "../third-party-imports"
import { getNblyMicroServiceApiHeaders } from "../auth/api";
import { apiConfig } from "../config";
import { getNblyApiHeaders } from "../auth/api";



export function generateOtpNumber(phoneNumber: any, type: any, generateHandler: any)
{
    var payload = {
        "NeighborlyUserId": 0, //profileVars.neighborlyUserId,
        "ContactTypeId": 1,
        "userPhoneNumber": phoneNumber,
        "OtpGenerateType": type
    };
    $.ajax({
        url: apiConfig.editPhoneApi + '/GenerateOTP'+"?"+getNblyApiHeaders(),
        data: JSON.stringify(payload),
        headers: getNblyMicroServiceApiHeaders(),
        type: "POST",
        contentType: "application/json",
        success: generateHandler,
        error: function (xhr, status, error)
        {
            // console.log(error);
        }
    });
}
