
import { $ } from "../third-party-imports"

export function getCurrentContext()
{
    $('.blog-txt .link-text').click(function (e)
    {
        localStorage.setItem('current_context_url', window.location.href);
    });
}
 