export function convertHTML(str: any) {
    let replacements = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '""': "&quot;",//THIS PROBLEM ME MUCH
      "'": "&apos;",
      "<>": "&lt;&gt;",
    }
    return str.replace(/(&|<|>|""|'|<>)/gi, function(val: any) {
      return replacements[val];
    });
    }