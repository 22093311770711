
import { $ } from "../third-party-imports";
import { getFranchiseWeblocationID } from '../util/getFranchiseWeblocationID';
import { getFranchiseWebServices } from '../services/getFranchiseWebServices';
import { apiCall, mraOnlineFormSubmit, mraOnlineScheduleFlowSubmitForm, mrhs3upload, refreshScheduleData, startLoader, stopLoader } from "../util";
import { gtmCustomEventLeadFlow } from "../util/gtm-custom-event-lead-flow";
import { servieModal } from "../util/get-brands-json";
import { makeActive, mrh_AddressInfoValOpus, mrh_Form_Information, schedulerState } from ".";
import { mollyMaidEstimate } from "./mollyMaidEstimate";
import { gtmEventBrandWise } from "./gtm-event-brand";
import { confimationScreen } from "./confirmation-secreen";
import { bookingApiPayload } from "./booking-api-payload";
import { bookingApiSuccessHandlerData } from "./booking-api-success-handler";
import { bookingFailureHandlerData } from "./booking-api-failure-handler";
import { bookingApiConfirmationScreen } from "./booking-api-confirmation-screen";
import { brandJsonConfimationScreen } from "../util/brand-json-call";
import { confirmationClickEvent } from "../util/confirmation-click-event";
import { onlineThreeProgressBar } from "./online-three-progressbar";
import { Get_Diagnostic_Fee_API_MRA } from "./getDiagnosticFeeApiMra";
import { storeCroglobalObjectValue } from "./cro-os-flow/cro-os-flow";
export function addressDetailsScreen(nextId:any,addressParam:any){
    let checkConcepCode = $("#conceptCode").val().toUpperCase();
    let newZip:any;
    startLoader();
    if (localStorage.getItem('zipcode') == window.objOSGlobal.requestBodyValues.PostalCode){
        stopLoader();
        $('[href="#' + nextId + '"]').tab('show');
        return;
    }
    if(checkConcepCode ==='MLY'){
        newZip = $('.mly-service-contact-form-screen #inputZipCode').val();
    }else if(checkConcepCode ==='MRA'){
        newZip = $('.mra-contact-information-screen #inputZipCode').val();
    }else if(checkConcepCode ==='PLG'){
        newZip = $('.plg-contact-screen input[name="postal"]').val();
    }else if(checkConcepCode ==='MRR'){
        newZip = $('.common-conatct-info-screen input[name="inputZipCode"]').val();
    }else if(checkConcepCode ==='GUY' || checkConcepCode ==='ASV' || checkConcepCode ==='FSP'){
        newZip = $('.contact-information-screen input[name="inputZipCode"]').val();
    }else if(checkConcepCode ==='MHM' || checkConcepCode ==='HMC'){
        newZip = $('#service_contact_information input[name="mrhZipCode"]').val();
    }else{
        newZip = $('.address-details-screen #inputZipCode').val();
    }
    localStorage.setItem('zipcode', newZip);
    window.objOSGlobal.requestBodyValues.PostalCode = newZip;
    var found = false;
    getFranchiseWeblocationID(addressParam, async function (result: any){
        if(result.length != 0){
            if(window.objOSGlobal.requestBodyValues.ConceptCode == 'FSP' || servieModal.enable){
                let phoneNumber = result[0].websiteTracking ? result[0].websiteTracking.match(/(\d{3})(\d{3})(\d{1}.*)/) : null;
                if($("#brandName").val() !== "molly-maid"){
                    stopLoader();
                }
                $('.schedulerModalLabel').text(result[0].doingBusinessAs);
                if (phoneNumber){
                    phoneNumber = "(" + phoneNumber[1] + ") " + phoneNumber[2] + "-" + phoneNumber[3];
                }
                $('.call-details-text a .phnNumber').text(phoneNumber);
                $('.call-details-text a .phnNumber').attr('href',"tel:"+phoneNumber); 
                window.objOSGlobal.requestBodyValues.WebLocationId = result[0].franchiseWebLocationId;
                window.objOSGlobal.requestBodyValues.FranchiseId = result[0].franchiseId;
                window.objOSGlobal.paramsUI.doingBusinessAs =result[0].doingBusinessAs;
                window.objOSGlobal.requestBodyValues.licenseNumber = result[0].licenseNumber;
                let brandWise = $("#conceptCode").val().toUpperCase();
                if(gtmEventBrandWise(brandWise)){
                    let leadObj = {
                        event:'custEv_LocalDataLayer',
                        locationID: window.objOSGlobal.requestBodyValues.WebLocationId,
                        locationDBA:window.objOSGlobal.paramsUI.doingBusinessAs,
                        locationZipcode:window.objOSGlobal.requestBodyValues.PostalCode,
                        licenseNumber:window.objOSGlobal.requestBodyValues.licenseNumber
                    }
                    gtmCustomEventLeadFlow(leadObj);
                }
                if($("#brandName").val() == "molly-maid"){
                    let isleadOnlyCheck = window.objOSGlobal.requestBodyValues.IsLeadOnly;
                    storeCroglobalObjectValue();
                    if(isleadOnlyCheck){
                        confimationScreen();
                    }else{
                        if(localStorage.getItem("selectedAboutHome") == "onetime"){
                            $('.estimation-list-screen').show();
                            mollyMaidEstimate();
                        }
                        else if(localStorage.getItem("selectedAboutHome") == "recurring"){
                            $('.estimation-list-screen').hide(); 
                            schedulerState.numberOfSlot = 1;
                            await refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);   
                        }
                        $('[href="#' + nextId + '"]').tab("show");
                        $('a[href="#' + nextId + '"]').parents("li").removeClass("disabled");
                        $('a[href="#' + nextId + '"]').parents("li").addClass("active")
                            .siblings("li").removeClass("active").addClass("complete");
                    }
                }else if ($("#conceptCode").val() == "MRA"){
                    mraOnlineFormSubmit();
                    let isleadOnlyCheck = window.objOSGlobal.requestBodyValues.IsLeadOnly;
                    if(isleadOnlyCheck || $("#warranty").is(":checked")){
                        Get_Diagnostic_Fee_API_MRA();
                        $('a[href="#step3"]').parent().addClass("d-none");
                        $('a[href="#step5"]').parent().removeClass("d-none");
                        $('[href="#' + nextId + '"]').tab("show");
                    }else if(apiCall.error){
                        window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
                        startLoader();
                        mraOnlineScheduleFlowSubmitForm();
                    }
                    else{
                        mraOnlineScheduleFlowSubmitForm();
                    }
                }else if ($("#conceptCode").val() === "MHM" ||$("#conceptCode").val()==='HMC' ){
                    let isleadOnlyCheck = window.objOSGlobal.requestBodyValues.IsLeadOnly;
                    mrh_Form_Information();
                    mrh_AddressInfoValOpus();
                    if(isleadOnlyCheck){
                        $(".tab-pane.fieldset").removeClass("active");
                        $('[href="#' + nextId + '"]').tab('show');
                        $('#'+nextId).addClass('active');
                        makeActive(nextId);
                    }else{
                        $('.progress, .progressbar').show();
                        $(".form-progressbar ul").hide();
                        $("#" + nextId + ' .form-progressbar').find('h5').hide();
                        $('#mrhstep3 .js-next-btn').addClass("disble-btn");
                        $('#mrhstep3 .js-next-btn').prop("disabled", true);
                        makeActive(nextId);
                        startLoader();
                        await refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
                        if(!apiCall.error){
                            $(".tab-pane.fieldset").removeClass("active");
                            $('[href="#' + nextId + '"]').tab('show');
                            $('#'+nextId).addClass('active');
                        }
                    }
                }else if ($("#conceptCode").val() == "PLG"){
                    if(phoneNumber ==null ||phoneNumber ==""){
                        let plgNumber = result[0].phoneNumber ? result[0].phoneNumber.match(/(\d{3})(\d{3})(\d{1}.*)/) : null;
                        if (plgNumber){
                            plgNumber = "(" + plgNumber[1] + ") " + plgNumber[2] + "-" + plgNumber[3];
                        }
                        $('.call-details-text a .phnNumber').text(plgNumber);
                        $('.call-details-text a .phnNumber').attr('href',"tel:"+plgNumber); 
                    }
                    let selectServiceType = $(".glass-radio-boxs input:radio[name=customerType]:checked").attr('id');
                    if(selectServiceType !="Glass1"){
                        $('.payment-info .js-next-btn').html("Next "+$('.js-next-btn img')[0].outerHTML);
                        brandJsonConfimationScreen(function(){
                            let url = window.DEFINE_VALUE.bookingEndpoint + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
                            let payload  = bookingApiPayload();
                            bookingApiConfirmationScreen(payload,url,function(requesData:any){
                                bookingApiSuccessHandlerData(requesData);
                            },function(status:any){
                                bookingFailureHandlerData(status)
                            });
                        })
                    }else{
                        onlineThreeProgressBar();
                        $('[href="#' + nextId + '"]').tab('show');
                        $('.payment-info .js-next-btn').html("Submit "+$('.js-next-btn img')[0].outerHTML);
                        confirmationClickEvent();
                    }
                }else{
                    $('[href="#' + nextId + '"]').tab('show');
                }
                return;
            }
        }else{
            $('.progress, .progressbar').hide();
            $(".tab-pane.fieldset").removeClass("active");
            $('.scheduler-modal .modal-content').addClass('banner-wizard');
            if ($("#brandName").val()?.toLowerCase() === "mr-handyman"){
                $('.no-service-screen .head-title').html(`We do not service this ${window.objOSGlobal.requestBodyValues.PostalCode}. Please call for help`);
            }else{
                $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.PostalCode}</span>`);
            }
            $('.no-service-screen').addClass('active');
            sessionStorage.removeItem('changeZip');
            stopLoader();
        }
        if(window.objOSGlobal.requestBodyValues.ConceptCode !== 'FSP' && !
        servieModal.enable){
            window.objOSGlobal.requestBodyValues.WebLocationId = result[0].franchiseWebLocationId;
            window.objOSGlobal.requestBodyValues.FranchiseId = result[0].franchiseId;
            window.objOSGlobal.paramsUI.doingBusinessAs =result[0].doingBusinessAs;
            window.objOSGlobal.requestBodyValues.licenseNumber = result[0].licenseNumber;
            var webLocationId = window.objOSGlobal.requestBodyValues.WebLocationId;
            var serviceName = window.objOSGlobal.requestBodyValues.serviceName;
            getFranchiseWebServices(webLocationId, function (resultData: any){
                if (resultData.length == 0){
                    $('.address-details-screen').removeClass('active');
                    $('.service-details-screen').removeClass('active')
                    $('.scheduler-modal .modal-content').addClass('banner-wizard');
                    $('.no-service-screen').addClass('active');
                    $('.no-service-screen .head-title').text(`We're sorry. We don't currently provide ${serviceName} at this location`);
                    sessionStorage.removeItem('changeZip');
                    stopLoader();
                    return;
                }
                var data = JSON.parse(resultData.serviceMenu);
                var options = data["country"][0].brand[0].webLocation[0].option;
                var modifierObj = options && options.find((item: any) => item.name === window.objOSGlobal.requestBodyValues.modifier);
                
                if (modifierObj && modifierObj?.category){
                    for (let i = 0; i < modifierObj.category.length; i++){
                        var currentMenu = modifierObj.category[i].menu;
                        for (let j = 0; j < currentMenu.length; j++){
                            var serviceFound = false;
                            var serviceMenu = currentMenu[j].service;
                            if(serviceMenu){
                                for(let k=0; k<serviceMenu?.length;k++){
                                    let serviceObj = serviceMenu[k] && serviceMenu.find((itm: any) => itm.id == window.objOSGlobal.requestBodyValues.serviceSkuId);
                                    if (serviceObj){
                                        serviceFound = true;
                                        break;
                                    }
                                }
                                if (serviceFound){
                                    found = true;
                                    break;
                                }
                            }else{
                                let serviceObj = currentMenu[j] && currentMenu.find((itm: any) => itm.id == window.objOSGlobal.requestBodyValues.serviceSkuId);
                                if (serviceObj){
                                    serviceFound = true;
                                    break;
                                }
                            }
                           
                        }
                        if (serviceFound && !serviceMenu){
                            found = true;
                            break;
                        }
                    }
                }
                stopLoader();
                if (found){
                    $('.address-details-screen h2').append("<h5 class='locations' style='color:red;'>This address is not serviced by this location</h5>");
                    $('.address-details-screen h2').append("<h5 class='locations'><a href='#' class='click-schedule'>Click here</a> to be redirected to the appropriate location</h5>");
                    $('.address-details-screen .js-next-btn').hide();
                    sessionStorage.removeItem('changeZip');
                    let brandWise = $("#conceptCode").val().toUpperCase();
                    if(gtmEventBrandWise(brandWise)){
                        let leadObj = {
                            event:'custEv_LocalDataLayer',
                            locationID: window.objOSGlobal.requestBodyValues.WebLocationId,
                            locationDBA:window.objOSGlobal.paramsUI.doingBusinessAs,
                            locationZipcode:window.objOSGlobal.requestBodyValues.PostalCode,
                            licenseNumber:window.objOSGlobal.requestBodyValues.licenseNumber
                        }
                        gtmCustomEventLeadFlow(leadObj);
                    }
                } else{
                    $('.address-details-screen h2').text('Service Address');
                    $('.address-details-screen h5').remove();
                    $('.address-details-screen .js-next-btn').show();
                    $('.address-details-screen').removeClass('active');
                    $('.service-details-screen').removeClass('active')
                    $('.scheduler-modal .modal-content').addClass('banner-wizard');
                    $('.no-service-screen').addClass('active');
                    $('.no-service-screen .head-title').text(`We're sorry. We don't currently provide ${serviceName} at this location`);
                    sessionStorage.removeItem('changeZip');
                }
                
            });
        }
    });
}
