import { $ } from "../third-party-imports";
export function getAddressBumperValue(addresses:any){
    var addressModalObj ={};
    var address1Obj = addresses.find((form:any) => form.name === "Address1");
    var address2Obj = addresses.find((form:any) => form.name === "Address2");
    var cityObj = addresses.find((form:any) => form.name === "City");
    var stateObj = addresses.find((form:any) => form.name === "state");
    var zipcodeObj = addresses.find((form:any) => form.name === "zipcode");
    addressModalObj['addressLine1']=address1Obj.value;
    addressModalObj['addressLine2']=address2Obj.value;
    addressModalObj['city']=cityObj.value;
    addressModalObj['state']=stateObj.value;
    addressModalObj['zip']=zipcodeObj.value;
    setAddressFormValue(addressModalObj);
}
function setAddressFormValue(address:any){
    if(address){
         $("#serviceAddress #inputAddress").val(address.addressLine1);
         $("#serviceAddress #inputAddress2").val(address.addressLine2);
         $("#serviceAddress #inputcity").val(address.city);
         $('#inputstate option[value='+address.state+']').attr('selected','selected');
         $("#serviceAddress #inputZipCode").val(address.zip);
    }
 }

 export function setAddressPrimaryForm(address:any){
    if(address){
       if(address.zip == window.objOSGlobal.requestBodyValues.PostalCode){
        $("#serviceAddress #inputAddress").val(address.addressLine1);
        $("#serviceAddress #inputAddress2").val(address.addressLine2);
        $("#serviceAddress #inputcity").val(address.city);
        $('#inputstate option[value='+address.state+']').attr('selected','selected');
        $("#serviceAddress #inputZipCode").val(address.zip);
        if(address.state !== ''){
            $(".address-details-screen .next").removeClass("disble-btn").prop("disabled", false);
         } else {
            $(".address-details-screen .next").addClass("disble-btn").prop("disabled", true);
         }
       }         
    }
}

export function setAddressFormValueMly(addressObj:any){
    if(addressObj){
         $(".personal-info-form #inputAddress").val(addressObj.address);
         $(".personal-info-form #inputAddress2").val(addressObj.apartment);
         $(".personal-info-form #inputcity").val(addressObj.city);
         $('.personal-info-form option[value='+addressObj.state+']').attr('selected','selected');
         $(".personal-info-form #inputZipCode").val(addressObj.zipCode);
    }
 }