import axios from "axios";
import { apiConfig } from "../config/apiConfig";
// import { $ } from "../third-party-imports";
export function getDisplayServicesList(postalCode:any,serviceModifier:any,successHandler:any,errorHandler:any){
    var query = 'FranchiseWebLocationId='+window.objOSGlobal.requestBodyValues.WebLocationId;
    let apiUrl = apiConfig.serviceWebcategoriesAggregates+encodeURI(query)+'&ServiceModifier='+serviceModifier+'&ZipCode='+postalCode+'&apikey=' + window.DEFINE_VALUE.JS_API_KEY;
    let config = {
        method: 'get',
        url: apiUrl,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    axios(config)
    .then(function (response:any) {
        successHandler(response.data);
    }).catch(function (error:any) {
        errorHandler(error);
    })
}