import { $ } from "../third-party-imports";
import {getFullAttributeApi} from './getFullAttributeApi';
import {chunkArray} from './chunkArray';
export function categoryResults(result:any,postalCode:any){
    var categoryName:any = $("#categoryName").val();
    if(result.length==0){
        $(".tab-pane.fieldset").removeClass("active");
        $(".no-service-screen").addClass("active");
        $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${postalCode}</span>`);
    }
    else{
	    $('.service-list-screen .services-link-content .service-list-row').remove();
		$('.service-list-screen').addClass('active');
        $('.service-list-screen .service-head p.primary-theme-clr').text(categoryName);
		window.objOSGlobal.requestBodyValues.WebLocationId = result[0].franchiseWebLocationId;
        window.objOSGlobal.requestBodyValues.FranchiseId = result[0].franchiseId;
        getFullAttributeApi(window.objOSGlobal.requestBodyValues.WebLocationId,'');
        var splitCount;
        if (result.length < 3) splitCount = 1;
        else splitCount = Math.round(result.length / 3);
        result = chunkArray(result, splitCount);
        var colmd4Elements = $.map(result, function (el:any)
        {
            var sub_ul = $('<ul class="list-unstyled services-link-list">');
            $.each(el, function (index:any, service:any)
            {
                let liEl = window.document.createElement("li");
                liEl.id = `${service.serviceSkuId}`;
                liEl.innerHTML = `<a class="categoryListLink js-next-btn">${service.serviceName}</a>`;
                var sub_li = $(liEl);
                sub_ul.append(sub_li);
            });
            var colmd4 = $('<div class="col-md-4"></div>').append(sub_ul);
            return colmd4;
        })
        var rowClass = $('<div class="row service-list-row" id="service-list-row"> <div> ').append(colmd4Elements);
        $(".service-list-screen .services-link-content").append(rowClass);
    }
}