import { getBlogRow } from "./getBlogRow";
import { $ } from "../third-party-imports"

export function createArticleList()
{
    const blogs = window.g2Data.response.docs;
    const concepCode:any =  $('#conceptCode').val()
    const brandName:any = $("#brandName").val();
    let blogWrapper:any;
    if(concepCode === "FSP" || concepCode === "MLY" 
    || brandName === 'mr-handyman'
    || concepCode === 'GUY' || concepCode === 'ASV' || concepCode === 'MRR' || concepCode === 'MRA') {
    blogWrapper = $('.right-content .list ul');
    }else {
        blogWrapper = $('.blog-section');
    }
    //const blogWrapper = $('#conceptCode').val() != "FSP" ? $('.blog-section') : $('.right-content .list ul');
    blogs.forEach(function (e: any, i: any)
    {
        const row = getBlogRow(e, i);
        blogWrapper.append(row);
    });
}
 