import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiCaching } from "../config/cachingConfig";

export async function dynamicGetMenu(url:any,successHandler:any){
    try{
    const result = await getCachedAPIResponse(url, apiCaching.DynamicMenu, false);
    successHandler(result);
    }catch (error: any)
    {
        console.error(`Error in fetching Dynamic menu Json Error message: ${error}`);
    }
}
