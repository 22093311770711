import { $ } from "../third-party-imports"
import { setFieldsValue } from "./setFieldsValue";
import { getMultipleData } from "./getMultipleData";
import { profileVars } from ".";

export function setMultipleaddress(profile: any)
{
    getMultipleData(profile.accountAddresses, function (value: any)
    {
        return value;
    }, function (value: any, index: any)
    {
        $('.profileAddress .addlink').css("display", "block");
        if (index === 2)
        {
            $('.profileAddress .addlink').css("display", "none");
        }
        $('.profileAddress').append('<div class="text-withedit">' +
            '<div class="label-name">' +
            '<div class="labelTxtBox">' +
            '<div class="address-head">' +
            (value.accountAddressTypeID == 1 ? 'Residential' : 'Business') +
            '</div>' +
            '<div class="labelTxt">' + (value.addressLine1 ? value.addressLine1 + '<br/>' : '') +
            (value.addressLine2 ? value.addressLine2 + '<br/>' : '') + (value.city ? value.city + ', ' : '') + value.state + ' ' + value.zip +
            '</div>' +
            '</div>' +
            (value.primaryAddressID == 1 ?
                '<div class="labelPrimary">' +
                '<div class="primaryWithStar">' +
                '<img src="/us/en-us/_assets/images/Gold-Star-Outline.svg" alt="Gold star" fetchpriority="low" loading="lazy">Primary' +
                '</div>' +
                '</div>' : '') +
            '</div>' +
            '<div class="editBox">' +
            '<a href="#" class="link-btn editLink" data-attr="editAddress" data-index="' + index + '">Edit</a>' +
            '</div>' +
            '</div>'
        );
    }, function (arrayVal: any)
    {
        $(".profileAddress a.editLink").click(function ()
        {
            profileVars.allowSubmit = true;
            profileVars.deleteButton = true;
            $('#profileAddressBtn').removeClass('disble-btn');
            $('#profileAddressBtn').prop("disabled", false);
            $('#js-popupModalDialog span.delete').css("display", "block");
            $("#editAddress .top_txt h3").text('Edit Address');
            $('.form_container_wrapperBox:first-child').hide();
            var index = $(this).attr("data-index");
            var obj = arrayVal[index];
            $('#editAddress').addClass('active');
            setFieldsValue(obj);
            $('#editAddress .delete').attr("data-nblyacntId", obj.neighborlyAccountAddressId);
        });
    });
}
