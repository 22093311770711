import {$} from "../third-party-imports";

export function clickToCallBtn(){
    let viewportWidth = $(window).width();
    if (viewportWidth <= 767)
    {
        let brandJsonData = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj;
        if(brandJsonData){
            if (brandJsonData[0]?.enable_click_to_call && !brandJsonData[0]?.enable_click_to_call_business_hour)
                {
                    clickToCallShow();
                }else if (brandJsonData[0]?.enable_click_to_call && brandJsonData[0]?.enable_click_to_call_business_hour)
                {
                    if (timeOfViewing())
                    {
                        clickToCallShow();
                    } else
                    {
                        clickToCallHide();
                    }
                }else{
                    clickToCallHide();
                }
        }
    }

}

function timeOfViewing(){
    let fullAttrOnLoad = localStorage.getItem("fullAttrOnLoad");
    let isValid = false
    if (fullAttrOnLoad){
        const data = JSON.parse(fullAttrOnLoad);
        if(data){
            isValid = localizationHours(data);
        }
    }
    return isValid;
}


function clickToCallHide(){
    $('.click-tocall-btn-mobile').addClass('d-none');
    $('.tiles-button .box-tiles-col').removeClass('d-none');
    $('.tiles-button .desktop-request-appointment').removeClass('d-none');
    $('.desktop-request-appointment').removeClass('d-none');
    $(".request-mobile-sec.sticky-header a[data-target='#js-schedulerDialog']").parent().removeClass('d-none');
}
function clickToCallShow(){
    $('.click-tocall-btn-mobile').removeClass('d-none');
    $('.tiles-button .box-tiles-col').addClass('d-none');
    $('.tiles-button .desktop-request-appointment').addClass('d-none');
    $('.desktop-request-appointment').addClass('d-none');
    $(".request-mobile-sec.sticky-header a[data-target='#js-schedulerDialog']").parent().addClass('d-none');
    $('.req-ctc-tile-desktop').removeClass('d-none');
    $('.click-tocall-btn-mobile.ctc-tile').addClass('d-none');
}


function localizationHours(data:any){
    try
    {
        let curDate = new Date();
        let curDay = curDate.getDay();
        if (data.hoursofOperation)
        {
            let hoursofOperation = data.hoursofOperation.sort((a: any, b: any) => (a.dayOfWeek > b.dayOfWeek) ? 1 : -1);
            if (hoursofOperation)
            {
                let currentDateValue = hoursofOperation.find((el: any) => el.dayOfWeek === curDay);
                if (currentDateValue)
                {
                    if (currentDateValue.isOpenForNormalHours)
                    {
                        let currentStartTiming = convertMinsToHrsMins(currentDateValue?.startMinutes);
                        let currentEndTiming = convertMinsToHrsMins(currentDateValue?.endMinutes);
                        if (currentEndTiming !== '24')
                        {
                            let localSystemTime = localTimeNow();
                            if (localSystemTime >= currentStartTiming && localSystemTime <= currentEndTiming)
                            {
                                return true;
                            }
                        }
                    }
                }
            }
        }
    } catch (e){
    }
}

function convertMinsToHrsMins(mins:any) {
        let h:any = Math.floor(mins / 60);
        let m:any = mins % 60;
        h = h < 10 ? '0' + h : h;
        m = m < 10 ? '0' + m : m;
        return `${h}:${m}`;
}

function localTimeNow() {
    let d = new Date(),
    h = (d.getHours()<10?'0':'') + d.getHours(),
    m = (d.getMinutes()<10?'0':'') + d.getMinutes();
    return h + ':' + m;
}