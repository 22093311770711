import { $ } from "../third-party-imports";
import {animation} from '../util/refreshScheduleData';
import {refreshScheduleData} from '../util/refreshScheduleData';
import {mrApplianceBrandList} from '../util/mrApplianceBrandList';
// import {getApplianceProblemList} from '../util/util';
import {FspSchedulerFlow} from '../util/FspSchedulerFlow';
import {startLoader} from '../util/startLoader';
import { servieModal } from "../util/get-brands-json";
import { onlineThreeProgressBar } from "../scheduler/online-three-progressbar";
import { plgPreFilledData } from "../scheduler/plg-prefilled-data";
export function storeProTypeID(serviceSkuId:any){
    animation.loaderFlag = true;
    let nextId = $("#service-search-row .pointer").parents('.service-search-screen').next().attr("id");
    let conceptCode:any  = $("#conceptCode").val();
    if(conceptCode.toUpperCase() =='FSP' || servieModal.enable){
        startLoader();
        FspSchedulerFlow(window.objOSGlobal.requestBodyValues.PostalCode);
    }else {
	if($("#brandName").val() != "molly-maid" && $("#brandName").val() != "mr-appliance"){
          if($("#brandName").val() == "portland-glass"){
            plgPreFilledData();
            onlineThreeProgressBar();
            $('[href="#' + nextId + '"]').tab('show');
          }else{
            if(!window.objOSGlobal.requestBodyValues.IsLeadOnly){
                $(".tab-pane.fieldset").removeClass("active");
                refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
                $('[href="#' + nextId + '"]').tab('show');
                $('#'+nextId).addClass('active');
                $('.nav-wizard.progressbar').removeClass('hide-schedule');
            }else{
                $('.tab-pane.fieldset').removeClass('active');
                $('.nav-wizard.progressbar').addClass('hide-schedule');
                $('.contact-information-screen').addClass('active');
                $('.progress-bar').css({ width:'3.2%' });
            }
			if (nextId == "step1") {
				$('.scheduler-modal .modal-content').addClass('banner-wizard');
			}else{
				$('.scheduler-modal .modal-content').removeClass('banner-wizard');
			}
          }
	}else{
         $('.tab-pane.fieldset').removeClass('active');
         $('[href="#' + nextId + '"]').tab('show');
         $('#'+nextId).addClass('active');
		 if (nextId == "step1" || nextId == "step2") {
				$('.scheduler-modal .modal-content').addClass('banner-wizard');
                if($("#brandName").val() == "mr-appliance"){
                    // getApplianceProblemList(serviceSkuId);
                    mrApplianceBrandList((data:any,status:any)=>{
                        var mrBrandList:any = [];
                        $.each(data, function (index:any, element:any){
                            mrBrandList.push('<option id="' + element.makeId + '" value="' + element.makeId + '" data-makeCode="' + element.makeCode + '">'+element.makeName+'</option>');
                        });
                        $('#warrantyDetails #referenceDetails').empty();
                        $('#warrantyDetails #referenceDetails').append('<option value="">- select one</option>')
                        $('#warrantyDetails #referenceDetails').append(mrBrandList);
                    });
                }
			}else if(nextId == "step3"){
                $('.scheduler-modal .modal-content').removeClass('banner-wizard');
                if($("#brandName").val() == "molly-maid"){
                    if(!window.objOSGlobal.requestBodyValues.IsLeadOnly){
                        refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
                        $('[href="#' + nextId + '"]').tab('show');
                    }else{
                        $('.nav-wizard.progressbar').addClass('hide-schedule');
                        $('.progress-bar').css({ width:'3.2%' });
                        $('.about-home-screen').addClass('active');
                    }
                }
            }else{
				$('.scheduler-modal .modal-content').removeClass('banner-wizard');
			}
		}
        return false;
    }
}