import { getBrandDetailsFromBrandJson } from "../util/util";
export async function brandJsonConfimationScreen(bookingApiCall:any){
    
    const result = await getBrandDetailsFromBrandJson();
    if (result.length > 0){
            var items = $.grep(result, function (e: any) { return e.code === $('#conceptCode').val() });
            window.objOSGlobal.requestBodyValues.brandJsonGlobalObj = items;
            window.objOSGlobal.requestBodyValues.VendorId = items[0].vendorId ? items[0].vendorId : "";
            window.objOSGlobal.requestBodyValues.VendorName = items[0].vendorName ? items[0].vendorName : "";
            localStorage.setItem("isTrailingSlash", items[0].includeTrailingSlash);
            bookingApiCall();
        }
}