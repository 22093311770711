import {$} from "../third-party-imports";


$(function(){
    $("#chat-now-btn").click (function(e:any) {
	 
        e.preventDefault();
        
        window.LiveChatWidget.call("maximize");
    
    })
});