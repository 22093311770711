import { mrhDynamicGetMenu } from "./mrh-Dynamic-get-menu";
import {mrhEnableImageObj} from "./mrh-global-variable";
import { filterByVisibility } from "./filter-by-visibility";
import { mrh_rootarraymenu } from "./mrh-root-array-menu";
import { mrhCategotyListGenerator } from "./mrh-category-list";
import { mrh_innerSubMenuService } from "./mrh-innersubmenu-service";
import { searchServiceInitial } from "../search-service/search-service-initial";


export function mrhHeaderDynamicBrandMenu(){
    //added for time being
    $('.nav-section a[href="/neighborly-done-right-promise/"]').parent().remove();
    let brandMenu:any;
    let categoryMenu:any;
    let brandName = $('#brandName').val();
    let countryCode = $('#countryCode').val();
    let brandNavigationUrl = `${window.location.origin}/${countryCode}/en-${countryCode}/${brandName}/_assets/js/data/dynamic_menu.json`;
    mrhDynamicGetMenu(brandNavigationUrl,function(result:any){
        let data =  result.serviceMenu;
        let options =  data["country"][0].brand[0].option;
        //enableImage = data["country"][0].brand[0].enableImages;
        let rootMenus:any = [];
        mrhEnableImageObj.enableImage = data["country"][0].brand[0].enableImages;
        brandMenu = options;
        let filterBrandMenu = filterByVisibility(brandMenu);
        sessionStorage.setItem('filterService',JSON.stringify(filterBrandMenu));
        searchServiceInitial();
        for(let i = 0;i<filterBrandMenu.length;i++){
            categoryMenu = filterBrandMenu[i].category;
            mrh_rootarraymenu(filterBrandMenu[i],rootMenus);  
        }
        $('.main-nav .nav-section .nav.navbar-nav').prepend(rootMenus);
        for(let i = 0;i<filterBrandMenu.length;i++){
            categoryMenu = filterBrandMenu[i]?.category || [];
            mrhCategotyListGenerator(categoryMenu,filterBrandMenu[i])
        }
        for(let i = 0;i<filterBrandMenu.length;i++){
            categoryMenu = filterBrandMenu[i]?.category || [];
            mrh_innerSubMenuService(categoryMenu,filterBrandMenu[i])
        } 

    })
}

    