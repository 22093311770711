import { $ } from "../third-party-imports";
import { apiConfig } from "../config/apiConfig";
import { stopLoader } from "./stopLoader";
export function getFranchiseWeblocationID(addressParam:any,successHandler:any){
    var conceptID = window.objOSGlobal.requestBodyValues.ConceptId;
    var apiUrl = apiConfig.FranchiseLookUpService + window.DEFINE_VALUE.JS_API_KEY+'&Address=sAddressParamValue&IsRoundRobin=true&conceptID='+conceptID;
    apiUrl = apiUrl.replace("sAddressParamValue", encodeURIComponent(addressParam));
    $.ajax({
        type: "GET",
        url:apiUrl,
        contentType: "application/json",
        dataType: 'json',
        success: successHandler,
        error: function(error:any){
            stopLoader();
        //  console.log(error);
        if (window.objOSGlobal.requestBodyValues.irvingrewardsform){
        $('.irving-reward-form').addClass('d-none');
        $('.irving-error-thankyou').removeClass('d-none');
        $('.card-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.PostalCode}</span>`)
        }
        if (window.objOSGlobal.requestBodyValues.plgcontactform){
        $('.plg-contact-from').addClass('d-none');
        $('.contact-error-thankyou').removeClass('d-none');
        $('.card-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.PostalCode}</span>`)
        }
        $('.progress, .progressbar').hide();
        $(".tab-pane.fieldset").removeClass("active");
        $('.scheduler-modal .modal-content').addClass('banner-wizard');
        $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.PostalCode}</span>`);
        $('.no-service-screen').addClass('active');
        }
    });
}