import { $ } from "../../third-party-imports";
export function mrrOsOptimizeFlow(){
    $('.call-details-text a img').attr("src","/us/en-us/mr-rooter/_assets/images/phone-red.svg");
    $.validator.setDefaults({
        errorElement: "span",
        errorPlacement: function (error: any, element: any)
        {
            error.addClass("error-msg help-block invalid-feedback");
            error.insertAfter(element);
        },
        highlight: function (element: any, errorClass: any, validClass: any)
        {
            $(element)
                .parents(".form-group")
                .addClass("has-error")
                .removeClass("has-success");
        },
        unhighlight: function (element: any, errorClass: any, validClass: any)
        {
            $(element)
                .parents(".form-group")
                .addClass("has-success")
                .removeClass("has-error");
        },
    });
    ($("#serviceDetails") as any).validate({
        onkeyup: function (this: any, element: any)
        {
            if ($(element).val() != "" || $(element).val() == "")
            {
                $(element).valid();
            }
        },
        rules: {
            referenceDetails: {
                required: $("#referenceDetails").attr("required")?.length ? true : false
            }
        }
    });
}


export function mrrConfirmationTextColor(){
    $('.appointment .cust-opus-h3-cls').text('Preferred Date and Time');
    $('.confirmation-data .cust-opus-h3-cls').css("color","#003DA6");
    $('.confirmation-data .confirmation-col.contact').css("color","#003DA6");
}