import { detectMob } from "../util/detect-mob";

export function rootMenuList(root:any){
    let mob = detectMob();
    let dataToggle = mob ?  "data-toggle='dropdown'" : "";
    let jsDropdown = mob ? "js-dropdown" : "" ;
    let dropdownToggle  = mob ? "dropdown-toggle" : "" ;
    let ariaHaspopup = mob ? "aria-haspopup='true'" : "" ;
    let ariaExpanded =  mob ? "aria-expanded='false'" : "" ;
    
    let linkTarget = root.openNewTab ? "target='_blank'" : "";
    let rootMobLinkAppend = root?.category?.length ? '#' : root.link;
    let isCatogory = root?.category?.length ? true : false;
    if(mob) {
        if(isCatogory){
            return `<li class="dropdown menu-large nav-item ${jsDropdown} service-menu">
              <a role="button" class="nav-link ${dropdownToggle} arrow-right-link mob-link" data-mobLink= "${root.link}" href="${rootMobLinkAppend}" id="menu-${root.name.toLowerCase()}" ${linkTarget} ${dataToggle} ${ariaExpanded} ${ariaHaspopup} data-title="${root.name}">${root.name}</a>
           </li>`
        }else{
            return `<li class="menu-large nav-item service-menu">
              <a role="button" class="nav-link arrow-right-link mob-link" data-mobLink= "${root.link}" href="${rootMobLinkAppend}" id="menu-${root.name.toLowerCase()}"  data-title="${root.name}">${root.name}</a>
           </li>`
        }
        
    } else {
        return `<li class="dropdown menu-large nav-item ${jsDropdown} service-menu">
        <a role="button" class="nav-link dropdown-toggle arrow-right-link desktop-link" style="white-space: nowrap;" href="${root.link}" ${linkTarget} data-title="${root.name}">${root.name}</a>
        </li>`
    }
}