import axios from "axios";
import { apiConfig } from "../config/apiConfig";
import { formatPhone } from "../location-search-map/formatPhone";
import { stopLoader } from "./stopLoader";
export function mrhserviceSearchTermApi(resData:any,successHandler:any,errorHandler:any) {
let search_term:any = window.location.pathname?.split('/')[2]?.charAt(0).toUpperCase() + window.location.pathname?.split('/')[2]?.slice(1);
window.objOSGlobal.requestBodyValues.SearchTeam = search_term.toLowerCase();
let serviceType:any = window.location.pathname?.split('/')[1]?.charAt(0).toUpperCase() + window.location.pathname?.split('/')[1]?.slice(1);
let getServiceJson:any = JSON.parse(sessionStorage.getItem('filterService'))
let getServiceModifier:any = getServiceJson?.find((item:any)=> item.link=== "/"+serviceType.toLowerCase()+"/")

let webLocationId:any = resData[0].franchiseWebLocationId;
var apiUrl = apiConfig.FranchiseWebLocations+webLocationId+'/service-search-term-aggregates/search?apikey='+window.DEFINE_VALUE.JS_API_KEY+'&search_term='+search_term+'&service_modifier='+getServiceModifier.name+'&postal_code='+window.objOSGlobal.requestBodyValues.PostalCode+'&skip=0';
let config = {
    method: 'get',
    url: apiUrl,
    headers: {
        'Content-Type': 'application/json'
    }
};
axios(config)
.then(function (response:any) {
    if(response.data.length == 0) {
        stopLoader();
    const postalCode:any = window.objOSGlobal.requestBodyValues.PostalCode;
    $("#mrh_modal_services_not_given").removeClass('d-none');
    $("#mrh_modal_services_not_given").addClass('show');
    $('body').append(`<div class="modal-backdrop fade show"></div>`);
    $('body.mr-handyman.wf-loading').css({overflowY: 'hidden'});
    $("#mrh_modal_services_not_given .modal-body .zipcode-des").find('h5').html(`We are Sorry ${search_term.toLowerCase()} services are not offered for Zipcode `);
    $("#mrh_modal_services_not_given .modal-body .zipcode-des").find('h5').append(`<span>${postalCode}.</span>`)
    //$("#mrh_modal_services_not_given .modal-body .zipcode-des").find('h5').find('span').html(" "+postalCode+".");
    $("#mrh_modal_services_not_given .website-wrapper").find('h5').html(resData[0].doingBusinessAs);
    let phoneNum:any = formatPhone(resData[0].phoneNumber);
    $("#mrh_modal_services_not_given .website-wrapper a.call-text").get(0).lastChild.nodeValue = phoneNum;
    $("#mrh_modal_services_not_given .website-wrapper a.call-text").attr('href', 'tel:' + phoneNum);
    $("#mrh_modal_services_not_given .website-wrapper p.contact-number").find('.primary-btn').attr('href',resData[0].locationWebsiteUrl);
    }else {
    successHandler(resData);
    }
})
.catch(function (error:any) {
    stopLoader();
});
}