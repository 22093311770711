//import { updateFooterLinks } from "./updateFooterLinks";
import {createFooterLinks} from './createFooterLinks';
import { attachNextPrevClickEvent } from "./attachNextPrevClickEvent";
import { $ } from "../third-party-imports"

/**
 * Get All Blogs
 */

export function getAllBlogs(g2CollectionURL: any)
{
    $.ajax({
        type: 'GET',
        url: g2CollectionURL,
        dataType: 'json',
        success:  function (data)
        {
            var allBlogs = data.response.docs;
            // Update Footer Links
            //updateFooterLinks(allBlogs);
            createFooterLinks(allBlogs);
            // attach click event only if links are enabled.
            attachNextPrevClickEvent();
        },
        error: function (e: any)
        {
            // console.log(e.message);
        }
    });
}
 