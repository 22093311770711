export function generateSideServices(ser:any){
    let currentPath = window.location.pathname.replace(/\/$/, "");
    let cls = '';
    let linkTarget = ser.openNewTab ? "target='_blank'" : "";
    //if (ser.link == currentPath) {
    if (currentPath.indexOf(ser.link.replace(/\/$/, "")) == 0) {
        cls = 'active';
    }
    return `<li><a href="${ser.link}" ${linkTarget} class="primary-theme-clr ${cls}">${ser.name}</a></li>`
}
