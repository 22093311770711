import { $ } from "../third-party-imports"
import { verifyOtpRegFun } from "./verifyOtpRegFun";
import { profileVars } from ".";
import { generateOtpNumber } from "./generateOtpNumber";
import { phoneFormatter } from "../util"
import { resendCodeFun } from "./resendCodeFun";

// Verify Phone phone Number after registration first time

export function verificatinPhoneNumber(phoneNumberVal: any)
{
    if (profileVars.neighborlyUserId)
    {
        phoneFormatter();
        $('.phoneVerificationNumber').show();
        profileVars.neighborlyAccountContactId = phoneNumberVal.neighborlyAccountContactId;
        var phoneNumber = phoneNumberVal.contactDetail.match(/(\d{3})(\d{3})(\d{4})/);
        if (phoneNumber != null)
        {
            phoneNumber = "(" + phoneNumber[1] + ") " + phoneNumber[2] + "-" + phoneNumber[3];
        }
        else
        {
            phoneNumber = phoneNumberVal.contactDetail;
        }
        $('#verifyPh').val(phoneNumber);
        $('#phoneVerifyContinoue').off('click').click(function ()
        {
            var mobileNumber = $('#verifyPh').val();
            mobileNumber = mobileNumber.toString().replace(/[- )(]/g,'');
            var verifyPhone = phoneNumberVal;
            var type = $("input[name=registerVerifyNumber]:checked").val();
            if (mobileNumber)
            {
                generateOtpNumber(mobileNumber, type, function (data: any, sucess: any)
                {
                    if (data.status == 'Success')
                    {
                        $('.phoneVerificationNumber').hide();
                        $('body').addClass("hidebodyscroll");
                        $('.phoneVerificationNumberOtp').show();
                        resendCodeFun(verifyPhone);
                        verifyOtpRegFun(data.neighborlyAccountOtpid, verifyPhone);
                    }
                });
            }
        });
    }
}
