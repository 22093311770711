import { $ } from "../third-party-imports"
import { getNblyMicroServiceApiHeaders } from "../auth/api";
import { apiConfig } from "../config";
import { addProfilePoneNumber } from "./addProfilePoneNumber";
import { profileVars } from ".";
import { getNblyApiHeaders } from "../auth/api/getNblyApiHeaders";

export function validatePhoneNumber(neighborlyAccountOtpid: any)
{
    if (neighborlyAccountOtpid)
    {
        $('.validatePhoneBtn').off('click').click(function (){
         if(profileVars.validateBtn){
            profileVars.validateBtn = false;
            var digit1 = $('#digit-1').val() as string;
            var digit2 = $('#digit-2').val() as string;
            var digit3 = $('#digit-3').val() as string;
            var digit4 = $('#digit-4').val() as string;
            var digit5 = $('#digit-5').val() as string;
            var digit6 = $('#digit-6').val() as string;
            var combineDigit = digit1 + digit2 + digit3 + digit4 + digit5 + digit6;
            var payload = {
                "NeighborlyAccountOtpid": neighborlyAccountOtpid,
                "ContactOtp": combineDigit
            };
            /*To show the loader only in online scheduler flow so commenting the loader [+57326] 
            start_loader();
            */
            $.ajax({
                url: apiConfig.editPhoneApi + '/ValidateOTP'+"?"+getNblyApiHeaders(),
                data: JSON.stringify(payload),
                headers: getNblyMicroServiceApiHeaders(),
                type: "POST",
                contentType: "application/json",
                success: function (data, success)
                {
                    // stop_loader();
                    $('#generateOtp').removeClass('active');
                    if (data.status == 'Success')
                    {
                        $('.form_container_wrapperBox:first-child').show();
                        addProfilePoneNumber();
                    }
                },
                error: function (xhr, status, error)
                {
                    // stop_loader();
                    profileVars.validateBtn = true;
                    $('.otpError').text(xhr.responseJSON.Message);
                }
            });
           }    
        });
    }
}
