export function RLPostData(payload: any){
    var RLPayload = {
        "FirstName": payload.FirstName ? payload.FirstName : "",
        "LastName": payload.LastName ? payload.LastName : "",
        "Email": payload.Email ? payload.Email.trim() : "",
        "Phone": payload.Phone ? payload.Phone : "",
        "City": payload.City ? payload.City : "",
        "Country": payload.Country ? payload.Country : "",
        "State": payload.State ? payload.State : "",
        "Address": payload.Address ? payload.Address : "",
        "Address2": payload.Address2 ? payload.Address2 : "",
        "CampaignName": payload.CampaignName ? payload.CampaignName : "",
       "ConceptCode": payload.ConceptCode ? payload.ConceptCode : "",
       "DeductibleAmount": payload.DeductibleAmount ? payload.DeductibleAmount : 0,
       "EmailOptOut": payload.EmailOptOut,
       "EstimateBathrooms": payload.EstimateBathrooms ? payload.EstimateBathrooms : "",
       "EstimateBedrooms": payload.EstimateBedrooms ? payload.EstimateBedrooms : "",
       "EstimateSquareFeet": payload.EstimateSquareFeet ? payload.EstimateSquareFeet : "",       
       "IsEmergencyCall": payload.IsEmergencyCall ? payload.IsEmergencyCall : false,
       "LeadAdditionalNotes": {
           "LeadAdditionalNoteLabel": payload.LeadAdditionalNoteLabel ? payload.LeadAdditionalNoteLabel : "",
           "LeadAdditionalNoteText": payload.LeadAdditionalNoteText ? payload.LeadAdditionalNoteText : "",
       },
       "LeadOrigin": payload.LeadOrigin ? payload.LeadOrigin : "Web",
       "LeadSource": payload.LeadSource ? payload.LeadSource : "Web",
       "Note": payload.Note ? payload.Note : "",
       "PostalCode": payload.PostalCode ? payload.PostalCode : "",
       "ServiceText": payload.ServiceText ? payload.ServiceText : "",
       "TimeBlock": {
           "JobPromisedDate": payload.JobPromisedDate ? payload.JobPromisedDate : "",
           "DisplayText" : $('.selected-date-txt').html(),
       },
       "VendorName": payload.VendorName,
       "Warranty": payload.Warranty ? payload.Warranty : false,
   };

   return RLPayload;
}