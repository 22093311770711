import {$} from '../third-party-imports';
export function updatedOpusOnlineSchedulerFlow(nextId:any){
    $('.scheduler-modal .modal-content').removeClass('banner-wizard');
    var nextImg = $('.mly-service-contact-form-screen .js-next-btn').find('img');
    updateCommercialFlow();
    if($('.scheduler-slide .tab-pane.fieldset').hasClass('confirmation-screen')){
        $('.confirmation-screen .js-next-btn').html('Submit').append(nextImg);
    }
    let leadFlow =  window.objOSGlobal.requestBodyValues.optInForPOSScheduling ? false : true;
    if(leadFlow){
        $('.tab-pane.fieldset').removeClass('confirmation-screen');
        $('.progressbar li').css('width', 'calc(100%/2)');
        $('#schedulerWizard .scheduler-slide .progressbar').addClass('hide-estimate-flow');
        $('.mly-service-contact-form-screen').addClass('confirmation-screen');
    }else{
        $('.tab-pane.fieldset').removeClass('confirmation-screen');
        $('.mly-service-contact-form-screen .js-next-btn').html('Next').append(nextImg);
        $('.progressbar li').css('width', 'calc(100%/3)');
        $('#schedulerWizard .scheduler-slide .progressbar').removeClass('hide-estimate-flow');
        $('.mly-service-estimate-screen').addClass('confirmation-screen');
    }
    $('.tab-pane.fieldset').removeClass('active');
    $('[href="#' + nextId + '"]').tab('show');
    $('#'+nextId).addClass('active');
    $('.progress, .progressbar').show();
    $('#schedulerWizard .scheduler-slide .progress').addClass('mly-flow-progress');
    $('#schedulerWizard .scheduler-slide .progressbar').addClass('mly-flow-progressbar');

}
export function removeClassForUpdateFlow(){
    $('#schedulerWizard .scheduler-slide .progress').removeClass('mly-flow-progress');
    $('#schedulerWizard .scheduler-slide .progressbar').removeClass('mly-flow-progressbar');
    $('.progress, .progressbar').hide();
    $('.localized-progress').hide();
    localStorage.removeItem("addressMly");
}

export function updateCommercialFlow(){
        mlyServicesInfomationResidentialCommercial('commercial');
        $('.mly-service-how-often-screen .services-list-screen.mly-service-residential-list').addClass('d-none');
        $('.mly-service-how-often-screen .services-list-screen.mly-service-commercial-list').removeClass('d-none');
        $('.progressbar li a[data-step="2"]').html('About <span>Your Office</span>');
        $('.about-home-screen').children('.abt-title').text('Tell us about your Office.');
        $('#aboutHome label[for="inputbedrooms"]').text('Offices *');
        window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
        window.objOSGlobal.requestBodyValues.optInForPOSScheduling = false;
        window.objOSGlobal.requestBodyValues.optInForPOSScheduling  = false;
        window.objOSGlobal.requestBodyValues.modifier = 'Commercial';
        sessionStorage.setItem('ServiceModifierValue', window.objOSGlobal.requestBodyValues.modifier);
}

export function aboutYourHomeScreen(){
    let selectAbtHome = localStorage.getItem('selectedAboutHome');
    let mlyCleaning = sessionStorage.getItem('MlyCleaning');
    if(selectAbtHome == "onetime"){
        if (mlyCleaning == "No"){
            window.objOSGlobal.requestBodyValues.Note = '';
            window.objOSGlobal.requestBodyValues.Note = 'MLY Frequency: One Time, Not a Move in or Move out clean'
        }else{
            window.objOSGlobal.requestBodyValues.Note = '';
            window.objOSGlobal.requestBodyValues.Note = 'MLY Frequency: One Time, Move in or Move out clean'
        }
    }
    if(selectAbtHome == "recurring"){
        var mlyFrequency = $('input[name="chkFrequencyMethod"]:checked').siblings('.radio-txt').text();
        window.objOSGlobal.requestBodyValues.Note = '';
        window.objOSGlobal.requestBodyValues.Note = 'MLY Frequency: Recurring, ' + mlyFrequency;
    }
}

function mlyServicesInfomationResidentialCommercial(modifier:any){
    $('.service-request-details input[type=radio][name=chkFrequesy]').change(function(this:any) {
        $('.services-list-screen').addClass('d-none');
        if (this.value === 'onetime'){
            let id = $('.cleaning-details input[name="chkCleaning"]:checked').attr('id');
            if(modifier ==='commercial'){
                if(id==='inputChkYes'){
                    $('.mly-service-commercial-list-onetime_moveinout_yes').removeClass('d-none');
                }else{
                    $('.mly-service-commercial-list-onetime_moveinout_no').removeClass('d-none');
                }
                
            }
            checkMlyOneTimeYesNo(modifier);
        }else{
            if(modifier ==='commercial'){
                $('.mly-service-commercial-list').removeClass('d-none');
            }
        }
    });
}

function checkMlyOneTimeYesNo(modifier:any){
    $('.cleaning-details input[type=radio][name=chkCleaning]').change(function(this:any) {
        let idVal = $(this).attr("id");
        let textVal =  $("label[for='" + idVal + "']").text().replace(/\s+/g,'');
        $('.services-list-screen').addClass('d-none');
        if(modifier ==='commercial'){
            if (textVal.toLowerCase() === 'yes') {
                $('.mly-service-commercial-list-onetime_moveinout_yes').removeClass('d-none');
            }
            else{
                $('.mly-service-commercial-list-onetime_moveinout_no').removeClass('d-none');
            }

        }
    });
}