import { $ } from "../third-party-imports"
import { getNblyMicroServiceApiHeaders } from "../auth/api"
import { apiConfig } from "../config";
import { addProfileVerifyPhoneNumber } from "./addProfileVerifyPhoneNumber";
import { updateProfile } from "./updateProfile";
import { getNblyApiHeaders } from "../auth/api";


export function verifyOtpRegFun(neighborlyAccountOtpid: any, verifyPhone: any)
{
    if (neighborlyAccountOtpid)
    {
        $('#verifyOtpReg').off('click').click(function ()
        {
            var digit1 = $('#vigit-1').val() as string;
            var digit2 = $('#vigit-2').val() as string;
            var digit3 = $('#vigit-3').val() as string;
            var digit4 = $('#vigit-4').val() as string;
            var digit5 = $('#vigit-5').val() as string;
            var digit6 = $('#vigit-6').val() as string;
            var combineDigit = digit1 + digit2 + digit3 + digit4 + digit5 + digit6;
            var payload = {
                "NeighborlyAccountOtpid": neighborlyAccountOtpid,
                "ContactOtp": combineDigit
            };
            // start_loader();
            $.ajax({
                url: apiConfig.editPhoneApi + '/ValidateOTP'+"?"+getNblyApiHeaders(),
                data: JSON.stringify(payload),
                headers: getNblyMicroServiceApiHeaders(),
                type: "POST",
                contentType: "application/json",
                success: function (data, success)
                {
                    // stop_loader();
                    $('.phoneVerificationNumberOtp').removeClass('active');
                    if (data.status == 'Success')
                    {
                        addProfileVerifyPhoneNumber(verifyPhone, function (data: any, success: any)
                        {
                            updateProfile(function ()
                            {
                                if (data)
                                {
                                    $('body').removeClass("hidebodyscroll");
                                    $('.phoneVerificationNumberOtp').hide();
                                    $('.wlcmMsgModal').show();
                                }
                            });
                        });
                    }
                },
                error: function (xhr, status, error)
                {
                    // stop_loader();
                    $('.otpError').text(xhr.responseJSON.Message ? xhr.responseJSON.Message : 'OTP is required');
                }
            });
        });
    }
}
