import { mrhEnableImageObj } from "./mrh-global-variable";
import { innerServiceList } from "./mrh-inner-serviceList";
import { mrhSubMenuLeftcatgoryList } from "./mrh-leftsubmenu-category-list";
import { mrhSubMenuRightcatgoryList } from "./mrh-rightsubmenu-category-list";
import { mrhGenerateMenuServiceList } from "./mrh-generate-menu-servicelist";
import { mrhGenerateServices } from "./mrh-generate-service-list";
import { detectMob } from "../util/detect-mob";
export function mrh_innerSubMenuService(categoryMenuArr:any,root:any) {
    let mob:any = detectMob();
    let submenuCategotyList:any = [];
    let subMenuCategoryListArr:any = [];
    let subMenuLeftcatgoryList:any = [];
    let subMenuRightCategoryList:any = [];
    submenuCategotyList.push(innerServiceList(root))
    if(!mob) { 
    $('.dropdown.menu-large a[href="'+root.link+'"] + .dropdown-menu.megamenu .dropmenu .mega-menu-links').append(submenuCategotyList);   
    }
    else {
    $('.dropdown.menu-large a[href="'+root.link+'"] + .dropdown-menu.megamenu .dropmenu .mega-menu-links.mob-mega').append(submenuCategotyList); 
    }
    
    if(mrhEnableImageObj.enableImage) {
        categoryMenuArr?.forEach((cat:any)=>{
           if(cat.serviceType.toLowerCase() === "ProType".toLowerCase() 
           || cat.serviceType.toLowerCase() === "Service WebCategory".toLowerCase()
           || cat.serviceType.toLowerCase() === "OtherService".toLowerCase()) {
            subMenuCategoryListArr.push(cat)
           }
        })  
       }
       for(let i =0;i<subMenuCategoryListArr.length;i++){
        subMenuLeftcatgoryList.push(mrhSubMenuLeftcatgoryList(subMenuCategoryListArr[i],root,i));
       }
       if(!mob){
        $('.dropdown.menu-large a[href="'+root.link+'"] + .dropdown-menu.megamenu .dropmenu.new-submenu .mega-menu-links').find('.inner-menu-list.col-list').append(subMenuLeftcatgoryList);
       } 
    //    else {
    //     $('.dropdown.menu-large a[href="'+root.link+'"] + .dropdown-menu.megamenu .dropmenu .mega-menu-links.mob-mega').find('.inner-menu-row').append(subMenuLeftcatgoryList);
    //    }
       
       for(let i =0;i<subMenuCategoryListArr.length;i++){
        subMenuRightCategoryList.push(mrhSubMenuRightcatgoryList(subMenuCategoryListArr[i],root,i));
       }
       $('.dropdown.menu-large a[href="'+root.link+'"] + .dropdown-menu.megamenu .dropmenu.new-submenu .mega-menu-links').find('.col-lg-10').append(subMenuRightCategoryList);

       for(let i =0;i<subMenuCategoryListArr.length;i++){
       mrhGenerateMenuServiceList(subMenuCategoryListArr[i],root);
       }

       for(let i =0;i<subMenuCategoryListArr.length;i++){
        mrhGenerateServices(subMenuCategoryListArr[i],root);
        }
}