import { $ } from "../third-party-imports";
import {getDisplayServicesList} from '../services/getDisplayServicesList';
import {getDisplayServicesResults} from '../services/getDisplayServicesResults';
import {getFullAttributeApi} from './getFullAttributeApi';
import {getFranchiseWebServices} from '../services/getFranchiseWebServices';
import {getServiceTypeString} from '../services/getServiceTypeString';
import {generateServiceType} from '../services/generateServiceType';
import { fullAddressRoundRobinApi } from "./fullAddressRoundRobinApi";
import { FspSchedulerFlow } from "./FspSchedulerFlow";
import { getBrandJsonValue, servieModal } from "./get-brands-json";
import { FsaOSFlowScenarion } from "../scheduler/initialCallFunction";
export function localBrandsSitesFlow(){
    var zipCode = localStorage.getItem('zipcode');
    $('#service-search-row').remove();
    if(zipCode){
      window.objOSGlobal.requestBodyValues.PostalCode = zipCode;
      getBrandJsonValue();
      let conceptCode:any  = $("#conceptCode").val();
      const countryCode = $("#countryCode").val()?.toLowerCase();
      let brandJsonItems = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj;
      const croleadNoZipEnable = brandJsonItems[0].enable_cro_enhance_lead_no_zip_match || false ;
      if(conceptCode.toUpperCase() =='FSP' || servieModal.enable){
            $('.address-details-screen input[name="inputZipCode"]').val(zipCode);
            if(conceptCode?.toLowerCase() ==="mrr" && countryCode ==="ca" || croleadNoZipEnable){
                FsaOSFlowScenarion(zipCode);
            }else{
                FspSchedulerFlow(zipCode);
            }
            return;
        }
        fullAddressRoundRobinApi(zipCode,false,()=>{
        getDisplayServicesList(zipCode ,4,function(result:any,status:any,xhr:any){
            $('.zipcode-service-search').text(zipCode);
            getDisplayServicesResults(result,zipCode);
            getFullAttributeApi(window.objOSGlobal.requestBodyValues.WebLocationId,'');
            getFranchiseWebServices(window.objOSGlobal.requestBodyValues.WebLocationId,function(result:any){
                var serviceTypeList = [];  
                var serviceTypeObject = [];
                if(result.length !==0){
                var data = JSON.parse(result.serviceMenu);
                var options =  data["country"][0].brand[0].webLocation[0].option;
                for(let i=0;i<options.length;i++){
                  var serviceTypeValue =  getServiceTypeString(options[i].name);  
                  serviceTypeObject.push({name:options[i].name,value:serviceTypeValue});
                }
                serviceTypeObject.sort(function(a, b) {
                     return parseInt(a.value) - parseInt(b.value);
                 });
                for(let i=0;i<serviceTypeObject.length;i++){
                  var serviceList = generateServiceType(serviceTypeObject[i].name,serviceTypeObject[i].value);
                  serviceTypeList.push(serviceList);
                } 
                serviceTypeList.reverse();
                $('.search-services-form .radio-group-wrap').empty();
                $('.search-services-form .radio-group-wrap').append(serviceTypeList);
                $(".search-services-form input[value='4']").prop('checked', true);
              }
            });
        },function(data:any, status:any, error:any){
            $(".tab-pane.fieldset").removeClass("active");
            $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${zipCode}</span>`);
            $(".no-service-screen").addClass("active");
        });
    },
    function(data:any, status:any, error:any){
        $(".tab-pane.fieldset").removeClass("active");
        $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${zipCode}</span>`);
        $(".no-service-screen").addClass("active");
    });
    }else{
       $('.zip-entry-screen').addClass('active');
    }
}