
import { $ } from "../third-party-imports"

export function disableCurrentPageOnPaginationSection(pageNumber: any)
{
    var liElements = $('.pagination li .page-numbers li .pagination-item');
    for (var i = 0; i < liElements.length; i++)
    {
        if ($(liElements[i]).html() === pageNumber)
        {
            $(liElements[i]).css('pointer-events', 'none');
            $(liElements[i]).addClass('blackColor');

        }
    }
}
 