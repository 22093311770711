export function  validatePurchaserInfoForm() {
    let firstName = (document.getElementById('inputFirstName2') as HTMLInputElement).value;
    if (firstName.length === 0) return false;
    let lastName = (document.getElementById('inputLastName2') as HTMLInputElement).value;
    if (lastName.length === 0) return false;
    if (validateEmailFields() === false) return false;
    if (validatePhoneNumField() === false) return false;
    return true;
}
const validateEmailFields = (): boolean => {
    let email = (document.getElementById('inputEmail2') as HTMLInputElement).value;
    let confirmEmail = (document.getElementById('inputConfirmEmail2') as HTMLInputElement).value;
    if (email.length === 0) return false;
    if (confirmEmail.length === 0) return false;
    if(email !== confirmEmail) return false;
    return validateEmailFormat(email) && validateEmailFormat(confirmEmail);
}
const validateEmailFormat = (email: string): boolean => {
    const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const disallowedTopDomains = ['comm', 'co'];
    if (filter.test(email) == false) return false;
    for(let i=0; i<disallowedTopDomains.length; ++i) {
        if (email.endsWith(disallowedTopDomains[i])) return false;
    }
    return true;
}
const validatePhoneNumField = (): boolean => {
    let phoneNum = (document.getElementById('inputPhoneNumber2') as HTMLInputElement).value;
    const re = /\(\d{3}\) \d{3}-\d{4}/;
    return re.test(phoneNum);
}