import { getMSALInstance } from '../../config';

export async function logout()
{
    localStorage.removeItem('loginWith');
    localStorage.removeItem('profile');
    localStorage.removeItem('isRememberMeNotSelected');
    sessionStorage.removeItem('isRememberMeNotSelected');
    const myMSALObj = await getMSALInstance();
    myMSALObj.logout();
}
