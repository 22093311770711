// Code goes here
import { $ } from '../third-party-imports';
import {headerDynamicBrandMenu as headerBrandMenuGenrate} from '../dynamic-menu';
import {headerDynamicLocalMenu as headerLocalMenuGenrate} from '../dynamic-menu';
import {mrhHeaderDynamicBrandMenu as mrhHeaderBrandMenuGenrate, mrhHeaderDynamicLocalMenu} from '../mrh-dynamic-menu'
import {Get_HoursofOperation_From_FullAttributeApi, ServiceModifier_Redirection,setPhoneNumberfromHeader,matchCtaHeaderPhoneNumber, hidingMttMenuLink, hidingPhgMenuLink} from '../util';

$(function () {
    var brand = $('body').attr('class');
    if ($('body').attr('id')) { // body has only id -> local
        if ($('body').attr('id').indexOf('local') != -1) {
            if($("#brandName").val()?.toLowerCase() !== 'mr-handyman' && $("#brandName").val()?.toLowerCase() !== 'my-handyman') {
                headerLocalMenuGenrate();
                hidingMttMenuLink();
                hidingPhgMenuLink();
            }else{
                mrhHeaderDynamicLocalMenu();
            }
            localStorage[brand] = $(location).attr('href');
        }
    }
    if (!$('body').attr('id') && $('body').attr('class')) { // body has only class -> national 
        Object.keys(localStorage).forEach(function (key) {
                if($('body').attr('class').indexOf(key) != -1){
                    if($("#brandName").val()?.toLowerCase() !== 'mr-handyman' && $("#brandName").val()?.toLowerCase() !== 'my-handyman') {
                        headerLocalMenuGenrate();
                        hidingMttMenuLink();
                        hidingPhgMenuLink();
                    }else{
                        mrhHeaderDynamicLocalMenu();
                    }
                var referrer = localStorage.getItem(key);
                if($("#conceptCode").val() === 'ASV'){
                    $('header#site-header').load('' + referrer + ' header#site-header');
                }else {
                    $('header#site-header').load('' + referrer + ' header');
                }
                $('footer').load('' + referrer + ' footer');
                setTimeout(function () {
                    $('footer footer').each(function () {
                        $(this).replaceWith($(this).html());
                    });
                    $('header header').each(function () {
                        $(this).replaceWith($(this).html());
                    });
                    $(document).on("click", ".dropdown-menu", function (e) {
                        e.stopPropagation();
                    });
                    $(".close-icn").on("click", function () {
                        $(".notify-sec").hide();
                        $("body").addClass("notify-remove");
                    });
                    // close if press ESC button
                    $(document).on("keydown", function (event) {
                        if (event.keyCode === 27) {
                            $(".navbar-collapse").removeClass("show");
                            $("body").removeClass("overlay-active");
                        }
                    });
                    /*$("[data-trigger]").on("click", function(event) {
                    event.preventDefault();
                        let trigger_id = $(this).attr("data-trigger");
                        $(trigger_id).toggleClass("show");
                        $("body").toggleClass("offcanvas-active");
                        $(".js-dropdown").removeClass("active");
                        $(".js-dropdown").removeClass("show");
                        $(".megamenu").removeClass("show");
                        $(".js-dd-inner").removeClass("active");
                    });*/
                    $('.close-btn').on('click', function () {
                        $('.navbar-collapse').removeClass('show');
                        $("body").removeClass("offcanvas-active");
                    });
                    // Class Target to MAC Screen
                    if (navigator.userAgent.indexOf('Mac') > 0) {
                        $("body").addClass("mac");
                    }
                    $("body").addClass("wf-loading");
                    setTimeout(function () {
                        //FOR Service Page Banner with CTA Comp
                        /*
                                            if ($("#div_servicepage_bannerwithcta").length > 0) {
                                                var phoneNumber = $(".col-sec-right .call-icon").html();
                                                phoneNumber = phoneNumber.replace(/<img[^>]*>/g,"");
                        
                                                var hrefphoneNumber = $(".col-sec-right .call-icon").attr("href");
                                                $("#a_servicepage_bannerwithcta_locationphone").html(phoneNumber);
                        
                                                $("#a_servicepage_bannerwithcta_locationphone").attr("href", hrefphoneNumber);
                                                $("#a_servicepage_fullwidthcta_locationphone").html(phoneNumber);
                                                $("#a_servicepage_fullwidthcta_locationphone").attr("href", hrefphoneNumber);
                        
                                                if ($("#a_servicepage_bannerwithcta_button").length > 0) {
                                                    var ctaText = $(".header-left-sec .col-btn a.primary-btn").text();
                                                    if(ctaText != ""){
                                                        $("#a_servicepage_bannerwithcta_button").text(ctaText);
                                                        $("#a_servicepage_fullwidthcta_button").text(ctaText);
                                                    }
                                                }
                                            }*/
                        //if ($("#div_servicepage_bannerwithcta").length > 0) {
                        try {
                            var phoneNumber = $(".col-sec-right .call-icon").html();
                            phoneNumber = phoneNumber.replace(/<img[^>]*>/g, "");
                            var hrefphoneNumber = $(".col-sec-right .call-icon").attr("href");
                            if ($("#a_servicepage_bannerwithcta_locationphone").length > 0) {
                                $("#a_servicepage_bannerwithcta_locationphone").html(phoneNumber);
                                $("#a_servicepage_bannerwithcta_locationphone").attr("href", hrefphoneNumber);
                            }
                            if ($("#a_servicepage_fullwidthcta_locationphone").length > 0) {
                                $(".cta-assessment #a_servicepage_fullwidthcta_locationphone").each(function () {
                                    $(this).html(phoneNumber);
                                    $(this).attr("href", hrefphoneNumber);
                                });
                            }
                            if ($("#a_servicepage_bannerwithcta_button").length > 0) {
                                var ctaText = $(".header-left-sec .col-btn a.primary-btn:first").text();
                                if (ctaText != "") {
                                    $("#a_servicepage_bannerwithcta_button").text(ctaText);
                                    $("#a_servicepage_fullwidthcta_button").text(ctaText);
                                }
                            }
                            $(".entry-call-details-text a").attr("href",hrefphoneNumber);
                            $(".entry-call-details-text a .phnNumber").html(phoneNumber);
                            if($('.banner-txt-sec.js-banner-spacing')[0]) {
                            $('.banner-txt-sec.js-banner-spacing').find('.or-call').remove();
                            let phone = $($(".anchor_desktop_phone_number").clone()[1]).children().remove().end().text().trim();
                            $('.banner-txt-sec.js-banner-spacing').append(`<a href="${hrefphoneNumber}" class="or-call">or call <b>${phone} </b></a>`);
                            }
                            matchCtaHeaderPhoneNumber();
                        }
                        catch (e) { }
                        if ($("#a_contactpage_brandphone").length > 0) {
                            var phoneNumber = $(".col-sec-right .call-icon").html();
                            phoneNumber = phoneNumber.replace(/<img[^>]*>/g, "");
                            var hrefphoneNumber = $(".col-sec-right .call-icon").attr("href");
                            var CallIconImg = $("#a_contactpage_brandphone").find("img");
                            $("#a_contactpage_brandphone").html("");
                            $("#a_contactpage_brandphone").append(CallIconImg);
                            $("#a_contactpage_brandphone").append(phoneNumber);
                            $("#a_contactpage_brandphone").attr("href", hrefphoneNumber);
                        }
                    }, 100);
                    ServiceModifier_Redirection();
                    setPhoneNumberfromHeader();
                }, 1000);
            }
        });
    }
    if(!$('.locate-form-wrapper.js-locate-form').hasClass('d-none') && !localStorage.getItem('dbaname')){
        if($("#brandName").val()?.toLowerCase() !== 'mr-handyman' && $("#brandName").val()?.toLowerCase() !== 'my-handyman') { // condtion for MRH
            headerBrandMenuGenrate();
        }else {
            mrhHeaderBrandMenuGenrate();
        }
    }
   
    //(+)service side nav
	var currentPath = window.location.pathname;
    $('.services-left-nav .collapsable-list').each(function () {
        var $this = $(this);
        $(this).find('a.primary-theme-clr').each(function () {
            //if ($(this).attr('href').indexOf(currentPath) >= 0) {
            if ($(this).attr('href') == currentPath) {
                $this.find("button").trigger('click');
                $(this).addClass('active');
            }
        });
    });	
    //(-)service side nav
    Get_HoursofOperation_From_FullAttributeApi();
    setTimeout(() => {
        updateBodyPhoneNumber();
        }, 5000)
    ServiceModifier_Redirection();
});


function updateBodyPhoneNumber() {
    let phoneNumber = $(".col-sec-right .call-icon").html();
                            phoneNumber = phoneNumber.replace(/<img[^>]*>/g, "");
                            let hrefphoneNumber = $(".col-sec-right .call-icon").attr("href");
                            let CallIconImg = $("#a_contactpage_brandphone").find("img");
                            $("#a_contactpage_brandphone").html("");
                            $("#a_contactpage_brandphone").append(CallIconImg);
                            $("#a_contactpage_brandphone").append(phoneNumber);
                            $("#a_contactpage_brandphone").attr("href", hrefphoneNumber);
}

