import {chunkArray} from '../util/chunkArray';
import { $ } from "../third-party-imports";
export function getDisplayServicesResults(result: any[],zipCode: string){
    if(result.length==0){
        $('.scheduler-modal .modal-content').addClass('banner-wizard');
        $(".tab-pane.fieldset").removeClass("active");
        $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${zipCode}</span>`);
        $(".no-service-screen").addClass("active");
        localStorage.removeItem('zipcode');
    }else{
        $('.service-search-row .services-link-list').empty();
        $(".tab-pane.fieldset").removeClass("active");
        $(".service-search-screen").addClass("active");
        window.objOSGlobal.requestBodyValues.FranchiseId = result[0].franchiseId;
        var splitCount;
        if (result.length < 3) splitCount = 1;
        else splitCount = Math.round(result.length / 3);
        result = chunkArray(result, splitCount);
        var colmd4Elements = $.map(result, function (el)
        {
            var sub_ul = $('<ul class="list-unstyled services-link-list">');
            $.each(el, function (index, service)
            {
                let liEl = window.document.createElement("li");
                liEl.id = `${service.serviceId}`;
                liEl.innerHTML = `<a class="pointer js-next-btn" id="${service.serviceSkuId}">${service.serviceName}</a>`;
                var sub_li = $(liEl);
                sub_ul.append(sub_li);
            });
            var colmd4 = $('<div class="col-md-4"></div>').append(sub_ul);
            return colmd4;
        })
        var rowClass = $('<div class="row service-search-row" id="service-search-row"> <div> ').append(colmd4Elements);
        $(".service-search-screen .services-links-cont").append(rowClass);
    }
}