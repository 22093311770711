import { $ } from "../third-party-imports";
import {refreshScheduleData} from '../util/refreshScheduleData';
import {getServiceTypeString} from './getServiceTypeString';
import {categoryResults} from '../util/categoryResults';
import {getServicesForCategory} from './getServicesForCategory';
import {schedulerState} from '../scheduler/scheduler';
export function categoryServicePage(webLocationId:any){
    var pageType = $('#pageType').val();
    var serviceModifier = $("#serviceModifier").val();
    var serviceId = $('#serviceId').val();
    var postalCode = localStorage.getItem('zipcode');
    window.objOSGlobal.requestBodyValues.modifier = serviceModifier;
    if(pageType =="servicePage"){
        if(webLocationId){
            if(postalCode){
                $('.scheduler-modal .modal-content').removeClass('banner-wizard');
                $('.tab-pane.fieldset').removeClass('active');
                window.objOSGlobal.requestBodyValues.WebLocationId = webLocationId; //weblocationId;
                window.objOSGlobal.requestBodyValues.serviceSkuId = serviceId;
                window.objOSGlobal.requestBodyValues.PostalCode = postalCode; //zipCode;
                window.objOSGlobal.requestBodyValues.WebLocationId =webLocationId;
                if(!window.objOSGlobal.requestBodyValues.IsLeadOnly){
                    schedulerState.numberOfSlot = 1;
                    refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
                    $('.progress-bar').css({ width:'3.2%' });
                    $('.calendar-screen').addClass('active');
                 }else{
                    $('.nav-wizard.progressbar').addClass('hide-schedule');
                    $('.contact-information-screen').addClass('active');
                    $('.progress-bar').css({ width:'3.2%' });
                 }
            }else{
                $('.zip-entry-screen').addClass('active');
            }
        }else{
            $('.zip-entry-screen').addClass('active');
        }
    }
    else if(pageType =="categoryPage"){
        if(webLocationId){
            if(postalCode){
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
                $('.zip-entry-screen').removeClass('active');
                $('.service-list-screen').addClass('active');
                var serviceType = getServiceTypeString(serviceModifier);
                var conceptId = $('#conceptId').val();
                    getServicesForCategory(postalCode,conceptId,serviceType,serviceId,function(result:any){
                      categoryResults(result,postalCode);
                    },function(data:any, status:any, error:any){
                        if(data.responseJSON.Message.includes("Can not find the location with")){
                            $(".tab-pane.fieldset").removeClass("active");
                            $(".no-service-screen").addClass("active");
                            $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${postalCode}</span>`);
                        }
                    });
            }else{
                $('.zip-entry-screen').addClass('active');
            }
        }else{
            $('.zip-entry-screen').addClass('active');
        }
    }else{
        $('.zip-entry-screen').addClass('active');
        $('#js-schedulerDialog').modal('show');
    }
}