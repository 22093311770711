import { getAllBlogs } from "./getAllBlogs";
//import { envConfig } from "../envConfig";

/**
 * Build Search G2 Query Params
 */

export function searchG2QueryParams(key: any, value: any)
{
    let domainName = window.location.origin.toLowerCase();   
    let linkHref = window.location.href.toLowerCase();
    let domainType = window.DEFINE_VALUE.Domain_Type;
    if (domainType != "digital")
    {
    var g2Collection = "https://searchg2.crownpeak.net/"+window.DEFINE_VALUE.Search_G2_URL+"/select/";
    var langauge = window.location.href.split('/')[3] === "ca" ? "ca" : "us";
    let brandQuery = "";
    const localDBA = window.location.href.split('/')[3] === "ca" ? linkHref.split("/")[6] : linkHref.split("/")[5];
    const brandName = window.location.href.split('/')[3] === "ca" ? linkHref.split("/")[4] : linkHref.split("/")[3];
   
    var localCount = window.location.href.split('/')[3] === "ca" ? 7 : 6;
    if (linkHref.split("/")[localCount] != undefined) {
        if (linkHref.split("/")[localCount].indexOf("expert-tips") != -1) {
           brandQuery = `&fq=custom_s_local_dba:(${​localDBA})`;
        }
    }
    else{        
        brandQuery = `&fq=custom_s_local_dba:none`;
    }
    var g2Brand = `?q=custom_s_brand:${brandName}${brandQuery}`;
    var start = 0;
    //var prevArticleIndex = parseInt(localStorage.getItem('blog_article_prev_index') || 0);
    //var totalArticleCounts = parseInt(localStorage.getItem('blog_total_count') || 0);
 
    var otherParams = `&start=${start}&rows=1000&wt=json&indent=true&sort=custom_dt_created desc`;
    var filteredParams = "&fl=custom_s_heading,custom_s_article_summary_Image_url,custom_t_content_summary_Image_url_alt,custom_t_content_summary_Desc,custom_s_local_url,custom_s_context_url";
    var g2CollectionURL;
    if (key && value)
    {
        g2CollectionURL = g2Collection + g2Brand + '&fq=' + key + ':' + value + '&fq=custom_s_status:Active&fq=custom_s_type:blog&fq=custom_s_country:'+langauge+'' + otherParams + filteredParams;
     
    } else
    {
        g2CollectionURL = g2Collection + g2Brand + '&fq=*&fq=custom_s_status:Active&fq=custom_s_type:blog&fq=custom_s_country:'+langauge+'' + otherParams + filteredParams;
      //  console.log(g2CollectionURL + "-new else search-collection");
    }
    }
    else{
        let langauge = "";
        let brandQuery = "";
        let localDBA = "";
        let brandName = ""; 
        var g2Collection = "https://searchg2.crownpeak.net/"+window.DEFINE_VALUE.Search_G2_URL+"/select/";  
        if(window.g2Data){ 
            langauge = window.g2Data.responseHeader.params.fq[1].replace("custom_s_country:","");
        
            localDBA = window.g2Data.responseHeader.params.fq[0].toLowerCase().replace("custom_s_local_dba:","").replace("(","").replace(" or none)","");
            brandName = window.g2Data.responseHeader.params.q.replace("custom_s_brand:","");
        }
        else{
            langauge = $("#countryCode").length ? $("#countryCode").val().toString() : (domainName.indexOf(".ca") == -1 ? (domainName.indexOf("-ca") != -1 ? "ca" : "us") : "ca");
            localDBA = $("#weblocationId").length ? linkHref.split('/')[3] : "none";
            // var brands = window.objOSGlobal.requestBodyValues.Country == 'CA' ? "https://www.neighborly.com/ca/en-ca/_assets/brands.json" : "https://www.neighborly.com/us/en-us/_assets/brands.json";
        
            // $.getJSON(brands, function (result:any) { 
            //     if (result.length > 0) {
            //         result.map((ele: any)=>{
            //             if(ele.code == $("#conceptCode").val()){
            //                 brandName = ele.name.toLowerCase().replace(" ", "-");
            //             }
            //         });
            //     }
            // });
            brandName = $("#brandName").length ? $("#brandName").val().toString() : "";
        }
        brandQuery = `&fq=custom_s_local_dba:(${​localDBA})`;
        var g2Brand = `?q=custom_s_brand:${brandName}${brandQuery}`;
        var start = 0;
        //var prevArticleIndex = parseInt(localStorage.getItem('blog_article_prev_index') || 0);
        //var totalArticleCounts = parseInt(localStorage.getItem('blog_total_count') || 0);
     
        var otherParams = `&start=${start}&rows=1000&wt=json&indent=true&sort=custom_dt_created desc`;
        var filteredParams = "&fl=custom_s_heading,custom_s_article_summary_Image_url,custom_t_content_summary_Image_url_alt,custom_t_content_summary_Desc,custom_s_local_url,custom_s_context_url";
        var g2CollectionURL;

        if (key && value)
        {
            g2CollectionURL = g2Collection + g2Brand + '&fq=' + key + ':' + value + '&fq=custom_s_status:Active&fq=custom_s_type:blog&fq=custom_s_country:'+langauge+'' + otherParams + filteredParams;
         
        } else
        {
            g2CollectionURL = g2Collection + g2Brand + '&fq=*&fq=custom_s_status:Active&fq=custom_s_type:blog&fq=custom_s_country:'+langauge+'' +  otherParams + filteredParams;
          //  console.log(g2CollectionURL + "-new else search-collection");
        }
    }
    // Get All Blogs
    getAllBlogs(g2CollectionURL);
}