import { paginationServiceSearch } from "./search-service-pagination";

export function searchServiceListGenerator(filterArray: any,namecheck:any)
{
    let searchRowList: any = [];
    $('.service-search-result-display').empty();
    $('#mrh_localservicemessage_electric').addClass('d-none');
    $('#mrh_search_pagination').addClass('d-none');
    for (let i = 0; i < filterArray.length; i++)
    {
        searchRowList.push(serviceSearchRowGenerator(filterArray[i]));
    }
    let valid = checkServiceTerm(namecheck);
    if(valid){
        if(namecheck.includes('elect')){
            $('#mrh_localservicemessage_electric').removeClass('d-none');
        }else{
            $('#mrh_localservicemessage_plumbing').removeClass('d-none');
        }
    }else{
        $('#mrh_localservicemessage').addClass('d-none');
    }
    $('#mrh_resultcount').text('('+filterArray?.length+')');
    $('#mrh_searchterm').text('"'+namecheck+'"');

     if(searchRowList?.length>0){
        $('.service-search-result-display').append(searchRowList);
        $('#mrh_search_pagination').removeClass('d-none');
     }else{
        $('.service-search-result-display').html('<span class="services-offer text-center d-block"> Your search produced (0) results. Please refine your search terms or contact us about your service needs. </span>');
        $('#mrh_search_pagination').addClass('d-none');
     }
    
    paginationServiceSearch();

}


function serviceSearchRowGenerator(searchData: any)
{
    if(searchData.thumbnail !=''){
        return (
            `<div class="row row-list">
                <div class="col-md-7">
                    <h2 class="primary-title primary-theme-clr text-xmd"> ${searchData.name}</h2>
                    <div class="search-results-image">
                        <img class="img-fluid w-100" src="${searchData.thumbnail}" alt="${searchData?.name}">
                    </div>
                    <p class="description">${searchData.description}</p>
                    ${searchData.description != "" ? `<a href="${searchData.link}" class="link-text text-sm primary-theme-clr">Read More</a>`:''}
                </div>
                <div class="col-md-5">
                    <div class="search-results-image">
                        <img class="img-fluid w-100" src="${searchData.thumbnail}" alt="${searchData?.name}">
                    </div>
                </div>
          </div>`
        )
    }else{
        return (
            `<div class="row row-list">
                   <div class="col-md-12">
                      <h2 class="primary-title primary-theme-clr text-xmd"> ${searchData.name}</h2>
                      <p class="description">${searchData.description}</p>
                     ${searchData.description != "" ? `<a href="${searchData.link}" class="link-text text-sm primary-theme-clr">Read More</a>`:''}
                    </div>
             </div>`
        )
    }
}

function checkServiceTerm(term:any){
    let serviceArray = ["elect","plum"];
    return serviceArray.some((ser)=> term.includes(ser));
}