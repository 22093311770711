import { $ } from '../third-party-imports';
export function ServiceModifier_Redirection()
{
if( !$(".main-nav .nav-links .hamburger-menu").is(":visible"))
{
	//alert(' not mobile');
$(".main-nav .nav-links .navbar-nav .js-dropdown a[role='button'][data-toggle='dropdown']").click(function(c) {c.preventDefault();
	//$(document).on("click", ".main-nav .nav-links .navbar-nav .js-dropdown a[role='button'][data-toggle='dropdown']", function(c) {
		var mainAnchor = $(this);//.find("a[role='button'][data-toggle='dropdown']");
		if(typeof(mainAnchor) !== 'undefined' && typeof($(mainAnchor).attr("href")) !== 'undefined' && $(mainAnchor).attr("href") !== 'javascript:void(0)' )
		{
			var curlink = $(mainAnchor).attr("href");
			//alert(curlink);
			var curlink_target = $(mainAnchor).attr("target");
			if(typeof(curlink_target) !== 'undefined' && curlink_target === '_blank')
			{
				$("<a href='"+ curlink +"' target='blank'></a>")[0].click()	
			}
			else
			{
				window.location.assign(curlink);
			}
		}
	});
}
	//$('.js-dropdown').off('click').on('click',function (e) {
	//e.preventDefault();
	//var h = $(this).find('a').attr('href');
	//window.location.assign(h)
	//});
}