import { $ } from "../third-party-imports"
import { profileVars } from ".";
import { apiConfig } from "../config";
import { callAddEDitApi } from "./callAddEDitApi";
import { updateProfile } from "./updateProfile";


export function addProfilePoneNumber()
{
    var primaryPhoneCheckBox = function ()
    {
        if ($('#customCheck-nb10').is(':checked')) { return 1; }
        return 0;
    };

    var optInVal = function ()
    {
        if ($('#customCheck-nb11').is(':checked')) { return 1; }
        return 0;
    };
    var contactphone = $('#phone1').val();
    contactphone = contactphone.toString().replace(/[- )(]/g, '');

    var payload: any = {
        "ContactTypeId": 1,
        "ContactDetail": contactphone,
        "ContactVerificationStatusId": 2,
        "PrimaryPhoneID": primaryPhoneCheckBox(),
        "isOptin": optInVal()
    };
    let type: string;
    if (profileVars.editId)
    {
        payload.neighborlyAccountContactId = profileVars.editId;
        type = "PUT";
    }
    else
    {
        payload.NeighborlyAccountId = 0; //profileVars.neighborlyAccountId;
        type = "POST";
    }

    callAddEDitApi(payload, apiConfig.editPhoneApi, function (data: any, status: any)
    {
        $("#editPhone").removeClass('active');
        $('.form_container_wrapperBox:first-child').show();
        updateProfile();
    }, type);
}
